import PropTypes from "prop-types";
import useAuth from "../../context/useAuth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import AccountIcon from "@mui/icons-material/AccountCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LockReset from "@mui/icons-material/LockReset";
import ProfileIcon from "@mui/icons-material/Face";
import TrainedIcon from "@mui/icons-material/Verified";
// material
import { styled, useTheme } from "@mui/material/styles";
import {
  IconButton,
  Stack,
  BottomNavigationAction,
  Tooltip,
  Divider,
  Typography,
  Box,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
import SecondaryButton from "../../components/buttons/SecondaryButton";
//  API
import { getCurrentUserApi } from "../../api/auth";
import { getAttachmentsApi } from "../../api/attachments";

// ----------------------------------------------------------------------

Profile.propTypes = {
  isMobile: PropTypes.bool,
};

const IconButtonStyled = styled(IconButton)({
  height: "50px",
  width: "50px",
  borderRadius: "10px",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.11)",
});

export default function Profile({ isMobile = false }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [trained, setTrained] = useState(null);
  const { logout, profileUpdate } = useAuth();

  const iconStyled = {
    color: theme.palette.primary.black,
    width: 20,
    height: 20,
  };

  const settingsButtonStyled = {
    color: theme.palette.primary.white,
    width: 16,
    height: 16,
  };

  const trainedTickStyle = {
    fill: theme.palette.primary.green,
    cursor: "pointer",
    fontSize: `${14 / 15}rem`,
  };

  useEffect(() => {
    const getProfileDetails = async () => {
      try {
        setLoading(true);
        const data = await getCurrentUserApi();
        setName(`${data.firstName} ${data.lastName}`);
        setEmail(data.emailAddress);
        setTrained(data.trainingCompletedAt);
        if (data.attachment) {
          const attachmentInfo = await getAttachmentsApi([data.attachment.id]);
          setProfilePicture(attachmentInfo[0].url);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("Unable to get profile details");
      }
    };
    getProfileDetails();
  }, [profileUpdate]);

  return (
    <>
      {!isMobile ? (
        <Tooltip title={t("navBar.profile.title")}>
          <IconButtonStyled
            ref={anchorRef}
            size="large"
            color={open ? "primary" : "default"}
            onClick={() => setOpen(true)}
          >
            <AccountIcon style={iconStyled} />
          </IconButtonStyled>
        </Tooltip>
      ) : (
        <BottomNavigationAction
          onClick={() => setOpen(true)}
          icon={<AccountIcon />}
        />
      )}
      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{
          width: isMobile ? "99vw" : 300,
          pt: "10px",
          pb: "10px",
          pl: "14px",
          pr: "14px",
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="h8" textAlign="center">
                  {name}
                </Typography>
                {trained && (
                  <Tooltip title={t("profile.trained")}>
                    <TrainedIcon style={trainedTickStyle} />
                  </Tooltip>
                )}
              </Stack>
              <Typography variant="caption">{email}</Typography>
            </Stack>
            <Box
              component="img"
              alt="Profile picture"
              src={profilePicture || "/static/profile_icon.svg"}
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            />
          </Stack>
          <Divider />
          <SecondaryButton
            compact
            disabled={loading}
            label={t("profile.manage")}
            icon={<ProfileIcon style={settingsButtonStyled} />}
            onClick={() => {
              setOpen(false);
              navigate("/user/profile");
            }}
          />
          {!localStorage.getItem("sso") && (
            <SecondaryButton
              compact
              disabled={loading}
              label={t("navBar.profile.changePassword")}
              icon={<LockReset style={settingsButtonStyled} />}
              onClick={() => {
                setOpen(false);
                navigate("/user/changePassword");
              }}
            />
          )}
          <Divider />
          <SecondaryButton
            compact
            isRed
            disabled={loading}
            label={t("navBar.profile.logout")}
            icon={<PowerSettingsNewIcon style={settingsButtonStyled} />}
            onClick={() => logout()}
          />
        </Stack>
      </MenuPopover>
    </>
  );
}
