import apiHelper from "./apiHelper";

export const trendsDirectorateFiltersApi = (type) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/directorateTrendsFilters`, {
        params: {
          type,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const trendsCompanyFiltersApi = (type, directorateId, companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/companyTrendsFilters`, {
        params: {
          type,
          directorateId,
          companyId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const smartTrendsBubbleApi = (
  type,
  directorateId,
  companyId,
  disciplineId,
  locationId,
  startDate,
  endDate
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/trendsBubble`, {
        params: {
          type,
          directorateId,
          companyId,
          disciplineId,
          locationId,
          ...(startDate ? { startDate } : {}),
          ...(endDate ? { endDate } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const smartTrendsTableDirectoratesApi = ({
  pageNo,
  pageSize,
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  category,
  search,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/smartTrendsTableDirectorates`, {
        params: {
          pageSize,
          pageNo,
          directorateId,
          locationId,
          disciplineId,
          companyId,
          obsType,
          ...(startDate ? { startDate } : {}),
          ...(endDate ? { endDate } : {}),
          ...(category ? { category } : {}),
          ...(search ? { search } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTrendsAlertsApi = ({
  companyId,
  directorateId,
  locationId,
  disciplineId,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/smartAlerts`, {
        params: {
          type: "TRENDS",
          companyId,
          locationId,
          directorateId,
          disciplineId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
