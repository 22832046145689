import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { displayToast } from "../../../utils/general";
import { Grid, Box } from "@mui/material";
import TextInput from "../../../components/inputs/TextInput";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SaveIcon from "@mui/icons-material/Save";
import PageLoader from "../../../components/PageLoader";
//  API
import {
  getKpiWeightsApi,
  updateKpiWeightsApi,
} from "../../../api/kpiConfigurations";

const loaderStyling = {
  height: "50vh",
  display: "flex",
  alignItems: "center",
};

function ManageWeightage(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [weights, setWeights] = useState([]);

  const getWeightage = async () => {
    try {
      setLoading(true);
      const response = await getKpiWeightsApi(props.companyId);
      const tempWeights = Object.keys(response).map((key) => {
        return {
          kpiType: response[key].kpiType,
          kpiConfigId: response[key].id,
          weight: response[key].paramWeight,
          name: response[key].name,
        };
      });
      setWeights(tempWeights);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleFieldChange = (val, index) => {
    const tempWeights = [...weights];
    tempWeights[index].weight = val;
    setWeights(tempWeights);
  };

  const renderFields = () => {
    return weights.map((field, index) => {
      return (
        <Grid item xs={12} sm={6} key={field.kpiType}>
          <TextInput
            label={t(`kpi.weightManagement.${field.kpiType}`)}
            placeholder={t("general.percentage")}
            type="number"
            value={field.weight}
            disabled={loading}
            handler={(e) => {
              let temp = parseFloat(e.target.value);
              if (temp >= 100) temp = 100;
              if (temp < 0) temp = 0;
              handleFieldChange(temp ?? 0, index);
            }}
          />
        </Grid>
      );
    });
  };

  const submitWeightage = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("kpi.weightManagement.saving")
    );
    try {
      weights.forEach((w, index) => {
        if (w.weight) {
          weights[index].weight = parseFloat(w.weight);
        } else {
          weights[index].weight = 0;
        }
      });
      await updateKpiWeightsApi({
        companyId: props.companyId,
        weights,
      });
      displayToast(
        setLoading,
        "success",
        t("kpi.weightManagement.success"),
        activeToast
      );
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  useEffect(() => {
    if (props.companyId) {
      getWeightage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.companyId]);

  return (
    <Box sx={{ mt: 1 }}>
      {weights.length === 0 ? (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      ) : (
        <Grid container spacing={2}>
          {renderFields()}
          <Grid item xs={12}>
            <PrimaryButton
              icon={<SaveIcon />}
              disabled={loading}
              alignment="left"
              label={t("general.saveChanges")}
              onClick={submitWeightage}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default ManageWeightage;
