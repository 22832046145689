import apiHelper from "./apiHelper";

export const getQualityDirectorateFiltersApi = (type) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/directorateQualityFilters`, {
        params: {
          type,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityCompanyFiltersApi = (type, directorateId, companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/companyQualityFilters`, {
        params: {
          type,
          directorateId,
          companyId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityHeatmapDirectoratesApi = (
  directorateId,
  companyId,
  locationId,
  disciplineId,
  obsType
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityHeatmapDirectorates`, {
        params: {
          directorateId,
          companyId,
          locationId,
          disciplineId,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityHeatmapCompaniesApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityHeatmapCompanies`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityBreakdownDirectoratesApi = (
  directorateId,
  companyId,
  locationId,
  disciplineId,
  obsType
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityBreakdownDirectorates`, {
        params: {
          directorateId,
          companyId,
          locationId,
          disciplineId,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityBreakdownCompaniesApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityBreakdownCompanies`, {
        params: { directorateId, locationId, disciplineId, companyId, obsType },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityDisciplineRelevanceBehaviorApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityHeatmapDisciplineRelevanceBehavior`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityLocationRelevanceBehaviorApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityHeatmapLocationRelevanceBehavior`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityDisciplineRelevanceRootCauseApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityHeatmapDisciplineRelevanceRootCause`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityLocationRelevanceRootCauseApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityHeatmapLocationRelevanceRootCause`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityOverviewApi = ({
  directorateId,
  companyId,
  locationId,
  disciplineId,
  startDate,
  endDate,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityOverview`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getRelevanceTableApi = ({
  directorateId,
  companyId,
  locationId,
  disciplineId,
  startDate,
  endDate,
  type,
  pageNo,
  pageSize,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/qualityRelevanceTable`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          type,
          pageNo,
          pageSize,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getIncidentHeatmapApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  obsType,
  type,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/incidentPreventionHeatmap`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          type,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getIncidentTableApi = ({
  directorateId,
  companyId,
  locationId,
  disciplineId,
  startDate,
  endDate,
  type,
  pageNo,
  pageSize,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/incidentPreventionTable`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          type,
          pageNo,
          pageSize,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getIncidentBreakdownApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/incidentPreventionBreakdown`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLowEffortHeatmapApi = ({
  directorateId,
  locationId,
  disciplineId,
  companyId,
  startDate,
  endDate,
  type,
  obsType,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/lowEffortHeatmap`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          type,
          obsType,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLowEffortTableApi = ({
  directorateId,
  companyId,
  locationId,
  disciplineId,
  startDate,
  endDate,
  type,
  pageNo,
  pageSize,
  obsType,
  reasonTag,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/lowEffortTable`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          startDate,
          endDate,
          type,
          pageNo,
          pageSize,
          obsType,
          ...(reasonTag && { reasonTag }),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityAlertsApi = ({
  directorateId,
  companyId,
  locationId,
  disciplineId,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/smartAlerts`, {
        params: {
          type: "QUALITY",
          directorateId,
          companyId,
          locationId,
          disciplineId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
