import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
//  Components
import { Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PageLoader from "../../../components/PageLoader";
import InfoBox from "../../../components/InfoBox";
import ContextMenu from "../../../components/ContextMenu";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
//  API
import { getVisits, deleteVisit } from "../../../api/implementation";
import { displayToast } from "../../../utils/general";
import ScheduleVisit from "./ScheduleVisit";

const localizer = momentLocalizer(moment);

export const CalenderVisit = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { planId, activityId, companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDetails, setDeleteDetails] = useState({
    open: false,
    id: null,
  });

  const contextOptions = [
    {
      label: t("general.edit"),
      onClick: () => {
        if (selectedEvent) {
          navigate(
            `/implementations/visit-meeting/${props.type}/${planId}/${companyId}/${selectedEvent.id}`
          );
        }
      },
    },
    {
      label: t("general.delete"),
      onClick: () => {
        setAnchorEl(null);
        setDeleteDetails({ open: true, id: selectedEvent.id });
      },
    },
  ];

  const handleEventSelect = (event, thing) => {
    setSelectedEvent(event);
  };

  const handleSelect = ({ start, end }) => {
    setStartDate(moment(start).startOf());
    setEndDate(moment(end).startOf().subtract(1, "days"));
    setDialogOpen(true);
  };

  const getAllVisits = async () => {
    try {
      setLoading(true);
      let query = `?activityId=${activityId}&type=${props.type}`;
      let tempPlans = await getVisits(query);
      let tempEvents = tempPlans?.result?.map((plan, index) => {
        return {
          id: plan.id,
          allDay: true,
          title:
            props?.type === "Leadership Visibility"
              ? t("implementations.schedule.visit")
              : t("implementations.schedule.meeting"),
          end: moment(moment(plan.startDate).endOf("day").toISOString())._d,
          start: moment(moment(plan.startDate).startOf("day").toISOString())._d,
        };
      });
      setLoading(false);
      setEvents(tempEvents);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.calenderError"),
        "errorCalenderComponent"
      );
    }
  };

  const handleSuccess = async () => {
    setDialogOpen(false);
    getAllVisits();
  };

  const renderDeleteModal = () => (
    <InfoBox
      disabled={loading}
      minWidth="500px"
      open={deleteDetails.open}
      title={`${t(`remove`)} ${
        props?.type === "Leadership Visibility"
          ? t("implementations.schedule.leadership")
          : t("implementations.schedule.steeringCommittee")
      }
      `}
      content={
        <>
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.red, marginBottom: 30 }}
          >
            {props?.type === "Leadership Visibility"
              ? t(`implementations.schedule.deleteVisit`)
              : t(`implementations.schedule.deleteConfirm`)}
          </Typography>
          <Stack direction="row" spacing={1}>
            <SecondaryButton isRed label="Delete" onClick={submitDeleteVisit} />
            <SecondaryButton
              label="Cancel"
              onClick={() => setDeleteDetails({ open: false, id: null })}
            />
          </Stack>
        </>
      }
      handleClose={() => setDeleteDetails({ open: false, id: null })}
    />
  );

  const submitDeleteVisit = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      props?.type === "Leadership Visibility"
        ? t("implementations.schedule.deleteVisitLoading")
        : t("implementations.schedule.deleteLoading")
    );
    try {
      await deleteVisit(deleteDetails.id);
      setDeleteDetails({ open: false, id: null });
      getAllVisits();
      displayToast(
        setLoading,
        "success",
        props?.type === "Leadership Visibility"
          ? t("implementations.schedule.deleteVisitSuccess")
          : t("implementations.schedule.deleteSuccess"),
        activeToast
      );
    } catch (err) {
      displayToast(
        setLoading,
        "error",
        props?.type === "Leadership Visibility"
          ? t("implementations.schedule.deleteVisitFail")
          : t("implementations.schedule.deleteFail"),
        activeToast
      );
    }
  };

  useEffect(() => {
    getAllVisits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <>
      <Calendar
        selectable
        views={["month"]}
        events={events}
        localizer={localizer}
        onSelectEvent={(event) => handleEventSelect(event)}
        onSelectSlot={handleSelect}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        elementProps={{
          onClick: (e) => {
            if (
              e.target.getAttribute("title") === "Meeting" ||
              e.target.getAttribute("title") === "Visit"
            ) {
              setAnchorEl(e.target);
            } else {
              setSelectedEvent(null);
            }
          },
        }}
        popup
      />
      <InfoBox
        overflowV
        handleClose={() => setDialogOpen(false)}
        disabled={false}
        open={dialogOpen}
        title={
          props?.type === "Leadership Visibility"
            ? t("implementations.schedule.schedule")
            : t("implementations.schedule.scheduleMeeting")
        }
        content={
          <ScheduleVisit
            startDate={startDate}
            type={props?.type}
            onSuccess={() => {
              handleSuccess();
            }}
          />
        }
        // minWidth="1000px"
      />
      <ContextMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        menu={contextOptions}
      />
      {renderDeleteModal()}
    </>
  ) : (
    <div style={{ height: "60vh", display: "flex", alignItems: "center" }}>
      <PageLoader />
    </div>
  );
};
