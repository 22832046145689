import apiHelper from "./apiHelper";

export const getDirectorateFiltersApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/fetchDirectorateFilters`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getContractorFiltersApi = (directorateIds) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/fetchCompanyFilters`, {
        params: {
          directorateIds,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
