import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { displayToast } from "../utils/general";
import useAuth from "../context/useAuth";
//  MUI
import {
  Box,
  Container,
  Grid,
  Typography,
  Stack,
  Card,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CameraIcon from "@mui/icons-material/CameraAlt";
import TrainedIcon from "@mui/icons-material/Verified";
//  Components
import Page from "../components/Page";
import PageLoader from "../components/PageLoader";
import RoundedButton from "../components/buttons/RoundedButton";
import TextInput from "../components/inputs/TextInput";
import SelectInput from "../components/inputs/SelectInput";
import PrimaryButton from "../components/buttons/PrimaryButton";
//  API
import { getCurrentUserApi } from "../api/auth";
import { getAttachmentsApi } from "../api/attachments";
import { getCompaniesUsingLocationApi } from "../api/company";
import { getLocationsForObsApi } from "../api/location";
import { getUnitsTiedApi } from "../api/unit";
import {
  updateUserProfileApi,
  updateUserProfilePictureApi,
} from "../api/users";

export default function UserProfile() {
  const { t } = useTranslation();
  const { profileUpdated } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [attachmentId, setAttachmentId] = useState(null);
  const [previewLink, setPreviewLink] = useState();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [location, setLocation] = useState("");
  const [company, setCompany] = useState("");
  const [directorate, setDirectorate] = useState("");
  const [unit, setUnit] = useState("");

  //  Dropdown options
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [unitDropdown, setUnitDropdown] = useState([]);

  const trainedTickStyle = {
    fill: theme.palette.primary.green,
    cursor: "pointer",
  };

  const renderUserDetails = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t("profile.manage")}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label={t("profile.firstName")}
          placeholder={t("profile.firstNamePlaceholder")}
          value={firstName}
          handler={(e) => setFirstName(e.target.value)}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label={t("profile.lastName")}
          placeholder={t("profile.lastNamePlaceholder")}
          value={lastName}
          handler={(e) => setLastName(e.target.value)}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label={t("profile.companyNumber")}
          placeholder={t("profile.companyNumberPlaceholder")}
          value={companyNumber}
          handler={(e) => setCompanyNumber(e.target.value)}
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label={t("profile.phoneNumber")}
          placeholder={t("profile.phoneNumberPlaceholder")}
          value={phoneNumber}
          handler={(e) => setPhoneNumber(e.target.value)}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{t("profile.helpText")}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <SelectInput
          label={t("profile.location")}
          value={location}
          disabled
          options={locationsDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <SelectInput
          label={t("profile.company")}
          value={company}
          disabled
          options={companyDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <SelectInput
          label={t("profile.directorate")}
          value={directorate}
          disabled
          options={directorateDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <SelectInput
          optional
          label={t("profile.unit")}
          value={unit}
          disabled
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "70px" }}>
        <PrimaryButton
          label={t("profile.details")}
          alignment="left"
          onClick={submitUpdateProfile}
          disabled={loading}
        />
      </Grid>
    </Grid>
  );

  // //  Return location options array
  // const getLocations = async () => {
  //   setLoading(true);
  //   try {
  //     const locationData = await getLocationsForObsApi();
  //     const locationOptions = locationData.map((c) => {
  //       return {
  //         label: c.name,
  //         value: c.id,
  //       };
  //     });

  //     return locationOptions;
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //     return [];
  //   }
  // };

  // //  Return companies options array based on locationId
  // const getCompanies = async (locationId) => {
  //   try {
  //     setLoading(true);
  //     const companyData = await getCompaniesUsingLocationApi(locationId);

  //     const companyOptions = companyData.map((c) => {
  //       return {
  //         label: c.name,
  //         value: c.id,
  //         directorates: c.directorates,
  //       };
  //     });

  //     setLoading(false);
  //     return companyOptions;
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //     return [];
  //   }
  // };

  // //  Return directorates options array based on companyDropdown and searched using companyId
  // const getDirectorates = async (companyId, companyList) => {
  //   setLoading(true);
  //   let finalDArray = [];
  //   if (companyId) {
  //     const targetCompany = companyList.find((c) => c.value === companyId);
  //     if (targetCompany && targetCompany.directorates) {
  //       finalDArray = targetCompany.directorates.map((d) => {
  //         return {
  //           label: d.name,
  //           value: d.id,
  //         };
  //       });
  //     }
  //   }

  //   setLoading(false);
  //   return finalDArray;
  // };

  // // Return units options array based on locationId and companyId
  // const getUnits = async (directorateId, locationId, companyId) => {
  //   try {
  //     setLoading(true);
  //     const unitData = await getUnitsTiedApi(
  //       directorateId,
  //       locationId,
  //       companyId
  //     );

  //     const unitOptions = unitData.map((u) => {
  //       return {
  //         label: u.name,
  //         value: u.id,
  //       };
  //     });

  //     setLoading(false);
  //     return unitOptions;
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //     return [];
  //   }
  // };

  const getCurrentUser = async () => {
    try {
      setLoading(true);
      const data = await getCurrentUserApi();

      setFirstName(data.firstName);
      setLastName(data.lastName);
      setPhoneNumber(data.phoneNumber);
      setCompanyNumber(data.companyNumber);

      if (data.attachment) {
        const attachmentInfo = await getAttachmentsApi([data.attachment.id]);
        setProfilePicture(attachmentInfo[0].url);
        setAttachmentId(data.attachment.id);
      }

      if (data.Location) {
        setLocationsDropdown([
          { label: data.Location.name, value: data.Location.id },
        ]);
        setLocation(data.Location.id);
      }

      if (data.company) {
        setCompanyDropdown([
          { label: data.company.name, value: data.company.id },
        ]);
        setCompany(data.company.id);
      }

      if (data.Directorate) {
        setDirectorateDropdown([
          { label: data.Directorate.name, value: data.Directorate.id },
        ]);
        setDirectorate(data.Directorate.id);
      }

      if (data.Unit) {
        setUnitDropdown([{ label: data.Unit.name, value: data.Unit.id }]);
        setUnit(data.Unit.id);
      }

      setUserDetails(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // const handleLocationSelect = async (e) => {
  //   try {
  //     setLoading(true);
  //     //  Reset company, directorate, units and disciplines
  //     setDirectorateDropdown([]);
  //     setUnitDropdown([]);

  //     //  Get new companies, if there is just one company, add it as the selected option, get it's directorates
  //     const companyOptions = await getCompanies(e.target.value);
  //     setCompanyDropdown(companyOptions);
  //     let updatedDirectorateId = "";
  //     let updatedCompanyId = "";
  //     let updatedUnitId = "";
  //     if (companyOptions.length === 1) {
  //       updatedCompanyId = companyOptions[0].value;
  //       //  Set the directorates if there's one company and set units too
  //       const updatedDirectorates = await getDirectorates(
  //         companyOptions[0].value,
  //         companyOptions
  //       );
  //       setDirectorateDropdown(updatedDirectorates);

  //       if (updatedDirectorates.length >= 1 && updatedDirectorates[0].value) {
  //         updatedDirectorateId = updatedDirectorates[0].value;
  //         const updatedUnits = await getUnits(
  //           updatedDirectorates[0].value,
  //           e.target.value,
  //           companyOptions[0].value
  //         );
  //         setUnitDropdown(updatedUnits);
  //         if (updatedUnits.length === 1) {
  //           updatedUnitId = updatedUnits[0].value;
  //         }
  //       }
  //     }

  //     setLocation(e.target.value);
  //     setCompany(updatedCompanyId);
  //     setDirectorate(updatedDirectorateId);
  //     setUnit(updatedUnitId);
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     console.log("Error selecting location");
  //   }
  // };

  // const handleCompanySelect = async (e) => {
  //   try {
  //     setLoading(true);
  //     //  Reset directorates, units and disciplines
  //     setDirectorateDropdown([]);
  //     setUnitDropdown([]);

  //     const updatedDirectorates = await getDirectorates(
  //       e.target.value,
  //       companyDropdown
  //     );
  //     setDirectorateDropdown(updatedDirectorates);

  //     let updatedUnitId = "";
  //     if (updatedDirectorates.length >= 1 && updatedDirectorates[0].value) {
  //       const updatedUnits = await getUnits(
  //         updatedDirectorates[0].value,
  //         location,
  //         e.target.value
  //       );
  //       setUnitDropdown(updatedUnits);
  //       if (updatedUnits.length === 1) {
  //         updatedUnitId = updatedUnits[0].value;
  //       }
  //     }

  //     setCompany(e.target.value);
  //     setDirectorate(
  //       updatedDirectorates.length === 1 ? updatedDirectorates[0].value : ""
  //     );
  //     setUnit(updatedUnitId);
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     console.log("Error selecting location");
  //   }
  // };

  // const handleDirectorateSelect = async (e) => {
  //   try {
  //     setLoading(true);
  //     //  Have to get and populate units
  //     let updatedUnitId = "";
  //     const updatedUnits = await getUnits(e.target.value, location, company);
  //     setUnitDropdown(updatedUnits);
  //     if (updatedUnits.length === 1) {
  //       updatedUnitId = updatedUnits[0].value;
  //     }

  //     setDirectorate(e.target.value);
  //     setUnit(updatedUnitId);
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     console.log("Error selecting location");
  //   }
  // };

  const submitUpdateProfile = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("profile.updateLoading")
    );
    try {
      if (!firstName) {
        throw t("profile.firstNameError");
      }

      if (!lastName) {
        throw t("profile.lastNameError");
      }

      if (phoneNumber && phoneNumber.length < 7) {
        throw t("profile.phoneNumberError");
      }

      if (!companyNumber) {
        throw t("profile.companyNumberError");
      }

      const payload = {
        firstName,
        lastName,
        companyNumber,
        phoneNumber,
        locationId: location,
        companyId: company,
        directorateId: directorate,
        unitId: unit,
      };

      await updateUserProfileApi(payload);

      if (selectedImage) {
        await updateUserProfilePictureApi({
          attachments: [selectedImage],
          attachmentId: attachmentId,
        });
      }

      profileUpdated();
      displayToast(
        setLoading,
        "success",
        t("profile.updateSuccess"),
        activeToast
      );
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (!selectedImage) {
      setPreviewLink(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewLink(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  return (
    <Page title={t("profile.title")}>
      <Container maxWidth="xl">
        {userDetails ? (
          <Grid container sx={{ mt: 5 }}>
            {/* Left stack will be image and menu items */}
            <Grid item xs={12} sm={6} md={4}>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="center"
              >
                <Box
                  component="img"
                  alt="Profile picture"
                  src={
                    previewLink || profilePicture || "/static/profile_icon.svg"
                  }
                  sx={{
                    mt: 3,
                    height: "200px",
                    width: "200px",
                    borderRadius: "50%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                  }}
                />

                <Dropzone
                  accept={["image/png", "image/jpeg"]}
                  filesLimit={1}
                  onDrop={(acceptedImage) => {
                    setSelectedImage(acceptedImage[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <RoundedButton
                        disabled={loading}
                        label={t("profile.newPic")}
                        icon={
                          <CameraIcon
                            style={{
                              fill: theme.palette.primary.white,
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        }
                      />
                    </div>
                  )}
                </Dropzone>
              </Stack>
              <Stack justifyContent="center" sx={{ mt: 3, pr: "30px" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h5plain" textAlign="center">
                    {`${userDetails.firstName} ${userDetails.lastName}`}
                  </Typography>
                  {userDetails.trainingCompletedAt && (
                    <Tooltip title={t("profile.trained")}>
                      <TrainedIcon style={trainedTickStyle} />
                    </Tooltip>
                  )}
                </Stack>
                <Typography
                  variant="h7plain"
                  textAlign="center"
                  sx={{ pr: "5px" }}
                >
                  {userDetails.emailAddress}
                </Typography>
              </Stack>
            </Grid>
            {/* Right side will be card with header and input fields underneath */}
            <Grid item xs={12} sm={6} md={8}>
              <Card sx={{ p: 3 }}>{renderUserDetails()}</Card>
            </Grid>
          </Grid>
        ) : (
          <div style={{ height: "80vh" }}>
            <PageLoader />
          </div>
        )}
      </Container>
    </Page>
  );
}
