export const safeUnsafeOptions = [
  {
    label: "Safe",
    value: "Safe",
  },
  {
    label: "Unsafe",
    value: "Unsafe",
  },
];

export const exampleCompanies = [
  {
    name: "AL BARAKA",
    value: 1,
  },
  {
    name: "AL SAJ AL",
    value: 2,
  },
  {
    name: "BA OMAR",
    value: 3,
  },
  {
    name: "CACTUS",
    value: 4,
  },
  {
    name: "GULF",
    value: 5,
  },
  {
    name: "AL GHALBI",
    value: 6,
  },
];

export const barChartOptions = {
  colors: ["#43A1D7"],
  legend: {
    floating: true,
    horizontalAlign: "center",
    itemMargin: {
      vertical: 20,
    },
    show: false,
  },
  dataLabels: {
    enabled: false,
    dropShadow: { enabled: false },
  },
  xaxis: {
    categories: [],
    labels: {
      maxHeight: 1000,
    },
  },
  plotOptions: {
    bar: {
      distributed: true,
      columnWidth: "40%",
    },
  },
};
