import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import HelpText from "./HelpText";

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

export default function HelpIcon({ text = "No text has been provided" }) {
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <HelpText title={text}>
        <IconStyle />
      </HelpText>
    </Box>
  );
}

HelpIcon.propTypes = {
  text: PropTypes.string,
};
