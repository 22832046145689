import { defaultObservationAttr } from "../observation/ListObservations/defaults";

export const addChartDetailsDefaults = {
  open: false,
  chartId: null,
  chartName: "",
};

export const chartOptionDefaults = {
  overview: [
    {
      label: "Total Observation Overview (Heatmap chart)",
      value: "totalObservationsOverview",
    },
    {
      label: "Observation Trends Overview (Heatmap chart)",
      value: "observationTrendsOverview",
    },
  ],
  textAnalysis: [
    {
      label: "Quality Index (Bar chart)",
      value: "qualityIndexBar",
    },
    {
      label: "Word Cloud (Wordcloud chart)",
      value: "wordCloudTextAnalysis",
    },
    {
      label: "Most Common Phrases (Bar chart)",
      value: "mostCommonPhrases",
    },
  ],
  sentiment: [
    {
      label: "Sentiment by Location & Discipline (Heatmap chart)",
      value: "sentimentByLocationAndDiscipline",
    },
    {
      label: "Top Observation Text By Sentiment (Bar chart)",
      value: "topObservationTextBySentiment",
    },
    {
      label: "Top 20 Sentiment by Behaviour (Bar chart)",
      value: "top20SentimentByBehaviour",
    },
  ],
  riskSnapshot: [
    {
      label: "Risk Heatmap (Heatmap chart)",
      value: "riskHeatmap",
    },
    {
      label: "Component Score (Bar chart)",
      value: "componentScore",
    },
    {
      label: "Wordcloud (Wordcloud chart)",
      value: "wordCloudRiskSnapshot",
    },
    {
      label: "Risk by safe score (Bar chart)",
      value: "riskbySafeScore",
    },
  ],
};

export const gridDefaults = {
  isDraggable: false,
  isResizable: false,
  className: "layout",
  breakpoints: {
    // xlg: 1824,
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  },
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  resizeHandles: ["se"],
  autoSize: true,
  // compactType={"horizontal"}
  // preventCollision: true,
  isBounded: true,
  useCSSTransforms: false,
  // rowHeight={350}
};

const today = new Date();
export const filterModalDefaults = {
  open: false,
  tab: "overview",
  overview: {
    observationType: "",
    currentCriticalBehaviours: false,
    discipline: "",
    viewby: "location",
    viewbytrends: "action",
    directorate: "",
  },
  textAnalysis: {
    observationType: "",
    currentCriticalBehaviours: false,
    discipline: "",
    location: "",
    gram: "bigrams",
    viewByQualityIndex: "location",
    directorate: "",
  },
  sentiment: {
    observationType: "",
    currentCriticalBehaviours: false,
    discipline: "",
    location: "",
    directorate: "",
  },
  riskSnapshot: {
    observationType: "",
    discipline: "",
    location: "",
    directorate: "",
  },
};

export const filterParamDefaults = {
  pageSize: 10,
  pageNo: 1,
  searchTerm: "",
  overview: {
    observationType: "ALL",
    currentCriticalBehaviours: false,
    discipline: "",
    viewby: "location",
    viewbytrends: "action",
    directorate: "",
  },
  textAnalysis: {
    observationType: "",
    currentCriticalBehaviours: false,
    discipline: "",
    location: "",
    gram: "bigrams",
    viewByQualityIndex: "location",
    directorate: "",
  },
  sentiment: {
    observationType: "",
    currentCriticalBehaviours: false,
    discipline: "",
    location: "",
    directorate: "",
  },
  riskSnapshot: {
    observationType: "",
    discipline: "",
    location: "",
    directorate: "",
  },
};
