import apiHelper from "./apiHelper";
import { generateFormData } from "../utils/general";

// You can add searching the keyword in emailaddress by adding email=1 as query param.
export const searchUsersApi = (
  searchString,
  email,
  companyId,
  azureUser = false,
  planId
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users/search`, {
        params: {
          azureUser,
          searchString,
          email,
          ...(companyId ? { companyId } : {}),
          ...(planId ? { planId } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getUserApi = (id) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const fetchAllUsersApi = (payload) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users`, {
        params: {
          ...(payload ? { companyId: payload.companyId } : {}),
          ...(payload ? { type: payload.type } : {}),
          ...(payload && payload.pageSize
            ? { pageSize: payload.pageSize }
            : { pageSize: 99999999 }),
          ...(payload && payload.pageNo
            ? { pageNo: payload.pageNo }
            : { pageNo: 1 }),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getUserPreferencesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users/preferences`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response?.data?.errorMessage) {
          reject(err.response.data.errorMessage);
        } else {
          reject("Something went wrong");
        }
      });
  });
};

export const saveUserPreferencesApi = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/users/preferences`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const deleteUserApi = (id, type, planId = null) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .delete(`/api/users/${id}?planId=${planId}`, {
        params: {
          type,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const sendPasswordRecoveryApi = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/users/recoverPassword`, { emailAddress: body })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          reject("azureEmailReset");
        } else {
          reject(err.response.data.errorMessage);
        }
      });
  });
};

export const resetPasswordApi = (details, token) => {
  const url = `/api/users/resetPassword?token=${token}`;
  return new Promise((resolve, reject) => {
    apiHelper
      .post(url, details)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const sendOtpApi = (phoneNumber, token) => {
  const url = `/api/users/sendOtp?token=${token}`;
  return new Promise((resolve, reject) => {
    apiHelper
      .post(url, { phoneNumber: phoneNumber })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateUserProfileApi = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/users/me`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateUserProfilePictureApi = (body) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/users/me/updatePhoto`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const changePasswordApi = (details) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post("/api/users/changePassword", details)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const sendOtpApiToken = (token) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/users/sendOtp?token=${token}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const validateOtpApi = (body, token) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/users/validateOtp?token=${token}`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
export const checkOtpValidtedApi = (token) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users/checkOtpValidated?token=${token}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
