import apiHelper from "./apiHelper";
import fileDownload from "js-file-download";

export const getAttachmentsApi = (attachmentIds) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/attachments`, {
        params: {
          attachmentIds,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const downloadAttachmentApi = (url, filename) => {
  apiHelper
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
};
