import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { Container, Stack, Typography, Grid } from "@mui/material";
import Page from "../components/Page";
import { displayToast } from "../utils/general";
//  components
import TextInput from "../components/inputs/TextInput";
import PrimaryButton from "../components/buttons/PrimaryButton";
import AlertBox from "../components/AlertBox";
import useAuth from "../context/useAuth";
// ----------------------------------------------------------------------
// API
import { changePasswordApi } from "../api/users";

export default function ChangePassword() {
  const { token } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let oldPassword;
  let newPassword;
  let confirmPassword;
  const [details, setDetails] = useState({
    oldPassword,
    newPassword,
    confirmPassword,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFormEntry = (e, type) => {
    switch (type) {
      case "enter":
        if (e.code === "Enter") {
          changePassword(details);
        }
        break;

      case "oldPassword":
        setDetails({ ...details, oldPassword: e.target.value });
        break;

      case "newPassword":
        setDetails({ ...details, newPassword: e.target.value });
        break;

      case "confirmPassword":
        setDetails({ ...details, confirmPassword: e.target.value });
        break;

      default:
        console.log("Please enter a valid password.");
    }
  };

  const changePassword = async (formDetails) => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("resetPassword.onChangeLoading")
    );
    try {
      if (!formDetails.oldPassword) throw t("resetPassword.oldRequired");
      if (!formDetails.confirmPassword)
        throw t("resetPassword.confirmRequired");
      await changePasswordApi(formDetails);
      displayToast(
        setLoading,
        "success",
        t("resetPassword.onChangeSuccess"),
        activeToast
      );
      navigate("/login");
    } catch (err) {
      displayToast(
        setLoading,
        "error",
        err.errors ? err.errors[0] : err,
        activeToast
      );
      if (err.errors) {
        setError(err.errors[0]);
      } else {
        setError(err);
      }
    }
  };

  return (
    <Page title={t("resetPassword.change")}>
      <Container maxWidth="xl">
        <Grid
          item
          xs={6}
          justifyContent="center"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <Stack sx={{ mb: 10 }}>
            <Typography variant="h4" gutterBottom>
              {t("resetPassword.change")}
            </Typography>
          </Stack>
          <Stack spacing={3}>
            <AlertBox
              open={error ? true : false}
              type="error"
              message={error}
            />
            <TextInput
              label={t(`resetPassword.oldPassword`)}
              type="password"
              placeholder={t(`resetPassword.oldPasswordPlaceholder`)}
              value={details.oldPassword}
              disabled={loading}
              handler={(e) => handleFormEntry(e, "oldPassword")}
              keyPressHandler={(e) => handleFormEntry(e, "enter")}
            />
            <TextInput
              label={t(`resetPassword.newPassword`)}
              type="password"
              placeholder={t(`resetPassword.newPasswordPlaceholder`)}
              value={details.newPassword}
              disabled={loading}
              handler={(e) => handleFormEntry(e, "newPassword")}
              keyPressHandler={(e) => handleFormEntry(e, "enter")}
            />
            <TextInput
              label={t(`resetPassword.confirmPassword`)}
              type="password"
              placeholder={t(`resetPassword.confirmPasswordPlaceholder`)}
              value={details.confirmPassword}
              disabled={loading}
              handler={(e) => handleFormEntry(e, "confirmPassword")}
              keyPressHandler={(e) => handleFormEntry(e, "enter")}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <PrimaryButton
              label={t(`resetPassword.change`)}
              alignment="left"
              disabled={loading}
              onClick={() => changePassword(details)}
            />
          </Stack>
        </Grid>
      </Container>
    </Page>
  );
}
