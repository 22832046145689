import apiHelper from "./apiHelper";

//  Type can be one of:
// [PENDING, COMPLETED, ALL]
export const getMyTasksApi = ({ type, pageNo, pageSize }) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/tasks/me`, {
        params: {
          type,
          pageNo,
          pageSize,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
