import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { displayToast } from "../../../utils/general";
import { validateEmail } from "../../../utils/general";
//  MUI
import {
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
//  Components
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import InfoBox from "./../../../components/InfoBox";
import DefaultTable from "./../../../components/tables/DefaultTable";
import SecondaryButton from "./../../../components/buttons/SecondaryButton";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import TabsBar from "../../../components/navigation/TabsBar";
// API
import { deleteUserApi } from "../../../api/users";
import {
  manageActivityApi,
  implementationContractorsApi,
} from "../../../api/activities";

const deleteDefault = {
  open: false,
  id: "",
  name: "",
};

export const IdentifyFocalPoints = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { companyId, planId } = useParams();
  const [tab, setTab] = useState("viewFPs");
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [filterParams, setFilterParams] = useState({
    pageSize: 10,
    pageNo: 1,
  });
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [focalPoints, setFocalPoints] = useState([]);
  const [values, setValues] = useState(focalPoints.length ? focalPoints : [""]);
  const [deleteDetails, setDeleteDetails] = useState(deleteDefault);

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 300,
      sortable: false,
    },
    { field: "lastName", headerName: "Last Name", width: 300, sortable: false },
    { field: "emailAddress", headerName: "Email", width: 300, sortable: false },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <SecondaryButton
          isRed
          label={t("remove")}
          alignment={"center"}
          style={{ height: "35px" }}
          onClick={() =>
            setDeleteDetails({
              open: true,
              id: params.row.id,
              name: `${params.row.firstName} ${params.row.lastName}`,
            })
          }
          disabled={loading}
        />
      ),
    },
  ];

  const tabsList = [
    {
      label: t("implementations.fps.viewFPs"),
      value: 0,
      key: "viewFPs",
    },
    {
      label: t("implementations.fps.addFPs"),
      value: 0,
      key: "addFPs",
    },
  ];

  const renderAddFPs = () => (
    <>
      {/* <Grid width={1 / 2} p={2} sx={{ mb: 1 }}>
        <AutocompleteInput
          handler={(_, e) => {
            setUserSelected(e);
          }}
          keyPressHandler={handleSearchUser}
          label="Search users to mark as focal points"
          loading={searchLoading}
          disabled={loading}
          options={userDropdownOpt}
        />
      </Grid> */}
      <Card padding={2}>
        <Typography
          variant={"h6"}
          paddingTop={2}
          paddingBottom={1}
          paddingX={2}
        >
          {t("implementations.createNewFocalPoints")}
        </Typography>
        {values?.map((jump, index) => (
          <Grid container key={"jump" + index} marginBottom={1} padding={1}>
            <Card variant="outlined">
              <Grid container>
                <Grid item padding={2} paddingLeft={2}>
                  <Typography variant={"h6"}>
                    {t("implementations.firstName")}
                  </Typography>
                  <TextField
                    // autoFocus
                    margin="dense"
                    label={t("implementations.firstNamePlaceholder")}
                    value={values[index]?.firstName}
                    onChange={(e) => handleValueChange("firstName", index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item padding={2}>
                  <Typography variant={"h6"}>
                    {t("implementations.lastName")}
                  </Typography>
                  <TextField
                    // autoFocus
                    margin="dense"
                    label={t("implementations.lastNamePlaceholder")}
                    value={values[index]?.lastName}
                    onChange={(e) => handleValueChange("lastName", index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item padding={2}>
                  <Typography variant={"h6"}>
                    {t("implementations.email")}
                  </Typography>
                  <TextField
                    // autoFocus
                    margin="dense"
                    label={t("implementations.enterEmailText")}
                    value={values[index]?.emailAddress}
                    onChange={(e) =>
                      handleValueChange("emailAddress", index, e)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Card>
      <Grid paddingLeft={2}>
        <Button onClick={() => setValues([...values, ""])}>
          {t("implementations.addAnotherFocalPoint")}
        </Button>
      </Grid>
      <PrimaryButton
        style={{ marginTop: "20px" }}
        alignment="left"
        label={t("save")}
        onClick={handleSave}
        disabled={loading}
      />
    </>
  );

  const renderViewFPs = () => (
    <DefaultTable
      rows={rows}
      columns={columns}
      loading={loading}
      filterParams={filterParams}
      setFilterParams={setFilterParams}
      rowSelectable={false}
      tableInfo={tableInfo}
    />
  );

  const renderDeleteModal = () => (
    <InfoBox
      disabled={loading}
      open={deleteDetails.open}
      title={`${t(`focalPoints.delete`)} ${deleteDetails.name}`}
      content={
        <Stack>
          <Typography variant="h6" style={{ color: theme.palette.primary.red }}>
            {t(`focalPoints.deleteConfirm`)}
          </Typography>
        </Stack>
      }
      buttonLabel={t(`focalPoints.delete`)}
      handleClose={() => setDeleteDetails({ ...deleteDetails, open: false })}
      handleAction={submitRemoveFP}
    />
  );

  const handleValueChange = (name, index, e) => {
    let text = e.target.value;
    let roleValue = values;
    roleValue[index] = {
      ...roleValue[index],
      [name]: text,
    };
    setValues(roleValue);
  };

  const handleSave = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating")
    );

    try {
      const nonEmptyRecords = [];
      values.forEach((r) => {
        if (r.email !== null && r.email !== "" && !validateEmail(r.email)) {
          nonEmptyRecords.push(r);
        } else {
          throw t("validation.email");
        }
      });

      setFocalPoints(nonEmptyRecords);
      const body = {
        companyId,
        focalPoints: nonEmptyRecords,
        activityTypeStub: "IdentifyFocalPoints",
      };

      const res = await manageActivityApi(body);
      setValues([""]);
      setFocalPoints([]);
      getUsers();
      setTab("viewFPs");
      displayToast(setLoading, "success", res.data.message, activeToast);
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitRemoveFP = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("focalPoints.deleteLoading")
    );
    try {
      await deleteUserApi(deleteDetails.id, "focalPoints");
      getUsers();
      setDeleteDetails(deleteDefault);
      displayToast(
        setLoading,
        "success",
        t("focalPoints.deleteSuccess"),
        activeToast
      );
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  const getUsers = useCallback(async () => {
    const users = await implementationContractorsApi({
      companyId,
      planId,
    });
    setRows(users.data);
    setTableInfo({
      currentPage: users.currentPage,
      totalCount: users.totalItems,
      totalPages: users.totalPages,
    });
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getContent = () => {
    return (
      <>
        <Grid paddingLeft={4} paddingRight={1}>
          <Box sx={{ pb: 2 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <HeaderBackButton />
              <Typography variant="h4">
                {t("implementations.focalPoints")}
              </Typography>
            </div>
          </Box>
          <TabsBar
            tabs={tabsList}
            value={tab}
            handler={(e, v) => setTab(v)}
            sx={{ mb: 2 }}
          />
          {tab === "addFPs" && renderAddFPs()}
          {tab === "viewFPs" && renderViewFPs()}
        </Grid>
        {renderDeleteModal()}
      </>
    );
  };

  return !loading ? (
    <Grid>{getContent()}</Grid>
  ) : (
    <Grid
      sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 4 }}
    >
      <CircularProgress />
    </Grid>
  );
};
