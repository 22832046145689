import apiHelper from "./apiHelper";
import fileDownload from "js-file-download";

export const generateTemplateApi = ({
  directorate,
  company,
  location,
  discipline,
  unit,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/template`, {
        params: {
          directorateId: directorate,
          companyId: company,
          locationId: location,
          disciplineId: discipline,
          observationType: "BEHAVIOR",
          ...(unit ? { unitId: unit } : {}),
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `ObservationTemplate.xlsx`);
        resolve("File downloaded");
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
