import React, { useState, useEffect } from "react";
import { Grid, DialogContent, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams ,useNavigate} from "react-router-dom";
import { styled } from "@mui/material/styles";
import TextInput from "../../../../components/inputs/TextInput";
import HeaderBackButton from "../../../../components/buttons/HeaderBackButton";
import MultiSelectInput from "../../../../components/inputs/MultiSelectInput";
import SecondaryButton from "../../../../components/buttons/SecondaryButton";
import AddIcon from "@mui/icons-material/Add";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import {
  concludeTraining,
  getTrainingPlanUsersByTpId,
} from "../../../../api/implementation";
import RadioSet from "../../../../components/buttons/RadioSet";

const AttendeeDiv = styled("div")(({ theme }) => ({
  background: "gainsboro",
  width: "max-content",
  padding: "5px",
  borderRadius: "5px",
  color: "black",
}));

const NoShowDiv = styled("div")(({ theme }) => ({
  background: "#ffbebe",
  width: "max-content",
  padding: "5px",
  borderRadius: "5px",
  color: "#7f1313",
}));

export default function TrainingConclude() {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { activityId, planId, activityTypeStub, trainingId, companyId } =
    useParams();
  const [loading, setLoading] = useState(false);
  const [trainingData, setTrainingData] = useState({});
  const [availableSeats, setAvailableSeats] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selecetedNoShows, setSelectedNoShows] = useState([]);
  const [satisfactionStatus, setSatisfactionStatus] = useState("Average");
  const [endNote, setEndNote] = useState("");
  const [attending, setAttending] = useState([]);
  const [noShows, setNoShows] = useState([]);
  const [selectOptions, setSelectedOptions] = useState([]);

  const handleAddAttendees = () => {
    let tempAddAttendees = [];
    let tempDummyOptions = selectOptions;
    for (let i = 0; i < selectedUsers.length; i++) {
      if (
        attending.find((invited) => invited.value == selectedUsers[i].value) ==
        undefined
      ) {
        tempAddAttendees.push(selectedUsers[i]);
        let removeIndex = tempDummyOptions.findIndex(
          (x) => x.value === selectedUsers[i].value
        );
        tempDummyOptions.splice(removeIndex, 1);
      }
    }
    setSelectedOptions(tempDummyOptions);
    setAttending([...attending, ...tempAddAttendees]);
    setSelectedUsers([]);
  };

  const handleAddNoShows = () => {
    let tempNoShows = [];
    let tempDummyOptions = selectOptions;
    for (let i = 0; i < selecetedNoShows.length; i++) {
      if (
        noShows.find((invited) => invited.value == selecetedNoShows[i].value) ==
        undefined
      ) {
        tempNoShows.push(selecetedNoShows[i]);
        let removeIndex = tempDummyOptions.findIndex(
          (x) => x.value === selecetedNoShows[i].value
        );
        tempDummyOptions.splice(removeIndex, 1);
      }
    }
    setSelectedOptions(tempDummyOptions);
    setNoShows([...noShows, ...tempNoShows]);
    setSelectedNoShows([]);
  };

  const getTrainingById = async () => {
    try {
      let tempUsers = [];
      setLoading(true);
      let query = `trainingPlanId=${trainingId}`;
      let getTraining = await getTrainingPlanUsersByTpId(query);
      if (getTraining.users.length > 0) {
        for (let i = 0; i < getTraining.users.length; i++) {
          if(getTraining.users[i].userStatus==='Accepted'){
            tempUsers.push({
              value: getTraining.users[i].id,
              label: `${getTraining.users[i].User.firstName} ${getTraining.users[i].User.lastName ?? ''}`,
              userId: getTraining.users[i].User.id,
            });
          }
        }
      }
      setSelectedOptions(tempUsers);
      setLoading(false)
    } catch (e) {
      setLoading(false);
    }
  };

  const handleConclude = async () => {
    let tempObj = {
      trainingPlanId: trainingId,
      endingNote: endNote,
      trainerSatisfaction: satisfactionStatus,
      attendees: attending,
      noShows: noShows,
    };
    let conclude = await concludeTraining(tempObj);
    if(conclude.data=="Updated successfully"){
      navigate(-1)
    }
  };

  useEffect(() => {
    getTrainingById();
  }, []);
  return (
    <>
      <DialogContent>
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">{t("Manage Training Details")}</Typography>
          </div>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextInput
              label={t(`trainingSchedule.endNote`)}
              value={endNote}
              disabled={loading}
              handler={(e) => setEndNote(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6">Attendees :</Typography>
          </Grid>
          {attending.length > 0 &&
            attending.map((attended) => (
              <Grid item xs={6} md={3}>
                <AttendeeDiv>
                  <Typography>{attended.label}</Typography>
                </AttendeeDiv>
              </Grid>
            ))}
          <Grid item xs={9} md={10}>
            <MultiSelectInput
              disabled={false}
              options={selectOptions}
              handler={(newValue) => setSelectedUsers(newValue)}
              values={selectedUsers}
            />
          </Grid>
          <Grid item xs={3} md={2} style={{ marginTop: "9px" }}>
            <SecondaryButton
              icon={<AddIcon />}
              label={"Add"}
              // alignment="left"
              onClick={() => {
                handleAddAttendees();
              }}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6">No Shows :</Typography>
          </Grid>
          {noShows.length > 0 &&
            noShows.map((attended) => (
              <Grid item xs={6} md={3}>
                <NoShowDiv>
                  <Typography>{attended.label}</Typography>
                </NoShowDiv>
              </Grid>
            ))}
          <Grid item xs={9} md={10}>
            <MultiSelectInput
              disabled={false}
              options={selectOptions}
              handler={(newValue) => setSelectedNoShows(newValue)}
              values={selecetedNoShows}
            />
          </Grid>
          <Grid item xs={3} md={2} style={{ marginTop: "9px" }}>
            <SecondaryButton
              icon={<AddIcon />}
              label={"Add"}
              // alignment="left"
              onClick={() => {
                handleAddNoShows();
              }}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5">Trainer Satisfaction</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <RadioSet
              disabled={loading}
              label=""
              value={satisfactionStatus}
              handleChange={(e) => setSatisfactionStatus(e.target.value)}
              options={[
                {
                  label: "Below Average",
                  value: "Below Average",
                },
                {
                  label: "Average",
                  value: "Average",
                },
                {
                  label: "Above Average",
                  value: "Above Average",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
            <PrimaryButton
              label={"Conclude"}
              // alignment="left"
              onClick={() => {
                handleConclude()
              }}
              disabled={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
}
