import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DataGrid, GridCell } from "@mui/x-data-grid";
import {
  Pagination,
  Typography,
  Stack,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SelectInput from "../inputs/SelectInput";
import CellText from "../CellText";

DefaultTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  tableInfo: PropTypes.object,
  filterParams: PropTypes.object,
  setFilterParams: PropTypes.func,
  onRowClick: PropTypes.func,
  topTextContent: PropTypes.node,
  limitRowsPerPage: PropTypes.bool,
  triggerFunction: PropTypes.func,
  autoHeight: PropTypes.bool,
  hidePagination: PropTypes.bool,
  hiddenColumns: PropTypes.object,
  minHeight: PropTypes.string,
  autoRowHeight: PropTypes.bool,
  //  Make defaultLimit true if you are providing default limit in filterParams -> this will reduce 1 api call if dependent on filterParams useEffect
  defaultLimit: PropTypes.bool,
  handleSortModelChange: PropTypes.func,
};

const DataGridStyled = styled(DataGrid)(
  ({ theme, minHeight, onRowClick = null }) => ({
    minHeight,
    border: "none",
    ".MuiDataGrid-columnHeaders": {
      width: "99.5%",
      color: "#A3A3A3",
      background:
        "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
      boxShadow: " 0px 4px 9px rgba(0, 0, 0, 0.11)",
      borderRadius: "6px",
      border: "1px solid #f1f3f4",
    },
    ".MuiDataGrid-row": {
      cursor: onRowClick ? "pointer" : "auto",
      width: "99.5%",
      color: theme.palette.primary.black,
      background:
        "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
      boxShadow: " 0px 4px 9px rgba(0, 0, 0, 0.11)",
      borderRadius: "6px",
      border: "1px solid #f1f3f4",
      margin: "6px 0",
    },
    ".MuiDataGrid-virtualScrollerRenderZone": {
      width: "100% !important",
      position: "relative !important",
    },
    ".MuiDataGrid-virtualScroller": {
      minHeight,
    },
  })
);

const PaginationStyled = styled(Pagination)(({ theme }) => ({
  ".MuiPaginationItem-icon": {
    color: theme.palette.primary.orange,
  },
  ".MuiPaginationItem-textPrimary": {
    color: theme.palette.primary.orange,
    "&.Mui-selected": {
      color: theme.palette.primary.black,
      fontWeight: "bold",
    },
  },
}));

const mobileGridStyle = {
  display: "flex",
  justifyContent: "center",
};

export default function DefaultTable({
  rows,
  columns,
  loading,
  tableInfo,
  filterParams,
  setFilterParams,
  onRowClick,
  onCellClick,
  topTextContent,
  limitRowsPerPage = true,
  triggerFunction,
  autoHeight = false,
  minHeight = "50vh",
  hidePagination = false,
  hiddenColumns = {},
  noRowsText,
  height = undefined,
  autoRowHeight,
  defaultLimit = false,
  handleSortModelChange,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const { totalPages, totalCount } = tableInfo;
  const { pageNo, pageSize } = filterParams;
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (!limitRowsPerPage) setRowsPerPage(100);
    if (!defaultLimit) {
      setFilterParams({
        ...filterParams,
        pageNo: 1,
        pageSize: rowsPerPage,
      });
    }
    if (triggerFunction) {
      triggerFunction(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  const CustomPagination = () => {
    return isMobile ? (
      <Stack direction="row" alignItems={"center"} spacing={5}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {t("table.page", {
            pageNo,
            totalCount: !totalPages ? 1 : totalPages,
          })}
        </Typography>

        <SelectInput
          disabled={loading}
          value={rowsPerPage}
          style={{ width: "50px" }}
          handler={(e) => setRowsPerPage(e.target.value)}
          options={[
            {
              label: 10,
              value: 10,
            },
            {
              label: 20,
              value: 20,
            },
            {
              label: 50,
              value: 50,
            },
            {
              label: 100,
              value: 100,
            },
          ]}
        />
        <PaginationStyled
          color="primary"
          count={!totalPages ? 1 : totalPages}
          page={pageNo}
          onChange={(e, v) => {
            setFilterParams({ ...filterParams, pageNo: v });
            if (triggerFunction) {
              triggerFunction(true);
            }
          }}
        />
      </Stack>
    ) : (
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ ...mobileGridStyle }}>
          <SelectInput
            disabled={loading}
            value={rowsPerPage}
            handler={(e) => setRowsPerPage(e.target.value)}
            options={[
              {
                label: 10,
                value: 10,
              },
              {
                label: 20,
                value: 20,
              },
              {
                label: 30,
                value: 30,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} style={{ ...mobileGridStyle }}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {t("table.page", { pageNo, totalCount })}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ ...mobileGridStyle }}>
          <PaginationStyled
            color="primary"
            count={!totalPages ? 1 : totalPages}
            page={pageNo}
            onChange={(e, v) => {
              setFilterParams({ ...filterParams, pageNo: v });
              if (triggerFunction) {
                triggerFunction(true);
              }
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const CustomCell = ({ ...other }) => {
    if (other.value && !other.children) {
      return (
        <GridCell {...other}>
          <CellText title={other.value} />
        </GridCell>
      );
    } else if (other.children) {
      return <GridCell {...other}>{other.children}</GridCell>;
    } else {
      return <GridCell {...other} />;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {topTextContent ? (
        topTextContent
      ) : (
        <Typography
          variant="body2"
          style={{
            marginBottom: "15px",
            color: theme.palette.primary.orange,
            fontWeight: "bold",
          }}
        >{`${t("table.totalListed")} ${totalCount} `}</Typography>
      )}
      <DataGridStyled
        getRowHeight={autoRowHeight ? () => "auto" : undefined}
        minHeight={minHeight}
        autoHeight={autoHeight}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        loading={loading}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        scrollbarSize={1}
        height={height}
        components={{
          Pagination: hidePagination ? null : CustomPagination,
          Cell: CustomCell,
          NoRowsOverlay: () => (
            <Stack
              height={height || "35vh"}
              alignItems="center"
              justifyContent="center"
            >
              {noRowsText || t("table.noRows")}
            </Stack>
          ),
        }}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        columnVisibilityModel={hiddenColumns}
      />
    </div>
  );
}
