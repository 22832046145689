import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { renewTokenApi } from "../api/auth";

const expiryMinutes = 15;

export default function Renewer() {
  const [remaining, setRemaining] = useState(0);

  const onIdle = () => {
    window.location.href = "/login?expiry=true";
    localStorage.removeItem("token");
    localStorage.removeItem("sso");
  };

  const onActive = async () => {
    //  When the user comes back from being idle
  };

  const onAction = () => {
    //  If the user performs an action a minute before the idle time is up, renew the token
    if (remaining <= 60) {
      handleRenewToken();
    }
  };

  const handleRenewToken = async () => {
    try {
      await renewTokenApi();
    } catch (error) {
      console.error(error);
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * expiryMinutes,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return <></>;
}
