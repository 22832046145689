import PropTypes from "prop-types";
import { Stack, Typography, Box } from "@mui/material";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import HelpText from "./HelpText";

const dotStyle = {
  width: "12px",
  height: "12px",
  marginRight: "5px",
};

const boxStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ranges = [
  {
    color: "#858585",
    label: "No data",
  },
  {
    color: "#DC143C",
    label: "Low: Below 65%",
  },
  {
    color: "#F2994A",
    label: "Moderate: 65% - 85%",
  },
  {
    color: "#1bc262",
    label: "High: Above 85%",
  },
];

export default function AiLegend(props) {
  const rootStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: props.mt || 0,
  };
  if (props.variant === "compact") {
    return (
      <Stack direction="row" spacing={1} style={rootStyle}>
        {ranges.map((range) => (
          <Box style={{ ...boxStyle, marginTop: -20 }}>
            <HelpText title={range.label}>
              <DotIcon
                style={{
                  ...dotStyle,
                  height: "14px",
                  width: "14px",
                  color: range.color,
                  cursor: "help",
                }}
              />
            </HelpText>
          </Box>
        ))}
      </Stack>
    );
  } else {
    return (
      <Stack direction="row" spacing={1} style={rootStyle}>
        {ranges.map((range) => (
          <Box style={boxStyle}>
            <DotIcon style={{ ...dotStyle, color: range.color }} />
            <Typography variant="caption">{range.label}</Typography>
          </Box>
        ))}
      </Stack>
    );
  }
}

AiLegend.propTypes = {
  mt: PropTypes.number,
  variant: PropTypes.string,
};
