import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { openAttachment } from "../utils/general";
import { Typography, Box } from "@mui/material";

AttachmentViewer.propTypes = {
  disabled: PropTypes.bool,
  attachments: PropTypes.arrayOf(PropTypes.object),
  customLabel: PropTypes.string,
  attachmentLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function AttachmentViewer({
  noHeader,
  attachments,
  customLabel,
  attachmentLinks,
}) {
  const { t } = useTranslation();

  return (
    <Box>
      {!noHeader && (
        <Typography variant="h6">
          {customLabel || t("general.attachments")}
        </Typography>
      )}
      {attachments && attachments.length > 0 ? (
        attachments.map((c, i) => (
          <div
            style={{
              width: "95%",
              background:
                "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
              boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.11)",
              borderRadius: "10px",
              height: "43px",
              display: "flex",
              alignItems: "center",
              padding: "0 18px",
              marginRight: "32px",
              marginTop: "22px",
              cursor: "pointer",
            }}
            key={`uploaded-attachment-${i}-${c.filename}`}
            onClick={() => openAttachment(attachmentLinks, c.id)}
          >
            <Typography variant="body1">
              {c.originalFileName || c.filename}
            </Typography>
          </div>
        ))
      ) : (
        <Typography variant="body1" style={{ marginTop: "8px" }}>
          {t("general.noAttachments")}
        </Typography>
      )}
    </Box>
  );
}
