import React, { useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";
//  Material UI
import { Box, Container } from "@mui/material";
//  Components
import Page from "../../../../components/Page";
import { UserTrained } from "./UserTrained";
import { UserNotTrained } from ".//UserNotTrained";
import TabsBar from "../../../../components/navigation/TabsBar";
//  API

export const UserTrainings = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("userTrained");

  return (
<>
        <TabsBar
          value={tabValue}
          handler={(event, value) => setTabValue(value)}
          tabs={[
            {
              label: t('training.userTrained'),
              value: 0,
              key: "userTrained",
            },
            {
              label: t('training.userNotTrained'),
              value: 0,
              key: "userNotTrained",
            },
          ]}
        />

        {tabValue === "userTrained" && <UserTrained />}
        {tabValue === "userNotTrained" && <UserNotTrained/>}
</>
  );
};
