import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  alignment: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  variant: PropTypes.string,
  isred: PropTypes.bool,
  isgreen: PropTypes.bool,
};

const ButtonStyled = styled(Button)(({ theme, isred, isgreen }) => ({
  background: isred
    ? theme.palette.primary.red
    : isgreen
    ? theme.palette.primary.green
    : theme.palette.primary.buttonOrange,
  fontFamily: "Cairo, sans-serif",
  color:
    isred || isgreen
      ? theme.palette.primary.white
      : theme.palette.primary.black,
  textTransform: "none",
  height: "43px",
  fontWeight: "fontWeightRegular",
  border: `1px solid ${theme.palette.primary.orange}`,
  boxShadow: "none",
  borderRadius: "10px",
  "&:hover": {
    background: isred
      ? theme.palette.primary.hoverRed
      : isgreen
      ? theme.palette.primary.hoverGreen
      : theme.palette.primary.hoverOrange,
  },
  paddingLeft: "16px",
}));

export default function PrimaryButton({
  label,
  style,
  alignment,
  disabled,
  onClick,
  icon,
  isred,
  isgreen,
}) {
  return (
    <ButtonStyled
      isred={isred}
      isgreen={isgreen}
      startIcon={icon ? icon : <ArrowForwardOutlinedIcon />}
      variant="contained"
      disabled={disabled}
      fullWidth
      style={{
        ...style,
        justifyContent: alignment
          ? alignment === "left"
            ? "flex-start"
            : "center"
          : "center",
      }}
      onClick={onClick}
    >
      <Typography variant="body1" style={{ marginLeft: "8px" }}>
        {label}
      </Typography>
    </ButtonStyled>
  );
}
