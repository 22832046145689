import axios from "axios";
import { baseURL } from "./config.json";

const apiHelper = axios.create({
  baseURL,
  mode: "cors",
});

apiHelper.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

apiHelper.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 412) {
      localStorage.clear();
      window.location.href = "/login?expiry=true";
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiHelper;
