import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Stack,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";

const LabelStyled = styled(FormControlLabel)(({ theme }) => ({
  ".MuiFormControlLabel-label": {
    fontWeight: 700,
    color: theme.palette.primary.red,
  },
}));

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.red,
  marginLeft: "18px",
}));

const RedCheckbox = ({ label, checked, handler, disabled }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center">
      <FormControl component="fieldset">
        <FormGroup row aria-label={label} name={label}>
          <LabelStyled
            control={
              <Checkbox
                checked={checked}
                onChange={handler}
                disabled={disabled}
                sx={{
                  color: theme.palette.primary.red,
                  "&.Mui-checked": { color: theme.palette.primary.red },
                  "&:hover": {
                    backgroundColor: "rgba(209,45,38,0.08)",
                  },
                }}
              />
            }
            label={label}
            sx={{
              marginRight: "35px",
            }}
          />
        </FormGroup>
      </FormControl>
      <Tooltip
        title={
          "Escalating this observation will bypass the supervisor, making the observation directly available to the HSE manager."
        }
      >
        <IconStyle />
      </Tooltip>
    </Stack>
  );
};

RedCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  handler: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RedCheckbox;
