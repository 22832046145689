import { React } from "react";
import { useTranslation } from "react-i18next";
import * as labels from "./ObservationCardText.js";

import Logo from "../../components/Logo.js";
import { Grid, Stack, Typography } from "@mui/material";

const logoContainerStyle = {
  height: "100px",
  border: "1px solid black",
};

const ObservationCardTemplate = ({
  observationDetails,
  disciplineDropdown,
  logoUrl,
  pagesRef,
  today,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div id="page1" ref={(el) => (pagesRef.current[0] = el)}>
        {observationDetails.discipline !== "" && (
          <Grid>
            <Grid style={logoContainerStyle} padding={2}>
              <Stack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <img
                  src={logoUrl}
                  alt="company logo"
                  style={{
                    maxHeight: "80px",
                    maxWidth: "265px",
                    paddingBottom: "10px",
                  }}
                />
                <Logo sx={{ width: 120, height: 90, paddingBottom: "10px" }} />
              </Stack>
            </Grid>
            <Grid sx={{ border: "solid 1px" }}>
              <Grid
                padding={2}
                color="white"
                bgcolor={"#424242"}
                justifyContent="space-between"
                display={"flex"}
              >
                <Grid item> {labels.en.cardTitle}</Grid>
                <Grid item>{labels.ar.cardTitle}</Grid>
              </Grid>

              <Grid
                padding={2}
                color="white"
                bgcolor={"#F8931F"}
                justifyContent="space-between"
                display={"flex"}
              >
                <Grid>
                  {
                    disciplineDropdown.find(
                      (disc) => disc.value === observationDetails.discipline
                    )?.label
                  }
                </Grid>
                <Grid>
                  {
                    disciplineDropdown.find(
                      (disc) => disc.value === observationDetails.discipline
                    )?.labelAR
                  }
                </Grid>
              </Grid>

              <Grid item padding={1}>
                {["name", "date", "time", "area", "loc"].map((key) => (
                  <Grid
                    sx={{
                      border: "1px solid",
                      borderRadius: "10px",
                      borderColor: "#F8931F",
                      justifyContent: "space-between",
                      display: "flex",
                      marginX: 0.5,
                      marginBottom: 0.5,
                      padding: 0.5,
                    }}
                  >
                    <Grid item paddingLeft={1}>
                      {labels.en[key]}
                    </Grid>
                    <Grid item paddingRight={1}>
                      {labels.ar[key]}
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid
                sx={{
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                  backgroundColor: "#424242",
                }}
                display="flex"
              >
                <Grid width={2 / 3}>
                  {["behaviors", "observed"].map((key) => (
                    <Grid
                      color="white"
                      bgcolor={"#424242"}
                      justifyContent="space-between"
                      display={"flex"}
                      padding={2}
                    >
                      <Grid item> {labels.en[key]}</Grid>
                      <Grid item>{labels.ar[key]}</Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid width={1 / 3}>
                  <Grid bgcolor="gray" color="white" padding={1}>
                    <Grid item>{labels.ar.count}</Grid>
                    <Grid item>{labels.en.count}</Grid>
                  </Grid>

                  <Grid display={"flex"}>
                    {["safe", "unsafe"].map((key) => (
                      <Grid alignItems={"center"} width={1 / 2} color="white">
                        <Grid
                          item
                          bgcolor={key === "safe" ? "green" : "red"}
                          paddingLeft={2}
                          paddingBottom={1}
                        >
                          {labels.en[key]}
                        </Grid>
                        <Grid
                          item
                          bgcolor={key === "safe" ? "green" : "red"}
                          paddingBottom={1}
                          paddingLeft={2}
                        >
                          {labels.ar[key]}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              {observationDetails.criticalBehaviors.map((behavior, index) => (
                <Grid
                  borderBottom="solid"
                  display={"flex"}
                  key={`observationCardTemplate-behavior-critical-${index}`}
                >
                  <Grid borderRight="solid" width={"67%"} paddingX={2}>
                    <Grid>{behavior.label}</Grid>
                    <Grid
                      display="flex"
                      flexDirection="row-reverse"
                      paddingBottom={1}
                    >
                      {behavior.labelAR}
                    </Grid>
                  </Grid>
                  <Grid width={"17%"} borderRight="solid"></Grid>
                  <Grid width={"13%"}></Grid>
                </Grid>
              ))}

              {observationDetails.otherBehaviors.map((behavior, index) => (
                <Grid
                  border="solid"
                  display={"flex"}
                  key={`observationCardTemplate-behavior-other-${index}`}
                >
                  <Grid borderRight="solid" width={"67%"} paddingLeft={2}>
                    {behavior.label}
                  </Grid>
                  <Grid width={"17%"} borderRight="solid"></Grid>
                  <Grid width={"13%"}></Grid>
                </Grid>
              ))}

              {Array(
                observationDetails.criticalBehaviors.length +
                  observationDetails.otherBehaviors.length >
                  10
                  ? 2
                  : 10 -
                      (observationDetails.criticalBehaviors.length +
                        observationDetails.otherBehaviors.length)
              )
                .fill({ label: " _____", labelAR: "_____" })
                .map((behavior, index) => (
                  <Grid
                    borderBottom="solid"
                    display={"flex"}
                    key={`observation-card-template-${index}`}
                  >
                    <Grid borderRight="solid" width={"67%"} paddingX={2}>
                      <Grid color="white">{behavior.label}</Grid>
                      <Grid
                        color="white"
                        display="flex"
                        flexDirection="row-reverse"
                      >
                        {behavior.labelAR}
                      </Grid>
                    </Grid>
                    <Grid width={"17%"} borderRight="solid"></Grid>
                    <Grid width={"13%"}></Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
      </div>
      <div id="page2" ref={(el) => (pagesRef.current[1] = el)}>
        <Grid bgcolor={"gray"}>
          {["feedback", "rootCause", "actionTaken", "suggestion"].map((key) => (
            <Grid id={key} p={2}>
              <Grid
                color="white"
                bgcolor={"#F8931F"}
                justifyContent="space-between"
                display={"flex"}
                p={1}
              >
                <Grid item> {labels.en[key]}</Grid>
                <Grid item>{labels.ar[key]}</Grid>
              </Grid>
              {[...Array(5)].map((line) => (
                <Grid
                  bgcolor="white"
                  style={{ borderBottom: "1px solid #1C1C1C", height: "30px" }}
                  paddingTop={1}
                />
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid bgcolor="gray" padding={2}>
          <Stack justifyContent="space-between" direction="row">
            <Typography variant="body" color="white">
              {`${t("observations.observationCardPage.printDate")} ${today}`}
            </Typography>
            <Typography variant="body" color="white">
              {` ${today} ${t("observations.observationCardPage.printDateAr")}`}
            </Typography>
          </Stack>
        </Grid>
      </div>
    </>
  );
};

export default ObservationCardTemplate;
