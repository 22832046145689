import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { displayToast } from "../../../utils/general";
import moment from "moment";
//  Material UI
import { Grid, Typography, Box, Container } from "@mui/material";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import ConcludeIcon from "@mui/icons-material/Done";
import DateInput from "../../../components/inputs/DateInput";
//  Components
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import PageLoader from "../../../components/PageLoader";
import Page from "../../../components/Page";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
//  API
import { implementationContractorsApi } from "../../../api/activities";
import TextInput from "../../../components/inputs/TextInput";
import { getVisit, saveVisit } from "../../../api/implementation";

const activityDefaults = {
  minutes: "",
  attendeeIds: [],
  concluded: false,
  startDate: new Date(),
};

export const MeetingDetails = (props) => {
  const { t } = useTranslation();
  const timeout = useRef();
  const { planId, activityId, companyId, type } = useParams();
  const [loading, setLoading] = useState(false);
  const [activityDetail, setActivityDetail] = useState(activityDefaults);
  const [users, setUsers] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const getCurrentActivity = async () => {
    try {
      setLoading(true);
      const userData = await getUsers();
      const activity = await getVisit(`?id=${activityId}`);
      const { minutes } = activity?.result;
      const attendeeIds =
        activity?.result?.attendeeIds?.length > 0
          ? activity?.result?.attendeeIds.split(",")
          : [];
      const finalAttendees = [];
      attendeeIds.forEach((a) => {
        const foundUser = userData.find((u) => u.value === a);
        if (foundUser) {
          finalAttendees.push(foundUser);
        }
      });
      setActivityDetail({
        minutes,
        attendeeIds: finalAttendees,
        concluded: activity?.result?.concluded,
        startDate: new Date(activity?.result?.startDate) || new Date(),
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSubmit = async (concludeFlag) => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating"),
    );

    try {
      const meetingDate = moment(activityDetail.startDate);
      if (!meetingDate.isValid()) {
        throw t(`errors.dateValidError`);
      }
      if (!activityDetail.minutes) {
        throw t(`errorHandling.meetingMinutesError`);
      }
      if (
        !activityDetail.attendeeIds ||
        activityDetail.attendeeIds.length < 1
      ) {
        throw t(`errorHandling.attendeesError`);
      }
      const { minutes, attendeeIds } = activityDetail;
      const finalAttendees = attendeeIds.map((a) => a.value);
      const body = {
        minutes,
        attendeeIds: finalAttendees,
        meetingId: activityId,
        planId: planId,
        concluded: concludeFlag,
        startDate: moment(activityDetail.startDate).toISOString(),
      };

      await saveVisit(body);
      displayToast(
        setLoading,
        "success",
        t("implementations.detailsUpdated"),
        activeToast,
      );
      if (concludeFlag) {
        getCurrentActivity();
      }
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await implementationContractorsApi({
            planId,
            companyId,
            textSearch: value,
          });
          const finalDropdown = [];
          result.data?.forEach((user) => {
            const alrSelected = activityDetail.attendeeIds.find(
              (a) => a.value === user.id || a.id === user.id,
            );
            if (user && user.displayName && !alrSelected) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
                value: user.id,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const getUsers = async () => {
    const usersData = await implementationContractorsApi({
      planId,
      companyId,
    });
    const finalDropdown = [];
    usersData.data?.forEach((u) => {
      if (u && u.displayName) {
        finalDropdown.push({
          label: u.displayName,
          value: u.id,
        });
      }
    });
    setUsers(finalDropdown);
    return finalDropdown;
  };

  useEffect(() => {
    getCurrentActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading && users ? (
    <Page
      title={
        type === "Leadership Visibility"
          ? t("implementations.schedule.visitManagement")
          : t("implementations.schedule.steeringMeetings")
      }
    >
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {type === "Leadership Visibility"
                ? t("implementations.schedule.visitManagement")
                : t("implementations.schedule.steeringMeetings")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DateInput
              disabled={loading || activityDetail.concluded}
              label={t("training.tableHeaders.startDate")}
              date={activityDetail.startDate}
              handler={(newDate) => {
                setActivityDetail({
                  ...activityDetail,
                  startDate: newDate,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              disabled={loading || activityDetail.concluded}
              label={t("implementationsList.minutes")}
              placeholder={t("implementationsList.enterMinutesInput")}
              handler={(e) =>
                setActivityDetail({
                  ...activityDetail,
                  minutes: e.target.value,
                })
              }
              value={activityDetail.minutes}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteInput
              multiple
              value={activityDetail.attendeeIds}
              handler={(e, v) =>
                setActivityDetail({
                  ...activityDetail,
                  attendeeIds: v ? v : [],
                })
              }
              options={userDropdown}
              label={t("implementationsList.attendeesInputText")}
              disabled={loading || users.length < 1 || activityDetail.concluded}
              loading={searchLoading}
              keyPressHandler={handleSearchUser}
            />
          </Grid>
          {!activityDetail.concluded ? (
            <>
              <Grid item xs={12} sm={2}>
                <PrimaryButton
                  isgreen
                  icon={<ConcludeIcon />}
                  disabled={loading}
                  alignment="left"
                  label={t("trainingSchedule.conclude")}
                  onClick={() => handleSubmit(true)}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <PrimaryButton
                  icon={<SaveIcon />}
                  disabled={loading}
                  alignment="left"
                  label={t("general.saveChanges")}
                  onClick={() => handleSubmit(false)}
                />
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ConcludeIcon sx={{ color: "green", mr: 1 }} />
              <Typography sx={{ color: "green" }} variant="h7">
                {type === "Leadership Visibility"
                  ? t("training.concludedVisit")
                  : t("training.concludedMeeting")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  ) : (
    <Container
      style={{ height: "80vh", display: "flex", alignItems: "center" }}
    >
      <PageLoader />
    </Container>
  );
};
