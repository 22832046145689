import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
// material
import { AppBar, Box, Button, IconButton, Stack, Toolbar } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// components
import EnIcon from "../../components/icons/language_en";
import ArIcon from "../../components/icons/language_ar";
import MenuIcon from "@mui/icons-material/Menu";
import { MHidden } from "../../components/@material-extend";
import NavigationIconButton from "../../components/buttons/NavigationIconButton";
import Logo from "../../components/Logo";
import Chatbot from "./Chatbot";
import NotificationsPopover from "./NotificationsPopover";
import Profile from "./Profile";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 1),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ObservationButtonStyled = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.buttonOrange,
  padding: "20px",
  height: "50px",
  color: theme.palette.primary.black,
  fontWeight: "fontWeightSemibold",
  fontSize: "14px",
  textTransform: "none",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.primary.orange}`,
  boxShadow: "none",
  backdropFilter: "blur(20px)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverOrange,
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  notificationsData: PropTypes.object,
  clearData: PropTypes.func,
};

export default function DashboardNavbar({
  onOpenSidebar,
  notificationsData,
  clearData,
}) {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const changeLanguage = () => {
    if (i18n.language.includes("en")) {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const renderButtonControls = () => (
    <>
      <Chatbot />
      <NotificationsPopover
        parentNotifications={notificationsData}
        clearData={clearData}
      />
      <NavigationIconButton
        icon={i18n.language.includes("en") ? <ArIcon /> : <EnIcon />}
        label={t("navBar.changeLanguage")}
        path=""
        onClick={changeLanguage}
      />
      <Profile />
      {location.pathname !== "/observations/submit" &&
        !location.pathname.includes("/observations/edit") && (
          <ObservationButtonStyled component={Link} to="/observations/submit">
            <span>{t("navBar.submitObservation")}</span>
          </ObservationButtonStyled>
        )}
    </>
  );

  return (
    <RootStyle>
      <ToolbarStyle>
        {/* MOBILE */}
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary", pl: 0 }}
          >
            <MenuIcon />
          </IconButton>
        </MHidden>

        {/* DESKTOP */}
        <MHidden width="lgDown">
          <Logo sx={{ width: "150px" }} />
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 3 }}
          >
            {renderButtonControls()}
            {/* <AccountPopover /> */}
          </Stack>
        </MHidden>
      </ToolbarStyle>
    </RootStyle>
  );
}
