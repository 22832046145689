import React, { useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";
//  Material UI
import { Typography, Box, Container, Grid } from "@mui/material";
//  Components
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import Page from "../../../components/Page";
import { TrainingCalender } from "./TrainingComponents/TrainingCalender";
import TrainingManagement from "./TrainingComponents/TrainingManagement";
import { UserTrainee } from "./TrainingComponents/UserTrained";
import { UserNotTrained } from "./TrainingComponents/UserNotTrained";
import { UserTrainings } from "./TrainingComponents/UserTrainings";
import TabsBar from "../../../components/navigation/TabsBar";
//  API

export const TrainingSchedule = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("trainingCalendar");

  return (
    <Page title={t("trainingSchedule.title")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">{t("trainingSchedule.title")}</Typography>
          </div>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TabsBar
              value={tabValue}
              handler={(event, value) => setTabValue(value)}
              tabs={[
                {
                  label: t("trainingSchedule.trainingCalender"),
                  value: 0,
                  key: "trainingCalendar",
                },
                {
                  label: t("trainingSchedule.trainingManagement"),
                  value: 0,
                  key: "trainingManagement",
                },
                // {
                //   label: t("trainingSchedule.userTraining"),
                //   value: 0,
                //   key: "userTrainings",
                // },
              ]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingTop: tabValue === "userTrainings" ? 0 : "16px" }}
          >
            {tabValue === "trainingCalendar" && <TrainingCalender />}
            {tabValue === "trainingManagement" && <TrainingManagement />}
            {tabValue === "userTrainings" && <UserTrainings />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
