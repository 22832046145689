import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
//  Material UI
import { Grid, Container, Typography } from "@mui/material";
//  Components
import PageLoader from "../../../../components/PageLoader";
import { styled } from "@mui/material/styles";
import DefaultTable from "../../../../components/tables/DefaultTable";
import { getTrainingPlanUsersByImplementation } from "../../../../api/implementation";
import InfoBox from "../../../../components/InfoBox";
import MarkComplete from "./MarkComplete";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { displayToast } from "../../../../utils/general";
//  API

const AcceptedChip = styled("div")(({ theme }) => ({
  background: "#9EB23B",
  color: "#FCF9C6",
  borderRadius: "5px",
  fontSize: "14px",
  padding: "5px",
  marginLeft: "10px",
}));

const InviteChip = styled("div")(({ theme }) => ({
  background: "grey",
  color: "white",
  borderRadius: "5px",
  fontSize: "14px",
  padding: "5px",
  marginLeft: "10px",
}));

const filterParamDefaults = {
  startDate: null,
  disciplineId: null,
  pageSize: 10,
  pageNo: 1,
  type: "ALL",
};

export const UserTrained = () => {
  const { t } = useTranslation();
  const { planId, companyId, activityId, activityTypeStub, activityTypeId } =
  useParams();  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  const [trainingRows, setTrainingRows] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [dialogUser, setDialogUser] = useState(null);
  const [userTrainings,setUserTrainings] = useState([])

  const trainingColumns = [
    // { field: "id", headerName: "Table Id", width: 70 },
    { field: "name", headerName: t("training.tableHeaders.name"), width: 150 },
    {
      field: "training",
      headerName: t("training.tableHeaders.training"),
      width: 500,
      renderCell: (trainingArray) => {
        return (
          <>
            {trainingArray?.row?.training.length > 0 &&
              trainingArray?.row?.training.map((training) => (
                <>
                  {training.status === "Attended" && (
                    <AcceptedChip
                      onClick={() => {
                        navigate(
                          `/implementations/training-schedule/${planId}/${companyId}/${activityId}/${activityTypeStub}/${activityTypeId}/${training.trainingPlanId}`
                        );
                      }}
                    >
                      {training.name}
                    </AcceptedChip>
                  )}
                  {training.status === "Invited" && (
                    <InviteChip
                      onClick={() => {
                        navigate(
                          `/implementations/training-schedule/${planId}/${companyId}/${activityId}/${activityTypeStub}/${activityTypeId}/${training.trainingPlanId}`
                        );
                      }}
                    >
                      {training.name}
                    </InviteChip>
                  )}
                </>
              ))}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: t("training.tableHeaders.action"),
      width: 200,
      renderCell: (trainingArray) => {
        let checkAllMarked = false;
        for (let i = 0; i < trainingArray.row.training.length; i++) {
          if (trainingArray.row.training[i].markedDate == null) {
            checkAllMarked = false;
            break;
          } else {
            checkAllMarked = true;
          }
        }
        return (
          <>
            {checkAllMarked ? (
              <PrimaryButton
                label={t("training.alreadyMarked")}
                onClick={() => {}}
                disabled={true}
              />
            ) : (
              <PrimaryButton
                label={t("training.markCompleted")}
                onClick={() => {
                  setUserTrainings(trainingArray.row.training)
                  setDialogUser(trainingArray.row.id);
                  setDialogOpen(true);
                }}
                disabled={false}
              />
            )}
          </>
        );
      },
    },
  ];

  const handleMarkOnSuccess = async () => {
    getAllUsers();
    setDialogOpen(false);
    setUserTrainings([])
  };
  const getAllUsers = async () => {
    try {
      setLoading(true);
      let query = `companyId=${companyId}&page=${filterParams.pageNo}&pageSize=${filterParams.pageSize}`;
      let tempUsers = [];
      let GetRequest = await getTrainingPlanUsersByImplementation(query);
      GetRequest?.forEach((user) => {
        let tempTrainings = [];
        for (let i = 0; i < user.TraineeUsers.length; i++) {
          tempTrainings.push({
            id: user.TraineeUsers[i].id,
            trainingPlanId: user.TraineeUsers[i].TrainingPlan.id,
            name: user.TraineeUsers[i].TrainingPlan.TrainingType.name,
            status:
              user.TraineeUsers[i].adminStatus === "Attended"
                ? "Attended"
                : "Invited",
            markedDate: user.TraineeUsers[i].markedDate,
          });
        }
        tempUsers.push({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          training: tempTrainings,
        });
      });
      setTableInfo({
        totalCount: tempUsers.length,
        totalPages: Math.ceil(
          parseFloat(tempUsers.length / filterParams.pageSize)
        ),
      });
      setTrainingRows(tempUsers);
      setLoading(false);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.trainedUsers"),
        "errorTrainedUsers"
      );
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [filterParams]);

  return (
    <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "15px",
            }}
          >
            <div>
              <Typography variant="h7">{t("training.key")} :</Typography>
            </div>
            <div>
              <InviteChip>{t("training.invited")}</InviteChip>
            </div>
            <div>
              <AcceptedChip>{t("training.attended")}</AcceptedChip>
            </div>
          </Grid>
          <Grid
            style={{ paddingTop: "50px", paddingLeft: "60px" }}
            container
            spacing={5}
          >
            <DefaultTable
              rows={trainingRows}
              columns={trainingColumns}
              loading={loading}
              filterParams={filterParams}
              setFilterParams={(e)=>{
                if(!(e.page=== filterParams.page) || !(e.pageSize===filterParams.pageSize)){
                  setFilterParams(e)
                }
              }}
              tableInfo={tableInfo}
            />
          </Grid>
        </Grid>
        <InfoBox
          overflowV
          handleClose={() => setDialogOpen(false)}
          disabled={false}
          open={dialogOpen}
          title={t('training.markUserTrained')}
          content={
            <MarkComplete
              userId={dialogUser}
              trainings={userTrainings}
              onSuccess={() => {
                handleMarkOnSuccess();
              }}
            />
          }
          // minWidth="1000px"
        />
    </>
  )
};
