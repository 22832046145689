module.exports = {
  en: {
    cardTitle: "Observation Card",
    name: "Observer name",
    date: "Date",
    time: "Time",
    area: "Area",
    loc: "Specific Location",
    observed: "What have you observed?",
    count: "Number of people",
    safe: "Safe",
    unsafe: "Unsafe",
    feedback: "What feedback did you give?",
    rootCause: "What is the root cause of the behavior?",
    actionTaken: "Action taken / required",
    suggestion: "Suggestion",
    behaviors: "Behaviors",
  },
  ar: {
    cardTitle: "طباعة بطاقة الملاحظة ",
    name: "الاسم",
    date: "تاريخ",
    time: "الوقت",
    area: "المنطقة",
    loc: "الموقع بالتحديد",
    observed: "ما الذي رأيــتة؟",
    count: "عدد",
    safe: "آمن",
    unsafe: "غير آمن",
    feedback: "ما هي التعليقات التي أبديتها؟                        ",
    rootCause: "ما هو السبب الجوهري المؤدي لهذالسلوك؟",
    actionTaken: "ما هو الإجراء المتبع الذي أتخذ او سيتخذ؟",
    suggestion: "اقتـراح",
    behaviors: "السلوكيات",
  },
};
