import apiHelper from "./apiHelper";

export const getCompaniesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/companies`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompaniesUsingLocationApi = (locationId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/companies/location/${locationId}`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompaniesUsingDirectorateApi = (directorateId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/companies/directorate/${directorateId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyDetailsApi = (companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/companies/${companyId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
