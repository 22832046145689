import React from "react";

const EnIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7553 32V18.22H21.3953V19.88H14.6753V24.2H20.1953V25.82H14.6753V30.34H21.3953V32H12.7553ZM23.81 32V18.22H26.97L31.93 29.86H32.33V18.22H34.25V32H31.15L26.11 20.34H25.73V32H23.81Z"
        fill="black"
      />
    </svg>
  );
};

export default EnIcon;
