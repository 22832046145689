import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography, Box, Stack, IconButton } from "@mui/material";
import {
  InfoOutlined,
  DeleteOutlineOutlined,
  FileUpload,
} from "@mui/icons-material";
import SecondaryButton from "../buttons/SecondaryButton";
import Dropzone from "react-dropzone";
import { displayToast } from "../../utils/general";
import HelpText from "../HelpText";

FileUploader.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  setFiles: PropTypes.func,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  removedFiles: PropTypes.arrayOf(PropTypes.number),
  setRemovedFiles: PropTypes.func,
  multiple: PropTypes.bool,
  labelText: PropTypes.string,
  buttonText: PropTypes.string,
  acceptedFormats: PropTypes.arrayOf(PropTypes.string),
};

const defaultAcceptedFormats = [
  "image/png",
  "image/jpeg",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.red,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverRed,
  },
}));

const IconDeleteStyled = styled(DeleteOutlineOutlined)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function FileUploader({
  helpText,
  files,
  setFiles,
  disabled,
  removedFiles,
  setRemovedFiles,
  multiple = true,
  labelText,
  buttonText,
  acceptedFormats = defaultAcceptedFormats,
}) {
  const { t } = useTranslation();
  const handleRemoveFile = (index, fileObj) => {
    const currentFiles = files;
    const finalFiles = _.filter(currentFiles, (value, i) => {
      return i !== index;
    });
    if (fileObj.oldFile) {
      setRemovedFiles([...removedFiles, parseInt(fileObj.id)]);
    }
    setFiles(finalFiles);
  };

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ pb: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">
          {labelText || t("general.attachments")}
        </Typography>
        {helpText && (
          <HelpText title={helpText}>
            <IconStyle />
          </HelpText>
        )}
      </Stack>

      {files && files.length > 0 ? (
        files.map((f, i) => (
          <Stack direction="row" style={{ marginTop: "8px" }}>
            <div
              style={{
                width: "95%",
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                border: "1px solid #C2C2C2",
                borderRadius: "10px",
                height: "43px",
                display: "flex",
                alignItems: "center",
                padding: "0 18px",
                marginRight: "32px",
              }}
              key={`uploaded-attachment-${i}-${files[i].name}`}
            >
              <Typography variant="body1">{files[i].name}</Typography>
            </div>

            <HelpText title={t("general.removeAttached")}>
              <IconButtonStyle
                aria-label={t("general.removeAttached")}
                onClick={() => handleRemoveFile(i, files[i])}
                disabled={disabled}
              >
                <IconDeleteStyled />
              </IconButtonStyle>
            </HelpText>
          </Stack>
        ))
      ) : (
        <Typography variant="body1">{t("general.noFiles")}</Typography>
      )}

      <Dropzone
        multiple={multiple || false}
        fileLimit={multiple ? undefined : 1}
        accept={acceptedFormats}
        onDrop={(acceptedFiles) => {
          let fileTooLarge = false;
          const finalFiles = [];
          files.forEach((f) => {
            finalFiles.push(f);
          });
          acceptedFiles.forEach((f) => {
            finalFiles.push(f);
            //  5mb limit per file
            if (f.size > 5242880) {
              fileTooLarge = true;
            }
          });
          if (fileTooLarge) {
            displayToast(null, "pureError", t("errors.fileTooLarge"));
          } else {
            if (multiple) {
              setFiles(finalFiles);
            } else {
              //  Replace files with the latest one only
              const latestFile = finalFiles.pop();
              setFiles([latestFile]);
            }
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ marginTop: "14px" }}>
            <input {...getInputProps()} />
            <SecondaryButton
              icon={<FileUpload />}
              label={buttonText || t("general.selectFiles")}
              alignment={"left"}
              disabled={disabled}
            />
          </div>
        )}
      </Dropzone>
    </Box>
  );
}
