import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { displayToast, getMonthDiff } from "../../utils/general";
//  mui
import { InfoOutlined } from "@mui/icons-material";
import CalendarIcon from "@mui/icons-material/EventNote";
import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
//  components
import SecondaryButton from "../buttons/SecondaryButton";
import InfoBox from "../InfoBox";
import DateInput from "./DateInput";
import HelpText from "../HelpText";

DateRangeInput.propTypes = {
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handler: PropTypes.func,
  offOption: PropTypes.bool,
  dateEnabled: PropTypes.bool,
  setDateEnabled: PropTypes.func,
};

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

export default function DateRangeInput({
  disabled,
  helpText,
  label,
  startDate,
  endDate,
  handler,
  offOption = false,
  dateEnabled = true,
  setDateEnabled,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [internalStart, setInternalStart] = useState(null);
  const [internalEnd, setInternalEnd] = useState(null);

  const handleDateConfirm = () => {
    try {
      if (getMonthDiff(internalStart, internalEnd) < 1) {
        throw t("errors.monthError");
      } else {
        handler(internalStart, internalEnd);
        setOpen(false);
      }
    } catch (err) {
      displayToast(null, "pureError", err);
    }
  };

  useEffect(() => {
    if (startDate) {
      setInternalStart(startDate);
    }
    if (endDate) {
      setInternalEnd(endDate);
    }
  }, [startDate, endDate]);

  return (
    <>
      <Box>
        {label && (
          <Stack
            direction="row"
            sx={{ pb: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{label}</Typography>
            {helpText && (
              <HelpText title={helpText}>
                <IconStyle />
              </HelpText>
            )}
          </Stack>
        )}
        <Stack direction="row" spacing={2}>
          <SecondaryButton
            icon={<CalendarIcon />}
            label={
              offOption && !dateEnabled
                ? t("general.dateFilter")
                : `${moment(startDate).format("DD/MM/YY")} - ${moment(
                    endDate
                  ).format("DD/MM/YY")}`
            }
            onClick={() => setOpen(true)}
            disabled={disabled}
          />
        </Stack>
      </Box>
      <InfoBox
        compact
        disabled={disabled}
        open={open}
        title={t("observations.viewObservations.filters.selectDateRange")}
        content={
          <Stack spacing={2} direction="column">
            {offOption && setDateEnabled && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dateEnabled}
                    name={"dateEnablerInput"}
                    onChange={(e) => setDateEnabled(e.target.checked)}
                    sx={{
                      color: theme.palette.primary.orange,
                      "&.Mui-checked": {
                        color: theme.palette.primary.orange,
                      },
                      "&:hover": {
                        backgroundColor: "rgba(248,147,31,0.08)",
                      },
                    }}
                  />
                }
                disabled={disabled}
                label={t("observations.viewObservations.filters.dateRange")}
              />
            )}
            <DateInput
              disabled={disabled || (offOption && !dateEnabled)}
              label={t("implementations.startDate")}
              date={internalStart}
              handler={(newDate) => setInternalStart(newDate)}
              ukForm
            />
            <DateInput
              disabled={disabled || (offOption && !dateEnabled)}
              label={t("implementations.endDate")}
              date={internalEnd}
              handler={(newDate) => setInternalEnd(newDate)}
              ukForm
            />
          </Stack>
        }
        buttonLabel={t("confirm")}
        handleClose={() => setOpen(false)}
        handleAction={handleDateConfirm}
      />
    </>
  );
}
