import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import DateInput from "../../../components/inputs/DateInput";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
//Api
import { createVisit } from "../../../api/implementation";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { displayToast } from "../../../utils/general";

export default function ScheduleVisit(props) {
  const { planId, activityId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);

  const handleSchedule = async () => {
    try {
      setLoading(true);
      let dataObj = {
        startDate: moment(startDate).startOf("day").toISOString(),
        meetingType: props?.type,
        activityId,
        planId,
      };
      if (!startDate) {
        throw t("implementations.schedule.errNoDate");
      }
      let schedule = await createVisit(dataObj);
      if (schedule) {
        props.onSuccess();
        setLoading(false);
      }
    } catch (e) {
      displayToast(setLoading, "error", e, "errorScheduleTraining");
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <DateInput
            disabled={loading}
            // label={"*Visit on"}
            date={startDate}
            handler={(newDate) => {
              setStartDate(newDate);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PrimaryButton
            label={
              props?.type === "Leadership Visibility"
                ? t("implementations.schedule.createVisit")
                : t("implementations.schedule.createMeeting")
            }
            alignment="left"
            onClick={() => {
              handleSchedule();
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </>
  );
}
