import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
//  Material UI
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Stack } from "@mui/material";
//  Components
import TextInput from "./TextInput";
import LikertInput from "./LikertInput";

ReviewInput.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  handler: PropTypes.func,
  languageString: PropTypes.string,
};

export default function ReviewInput({
  questions,
  disabled,
  languageString,
  handler,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderHeaderQuestion = ({ itemText }) => (
    <Typography variant="h6">{itemText}</Typography>
  );

  const renderTextQuestion = ({
    itemText,
    itemTextAR,
    itemTextHI,
    questionNumber,
    responseAnswer,
  }) => (
    <Stack spacing={1}>
      <Typography variant="h7">{`${questionNumber}. ${
        languageString === "en"
          ? itemText
          : languageString === "ar"
          ? itemTextAR
          : itemTextHI
      }`}</Typography>
      <TextInput disabled={disabled} value={responseAnswer || ""} />
    </Stack>
  );

  const renderLikertQuestion = ({
    itemText,
    itemTextHI,
    itemTextAR,
    questionNumber,
    id,
    responseScore,
  }) => (
    <Stack spacing={2}>
      <Typography variant="h7">{`${questionNumber}. ${
        languageString.includes("en")
          ? itemText
          : languageString === "ar"
          ? itemTextAR
          : itemTextHI
      }`}</Typography>
      <LikertInput
        disabled={disabled}
        activeValue={responseScore || 3}
        languageString={languageString}
      />
    </Stack>
  );

  return (
    <Grid
      container
      spacing={3}
      style={{ direction: languageString === "ar" ? "rtl" : "ltr" }}
    >
      {questions && questions.length > 0
        ? questions.map((q, index) => (
            <Grid
              item
              xs={12}
              style={{
                background: index % 2 ? "#FFF3E4" : "none",
                padding: "28px 10px 32px 32px",
              }}
            >
              {q.itemType === "HEADER"
                ? renderHeaderQuestion(q)
                : q.itemType === "QUESTION" && q.questionType === "TEXTFIELD"
                ? renderTextQuestion(q)
                : renderLikertQuestion(q, languageString)}
            </Grid>
          ))
        : t("reviews.loadingQs")}
    </Grid>
  );
}
