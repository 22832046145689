import PropTypes from "prop-types";
import moment from "moment";
import faker from "faker";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { recolor } from "../../utils/general";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/useAuth";
import sidebarConfig from "./SidebarConfig";
// material
import { styled, useTheme } from "@mui/material/styles";
import BackIcon from "@mui/icons-material/ArrowBack";
import {
  IconButton,
  Grid,
  Stack,
  Typography,
  BottomNavigationAction,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import BotIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SendIcon from "@mui/icons-material/Send";
import MeIcon from "@mui/icons-material/Face";
// components
import MenuPopover from "../../components/MenuPopover";
import TextInput from "../../components/inputs/TextInput";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import HelpText from "../../components/HelpText";
//  api
import { askQuestionApi } from "../../api/chatbot";
// ----------------------------------------------------------------------

Chatbot.propTypes = {
  isMobile: PropTypes.bool,
};

const spacePadding = {
  padding: "8px 12px",
};

const IconButtonStyled = styled(IconButton)({
  height: "50px",
  width: "50px",
  borderRadius: "10px",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.11)",
});

const CloseIconStyled = styled(CancelOutlinedIcon)(({ theme }) => ({
  height: "22px",
  width: "22px",
  color: theme.palette.primary.red,
  cursor: "pointer",
}));

const avatarStyle = {
  height: "30px",
  width: "30px",
};

const SystemMessage = styled(`div`)({
  ...spacePadding,
  borderRadius: "8px 8px 8px 0px",
  border: "1px solid #FAC78C",
  background: "#FFE6C7",
  width: "100%",
});

const SystemPrompt = styled(`div`)({
  borderRadius: "8px 8px 8px 0px",
  border: "1px solid #FAC78C",
  width: "100%",
});

const UserMessage = styled(`div`)({
  borderRadius: "8px 8px 0px 8px",
  border: "1px solid #FAC78C",
  background: "#FEFEFE",
  padding: "8px 12px",
  width: "100%",
});

const selectedAnswerDefault = {
  id: null,
  answer: "",
  link: null,
  question: "",
};

export default function Chatbot({ isMobile = false }) {
  const theme = useTheme();
  const { access } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const chatHistoryDefault = [
    {
      answered: false,
      data: [
        {
          answer: t("navBar.chatbot.welcomeMessage"),
        },
      ],
      system: true,
      timeStamp: new Date(),
    },
  ];

  const anchorRef = useRef(null);
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState(chatHistoryDefault);
  const [loading, setLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [submittedQuestion, setSubmittedQuestion] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState(selectedAnswerDefault);
  const [triggerFlag, setTriggerFlag] = useState(false);

  const iconStyled = {
    color: theme.palette.primary.black,
    width: 20,
    height: 20,
  };

  const findAccessFeature = (arr, path) => {
    let result = null;

    arr.find((obj) => {
      if (obj.path === path) {
        result = obj;
        return true;
      }

      if (obj.children && obj.children.length > 0) {
        result = findAccessFeature(obj.children, path);
        return result !== null;
      }

      return false;
    });

    return result;
  };

  const validRoute = (path) => {
    //  Need to go within each object in sidebarConfig to find the route
    const foundAccessName = findAccessFeature(sidebarConfig, path);
    if (!foundAccessName) {
      return false;
    } else if (foundAccessName.accessName === "NONE") {
      return true;
    } else {
      if (foundAccessName.accessName) {
        if (access.includes(foundAccessName.accessName)) {
          return true;
        } else {
          return false;
        }
      } else if (foundAccessName.accessNames) {
        if (foundAccessName.accessNames.type === "OR") {
          const found = access.some((a) =>
            foundAccessName.accessNames.features.includes(a)
          );
          return found ? true : false;
        } else {
          //  Type is AND
          const found = access.every((a) =>
            foundAccessName.accessNames.features.includes(a)
          );
          return found ? true : false;
        }
      }
    }
  };

  const renderSystemMessage = (a) => (
    <Grid item xs={11} style={{ paddingTop: "8px", paddingRight: "24px" }}>
      {!a.answered ? (
        <SystemMessage>
          <Stack spacing={1} direction="column">
            <Typography variant="body1">{a.data[0].answer}</Typography>
            <Typography
              variant="caption"
              style={{ color: theme.palette.primary.hoverGrey }}
            >
              {moment(a.timeStamp).format("hh:mm a DD/MM/YYYY ")}
            </Typography>
          </Stack>
        </SystemMessage>
      ) : (
        <SystemPrompt>
          <Stack spacing={1} direction="column">
            <Typography
              variant="h7"
              style={{
                ...spacePadding,
                background: "#FFE6C7",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottom: "1px solid #FAC78C",
              }}
            >
              {selectedAnswer.id === a.id ? (
                <Stack spacing={1} direction="row">
                  <Typography
                    onClick={() => setSelectedAnswer(selectedAnswerDefault)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <BackIcon style={{ fontSize: "16px" }} />
                  </Typography>
                  {selectedAnswer.question}
                </Stack>
              ) : (
                t("navBar.chatbot.relevantAnswers")
              )}
            </Typography>
            {selectedAnswer.id === a.id ? (
              <>
                <Typography variant="body1" style={spacePadding}>
                  {selectedAnswer.answer}
                </Typography>
                {selectedAnswer.link && validRoute(selectedAnswer.link) ? (
                  <Typography
                    variant="body1"
                    style={{
                      ...spacePadding,
                      cursor: "pointer",
                    }}
                    sx={{
                      color: theme.palette.primary.hyperlinkBlue,
                      "&:hover": {
                        color: recolor(theme.palette.primary.hyperlinkBlue, 40),
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      navigate(selectedAnswer.link);
                      setOpen(false);
                    }}
                  >
                    Link
                  </Typography>
                ) : selectedAnswer.link ? (
                  <Typography variant="caption" style={spacePadding}>
                    {t("navBar.chatbot.linkAccess")}
                  </Typography>
                ) : null}
              </>
            ) : (
              a.data.map((ans) =>
                ans.answer ? (
                  <>
                    <Typography
                      noWrap
                      variant="body2"
                      style={{
                        fontWeight: 400,
                        padding: "0 12px 0 12px",
                      }}
                      onClick={() => setSelectedAnswer({ ...ans, id: a.id })}
                    >
                      {ans.question}
                    </Typography>
                    <Typography
                      noWrap
                      variant="h8plain"
                      style={{
                        cursor: "pointer",
                        padding: "0 12px 8px 12px",
                        borderBottom: "1px solid #FAC78C",
                        marginTop: "2px",
                      }}
                      onClick={() => setSelectedAnswer({ ...ans, id: a.id })}
                      sx={{
                        color: theme.palette.primary.hyperlinkBlue,
                        "&:hover": {
                          color: recolor(
                            theme.palette.primary.hyperlinkBlue,
                            40
                          ),
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {ans.answer}
                    </Typography>
                  </>
                ) : null
              )
            )}
            <Typography
              variant="caption"
              style={{
                color: theme.palette.primary.hoverGrey,
                padding: "0 12px 8px 12px",
              }}
            >
              {moment(a.timeStamp).format("hh:mm a DD/MM/YYYY ")}
            </Typography>
          </Stack>
        </SystemPrompt>
      )}
    </Grid>
  );

  const renderUserMessage = (m) => (
    <Grid
      item
      xs={11}
      justifyContent="flex-end"
      style={{ paddingLeft: 0, paddingRight: "16px", paddingTop: "8px" }}
    >
      <UserMessage>
        <Stack spacing={1} direction="column">
          <Typography variant="body1">{m.message}</Typography>
          <Typography
            variant="caption"
            style={{ color: theme.palette.primary.hoverGrey }}
          >
            {moment(m.timeStamp).format("hh:mm a DD/MM/YYYY ")}
          </Typography>
        </Stack>
      </UserMessage>
    </Grid>
  );

  const handleGetAnswer = async () => {
    try {
      setLoading(true);
      const answer = await askQuestionApi(submittedQuestion);
      const newHistory = [...chatHistory];
      newHistory.push({
        ...answer,
        id: faker.datatype.uuid(),
        system: true,
        timeStamp: new Date(),
      });
      setChatHistory(newHistory);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitQuestion = () => {
    const newHistory = [...chatHistory];
    newHistory.push({
      message: inputMessage,
      system: false,
      timeStamp: new Date(),
    });
    setChatHistory(newHistory);
    setSubmittedQuestion(inputMessage);
    setTriggerFlag(!triggerFlag);
    setInputMessage("");
  };

  //  Scroll to bottom when a new message appears
  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [containerRef, chatHistory.length]);

  useEffect(() => {
    if (submittedQuestion) {
      handleGetAnswer();
    }
  }, [triggerFlag]);

  useEffect(() => {
    setSelectedAnswer(selectedAnswerDefault);
    setChatHistory(chatHistoryDefault);
  }, [open]);

  return (
    <>
      {isMobile ? (
        <BottomNavigationAction
          onClick={() => setOpen(true)}
          icon={<ContactSupportIcon />}
        />
      ) : (
        <HelpText title={t("navBar.chatbot.tooltip")}>
          <IconButtonStyled
            ref={anchorRef}
            size="large"
            color={open ? "primary" : "default"}
            onClick={() => setOpen(true)}
          >
            <ContactSupportIcon style={iconStyled} />
          </IconButtonStyled>
        </HelpText>
      )}
      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{ width: 460, pt: "18px", pb: "18px", pl: "18px", pr: "18px" }}
      >
        <Grid container sx={{ mb: 3 }}>
          <Grid item xs={11}>
            <Typography variant="h5">{t("navBar.chatbot.title")}</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <CloseIconStyled
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        {/* Chat log */}
        <Stack
          ref={containerRef}
          style={{
            height: "400px",
            overflowX: "hidden",
            overflowY: "auto",
            paddingTop: "8px",
          }}
          spacing={1}
        >
          {chatHistory.map((m, index) =>
            m.system ? (
              <Grid
                container
                spacing={2}
                key={`chatbot-container-message-${index}`}
              >
                <Grid
                  key={`chatbot-system-message-${index}`}
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <BotIcon
                    style={{
                      ...avatarStyle,
                      fill: theme.palette.primary.orange,
                    }}
                  />
                </Grid>
                {renderSystemMessage(m)}
              </Grid>
            ) : (
              <Grid
                container
                spacing={2}
                key={`chatbot-container-message-${index}`}
              >
                {renderUserMessage(m)}
                <Grid
                  key={`chatbot-user-message-${index}`}
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingRight: "16px",
                  }}
                >
                  <MeIcon
                    style={{
                      ...avatarStyle,
                      fill: theme.palette.primary.hoverGrey,
                    }}
                  />
                </Grid>
              </Grid>
            )
          )}
        </Stack>
        <Grid container sx={{ mt: 3 }} spacing={2}>
          <Grid item xs={12} md={9}>
            <TextInput
              placeholder={t("navBar.chatbot.placeholder")}
              value={inputMessage}
              handler={(e) => setInputMessage(e.target.value)}
              keyPressHandler={(e) => {
                if (e.key === "Enter" && inputMessage) {
                  submitQuestion();
                  e.preventDefault();
                }
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrimaryButton
              label={t("navBar.chatbot.send")}
              alignment="left"
              disabled={loading || !inputMessage}
              onClick={submitQuestion}
              style={{ padding: "12px" }}
              icon={<SendIcon />}
            />
          </Grid>
        </Grid>
      </MenuPopover>
    </>
  );
}
