import { useEffect, useState, useRef, useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import "./styles.css";
import {
  addChartDetailsDefaults,
  chartOptionDefaults,
  gridDefaults,
} from "./defaults";
import {
  renderChart,
  submitAddChart,
  removeWidget,
  handleLayoutChange,
  cancelLayoutEdit,
  saveLayout,
  setEditMode,
  handleTabChange,
  getUserPreferences,
} from "./utils";
// material
import {
  Box,
  Stack,
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Badge,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/CancelOutlined";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";

// components
import Page from "../../components/Page";
import PageLoader from "../../components/PageLoader";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import TabsBar from "../../components/navigation/TabsBar";
import InfoBox from "../../components/InfoBox";
import SelectInput from "../../components/inputs/SelectInput";
import HelpText from "../../components/HelpText";
import {
  IconButtonStyled,
  IconStyled,
} from "../observation/ListObservations/styledComponents";
import { filterModalDefaults } from "../dashboard/defaults";
import { filterParamDefaults } from "../dashboard/defaults";
import RadioSet from "../../components/buttons/RadioSet";
import {
  getCommonFiltersApi,
  getFeedbackSentiment,
  getNGrams,
  getPercentTotalsApi,
  getQualityIndex,
  getRiskBarGraph,
  getRiskHeatmap,
  getSentimentByLocation,
  getTopBehavioursRisk,
  getTopBottomBehaviourBySentiment,
  getTotalObservationOverviewApi,
  getWordcloudTextAnalysis,
} from "../../api/ai_dashboard";
import HeatmapChart from "../../components/charts/HeatmapChart";
import BarChart from "../../components/charts/BarChart";
import WordcloudChart from "../../components/charts/WordcloudChart";
// ----------------------------------------------------------------------

const ResponsiveGridLayout = WidthProvider(Responsive);

const iconStyles = {
  cursor: "pointer",
  height: "18px",
  width: "18px",
};

export default function DashboardApp() {
  const { t } = useTranslation();
  const theme = useTheme();
  const previewRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("overview");
  const [chartIds, setChartIds] = useState({});
  const [mode, setMode] = useState("view");
  const [gridOptions, setGridOptions] = useState(gridDefaults);
  const [dashboardLayout, setDashboardLayout] = useState([]);
  const [oldLayout, setOldLayout] = useState([]);
  const [oldChartIds, setOldChartIds] = useState({});
  const [addChartDetails, setAddChartDetails] = useState(
    addChartDetailsDefaults
  );
  const [chartOptions, setChartOptions] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [filterModal, setFilterModal] = useState(filterModalDefaults);
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  // const [chartFilterModal, setChartFilterModal] = useState(chartFilterModalDefaults);
  // const [chartFilterParams, setChartFilterParams] = useState(chartFilterParamDefaults);
  const [triggerFlag, setTriggerFlag] = useState(false);

  const [commonFilterListDirectorate, setCommonFilterListDirectorate] =
    useState([]);
  const [commonFilterListDiscipline, setCommonFilterListDiscipline] = useState(
    []
  );
  const [commonFilterListLocation, setCommonFilterListLocation] = useState([]);

  const [
    totalObservationOverviewViewByFilter,
    setTotalObservationOverviewViewByFilter,
  ] = useState("location");
  const [
    totalObservationOverviewLocationFilter,
    setTotalObservationOverviewLocationFilter,
  ] = useState("");
  const [
    totalObservationOverviewDisciplineFilter,
    setTotalObservationOverviewDisciplineFilter,
  ] = useState("catering");
  const [OverviewDashboardFilter, setOverviewDashboardFilter] =
    useState("Safe");

  const [totalObservationOverviewData, setTotalObservationOverviewData] =
    useState(null);
  const [totalPercentData, setTotalPercentData] = useState(null);
  const [sentimentByLocationData, setSentimentByLocationData] = useState(null);
  const [topBottomBySentimentData, setTopBottomBySentimentData] =
    useState(null);
  const [wordcloud1Data, setWordcloud1Data] = useState(null);
  const [wordcloud2Data, setWordcloud2Data] = useState(null);
  const [mostCommonNGramsData, setMostCommonNGramsData] = useState(null);
  const [qualityIndexData, setQualityIndexData] = useState(null);
  const [feedbackSentimentData, setFeedbackSentimentData] = useState(null);
  const [riskHeatmapData, setRiskHeatmapData] = useState(null);
  const [riskBarGraphData, setRiskBarGraphData] = useState(null);
  const [topBehaviorsData, setTopBehaviorsData] = useState(null);

  const testGridCallback = (
    layout,
    oldsetTopBehaviorsDataItem,
    newItem,
    placeholder
  ) => {
    // console.log(layout, oldItem, newItem, placeholder);
  };

  useEffect(() => {
    if (tabValue === "overview") {
      setChartOptions(chartOptionDefaults.overview);
    } else if (tabValue === "textAnalysis") {
      setChartOptions(chartOptionDefaults.textAnalysis);
    } else if (tabValue === "sentiment") {
      setChartOptions(chartOptionDefaults.sentiment);
    } else if (tabValue === "riskSnapshot") {
      setChartOptions(chartOptionDefaults.riskSnapshot);
    } else {
      setChartOptions([{ label: "No Charts Available", value: "none" }]);
    }
  }, [tabValue]);

  const renderTopNavigation = () => (
    <Grid container sx={{ mb: 3 }}>
      <Grid item xs={12} md={8}>
        <TabsBar
          value={tabValue}
          handler={(event, value) =>
            handleTabChange(
              event,
              value,
              setTabValue,
              setFilterModal,
              filterModal
            )
          }
          tabs={[
            {
              label: t("dashboards.tabs.overview"),
              value: 0,
              key: "overview",
            },
            {
              label: t("dashboards.tabs.textAnalysis"),
              value: 0,
              key: "textAnalysis",
            },
            {
              label: t("dashboards.tabs.sentiment"),
              value: 0,
              key: "sentiment",
            },
            {
              label: t("dashboards.tabs.riskSnapshot"),
              value: 0,
              key: "riskSnapshot",
            },
          ]}
        />
      </Grid>
      <Grid item xs={6} md={3}></Grid>
      <Grid item xs={6} md={1}>
        <Stack direction="row" spacing={2}>
          {/*{mode === "edit" && (*/}
          {/*    <SecondaryButton*/}
          {/*        icon={<AddCircleIcon/>}*/}
          {/*        label={t("dashboards.buttons.addChart")}*/}
          {/*        alignment={"left"}*/}
          {/*        onClick={() =>*/}
          {/*            setAddChartDetails({...addChartDetails, open: true})*/}
          {/*        }*/}
          {/*        disabled={loading}*/}
          {/*    />*/}
          {/*)}*/}
          {mode === "view" && (
            <Tooltip title={t("general.advancedFilters")}>
              <IconButtonStyled
                aria-label={"Open filters"}
                onClick={() =>
                  setFilterModal({
                    ...filterModal,
                    open: true,
                    tab: tabValue,
                  })
                }
              >
                <Badge color="primary" variant="dot">
                  <IconStyled />
                </Badge>
              </IconButtonStyled>
            </Tooltip>
          )}
          {/*{mode === "view" && (*/}
          {/*    <SecondaryButton*/}
          {/*        icon={<EditIcon/>}*/}
          {/*        label={t("dashboards.buttons.editLayout")}*/}
          {/*        alignment={"left"}*/}
          {/*        onClick={() =>*/}
          {/*            setEditMode(*/}
          {/*                gridOptions,*/}
          {/*                setGridOptions,*/}
          {/*                setMode,*/}
          {/*                dashboardLayout,*/}
          {/*                setOldLayout,*/}
          {/*                chartIds,*/}
          {/*                setOldChartIds*/}
          {/*            )*/}
          {/*        }*/}
          {/*        disabled={loading}*/}
          {/*    />*/}
          {/*)}*/}
          {/*{mode === "edit" && (*/}
          {/*    <>*/}
          {/*        <SecondaryButton*/}
          {/*            icon={<SaveIcon/>}*/}
          {/*            label={t("dashboards.buttons.saveLayout")}*/}
          {/*            alignment={"left"}*/}
          {/*            onClick={() =>*/}
          {/*                saveLayout(*/}
          {/*                    gridOptions,*/}
          {/*                    setGridOptions,*/}
          {/*                    setMode,*/}
          {/*                    setLoading,*/}
          {/*                    dashboardLayout,*/}
          {/*                    chartIds,*/}
          {/*                    userPreferences*/}
          {/*                )*/}
          {/*            }*/}
          {/*            disabled={loading}*/}
          {/*        />*/}
          {/*        <SecondaryButton*/}
          {/*            icon={<DeleteIcon/>}*/}
          {/*            label={t("dashboards.buttons.cancelLayout")}*/}
          {/*            alignment={"left"}*/}
          {/*            onClick={() =>*/}
          {/*                cancelLayoutEdit(*/}
          {/*                    oldLayout,*/}
          {/*                    setDashboardLayout,*/}
          {/*                    setMode,*/}
          {/*                    oldChartIds,*/}
          {/*                    setChartIds,*/}
          {/*                    gridOptions,*/}
          {/*                    setGridOptions*/}
          {/*                )*/}
          {/*            }*/}
          {/*            disabled={loading}*/}
          {/*        />*/}
          {/*    </>*/}
          {/*)}*/}
        </Stack>
      </Grid>
    </Grid>
  );

  const renderAddChart = () => (
    <InfoBox
      disabled={loading || !addChartDetails.chartId}
      open={addChartDetails.open}
      title={t(`dashboards.addCharts.title`)}
      minWidth="60vw"
      content={
        <Stack spacing={3}>
          {addChartDetails.chartId ? (
            <Card
              className="chartContainer"
              ref={previewRef}
              style={{
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                borderRadius: 0,
                border: "1px solid #C2C2C2",
                minHeight: "50vh",
              }}
            >
              <CardHeader title="Observation status" />
              {renderChart(addChartDetails.chartId)}
            </Card>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                borderRadius: 0,
                border: "1px solid #C2C2C2",
                minHeight: "50vh",
              }}
            >
              <Typography variant="h5">
                {t(`dashboards.addCharts.previewText`)}
              </Typography>
            </div>
          )}
          <SelectInput
            value={addChartDetails.chartId}
            handler={(e) =>
              setAddChartDetails({
                ...addChartDetails,
                chartId: e.target.value,
              })
            }
            options={chartOptions}
            label={t(`dashboards.addCharts.title`)}
            disabled={loading}
          />
        </Stack>
      }
      buttonLabel={t(`dashboards.addCharts.submit`)}
      handleClose={() => setAddChartDetails(addChartDetailsDefaults)}
      handleAction={() => {
        submitAddChart(
          dashboardLayout,
          setDashboardLayout,
          addChartDetails.chartId,
          chartIds,
          setChartIds
        );
        setAddChartDetails(addChartDetailsDefaults);
      }}
    />
  );

  const DashboardGrid = ({ layout }) => {
    const children = useMemo(() => {
      return layout.map((item, index) => (
        <div
          key={item.i}
          style={{
            border: "1px solid #F7D5AE",
            background:
              "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
            borderRadius: 0,
            padding: "18px",
          }}
          data-grid={layout[index]}
        >
          <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
            <Typography variant="h6">{chartIds[item.i].title}</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <HelpText title={t("dashboards.helpText.filter")}>
                <FilterIcon
                  style={{ ...iconStyles, color: theme.palette.primary.grey }}
                  // onClick={() =>
                  //     setChartFilterModal({
                  //         ...chartFilterModal,
                  //         open: true,
                  //         chartId: tabValue,
                  //     })
                  // }
                />
              </HelpText>
              {mode === "edit" && (
                <HelpText title={t("dashboards.helpText.remove")}>
                  <DeleteIcon
                    style={{ ...iconStyles, color: theme.palette.primary.red }}
                    onClick={() =>
                      removeWidget(
                        item.i,
                        dashboardLayout,
                        setDashboardLayout,
                        chartIds,
                        setChartIds
                      )
                    }
                  />
                </HelpText>
              )}
            </Stack>
          </Stack>
          {chartIds[item.i]
            ? renderChart(chartIds[item.i].type)
            : "No id provided."}
        </div>
      ));
    }, [layout]);

    return (
      <ResponsiveGridLayout
        onLayoutChange={(newLayout) =>
          handleLayoutChange(newLayout, dashboardLayout, setDashboardLayout)
        }
        onResize={testGridCallback}
        {...gridOptions}
      >
        {children}
      </ResponsiveGridLayout>
    );
  };

  useEffect(() => {
    getCommonFiltersApi()
      .then((r) => {
        let discipline = [{ label: "All", value: "" }];
        let directorate = [{ label: "All", value: "" }];
        let location = [{ label: "All", value: "" }];
        for (let i = 0; i < r.data.data.Discipline.length; i++) {
          let temp = {
            label: r.data.data.Discipline[i],
            value: r.data.data.Discipline[i],
          };
          discipline.push(temp);
        }
        for (let i = 0; i < r.data.data.Directorate.length; i++) {
          let temp = {
            label: r.data.data.Directorate[i],
            value: r.data.data.Directorate[i],
          };
          directorate.push(temp);
        }
        for (let i = 0; i < r.data.data.Location.length; i++) {
          let temp = {
            label: r.data.data.Location[i],
            value: r.data.data.Location[i],
          };
          location.push(temp);
        }
        setCommonFilterListDirectorate(directorate);
        setCommonFilterListDiscipline(discipline);
        setCommonFilterListLocation(location);
      })
      .catch((e) => {
        console.log(e);
      });

    // overview tab
    getTotalObservationOverviewApi(
      filterModal[tabValue].viewby,
      "",
      "",
      filterModal["overview"].observationType,
      filterModal["overview"].directorate
    )
      .then((r) => {
        setTotalObservationOverviewData(r.data.data);
      })
      .catch((e) => console.log(e));

    getPercentTotalsApi(
      filterModal[tabValue].viewbytrends,
      "",
      "",
      filterModal["overview"].observationType,
      filterModal["overview"].directorate
    )
      .then((r) => {
        setTotalPercentData(r.data.data);
      })
      .catch((e) => console.log(e));

    // textanalysis tab

    getQualityIndex(
      "",
      filterModal["textAnalysis"].observationType,
      filterModal["textAnalysis"].viewByQualityIndex,
      filterModal["textAnalysis"].directorate
    )
      .then((r) => {
        setQualityIndexData(r.data);
      })
      .catch((e) => console.log(e));

    getWordcloudTextAnalysis(
      filterModal["textAnalysis"].location,
      filterModal["textAnalysis"].discipline,
      filterModal["textAnalysis"].observationType,
      filterModal["textAnalysis"].directorate
    )
      .then((r) => {
        setWordcloud1Data(r.data.data);
      })
      .catch((e) => console.log(e));

    getNGrams(
      filterModal["textAnalysis"].location,
      filterModal["textAnalysis"].discipline,
      filterModal["textAnalysis"].observationType,
      filterModal["textAnalysis"].gram,
      filterModal["textAnalysis"].directorate
    )
      .then((r) => {
        setMostCommonNGramsData(r.data);
      })
      .catch((e) => console.log(e));

    // sentiment tab
    getSentimentByLocation(
      "",
      "",
      filterModal["sentiment"].observationType,
      filterModal["sentiment"].directorate
    )
      .then((r) => {
        setSentimentByLocationData(r.data.data);
      })
      .catch((e) => console.log(e));

    getTopBottomBehaviourBySentiment(
      filterModal["sentiment"].location,
      filterModal["sentiment"].discipline,
      filterModal["sentiment"].observationType,
      filterModal["sentiment"].directorate
    )
      .then((r) => {
        setTopBottomBySentimentData(r.data);
      })
      .catch((e) => console.log(e));

    getFeedbackSentiment(
      filterModal["sentiment"].location,
      filterModal["sentiment"].discipline,
      filterModal["sentiment"].observationType,
      filterModal["sentiment"].directorate
    )
      .then((r) => {
        setFeedbackSentimentData(r.data);
      })
      .catch((e) => console.log(e));

    // risk tab
    getRiskHeatmap(
      filterModal["riskSnapshot"].observationType,
      filterModal["riskSnapshot"].directorate
    )
      .then((r) => {
        setRiskHeatmapData(r.data.data);
      })
      .catch((e) => console.log(e));

    getRiskBarGraph(
      filterModal["riskSnapshot"].discipline,
      filterModal["riskSnapshot"].location,
      filterModal["riskSnapshot"].directorate
    )
      .then((r) => {
        setRiskBarGraphData(r.data);
      })
      .catch((e) => console.log(e));

    getWordcloudTextAnalysis(
      filterModal["riskSnapshot"].location,
      filterModal["riskSnapshot"].discipline,
      filterModal["riskSnapshot"].observationType,
      filterModal["riskSnapshot"].directorate
    )
      .then((r) => {
        setWordcloud2Data(r.data.data);
      })
      .catch((e) => console.log(e));

    getTopBehavioursRisk(
      filterModal["riskSnapshot"].observationType,
      filterModal["riskSnapshot"].location,
      filterModal["riskSnapshot"].discipline,
      filterModal["riskSnapshot"].directorate
    )
      .then((r) => {
        setTopBehaviorsData(r.data);
      })
      .catch((e) => console.log(e));
  }, [triggerFlag]);

  useEffect(() => {
    getUserPreferences(
      setUserPreferences,
      setDashboardLayout,
      setChartIds,
      setLoading
    );
  }, []);

  const submitFilter = () => {
    const key = filterModal.tab;
    let finalColumnString = "";

    setFilterParams({
      ...filterParams,
      [key]: {
        observationType: filterModal[key].observationType,
        currentCriticalBehaviours: filterModal[key].currentCriticalBehaviours,
      },
    });
    setFilterModal({ ...filterModal, open: false });
    setTriggerFlag(!triggerFlag);
  };

  const handleOverviewRadioFilterChange = (event, value) => {
    const key = filterModal.tab;
    setFilterModal({
      ...filterModal,
      [key]: {
        ...filterModal[key],
        observationType: value,
      },
    });
  };

  const handleTextAnalysisRadioFilterChange = (event, value) => {
    const key = filterModal.tab;
    setFilterModal({
      ...filterModal,
      [key]: {
        ...filterModal[key],
        observationType: value,
      },
    });
  };

  const handleOverviewRadioViewChange = (event, value) => {
    const key = filterModal.tab;
    setFilterModal({
      ...filterModal,
      [key]: {
        ...filterModal[key],
        viewby: value,
      },
    });
    setTriggerFlag(!triggerFlag);
  };

  const handleOverviewRadioTrendsViewChange = (event, value) => {
    const key = filterModal.tab;
    setFilterModal({
      ...filterModal,
      [key]: {
        ...filterModal[key],
        viewbytrends: value,
      },
    });
    setTriggerFlag(!triggerFlag);
  };

  const handleOverviewRadioGramsViewChange = (event, value) => {
    const key = filterModal.tab;
    setFilterModal({
      ...filterModal,
      [key]: {
        ...filterModal[key],
        gram: value,
      },
    });
    setTriggerFlag(!triggerFlag);
  };

  const handleViewByRadioQualityIndexChange = (event, value) => {
    const key = filterModal.tab;
    setFilterModal({
      ...filterModal,
      [key]: {
        ...filterModal[key],
        viewByQualityIndex: value,
      },
    });
    setTriggerFlag(!triggerFlag);
  };

  const renderChartFilters = () => {
    return <>Chart Filter</>;
  };

  const renderObservationFilters = () => {
    const key = filterModal.tab;
    if (key === "overview") {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {t(`observations.viewObservations.filters.display`)}
              </Typography>
              <RadioSet
                disabled={loading}
                label=""
                value={filterModal[key].observationType}
                handleChange={handleOverviewRadioFilterChange}
                options={[
                  {
                    label: t(
                      `observations.viewObservations.filters.allObservations`
                    ),
                    value: "",
                  },
                  {
                    label: t(`observations.viewObservations.filters.safeOnly`),
                    value: "SAFE",
                  },
                  {
                    label: t(
                      `observations.viewObservations.filters.unsafeOnly`
                    ),
                    value: "UNSAFE",
                  },
                ]}
              />
            </Grid>
          </Grid>
          {/*<Grid item container xs={12} spacing={3}>*/}
          {/*    <Grid item xs={12}>*/}
          {/*        <FormControlLabel*/}
          {/*            control={*/}
          {/*                <Checkbox*/}
          {/*                    checked={filterModal[key].currentCriticalBehaviours}*/}
          {/*                    name={"currentCriticalBehaviours"}*/}
          {/*                    onChange={() =>*/}
          {/*                        setFilterModal({*/}
          {/*                            ...filterModal,*/}
          {/*                            [key]: {*/}
          {/*                                ...filterModal[key],*/}
          {/*                                currentCriticalBehaviours: !filterModal[key].currentCriticalBehaviours,*/}
          {/*                            },*/}
          {/*                        })*/}
          {/*                    }*/}
          {/*                    sx={{*/}
          {/*                        color: theme.palette.primary.orange,*/}
          {/*                        "&.Mui-checked": {*/}
          {/*                            color: theme.palette.primary.orange,*/}
          {/*                        },*/}
          {/*                        "&:hover": {*/}
          {/*                            backgroundColor: "rgba(248,147,31,0.08)",*/}
          {/*                        },*/}
          {/*                    }}*/}
          {/*                />*/}
          {/*            }*/}
          {/*            disabled={loading}*/}
          {/*            label={t(`observations.viewObservations.filters.currentCritical`)}*/}
          {/*        />*/}
          {/*    </Grid>*/}
          {/*</Grid>*/}
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].directorate}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      directorate: e.target.value,
                    },
                  })
                }
                options={commonFilterListDirectorate}
                label={t(`observations.viewObservations.filters.directorate`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (key === "textAnalysis") {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {t(`observations.viewObservations.filters.display`)}
              </Typography>
              <RadioSet
                disabled={loading}
                label=""
                value={filterModal[key].observationType}
                handleChange={handleTextAnalysisRadioFilterChange}
                options={[
                  {
                    label: t(
                      `observations.viewObservations.filters.allObservations`
                    ),
                    value: "",
                  },
                  {
                    label: t(`observations.viewObservations.filters.safeOnly`),
                    value: "SAFE",
                  },
                  {
                    label: t(
                      `observations.viewObservations.filters.unsafeOnly`
                    ),
                    value: "UNSAFE",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].location}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      location: e.target.value,
                    },
                  })
                }
                options={commonFilterListLocation}
                label={t(`observations.viewObservations.filters.location`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].discipline}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      discipline: e.target.value,
                    },
                  })
                }
                options={commonFilterListDiscipline}
                label={t(`observations.viewObservations.filters.discipline`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].directorate}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      directorate: e.target.value,
                    },
                  })
                }
                options={commonFilterListDirectorate}
                label={t(`observations.viewObservations.filters.directorate`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (key === "sentiment") {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {t(`observations.viewObservations.filters.display`)}
              </Typography>
              <RadioSet
                disabled={loading}
                label=""
                value={filterModal[key].observationType}
                handleChange={handleTextAnalysisRadioFilterChange}
                options={[
                  {
                    label: t(
                      `observations.viewObservations.filters.allObservations`
                    ),
                    value: "",
                  },
                  {
                    label: t(`observations.viewObservations.filters.safeOnly`),
                    value: "SAFE",
                  },
                  {
                    label: t(
                      `observations.viewObservations.filters.unsafeOnly`
                    ),
                    value: "UNSAFE",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].location}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      location: e.target.value,
                    },
                  })
                }
                options={commonFilterListLocation}
                label={t(`observations.viewObservations.filters.location`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].discipline}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      discipline: e.target.value,
                    },
                  })
                }
                options={commonFilterListDiscipline}
                label={t(`observations.viewObservations.filters.discipline`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].directorate}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      directorate: e.target.value,
                    },
                  })
                }
                options={commonFilterListDirectorate}
                label={t(`observations.viewObservations.filters.directorate`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (key === "riskSnapshot") {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {t(`observations.viewObservations.filters.display`)}
              </Typography>
              <RadioSet
                disabled={loading}
                label=""
                value={filterModal[key].observationType}
                handleChange={handleTextAnalysisRadioFilterChange}
                options={[
                  {
                    label: t(
                      `observations.viewObservations.filters.allObservations`
                    ),
                    value: "",
                  },
                  {
                    label: t(`observations.viewObservations.filters.safeOnly`),
                    value: "SAFE",
                  },
                  {
                    label: t(
                      `observations.viewObservations.filters.unsafeOnly`
                    ),
                    value: "UNSAFE",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].location}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      location: e.target.value,
                    },
                  })
                }
                options={commonFilterListLocation}
                label={t(`observations.viewObservations.filters.location`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].discipline}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      discipline: e.target.value,
                    },
                  })
                }
                options={commonFilterListDiscipline}
                label={t(`observations.viewObservations.filters.discipline`)}
                disabled={loading}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={filterModal[key].directorate}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      directorate: e.target.value,
                    },
                  })
                }
                options={commonFilterListDirectorate}
                label={t(`observations.viewObservations.filters.directorate`)}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <Page title="IHTIMAM Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t("dashboards.welcome")}</Typography>
        </Box>
        {renderTopNavigation()}
        {tabValue === "overview" && (
          <div
            style={{
              maxWidth: "100%",
              height: "70vh",
              overflowY: "auto",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 600,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {totalObservationOverviewData ? (
                    <>
                      <RadioSet
                        disabled={loading}
                        label=""
                        value={filterModal[tabValue].viewby}
                        handleChange={handleOverviewRadioViewChange}
                        options={[
                          {
                            label: t(
                              `observations.viewObservations.filters.viewByLocation`
                            ),
                            value: "location",
                          },
                          {
                            label: t(
                              `observations.viewObservations.filters.viewByDiscipline`
                            ),
                            value: "discipline",
                          },
                        ]}
                      />
                      <HeatmapChart
                        chartOptions={{
                          dataLabels: {
                            enabled: true,
                          },
                          xaxis: {
                            type: "category",
                            position: "top",
                            labels: {
                              rotate: -45,
                              trim: true,
                              minHeight: 120,
                              hideOverlappingLabels: false,
                              showDuplicates: true,
                              offsetY: 8,
                            },
                          },
                          axisTicks: {
                            show: false,
                          },
                          labels: {
                            show: true,
                          },
                          colors: ["#008FFB"],
                          title: {
                            text: "Total Observation Overview",
                          },
                        }}
                        chartData={totalObservationOverviewData}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 600,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {totalPercentData ? (
                    <>
                      <RadioSet
                        disabled={loading}
                        label=""
                        value={filterModal[tabValue].viewbytrends}
                        handleChange={handleOverviewRadioTrendsViewChange}
                        options={[
                          {
                            label: t(
                              `observations.viewObservations.filters.actionStatus`
                            ),
                            value: "action",
                          },
                          {
                            label: t(
                              `observations.viewObservations.filters.total`
                            ),
                            value: "total",
                          },
                        ]}
                      />
                      <HeatmapChart
                        chartOptions={{
                          dataLabels: {
                            enabled: true,
                          },
                          xaxis: {
                            type: "category",
                            position: "top",
                            labels: {
                              offsetY: 8,
                            },
                          },
                          axisTicks: {
                            show: false,
                          },
                          labels: {
                            show: true,
                          },
                          colors: ["#008FFB"],
                          title: {
                            text: "Observation Trends Overview",
                          },
                        }}
                        chartData={totalPercentData}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            {/*{dashboardLayout &&*/}
            {/*    dashboardLayout.length > 0 &&*/}
            {/*    chartIds &&*/}
            {/*    Object.keys(chartIds).length > 0 && (*/}
            {/*        <DashboardGrid layout={dashboardLayout}/>*/}
            {/*    )}*/}
          </div>
        )}
        {tabValue === "textAnalysis" && (
          <div
            style={{
              maxWidth: "100%",
              height: "70vh",
              overflowY: "auto",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <div
                  style={{
                    height: 500,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {qualityIndexData && qualityIndexData.data ? (
                    <>
                      <RadioSet
                        disabled={loading}
                        label=""
                        value={filterModal[tabValue].viewByQualityIndex}
                        handleChange={handleViewByRadioQualityIndexChange}
                        options={[
                          {
                            label: t(
                              `observations.viewObservations.filters.viewByLocation`
                            ),
                            value: "location",
                          },
                          {
                            label: t(
                              `observations.viewObservations.filters.viewByDiscipline`
                            ),
                            value: "discipline",
                          },
                        ]}
                      />
                      <BarChart
                        chartOptions={{
                          colors: ["#D12D26", "#F8931F", "#42934B"],
                          labels: ["Rejected", "Returned", "Completed"],
                          stroke: { colors: ["#FEFEFE"] },
                          legend: {
                            position: "top",
                            floating: false,
                            horizontalAlign: "center",
                          },
                          dataLabels: {
                            enabled: false,
                            dropShadow: { enabled: false },
                          },
                          xaxis: {
                            categories: qualityIndexData.categories,
                            labels: {
                              hideOverlappingLabels: false,
                              showDuplicates: true,
                            },
                          },
                          yaxis: {
                            title: {
                              text: "Length of text",
                            },
                          },
                          title: {
                            text: "Quality Index",
                          },
                        }}
                        chartData={qualityIndexData.data}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    height: 500,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {wordcloud1Data ? (
                    <>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {t(`observations.viewObservations.filters.wordCloud`)}
                      </Typography>
                      <p>Chart not supported</p>
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 500,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {mostCommonNGramsData && mostCommonNGramsData.data ? (
                    <>
                      <RadioSet
                        disabled={loading}
                        label=""
                        value={filterModal[tabValue].gram}
                        handleChange={handleOverviewRadioGramsViewChange}
                        options={[
                          {
                            label: t(
                              `observations.viewObservations.filters.bigram`
                            ),
                            value: "bigrams",
                          },
                          {
                            label: t(
                              `observations.viewObservations.filters.trigram`
                            ),
                            value: "trigrams",
                          },
                        ]}
                      />
                      <BarChart
                        chartOptions={{
                          colors: ["#D12D26", "#F8931F", "#42934B"],
                          labels: ["Rejected", "Returned", "Completed"],
                          stroke: { colors: ["#FEFEFE"] },
                          legend: { floating: true, horizontalAlign: "center" },
                          dataLabels: {
                            enabled: true,
                            dropShadow: { enabled: false },
                          },
                          xaxis: {
                            categories: mostCommonNGramsData.categories,
                          },
                          title: {
                            text: "Most common phrases",
                          },
                        }}
                        chartData={mostCommonNGramsData.data}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            {/*{dashboardLayout &&*/}
            {/*    dashboardLayout.length > 0 &&*/}
            {/*    chartIds &&*/}
            {/*    Object.keys(chartIds).length > 0 && (*/}
            {/*        <DashboardGrid layout={dashboardLayout}/>*/}
            {/*    )}*/}
          </div>
        )}
        {tabValue === "sentiment" && (
          <div
            style={{
              maxWidth: "100%",
              height: "70vh",
              overflowY: "auto",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <div
                  style={{
                    height: 600,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {sentimentByLocationData ? (
                    <HeatmapChart
                      chartOptions={{
                        plotOptions: {
                          heatmap: {
                            enableShades: false,
                            colorScale: {
                              ranges: [
                                {
                                  from: -1,
                                  to: -0.599999,
                                  color: "#750202",
                                  name: "negative",
                                },
                                {
                                  from: -0.6,
                                  to: -0.199999,
                                  color: "#e84040",
                                  name: "slightly negative",
                                },
                                {
                                  from: -0.2,
                                  to: 0.199999,
                                  color: "#058ad5",
                                  name: "neutral",
                                },
                                {
                                  from: 0.2,
                                  to: 0.599999,
                                  color: "#2fb203",
                                  name: "slightly positive",
                                },
                                {
                                  from: 0.6,
                                  to: 1,
                                  color: "#165701",
                                  name: "positive",
                                },
                              ],
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "category",
                          position: "top",
                          labels: {
                            offsetY: 8,
                          },
                        },
                        axisTicks: {
                          show: false,
                        },
                        labels: {
                          show: true,
                        },
                        // colors: ["#750202", "#e84040", '#058ad5', '#2fb203', '#165701'],
                        title: {
                          text: "Sentiment by Location & Discipline",
                        },
                      }}
                      chartData={sentimentByLocationData}
                    />
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
              {
                <Grid item xs={6}>
                  <div
                    style={{
                      height: 600,
                      border: "1px solid #F7D5AE",
                      background:
                        "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                      borderRadius: 0,
                      padding: "18px",
                    }}
                  >
                    {feedbackSentimentData && feedbackSentimentData.data ? (
                      <BarChart
                        chartOptions={{
                          plotOptions: {
                            bar: {
                              horizontal: true,
                            },
                          },
                          colors: ["#D12D26", "#F8931F", "#42934B"],
                          labels: ["Rejected", "Returned", "Completed"],
                          stroke: { colors: ["#FEFEFE"] },
                          legend: { floating: true, horizontalAlign: "center" },
                          dataLabels: {
                            enabled: false,
                            dropShadow: { enabled: false },
                          },
                          xaxis: {
                            categories: feedbackSentimentData.categories,
                            min: -1,
                            max: 1,
                          },
                          yaxis: {
                            min: -1,
                            max: 1,
                            forceNiceScale: true,
                            decimalsInFloat: 2,
                            tickAmount: 4,
                          },
                          title: {
                            text: "Top & Bottom Feedback By Sentiment",
                          },
                        }}
                        chartData={feedbackSentimentData.data}
                      />
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PageLoader />
                      </div>
                    )}
                  </div>
                </Grid>
              }
            </Grid>

            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 500,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {topBottomBySentimentData && topBottomBySentimentData.data ? (
                    <BarChart
                      chartOptions={{
                        plotOptions: {
                          bar: {
                            horizontal: true,
                          },
                        },
                        colors: ["#D12D26", "#F8931F", "#42934B"],
                        labels: ["Rejected", "Returned", "Completed"],
                        stroke: { colors: ["#FEFEFE"] },
                        legend: { floating: true, horizontalAlign: "center" },
                        dataLabels: {
                          enabled: false,
                          dropShadow: { enabled: false },
                        },
                        xaxis: {
                          categories: topBottomBySentimentData.categories,
                          min: -1,
                          max: 1,
                        },
                        yaxis: {
                          min: -1,
                          max: 1,
                          forceNiceScale: false,
                          decimalsInFloat: 2,
                          tickAmount: 4,
                        },
                        title: {
                          text: "Top & Bottom Behaviour By Sentiment",
                        },
                      }}
                      chartData={topBottomBySentimentData.data}
                    />
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            {/*{dashboardLayout &&*/}
            {/*    dashboardLayout.length > 0 &&*/}
            {/*    chartIds &&*/}
            {/*    Object.keys(chartIds).length > 0 && (*/}
            {/*        <DashboardGrid layout={dashboardLayout}/>*/}
            {/*    )}*/}
          </div>
        )}
        {tabValue === "riskSnapshot" && (
          <div
            style={{
              maxWidth: "100%",
              height: "70vh",
              overflowY: "auto",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 500,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {riskHeatmapData ? (
                    <HeatmapChart
                      chartOptions={{
                        plotOptions: {
                          heatmap: {
                            enableShades: false,
                            colorScale: {
                              ranges: [
                                {
                                  from: 0,
                                  to: 20,
                                  color: "#63e738",
                                  name: "very low",
                                },
                                {
                                  from: 20.01,
                                  to: 40,
                                  color: "#165701",
                                  name: "low",
                                },
                                {
                                  from: 40.01,
                                  to: 60,
                                  color: "#eeda5b",
                                  name: "medium",
                                },
                                {
                                  from: 60.01,
                                  to: 80,
                                  color: "#ee3333",
                                  name: "high",
                                },
                                {
                                  from: 80.01,
                                  to: 100,
                                  color: "#750202",
                                  name: "very high",
                                },
                              ],
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "category",
                          position: "top",
                          offsetY: 8,
                        },
                        axisTicks: {
                          show: false,
                        },
                        labels: {
                          show: true,
                        },
                        colors: ["#008FFB"],
                        // colors: ["#c5b60c", "#008FFB", '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                        title: {
                          text: "Risk Heatmap",
                        },
                      }}
                      chartData={riskHeatmapData}
                    />
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid container spacing={4} item xs={12}>
                <Grid item xs={3}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {t(
                      `observations.viewObservations.filters.riskBreakdownTitle`
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="h6" sx={{ mb: 1, mr: 1 }}>
                      {t(`observations.viewObservations.filters.locationTitle`)}
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {filterModal["riskSnapshot"].location === ""
                        ? "All"
                        : filterModal["riskSnapshot"].location}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="h6" sx={{ mb: 1, mr: 1 }}>
                      {t(
                        `observations.viewObservations.filters.disciplineTitle`
                      )}
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {filterModal["riskSnapshot"].discipline === ""
                        ? "All"
                        : filterModal["riskSnapshot"].discipline}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="h6" sx={{ mb: 1, mr: 1 }}>
                      {t(
                        `observations.viewObservations.filters.directorateTitle`
                      )}
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {filterModal["riskSnapshot"].directorate === ""
                        ? "All"
                        : filterModal["riskSnapshot"].directorate}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <div
                    style={{
                      height: 500,
                      width: "100%",
                      border: "1px solid #F7D5AE",
                      background:
                        "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                      borderRadius: 0,
                      padding: "18px",
                    }}
                  >
                    {riskBarGraphData && riskBarGraphData.data ? (
                      <BarChart
                        chartOptions={{
                          plotOptions: {
                            bar: {
                              horizontal: true,
                            },
                          },
                          colors: ["#17d9b1", "#1fbef8", "#2d58c5"],
                          dataLabels: {
                            enabled: false,
                            dropShadow: { enabled: false },
                          },
                          xaxis: {
                            type: "category",
                            categories: riskBarGraphData.categories,
                          },
                          yaxis: {
                            min: 0,
                            max: 100,
                            decimalsInFloat: 2,
                          },
                          title: {
                            text: "Breakdown of Risk by Category",
                          },
                        }}
                        chartData={riskBarGraphData.data}
                      />
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PageLoader />
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    height: 500,
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {wordcloud2Data ? (
                    <>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {t(`observations.viewObservations.filters.wordCloud`)}
                      </Typography>
                      <WordcloudChart
                        height={"400px"}
                        chartData={wordcloud2Data}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid container item xs={12} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 500,
                    width: "100%",
                    border: "1px solid #F7D5AE",
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    padding: "18px",
                  }}
                >
                  {topBehaviorsData && topBehaviorsData.data ? (
                    <BarChart
                      chartOptions={{
                        plotOptions: {
                          bar: {
                            horizontal: false,
                          },
                        },
                        // colors: ["#17d9b1", "#1fbef8", "#2d58c5"],
                        dataLabels: {
                          enabled: false,
                          dropShadow: { enabled: false },
                        },
                        xaxis: {
                          type: "category",
                          categories: topBehaviorsData.categories,
                          labels: {
                            trim: true,
                            minHeight: 120,
                            hideOverlappingLabels: false,
                            showDuplicates: true,
                          },
                        },
                        // yaxis: {
                        //   min: 0,
                        //   max: 100,
                        //   decimalsInFloat: 2,
                        // },
                        title: {
                          text: "Most Common Behaviors",
                        },
                      }}
                      chartData={topBehaviorsData.data}
                    />
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PageLoader />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>

            {/*{dashboardLayout &&*/}
            {/*    dashboardLayout.length > 0 &&*/}
            {/*    chartIds &&*/}
            {/*    Object.keys(chartIds).length > 0 && (*/}
            {/*        <DashboardGrid layout={dashboardLayout}/>*/}
            {/*    )}*/}
          </div>
        )}
      </Container>
      {renderAddChart()}
      <InfoBox
        open={filterModal.open}
        title={t("observations.viewObservations.filters.title")}
        content={renderObservationFilters()}
        buttonLabel={t("general.applyFilters")}
        handleClose={() => setFilterModal({ ...filterModal, open: false })}
        handleAction={submitFilter}
        minWidth="1000px"
      />
      {/*<InfoBox*/}
      {/*    open={chartFilterModal.open}*/}
      {/*    title={"Chart Filters"}*/}
      {/*    content={renderChartFilters()}*/}
      {/*    buttonLabel="Apply filters"*/}
      {/*    handleClose={() => setChartFilterModal({...chartFilterModal, open: false})}*/}
      {/*    handleAction={submitChartFilter}*/}
      {/*    minWidth="100px"*/}
      {/*/>*/}
    </Page>
  );
}
