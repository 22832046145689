/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// material
import { Box, Container, Typography, Stack } from "@mui/material";
// components
import Page from "../../../components/Page";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import TabsBar from "../../../components/navigation/TabsBar";
import Overview from "./Overview";
import FeedbackBehavior from "./FeedbackBehavior";
import FeedbackRootCause from "./FeedbackRootCause";
import IncidentPreventionScore from "./IncidentPreventionScore";
import LowEffortObservations from "./LowEffortObservations";
import LSRs from "./LSRs";
//  API
import { getTopicFiltersApi } from "../../../api/lsrDashboard";
import { getLocationsAndUnitsFilters } from "../utils";
// ----------------------------------------------------------------------

export default function CompanyDashboard() {
  const { tab, companyId } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(tab || "quality");
  const [qualityTab, setQualityTab] = useState(state?.tab || "overview");
  const [locationFilters, setLocationFilters] = useState([]);
  const [unitFilters, setUnitFilters] = useState([]);
  const [lsrTopicOptions, setLsrTopicOptions] = useState([]);

  const renderTabs = () => (
    <TabsBar
      value={tabValue}
      handler={(event, value) => {
        if (state.alertStartDate) {
          navigate(".", {
            state: {
              companyName: state.companyName,
              fromAlert: true,
            },
          });
        }
        setTabValue(value);
      }}
      tabs={[
        {
          label: t("dashboards.implementationDashboards.quality"),
          value: 0,
          key: "quality",
        },
        {
          label: t("dashboards.implementationDashboards.lsrs"),
          value: 0,
          key: "lsrs",
        },
      ]}
    />
  );

  const renderQualityView = () => (
    <>
      <TabsBar
        dense
        wrapped
        value={qualityTab}
        handler={(event, value) => {
          if (state.alertStartDate) {
            navigate(".", {
              state: {
                companyName: state.companyName,
                fromAlert: true,
              },
            });
          }
          setQualityTab(value);
        }}
        tabs={[
          {
            label: t("dashboards.implementationDashboards.tabs.overview"),
            value: 0,
            key: "overview",
          },
          {
            label: t(
              "dashboards.implementationDashboards.tabs.feedbackBehavior"
            ),
            value: 0,
            key: "feedbackBehavior",
          },
          {
            label: t("dashboards.implementationDashboards.tabs.feedbackRoot"),
            value: 0,
            key: "feedbackRoot",
          },
          {
            label: t(
              "dashboards.implementationDashboards.tabs.incidentPrevention"
            ),
            value: 0,
            key: "incidentPrevention",
          },
          // {
          //   label: t(
          //     "dashboards.implementationDashboards.tabs.assignedCorrectly"
          //   ),
          //   value: 0,
          //   key: "assignedCorrectly",
          // },
          {
            label: t("dashboards.implementationDashboards.tabs.lowEffort"),
            value: 0,
            key: "lowEffort",
          },
        ]}
      />
      {qualityTab === "overview" && (
        <Overview
          companyId={companyId}
          locationOptions={locationFilters}
          unitOptions={unitFilters}
          state={state}
        />
      )}
      {qualityTab === "feedbackBehavior" && (
        <FeedbackBehavior
          companyId={companyId}
          locationOptions={locationFilters}
          unitOptions={unitFilters}
          state={state}
        />
      )}
      {qualityTab === "feedbackRoot" && (
        <FeedbackRootCause
          companyId={companyId}
          locationOptions={locationFilters}
          unitOptions={unitFilters}
          state={state}
        />
      )}
      {qualityTab === "incidentPrevention" && (
        <IncidentPreventionScore
          companyId={companyId}
          locationOptions={locationFilters}
          unitOptions={unitFilters}
          state={state}
        />
      )}
      {qualityTab === "lowEffort" && (
        <LowEffortObservations
          companyId={companyId}
          locationOptions={locationFilters}
          unitOptions={unitFilters}
          state={state}
        />
      )}
    </>
  );

  const getFilters = async () => {
    try {
      const topicsRaw = await getTopicFiltersApi();
      setLsrTopicOptions(topicsRaw);
      if (companyId) {
        getLocationsAndUnitsFilters({
          setLoading,
          companyFilter: companyId,
          setLocationDropdown: setLocationFilters,
          setUnitDropdown: setUnitFilters,
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <Page
      title={t("dashboards.dashboards", {
        name: `${state.directorateName} > ${state.companyName}`,
      })}
    >
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton
              backFunction={
                state.fromAlert
                  ? () => navigate("/dashboard/aiDashboards")
                  : null
              }
            />
            <Typography variant="h4">
              {t("dashboards.dashboards", {
                name: `${state.directorateName} > ${state.companyName}`,
              })}
            </Typography>
          </div>
        </Box>
        <Stack spacing={2}>
          {renderTabs()}
          {tabValue === "quality" && renderQualityView()}
          {tabValue === "lsrs" && (
            <LSRs
              companyId={companyId}
              topicOptions={lsrTopicOptions}
              state={state}
              locationOptions={locationFilters}
              unitOptions={unitFilters}
            />
          )}
        </Stack>
      </Container>
    </Page>
  );
}
