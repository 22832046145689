import { useTranslation } from "react-i18next";
import { useState } from "react";
import { validateEmail } from "../../../utils/general";
// material
import { Stack, Typography, Box, useTheme } from "@mui/material";
import { displayToast } from "../../../utils/general";
//  components
import TextInput from "../../inputs/TextInput";
import PrimaryButton from "../../buttons/PrimaryButton";
import HeaderBackButton from "../../buttons/HeaderBackButton";
// ----------------------------------------------------------------------
// API
import { sendPasswordRecoveryApi } from "../../../api/users";

export default function RecoveryEmailForm({ showRecoveryEmailForm }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFormEntry = (e, type) => {
    switch (type) {
      case "enter":
        if (e.code === "Enter") {
          sendPasswordRecoveryEmail(recoveryEmail);
        }
        break;

      case "emailAddress":
        setRecoveryEmail(e.target.value);
        break;

      default:
        console.log("Please enter a valid email.");
    }
  };

  const sendPasswordRecoveryEmail = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("resetPassword.addLoading")
    );
    try {
      const isValid = validateEmail(recoveryEmail);
      if (isValid) {
        const response = await sendPasswordRecoveryApi(recoveryEmail);
        displayToast(
          setLoading,
          "success",
          t("resetPassword.onSuccess"),
          activeToast
        );
        const finalString = t("resetPassword.totalPassResetAttampts").replace(
          /0/g,
          response.totalPassResetAttempts
        );

        displayToast(setLoading, "success", finalString, activeToast);
        showRecoveryEmailForm(false);
      } else {
        throw t("validation.email");
      }
    } catch (err) {
      if (err === "azureEmailReset") {
        displayToast(
          setLoading,
          "error",
          t("errors.azureEmailReset"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Box>
        <div style={{ display: "flex", alignItems: "center" }}>
          <HeaderBackButton color={theme.palette.primary.white} />
        </div>
      </Box>
      <Stack spacing={3} sx={{ mt: 5 }}>
        <TextInput
          label={t(`resetPassword.enterRecoveryEmail`)}
          placeholder={t(`resetPassword.enterRecoveryEmailPlaceholder`)}
          value={recoveryEmail}
          disabled={loading}
          handler={(e) => handleFormEntry(e, "emailAddress")}
          keyPressHandler={(e) => handleFormEntry(e, "enter")}
          labelColor={theme.palette.primary.white}
        />

        <PrimaryButton
          label={t(`resetPassword.reset`)}
          alignment="left"
          disabled={loading}
          onClick={sendPasswordRecoveryEmail}
        />
      </Stack>
    </>
  );
}
