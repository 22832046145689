import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// material
import { Box, Container, Typography, Stack } from "@mui/material";
// components
import Page from "../../components/Page";
import TabsBar from "../../components/navigation/TabsBar";
import ImplementationQualityView from "../dashboard/implementationQualityView/ImplementationQualityView";
import ImplementationLSRView from "../dashboard/implementationLSRView/ImplementationLSRView";
import ImplementationTrendsView from "./implementationSmartTrendsView/implementationTrendsView";
import SmartAlerts from "./SmartAlerts";
//  API
import { getDirectorateFiltersApi } from "../../api/ai_dashboard";

export default function AiDashboards() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("quality");
  const [smartAlertsData, setSmartAlertsData] = useState([]);
  const [directorateFilters, setDirectorateFilters] = useState([]);

  const renderDashboardsView = () => (
    <div>
      <div>
        <TabsBar
          value={tabValue}
          handler={(event, value) => setTabValue(value)}
          tabs={[
            {
              label: t("dashboards.implementationDashboards.quality"),
              value: 0,
              key: "quality",
            },
            {
              label: t("dashboards.implementationDashboards.lsrs"),
              value: 0,
              key: "lsrs",
            },
            {
              label: t("dashboards.implementationDashboards.smartTrends"),
              value: 0,
              key: "smartTrends",
            },
            {
              label: t("dashboards.implementationDashboards.smartAlerts"),
              value: smartAlertsData.length,
              key: "smartAlerts",
            },
          ]}
        />
      </div>
      {tabValue === "quality" && (
        <ImplementationQualityView directorateFilters={directorateFilters} />
      )}
      {tabValue === "lsrs" && (
        <ImplementationLSRView directorateFilters={directorateFilters} />
      )}
      {tabValue === "smartTrends" && (
        <ImplementationTrendsView directorateFilters={directorateFilters} />
      )}
      {tabValue === "smartAlerts" && (
        <SmartAlerts directorateFilters={directorateFilters} />
      )}
    </div>
  );

  const getDirectorateFilters = async () => {
    try {
      const directorateList = await getDirectorateFiltersApi();
      setDirectorateFilters(directorateList);
    } catch (err) {}
  };

  useEffect(() => {
    getDirectorateFilters();
  }, []);

  return (
    <Page title={t("sideBar.aiDashboard")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography variant="h4">{t("sideBar.aiDashboard")}</Typography>
          </Stack>
        </Box>
        {renderDashboardsView()}
      </Container>
    </Page>
  );
}
