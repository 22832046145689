import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AttachmentViewer from "../components/AttachmentViewer";
import { ReactComponent as ActionAssigned } from "./icons/action_assigned.svg";
import { ReactComponent as ActionComplete } from "./icons/action_complete.svg";
import { ReactComponent as ActionPerformedApproved } from "./icons/action_performed_approved.svg";
import { ReactComponent as ActionPerformedRejected } from "./icons/action_performed_rejected.svg";
import { ReactComponent as ActionReassigned } from "./icons/action_reassigned.svg";
import { ReactComponent as ClosedBecauseSafe } from "./icons/closed_because_safe.svg";
import { ReactComponent as MarkedAsNearMiss } from "./icons/marked_as_near_miss.svg";
import { ReactComponent as ObservationClosedWithAction } from "./icons/observation_closed_with_action.svg";
import { ReactComponent as ObservationResubmitted } from "./icons/observation_resubmitted.svg";
import { ReactComponent as ObservationSubmitted } from "./icons/observation_submitted.svg";
import { ReactComponent as ReturnedToObserver } from "./icons/returned_to_observer.svg";
import { ReactComponent as ReturnedToSupervisor } from "./icons/returned_to_supervisor.svg";
import { ReactComponent as SupervisorClosedNoAction } from "./icons/supervisor_closed_no_action.svg";
import { ReactComponent as SupervisorRejected } from "./icons/supervisor_rejected.svg";
import ProvideAppreciation from "@mui/icons-material/EmojiEvents";
import InfoBox from "./InfoBox";

Timeline.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStep: PropTypes.number,
};

const lightOrange = "#F2C18C";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: lightOrange,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: lightOrange,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#eaeaf0",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.orange,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: lightOrange,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;
  const theme = useTheme();
  const styledIcon = {
    height: "25px",
    width: "25px",
    fill: theme.palette.primary.white,
  };

  const icons = {
    action_assigned: <ActionAssigned style={styledIcon} />,
    action_complete: <ActionComplete style={styledIcon} />,
    action_performed_approved: <ActionPerformedApproved style={styledIcon} />,
    action_performed_rejected: <ActionPerformedRejected style={styledIcon} />,
    action_reassigned: <ActionReassigned style={styledIcon} />,
    observation_resubmitted: <ObservationResubmitted style={styledIcon} />,
    observation_submitted: <ObservationSubmitted style={styledIcon} />,
    returned_to_observer: <ReturnedToObserver style={styledIcon} />,
    returned_to_supervisor: <ReturnedToSupervisor style={styledIcon} />,
    supervisor_closed_no_action: (
      <SupervisorClosedNoAction style={styledIcon} />
    ),
    supervisor_rejected: <SupervisorRejected style={styledIcon} />,
    observation_closed_with_action: (
      <ObservationClosedWithAction style={styledIcon} />
    ),
    marked_as_near_miss: <MarkedAsNearMiss style={styledIcon} />,
    closed_because_safe: <ClosedBecauseSafe style={styledIcon} />,
    appreciation_provided: <ProvideAppreciation style={styledIcon} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[icon]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function Timeline({ steps, activeStep, attachmentLinks }) {
  const { t } = useTranslation();
  const [commentDetails, setCommentDetails] = useState({
    open: false,
    name: "",
    role: "",
    status: "",
    comment: "",
    attachments: [],
    createdAt: "",
  });

  return (
    <>
      {steps && steps.length > 0 ? (
        <Stepper
          alternativeLabel
          activeStep={activeStep || 0}
          connector={<ColorlibConnector />}
        >
          {steps.map((step, index) => (
            <Step key={step.label} style={{ cursor: "pointer" }}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                icon={step.icon}
                {...(index <= activeStep && {
                  onClick: () =>
                    setCommentDetails({
                      open: true,
                      comment: step.comment,
                      status: t(step.label, { ns: "backend" }),
                      name: step.name,
                      role: step.role,
                      attachments: step.attachments,
                      createdAt: step.createdAt,
                    }),
                })}
              >
                {t(step.label, { ns: "backend" })}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        "Loading timeline..."
      )}
      <Typography
        variant="subtitle2"
        sx={{ textAlign: "center", fontWeight: "bold", mt: 2, mb: 2 }}
      >
        {t("stepper.description")}
      </Typography>
      <InfoBox
        open={commentDetails.open}
        handleClose={() =>
          setCommentDetails({ ...commentDetails, open: false })
        }
        handleAction={() =>
          setCommentDetails({ ...commentDetails, open: false })
        }
        title={commentDetails.status}
        content={
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2">{t("stepper.name")}</Typography>
              <Typography sx={{ mt: 1 }} variant="subtitle1">
                {commentDetails.name || t("stepper.anonymous")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{t("stepper.role")}</Typography>
              <Typography sx={{ mt: 1 }} variant="subtitle1">
                {commentDetails.role || t("stepper.roleNotFound")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{t("stepper.comment")}</Typography>
              <Typography sx={{ mt: 1 }} variant="subtitle1">
                {commentDetails.comment || t("stepper.noComment")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{`${t(
                "general.attachments"
              )}:`}</Typography>
              {commentDetails.attachments.length > 0 ? (
                <AttachmentViewer
                  disabled={false}
                  attachments={commentDetails.attachments}
                  attachmentLinks={attachmentLinks}
                  noHeader
                />
              ) : (
                <Typography sx={{ mt: 1 }} variant="subtitle1">
                  {t("general.noAttachments")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{t("stepper.createdAt")}</Typography>
              <Typography sx={{ mt: 1 }} variant="subtitle1">
                {moment(commentDetails.createdAt).format("DD/MM/YY hh:mm a") ||
                  "No date."}
              </Typography>
            </Grid>
          </Grid>
        }
        buttonLabel={t("general.close")}
      />
    </>
  );
}
