import React, { useState, useEffect, useRef } from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import DateInput from "../../../../components/inputs/DateInput";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SelectInput from "../../../../components/inputs/SelectInput";
import TextInput from "../../../../components/inputs/TextInput";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import InfoBox from "../../../../components/InfoBox";
import AddIcon from "@mui/icons-material/Add";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { styled, useTheme } from "@mui/material/styles";
import SecondaryButton from "../../../../components/buttons/SecondaryButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PageLoader from "../../../../components/PageLoader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DefaultTable from "../../../../components/tables/DefaultTable";
import TableButton from "../../../../components/buttons/TableButton";
import RemoveUser from "@mui/icons-material/PersonRemoveAlt1Outlined";
import AcceptIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RejectIcon from "@mui/icons-material/BlockOutlined";
import SendInviteIcon from "@mui/icons-material/ForwardToInbox";
import moment from "moment";
//Apis
import {
  getTrainingPlans,
  getTrainingPlanUsersByTpId,
  removeUserFromTraining,
  trainingRequestAction,
  updateTrainingAndUsers,
} from "../../../../api/implementation";
import { searchUsersApi } from "../../../../api/users";
import AutocompleteInput from "../../../../components/inputs/AutocompleteInput";
import { displayToast } from "../../../../utils/general";

const UserDiv = styled("div")(({ theme }) => ({
  backgroundColor: "gainsboro",
  borderRadius: "5px",
  marginTop: "5px",
  display: "flex",
  padding: "4px",
  justifyContent: "space-around",
}));

const AcceptedChip = styled("div")(({ theme }) => ({
  background: "#9EB23B",
  color: "#FCF9C6",
  borderRadius: "5px",
  fontSize: "14px",
  padding: "3px",
}));

const RejectedChip = styled("div")(({ theme }) => ({
  background: "#DC3535",
  color: "#FFD07F",
  borderRadius: "5px",
  fontSize: "14px",
  padding: "3px",
}));

const PendingChip = styled("div")(({ theme }) => ({
  background: "#85A392",
  color: "#FFECC7",
  borderRadius: "5px",
  fontSize: "14px",
  padding: "3px",
}));

const OptedForChip = styled("div")(({ theme }) => ({
  background: "#6E85B7",
  color: "#F8F9D7",
  borderRadius: "5px",
  fontSize: "14px",
  padding: "3px",
}));

const InviteChip = styled("div")(({ theme }) => ({
  background: "grey",
  color: "white",
  borderRadius: "5px",
  fontSize: "14px",
  padding: "3px",
}));

const deleteDefaults = {
  open: false,
  id: "",
  name: "",
};

export default function TrainingDetails() {
  const theme = useTheme();
  const timeout = useRef();
  const { t } = useTranslation();
  const { trainingId, planId } = useParams();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [trainingTypeId, setTrainingTypeId] = useState(null);
  const [description, setDescription] = useState("");
  const [totalSeats, setTotalSeats] = useState(0);
  const [waitingSeats, setWaitingSeats] = useState(0);
  const [concluded, setConcluded] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [autoSearchUser, setAutoSearchUser] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [alreadyInvited, setAlreadyInvited] = useState([]);
  const [sendInvite, setSendInvite] = useState([]);
  const [noShows, setNoShows] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [attendingCount, setAttendingCount] = useState(0);
  const [waitingList, setWaitingList] = useState([]);
  const [optedList, setOptedList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [trainingLocations, setTrainingLocations] = useState([]);
  const [trainingLocationId, setTrainingLocationId] = useState(null);
  const [rows, setRows] = useState([]);
  const [listedIds, setListedIds] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 1, totalCount: 0 });
  const [filterParams, setFilterParams] = useState({ pageSize: 10, pageNo: 1 });
  const [deleteDetails, setDeleteDetails] = useState(deleteDefaults);

  const columnDefaults = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "name",
      headerName: t("table.name"),
      width: 200,
      sortable: false,
    },
    {
      field: "email",
      headerName: t("table.email"),
      width: 200,
      sortable: false,
    },
    {
      field: "directorate",
      headerName: t("training.tableHeaders.directorate"),
      width: 150,
      sortable: false,
    },
    {
      field: "company",
      headerName: t("training.tableHeaders.company"),
      width: 150,
      sortable: false,
    },
    {
      field: "location",
      headerName: t("training.tableHeaders.location"),
      width: 150,
      sortable: false,
    },
    {
      field: "unit",
      headerName: t("training.tableHeaders.unit"),
      width: 150,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("table.status"),
      width: 200,
      sortable: false,
      renderCell: (params) => renderTag(params.row.internalStatus),
    },
    {
      field: "actions",
      headerName: t("table.actions"),
      width: 200,
      sortable: false,
      renderCell: (params) => renderRowActions(params.row),
    },
  ];

  const returnInternalStatus = (user) => {
    switch (true) {
      case user.adminStatus === "Attended":
        return "ATTENDED";

      case user.adminStatus === "No Shows":
        return "NO SHOW";

      case user.userStatus === "Accepted":
        return "ACCEPTED";

      case user.userStatus === "pending" && user.isOpted:
        return "REGISTERED";

      case user.userStatus === "pending" && user.isWaiting:
        return "WAITING LIST";

      case user.userStatus === "pending":
        return "INVITED";

      case user.userStatus === "Rejected":
        return "REJECTED";

      default:
        return "";
    }
  };

  const renderTag = (status) => {
    const tagStyle = {
      borderRadius: "8px",
      background: theme.palette.primary.blue,
      color: theme.palette.primary.white,
      fontWeight: "bold",
      fontSize: "12px",
      padding: "4px 7px",
    };

    switch (status) {
      case "ATTENDED":
        return (
          <div style={{ ...tagStyle, background: theme.palette.primary.green }}>
            {status}
          </div>
        );

      case "NO SHOW":
        return (
          <div style={{ ...tagStyle, background: theme.palette.primary.red }}>
            {status}
          </div>
        );

      case "ACCEPTED":
        return (
          <div style={{ ...tagStyle, background: theme.palette.primary.green }}>
            {status}
          </div>
        );

      case "REGISTERED":
        return (
          <div
            style={{ ...tagStyle, background: theme.palette.primary.purple }}
          >
            {status}
          </div>
        );

      case "WAITING LIST":
        return (
          <div
            style={{ ...tagStyle, background: theme.palette.primary.hoverGrey }}
          >
            {status}
          </div>
        );

      case "INVITED":
        return (
          <div style={{ ...tagStyle, background: theme.palette.primary.blue }}>
            {status}
          </div>
        );

      case "REJECTED":
        return (
          <div style={{ ...tagStyle, background: theme.palette.primary.red }}>
            {status}
          </div>
        );

      default:
        return <div />;
    }
  };

  const renderRowActions = (row) => (
    <Stack spacing={1} direction="row">
      {row.internalStatus === "INVITED" && !concluded && (
        <TableButton
          disabled={loading}
          label={t("trainingSchedule.removeInvite")}
          onClick={() =>
            setDeleteDetails({
              open: true,
              id: row.id,
              name: row.name,
            })
          }
          icon={<RemoveUser />}
        />
      )}
      {(row.internalStatus === "REGISTERED" ||
        row.internalStatus === "WAITING LIST") && (
        <>
          <TableButton
            disabled={loading}
            label={t("trainingSchedule.acceptOpt")}
            onClick={() =>
              trainerAction({
                id: row.id,
                status: "Accepted",
              })
            }
            icon={<AcceptIcon />}
          />
          <TableButton
            disabled={loading}
            label={t("trainingSchedule.rejectOpt")}
            onClick={() =>
              trainerAction({
                id: row.id,
                status: "Rejected",
              })
            }
            icon={<RejectIcon />}
          />
        </>
      )}
    </Stack>
  );

  const handleRemoveUser = async (traineeId) => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("trainingSchedule.removeLoading")
    );
    try {
      setLoading(true);
      let removeReq = await removeUserFromTraining(deleteDetails.id);
      if (removeReq) {
        setDeleteDetails(deleteDefaults);
        getTrainingById();
      }

      displayToast(
        setLoading,
        "success",
        t("trainingSchedule.removeSuccess"),
        activeToast
      );
    } catch (e) {
      displayToast(setLoading, "pureError", e);
    }
  };
  const handleAddInvites = () => {
    if (
      autoSearchUser.length + alreadyInvited.length + sendInvite.length >
      totalSeats
    ) {
      return displayToast(
        setLoading,
        "error",
        "Adding users more than total seats",
        "errorTrainingDetails"
      );
    }
    let tempInvites = [];
    for (let i = 0; i < autoSearchUser.length; i++) {
      if (autoSearchUser[i]?.label) {
        let tempInvite = autoSearchUser[i];
        tempInvite["name"] = autoSearchUser[i].label.split("(")[0];
        if (
          alreadyInvited.find(
            (invited) => invited.value === autoSearchUser[i].id
          ) === undefined &&
          sendInvite.find((invited) => invited.id === autoSearchUser[i].id) ===
            undefined &&
          tempInvites.find((invited) => invited.id === autoSearchUser[i].id) ===
            undefined
        ) {
          tempInvites.push(tempInvite);
        }
      }
    }
    if (tempInvites.length > 0) {
      setSendInvite([...sendInvite, ...tempInvites]);
    }
    setAutoSearchUser([]);
    setUserDropdown([]);
  };

  const getTrainingById = async () => {
    try {
      setLoading(true);
      let query = `Id=${trainingId}`;
      let getTraining = await getTrainingPlans(query);
      if (getTraining?.result?.[0]) {
        let tempData = getTraining.result[0];
        setStartDate(moment(tempData.startDate));
        setEndDate(moment(tempData.endDate));
        setTrainingTypeId(tempData.TrainingTypesId);
        setDescription(tempData.description);
        setTotalSeats(tempData.numTotal);
        setWaitingSeats(tempData.numAwaiting);
        setAttendingCount(tempData.attendingCount);
        if (tempData?.location?.name) {
          setTrainingLocationId(tempData?.location.id);
          setTrainingLocations([
            {
              label: tempData?.location.name,
              value: tempData?.location.id,
            },
          ]);
        }
        if (tempData.concluded) {
          setConcluded(true);
        }
        setLoading(false);
      }
      let queryUsers = `trainingPlanId=${trainingId}&page=0&pageSize=99999`;
      const trainingUsers = await getTrainingPlanUsersByTpId(queryUsers);
      if (trainingUsers && trainingUsers?.data) {
        const finalRows = [];
        const idList = [];
        trainingUsers.data.forEach((t) => {
          idList.push(t.User.id);
          finalRows.push({
            id: t.id,
            userId: t.User ? t.User.id : "",
            name: t.User ? `${t.User.firstName} ${t.User.lastName}` : "",
            email: t.User ? t.User.emailAddress : "",
            status: t.userStatus || "",
            adminStatus: t.adminStatus || "",
            isOpted: t.isOpted || false,
            isWaiting: t.isWaiting || false,
            internalStatus: returnInternalStatus(t),
            directorate: t?.User?.Directorate?.name || "-",
            company: t?.User?.company?.name || "-",
            location: t?.User?.Location?.name || "-",
            unit: t?.User?.Unit?.name || "-",
          });
        });
        setListedIds(idList);
        setRows(finalRows);
        setTableInfo({
          totalPages: trainingUsers.totalPages,
          totalCount: trainingUsers.totalItems,
        });
      }
    } catch (e) {
      console.log("ShowMeHere", e);
      displayToast(
        setLoading,
        "error",
        t("training.errors.trainingPlanDetails"),
        "errorTrainingDetails"
      );
    }
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1, null, false, planId);
          const finalDropdown = [];
          const dropdownIds = autoSearchUser.map((u) => u.id);
          result.forEach((user) => {
            if (
              !listedIds.includes(user.id) &&
              !dropdownIds.includes(user.id) &&
              user.displayName
            ) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });

          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          // console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const handleSaveTraining = async (updateType) => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      updateType === "user"
        ? t("trainingSchedule.addLoading")
        : t("trainingSchedule.updateLoading")
    );

    let tempObj = {
      id: trainingId,
      description,
      numTotal: totalSeats,
      numAwaiting: waitingSeats,
      users: updateType === "user" ? autoSearchUser : [],
      startDate,
      endDate,
      isAdmin: true,
    };

    try {
      if (autoSearchUser.length + rows.length > totalSeats) {
        throw t("trainingSchedule.trainingFull");
      }

      await updateTrainingAndUsers(tempObj);
      getTrainingById();
      setAutoSearchUser([]);
      displayToast(
        setLoading,
        "success",
        updateType === "user"
          ? t("trainingSchedule.addSuccess")
          : t("trainingSchedule.updateSuccess"),
        activeToast
      );
    } catch (e) {
      displayToast(setLoading, "error", e, activeToast);
    }
  };

  const trainerAction = async (value) => {
    try {
      let tempObj = {
        trainingPlanUserId: value.id,
        userStatus: value.status,
      };
      await trainingRequestAction(tempObj);
      getTrainingById();
    } catch (e) {
      displayToast(setLoading, "error", e, "errorTrainingDetails");
    }
  };

  useEffect(() => {
    getTrainingById();
    // getUsersByCompanyId();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DateInput
            disabled={true}
            label={t("implementations.startDate")}
            date={startDate}
            handler={(newDate) => setStartDate(newDate)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            disabled={true}
            label={t("implementations.endDate")}
            date={endDate}
            handler={(newDate) => setEndDate(newDate)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            label={t("trainingSchedule.types")}
            value={trainingTypeId}
            disabled={true}
            // handler={(e) =>
            //   setTrainingT
            // }
            options={[
              {
                label: "TTT",
                value: "1",
              },
              {
                label: "Supervisor",
                value: "2",
              },
              {
                label: "H2s",
                value: "3",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            label={t("training.tableHeaders.location")}
            value={trainingLocationId}
            disabled={true}
            handler={(e) => setTrainingLocationId(e.target.value)}
            options={trainingLocations}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            label={t(`trainingSchedule.totalSeats`)}
            value={totalSeats}
            type={"number"}
            disabled={true}
            handler={(e) => setTotalSeats(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            label={t("trainingSchedule.waitingSeats")}
            value={waitingSeats}
            type={"number"}
            disabled={true}
            handler={(e) => setWaitingSeats(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            label={t("trainingSchedule.availableSeats")}
            value={totalSeats - attendingCount}
            type={"number"}
            disabled={true}
            // handler={(e) => setWaitingSeats(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <TextInput
            label={t(`trainingSchedule.availableSeats`)}
            value={totalSeats - totalAttendees}
            type={"number"}
            disabled={loading || concluded}
          />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <TextInput
            label={t(`trainingSchedule.description`)}
            value={description}
            disabled={true}
            handler={(e) => setDescription(e.target.value)}
          />
        </Grid>

        {concluded ? (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                color: theme.palette.primary.white,
                fontWeight: "bold",
                fontSize: "20px",
                padding: "4px 7px",
                background: theme.palette.primary.green,
              }}
            >
              <CheckCircleIcon />
              <div style={{ marginLeft: "10px" }}>Concluded</div>
            </div>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t("trainingSchedule.users")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DefaultTable
                hiddenColumns={{ id: false }}
                height="400px"
                rows={rows}
                columns={columnDefaults}
                loading={loading}
                tableInfo={tableInfo}
                setFilterParams={setFilterParams}
                filterParams={filterParams}
                noRowsText={t("trainingSchedule.noUsers")}
              />
            </Grid>
          </>
        )}

        {concluded && attendees.length > 0 && (
          <Grid item xs={12} md={12} style={{ marginTop: "33px" }}>
            <Typography variant="h6">{t(`training.attended`)} :</Typography>
          </Grid>
        )}

        {concluded &&
          attendees.length > 0 &&
          attendees.map((attendee) => (
            <Grid item spacing={2} xs={6} md={3}>
              <UserDiv>
                <div style={{ marginTop: "6px" }}>{attendee.label}</div>
                <div>
                  <AcceptedChip>{t(`training.attended`)}</AcceptedChip>
                </div>
              </UserDiv>
            </Grid>
          ))}

        {concluded && noShows.length > 0 && (
          <Grid item xs={12} md={12} style={{ marginTop: "33px" }}>
            <Typography variant="h6">{t(`training.noShows`)} :</Typography>
          </Grid>
        )}

        {concluded &&
          noShows.length > 0 &&
          noShows.map((noShow) => (
            <Grid item spacing={2} xs={6} md={3}>
              <UserDiv>
                <div style={{ marginTop: "6px" }}>{noShow.label}</div>
                <div>
                  <RejectedChip>{t("training.noShows")}</RejectedChip>
                </div>
              </UserDiv>
            </Grid>
          ))}

        {!concluded && alreadyInvited.length > 0 && (
          <Grid item xs={12} md={12} style={{ marginTop: "33px" }}>
            <Typography variant="h6">
              {t(`training.alreadyInvited`)} :
            </Typography>
          </Grid>
        )}

        {loading && <PageLoader />}

        {!concluded &&
          alreadyInvited.length > 0 &&
          alreadyInvited.map((invite) => (
            <Grid item spacing={2} xs={6} md={3}>
              <UserDiv>
                <div style={{ marginTop: "6px" }}>{invite.label}</div>
                <div>
                  {invite.status === "Accepted" && (
                    <AcceptedChip>Accepted</AcceptedChip>
                  )}
                  {invite.status === "pending" && (
                    <PendingChip>Pending</PendingChip>
                  )}
                  {invite.status === "Rejected" && (
                    <RejectedChip>Rejected</RejectedChip>
                  )}
                  {invite.status === "OptedFor" && (
                    <OptedForChip>Registered</OptedForChip>
                  )}
                </div>
              </UserDiv>
            </Grid>
          ))}

        {!concluded && rejectedList.length > 0 && (
          <Grid item xs={12} md={12} style={{ marginTop: "33px" }}>
            <Typography variant="h6">{"Rejected List"} :</Typography>
          </Grid>
        )}

        {!concluded &&
          rejectedList.length > 0 &&
          rejectedList.map((invite) => (
            <Grid item spacing={2} xs={6} md={3}>
              <UserDiv>
                <div style={{ marginTop: "6px" }}>{invite.label}</div>
                <div>
                  <RejectedChip>Rejected</RejectedChip>
                </div>
              </UserDiv>
            </Grid>
          ))}

        {!concluded && optedList.length > 0 && (
          <Grid item xs={12} md={12} style={{ marginTop: "33px" }}>
            <Typography variant="h6">{"Registered List"} :</Typography>
          </Grid>
        )}

        {!concluded &&
          optedList.length > 0 &&
          optedList.map((invite) => (
            <Grid item spacing={2} xs={6} md={3}>
              <UserDiv>
                <div style={{ marginTop: "6px" }}>{invite.label}</div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      color: "salmon",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      trainerAction({
                        id: invite.id,
                        status: "Rejected",
                      });
                    }}
                  >
                    <RemoveCircleIcon />
                  </div>
                  <div
                    style={{
                      color: "mediumseagreen",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      trainerAction({
                        id: invite.id,
                        status: "Accepted",
                      });
                    }}
                  >
                    <AddCircleIcon />
                  </div>
                </div>
              </UserDiv>
            </Grid>
          ))}

        {!concluded && waitingList.length > 0 && (
          <Grid item xs={12} md={12} style={{ marginTop: "33px" }}>
            <Typography variant="h6">{"Waiting List"} :</Typography>
          </Grid>
        )}

        {!concluded &&
          waitingList.length > 0 &&
          waitingList.map((invite) => (
            <Grid item spacing={2} xs={6} md={3}>
              <UserDiv>
                <div style={{ marginTop: "6px" }}>{invite.label}</div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      color: "salmon",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      trainerAction({
                        id: invite.id,
                        status: "Rejected",
                      });
                    }}
                  >
                    <RemoveCircleIcon />
                  </div>
                  <div
                    style={{
                      color: "mediumseagreen",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      trainerAction({
                        id: invite.id,
                        status: "Accepted",
                      });
                    }}
                  >
                    <AddCircleIcon />
                  </div>
                </div>
              </UserDiv>
            </Grid>
          ))}

        {!concluded && (
          <>
            <Grid item xs={12}>
              <AutocompleteInput
                multiple
                handler={(e, v) => {
                  setAutoSearchUser(v);
                }}
                keyPressHandler={handleSearchUser}
                loading={searchLoading}
                value={autoSearchUser}
                disabled={loading || concluded}
                options={userDropdown}
                label={t("trainingSchedule.addUsers")}
              />
            </Grid>
            <Grid item xs={12}>
              <SecondaryButton
                alignment="left"
                icon={<SendInviteIcon />}
                label={t("trainingSchedule.add")}
                onClick={() => {
                  if (autoSearchUser.length > 0) handleSaveTraining("user");
                }}
                disabled={autoSearchUser.length <= 0}
              />
            </Grid>
          </>
        )}
        {/* <InfoBox
          handleClose={() => setDialogOpen(false)}
          buttonLabel={t("trainingSchedule.concludeTraining")}
          handleAction={() => {
            console.log("ActionHere");
          }}
          disabled={false}
          open={dialogOpen}
          title={t("trainingSchedule.concludeTraining")}
          content={<TrainingConclude />}
        /> */}
        <InfoBox
          disabled={loading}
          open={deleteDetails.open}
          title={`${t(`remove`)}  ${deleteDetails.name}`}
          content={
            <Stack>
              <Typography
                variant="h6"
                style={{ color: theme.palette.primary.red }}
              >
                {t(`training.removeTraineeConfirm`)}
              </Typography>
            </Stack>
          }
          buttonLabel={t(`remove`)}
          handleClose={() =>
            setDeleteDetails({ ...deleteDetails, open: false })
          }
          handleAction={handleRemoveUser}
        />
      </Grid>
    </>
  );
}
