import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";

HollowButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  isRed: PropTypes.bool,
};

const ButtonStyled = styled(Button)(({ theme, isRed }) => ({
  border: `1px solid ${
    isRed ? theme.palette.primary.red : theme.palette.primary.orange
  }`,
  borderRadius: "4px",
  padding: "8px 12px",
  height: "28px",
  "&:hover": {
    background: isRed ? "rgba(209, 45, 38, 0.3)" : "rgba(255, 170, 72, 0.3)",
  },
}));

export default function HollowButton({
  label,
  style,
  disabled,
  onClick,
  icon,
  isRed = false,
}) {
  const theme = useTheme();
  return (
    <ButtonStyled
      startIcon={icon ? icon : null}
      variant="oulined"
      disabled={disabled}
      fullWidth
      onClick={onClick}
      isRed={isRed}
    >
      <Typography
        variant="caption"
        style={{
          color: isRed
            ? theme.palette.primary.red
            : theme.palette.primary.orange,
        }}
      >
        {label}
      </Typography>
    </ButtonStyled>
  );
}
