export const mockResponse = {
  header: {
    text: "IHTIMAM Progress Report",
    style: {
      fontSize: "h1",
    },
  },
  layoutType: "4x4",
  pages: [
    {
      page: 1,
      layout: [
        {
          id: "6f9ecea0-9e9d-40a0-8e4e-a3e96a3495cc",
          type: "chart",
          w: 6,
          chartDetails: {
            type: "noOfObservationsBar",
            title: "Number of observations",
            chartOptions: {
              colors: ["#D12D26", "#F8931F", "#42934B"],
              labels: ["Rejected", "Returned", "Completed"],
              stroke: { colors: ["#FEFEFE"] },
              legend: { floating: true, horizontalAlign: "center" },
              dataLabels: { enabled: true, dropShadow: { enabled: false } },
              xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
              },
            },
            chartData: [
              {
                name: "series-1",
                data: [30, 40, 45, 50, 49, 60, 70, 91],
              },
            ],
          },
        },
        {
          id: "6f9ecea0-9e9d-40a0-8e4e-a3e96a3495cc",
          type: "chart",
          w: 6,
          chartDetails: {
            type: "noOfObservationsBar",
            title: "Number of observations",
            chartOptions: {
              colors: ["#D12D26", "#F8931F", "#42934B"],
              labels: ["Rejected", "Returned", "Completed"],
              stroke: { colors: ["#FEFEFE"] },
              legend: { floating: true, horizontalAlign: "center" },
              dataLabels: { enabled: true, dropShadow: { enabled: false } },
              xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
              },
            },
            chartData: [
              {
                name: "series-1",
                data: [30, 40, 45, 50, 49, 60, 70, 91],
              },
            ],
          },
        },
        {
          id: "6f9ecea0-9e9d-40a0-8e4e-a3e96a3495cc",
          type: "chart",
          w: 6,
          chartDetails: {
            type: "noOfObservationsBar",
            title: "Number of observations",
            chartOptions: {
              colors: ["#D12D26", "#F8931F", "#42934B"],
              labels: ["Rejected", "Returned", "Completed"],
              stroke: { colors: ["#FEFEFE"] },
              legend: { floating: true, horizontalAlign: "center" },
              dataLabels: { enabled: true, dropShadow: { enabled: false } },
              xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
              },
            },
            chartData: [
              {
                name: "series-1",
                data: [30, 40, 45, 50, 49, 60, 70, 91],
              },
            ],
          },
        },
        {
          id: "6f9ecea0-9e9d-40a0-8e4e-a3e96a3495cc",
          type: "text",
          w: 6,
          textDetails: {
            justify: "center",
            align: "center",
            variant: "h1",
            text: "report",
          },
        },
      ],
    },
    {
      page: 2,
      layout: [
        {
          id: "6f9ecea0-9e9d-40a0-8e4e-a3e96a3495cc",
          type: "chart",
          w: 6,
          chartDetails: {
            type: "noOfObservationsBar",
            title: "Number of observations",
            chartOptions: {
              colors: ["#D12D26", "#F8931F", "#42934B"],
              labels: ["Rejected", "Returned", "Completed"],
              stroke: { colors: ["#FEFEFE"] },
              legend: { floating: true, horizontalAlign: "center" },
              dataLabels: { enabled: true, dropShadow: { enabled: false } },
              xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
              },
            },
            chartData: [
              {
                name: "series-1",
                data: [30, 40, 45, 50, 49, 60, 70, 91],
              },
            ],
          },
        },
        {
          id: "6f9ecea0-9e9d-40a0-8e4e-a3e96a3495cc",
          type: "text",
          w: 6,
          textDetails: {
            justify: "center",
            align: "left",
            variant: "body1",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consequat augue a nibh lobortis, eu hendrerit lectus posuere. Sed rutrum, mauris a pretium sollicitudin, quam eros venenatis elit, eget vestibulum elit ipsum nec enim. Integer elit massa, facilisis ut aliquam in, mollis sollicitudin tellus. Aenean dictum metus at commodo convallis. Pellentesque nisi turpis, porttitor et pulvinar eget, euismod et risus. Mauris sagittis posuere diam, et blandit ex aliquam sed. Donec placerat ornare congue.",
          },
        },
      ],
    },
  ],
};
