import React, { useState } from "react";
import { Grid } from "@mui/material";
import DateInput from "../../../../components/inputs/DateInput";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router-dom";
//Api
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { markTrainingComplete } from "../../../../api/implementation";
import { displayToast } from "../../../../utils/general";
import SelectInput from "../../../../components/inputs/SelectInput";
import MultiSelectInput from "../../../../components/inputs/MultiSelectInput";

export default function MarkComplete(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const { planId } = useParams();
  const [trainingTypeId, setTrainingTypeId] = useState(null);

  const handleMarkComplete = async () => {
    try {
      let tempObj = {
        planId,
        userId: props.userId,
      };
      let mark = await markTrainingComplete(tempObj);
      if (mark.data == "Record updated successfully") {
        displayToast(
          setLoading,
          "success",
          "User has been marked succesfully",
          "errorTrainedUsers"
        );
        props.onSuccess();
      }
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        "There was some error marking the user",
        "errorTrainedUsers"
      );
    }
  };
  const handleMarkCompleteTrainings = async () => {
    try {
      let tempObj = {
        planId,
        userId: props.userId,
        trainingPlanId: trainingTypeId
      };
      let mark = await markTrainingComplete(tempObj);
      if (mark.data == "Record updated successfully") {
        displayToast(
          setLoading,
          "success",
          "User has been marked succesfully",
          "errorTrainedUsers"
        );
        props.onSuccess();
      }
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        "There was some error marking the user",
        "errorTrainedUsers"
      );
    }
  };

  return (
    <>
      <Grid container spacing={4}>
        {props?.trainings && <Grid item xs={12} md={12}>
          <MultiSelectInput
            label={t("trainingSchedule.types")}
            value={trainingTypeId}
            disabled={loading}
            handler={(e) => setTrainingTypeId(e)}
            options={props.trainings?.map(training=>{
              if(training.markedDate===null)return {value:training.id, label:training.name}
            })}
          />
        </Grid>}
        <Grid item xs={12} md={12}>
          <DateInput
            disabled={loading}
            label={t("training.markCompletedDate")}
            date={startDate}
            handler={(newDate) => {
              setStartDate(newDate);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PrimaryButton
            label={t("training.markCompleted")}
            alignment="left"
            onClick={() => {
              if(props?.trainings?.length>0){
                handleMarkCompleteTrainings();
              }else{
                handleMarkComplete();
              }
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </>
  );
}
