import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import PageLoader from "../PageLoader";
// material

const CHART_DATA = [44, 55, 13, 43, 22];
const CHART_LABEL = ["Team A", "Team B", "Team C", "Team D", "Team E"];

export default function PieChart(props) {
  const [height, setHeight] = useState("0%");
  const chartOptions = {
    chart: {
      type: "pie",
    },
    colors: [
      "#2E93FA",
      "#66DA26",
      "#546E7A",
      "#E91E63",
      "#FF9800",
      "#FF5722",
      "#607D8B",
      "#9C27B0",
      "#3F51B5",
      "#03A9F4",
      "#FFC800",
      "#4D8B31",
      "#2DC2BD",
      "#5B2333",
    ],
    labels: props?.data?.labels ? [...props?.data?.labels] : CHART_LABEL,
  };
  useEffect(() => {
    setHeight("88%");
  }, []);
  return (
    <>
      {!props?.demo ? (
        <>
          {props?.data ? (
            <ReactApexChart
              type="pie"
              series={props?.data?.values}
              options={chartOptions}
              height={props?.height || height}
              width={"100%"}
            />
          ) : (
            <PageLoader />
          )}
        </>
      ) : (
        <ReactApexChart
          type="pie"
          series={CHART_DATA}
          options={chartOptions}
          height={props?.height || height}
          width={"100%"}
        />
      )}
    </>
  );
}
