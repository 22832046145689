import React, { useState } from "react";
import moment from "moment";
import {
  Grid,
  Box,
  Container,
  useTheme,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DefaultTable from "../../../../components/tables/DefaultTable";
//Apis
import { getTrainingPlans } from "../../../../api/implementation";
import { useEffect } from "react";
import { displayToast } from "../../../../utils/general";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../../components/PageLoader";
import TableSearch from "../../../../components/inputs/TableSearch";

function TrainingManagement() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activityId, planId, activityTypeStub, activityTypeId, companyId } =
    useParams();
  const [loading, setLoading] = useState(false);
  const [trainingRows, setTrainingRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterParams, setFilterParams] = useState({
    pageSize: 10,
    pageNo: 1,
    type: "ALL",
    searchTerm: "",
  });
  const [search, setSearch] = useState("");

  const trainingColumns = [
    {
      field: "id",
      headerName: t("training.tableHeaders.tableId"),
      width: 100,
      sortable: false,
    },
    {
      field: "type",
      headerName: t("training.tableHeaders.training"),
      width: 150,
      sortable: false,
    },
    {
      field: "description",
      headerName: t("training.tableHeaders.description"),
      width: 250,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("training.tableHeaders.status"),
      width: 150,
      renderCell: (params) => renderTag(params.row),
      sortable: false,
    },
    {
      field: "location",
      headerName: t("training.tableHeaders.location"),
      width: 150,
      sortable: false,
    },
    {
      field: "totalSeats",
      headerName: t("training.tableHeaders.totalSeats"),
      width: 100,
      sortable: false,
    },
    {
      field: "waitingSeats",
      headerName: t("training.tableHeaders.waitingSeats"),
      width: 150,
      sortable: false,
    },
    {
      field: "availableSeats",
      headerName: t("training.tableHeaders.availableSeats"),
      width: 150,
      sortable: false,
    },
    {
      field: "startDate",
      headerName: t("training.tableHeaders.startDate"),
      width: 100,
      sortable: false,
    },
    {
      field: "endDate",
      headerName: t("training.tableHeaders.endDate"),
      width: 100,
      sortable: false,
    },
  ];

  const renderTag = (row) => (
    <>
      {row?.status === "Concluded" ? (
        <div style={{ color: theme.palette.primary.green, fontWeight: "bold" }}>
          Concluded
        </div>
      ) : (
        <div> Active</div>
      )}
    </>
  );

  const getAllTrainingPlans = async () => {
    try {
      setLoading(true);
      let query = `companyId=true&page=${filterParams.pageNo}&pageSize=${filterParams.pageSize}`;
      if (filterParams.searchTerm) {
        query = query + `&textSearch=${filterParams.searchTerm}`;
      }
      let tempPlans = await getTrainingPlans(query);
      let tempTrainings = tempPlans?.result?.map((plan, index) => {
        return {
          id: index + 1,
          trainingId: plan.id,
          type: plan.TrainingType.name,
          description: plan.description,
          totalSeats: plan.numTotal,
          availableSeats: plan.numTotal - plan.attendingCount,
          waitingSeats: plan.numAwaiting,
          location: plan?.location?.name,
          status: plan?.concluded ? "Concluded" : "Active",
          startDate: moment(plan?.startDate).format("DD/MM/YY"),
          endDate: moment(plan?.endDate).format("DD/MM/YY"),
        };
      });
      setTrainingRows(tempTrainings);
      setTotalCount(tempPlans.totalCount);
      setLoading(false);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.trainingListError"),
        "errorCalenderComponent"
      );
    }
  };

  const submitSearch = async () => {
    try {
      setFilterParams({ ...filterParams, pageNo: 1, searchTerm: search });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllTrainingPlans();
  }, [filterParams]);

  return (
    <Stack sx={{ pb: 1 }} spacing={2}>
      <TableSearch
        searchTerm={search}
        handler={(e) => setSearch(e.target.value)}
        disabled={loading}
        submitSearch={submitSearch}
        clearable={filterParams.searchTerm}
        clearHandler={() => {
          setSearch("");
          setFilterParams({
            ...filterParams,
            pageNo: 1,
            searchTerm: "",
          });
        }}
      />
      <DefaultTable
        rows={trainingRows}
        columns={trainingColumns}
        loading={loading}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        tableInfo={{ totalPages: 1, totalCount: totalCount }}
        onRowClick={(p) => {
          navigate(
            `/implementations/training-schedule/${planId}/${companyId}/${activityId}/${activityTypeStub}/${activityTypeId}/${p.row.trainingId}`
          );
        }}
        topTextContent={
          <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
            {filterParams.searchTerm && (
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.primary.grey,
                  fontWeight: "bold",
                }}
              >{`${t("table.searchResults")} "${
                filterParams.searchTerm
              }" `}</Typography>
            )}
            <Typography
              variant="body2"
              style={{
                color: theme.palette.primary.orange,
                fontWeight: "bold",
              }}
            >{`${t("table.totalListed")} ${totalCount} `}</Typography>
          </Stack>
        }
      />
    </Stack>
  );
}

export default TrainingManagement;
