import apiHelper from "./apiHelper";

export const getDirectorateFilters = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/directorateFilters`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyFilters = (directorates) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/companyFilters?directorates=${directorates}`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getDirectorateHeatMap = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/directorateHeatMap${query}`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getDirectorateAverageMaturity = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/directorateAverageMaturity${query}`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyKPIs = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/companyKpis${query}`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTrainingKpis = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/trainingKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getPlannedObservationsKpi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/plannedObservationsKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLeadershipKpi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/leadershipKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObservationSystemKpi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/observationSystemKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getClosedActionKpi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/closedActionKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getSteeringKpi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/steeringKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObservationQualityKpi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/observationQualityKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getPostImplementationTotalKpi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/postImplementationTotalKpi`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationKpis = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiDashboards/locationKpis${query}`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
