import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import { displayToast } from "../../../utils/general";
import moment from "moment";
import { validateEmail, toTitleCase } from "../../../utils/general";
import { pullAt } from "lodash";
//  Mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Grid,
  Button,
  Box,
  Typography,
  Card,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import { FileUpload, PersonAdd } from "@mui/icons-material";
//  Components
import TextInput from "../../../components/inputs/TextInput";
import RadioSet from "../../../components/buttons/RadioSet";
import DateInput from "../../../components/inputs/DateInput";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
import TableSearch from "../../../components/inputs/TableSearch";

import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import TabsBar from "../../../components/navigation/TabsBar";
import FileUploader from "../../../components/inputs/FileUploader";
import DefaultTable from "./../../../components/tables/DefaultTable";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import InfoBox from "../../../components/InfoBox";
import SelectInput from "../../../components/inputs/SelectInput";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
//  API
import {
  uploadContractorsCSV,
  getLocationsAndUnitsApi,
  getUnitsApi,
} from "../../../api/implementation";
import { deleteUserApi, searchUsersApi, getUserApi } from "../../../api/users";
import { updateContractorApi } from "../../../api/contractors";
import {
  implementationContractorsApi,
  manageActivityApi,
} from "../../../api/activities";
import CheckboxSet from "../../../components/buttons/CheckboxSet";

const deleteDefault = {
  open: false,
  id: "",
  name: "",
};

const defaultEntry = {
  companyNumber: "",
  firstName: "",
  lastName: "",
  middleName: "",
  joiningDate: new Date(),
  gender: "male",
  completionDate: new Date(),
  jobPosition: "",
  status: "",
  emailAddress: "",
  phoneNumber: "",
  isHSEManager: false,
  isVerifier: false,
  isFocalPoint: false,
  isSupervisor: false,
  locationId: "",
  unitId: "",
  imported: false,
  userId: "",
  importedContractor: false,
  trainingRequired: true,
  trainingType: "Site Crew Training",
  trainingStatus: "Pending",
};

const defaultEdit = {
  open: false,
  id: "",
  jobPosition: "",
  firstName: "",
  lastName: "",
  middleName: "",
  gender: "",
  status: "",
  trainingRequired: true,
  completionDate: new Date(),
  joiningDate: new Date(),
  unitId: "",
  locationId: "",
  companyNumber: "",
  trainingType: "Site Crew Training",
  trainingStatus: "Pending",
  trainingCompletedAt: "",
  emailAddress: "",
  phoneNumber: "",
  department: "",
  isHSEManager: false,
  isVerifier: false,
  isFocalPoint: false,
  isSupervisor: false,
  active: false,
};

const supportedRoles = [
  "HSE_MANAGER",
  "VERIFIER",
  "SUPERVISOR",
  "CON_DIR_IMPL_PLN",
];

const DeleteButton = styled("div")(({ theme }) => ({
  minHeight: "43px",
  borderRadius: "6px",
  border: `1px solid ${theme.palette.primary.red}`,
  color: theme.palette.primary.red,
  padding: "12px",
  width: "100%",
  textAlign: "center",
  cursor: "pointer",
}));

const CHART_LABEL = ["Not trained", "Trained"];

export const ContractorDirectory = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const timeout = useRef();
  const { planId, companyId, stub, activityId } = useParams();
  const [departmentId, setDepartmentId] = useState("");
  const [directorateId, setDirectorateId] = useState("");
  const [tab, setTab] = useState("addContractors");
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([defaultEntry]);
  const [rows, setRows] = useState([]);
  const [files, setFiles] = useState([]);
  const [filterParams, setFilterParams] = useState({
    pageSize: 10,
    pageNo: 1,
  });
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [deleteDetails, setDeleteDetails] = useState(deleteDefault);
  const [editModal, setEditModal] = useState(defaultEdit);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [unitsDropdown, setUnitsDropdown] = useState({});
  const [locationUnitDropdown, setLocationUnitDropdown] = useState([]);
  const [locationUnitFilter, setLocationUnitFilter] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [userDropdown, setUserDropdown] = useState([]);
  const [importedUsers, setImportedUsers] = useState([]);
  const [importedUserId, setImportedUserId] = useState(defaultEntry);
  const [search, setSearch] = useState("");
  const [trainingType, setTrainingType] = useState("");
  const [trainingStats, setTrainingStats] = useState({
    trained: 0,
    notTrained: 0,
  });

  const trainingStatusOptions = [
    {
      label: t("training.status.pending"),
      value: "Pending",
    },
    {
      label: t("training.status.completed"),
      value: "Completed",
    },
  ];

  const trainingTypeOptions = [
    {
      label: "TTT",
      value: "TTT",
    },
    {
      label: "Site Crew Training",
      value: "Site Crew Training",
    },
    {
      label: "Leadership Workshop",
      value: "Leadership Workshop",
    },
  ];

  const [height, setHeight] = useState("100%");
  const chartOptions = {
    chart: {
      type: "pie",
    },
    labels: CHART_LABEL,
  };

  const tagStyling = {
    borderRadius: "8px",
    background: theme.palette.primary.orange,
    color: theme.palette.primary.black,
    fontWeight: "bold",
    fontSize: "12px",
    padding: "4px 7px",
    marginRight: "6px",
  };

  const renderRoleTags = (access) => {
    const finalRoles = getRolesFromAccess(access);

    return finalRoles.map((r) => <div style={tagStyling}>{r}</div>);
  };

  const getRolesFromAccess = (access) => {
    const finalRoles = [];
    Object.keys(access).forEach((r) => {
      if (supportedRoles.includes(r)) {
        if (access[r].access.some((ac) => ac.companyId == companyId))
          if (r === "CON_DIR_IMPL_PLN") {
            finalRoles.push("FOCAL POINT");
          } else {
            finalRoles.push(r.replace("_", " "));
          }
      }
    });
    finalRoles.sort();
    return finalRoles;
  };

  const renderTrainingType = (row) => {
    return (
      <SelectInput
        style={{ width: "100px" }}
        stackProps={{ paddingBottom: "0px" }}
        value={row.trainingType || getTrainingTypes(row.contractor)}
        handler={(e) => setCurrentTrainingData(row.id, e)}
        options={trainingTypeOptions}
        disabled={loading}
      />
    );
  };

  const renderTrainingStatus = (row, tType) => {
    return (
      <SelectInput
        style={{ width: "100px" }}
        stackProps={{ paddingBottom: "0px" }}
        value={row.trainingStatus || getTrainingStatus(row, tType)}
        handler={(e) => handleStatus(row, e.target.value)}
        options={trainingStatusOptions}
        disabled={loading || tType != "Site Crew Training"}
      />
    );
  };

  const renderTrainingCompletionDate = (row, tType, tStatus) => {
    return (
      <DateInput
        disableFuture
        style={{ width: "100px" }}
        stackProps={{ paddingBottom: "0px" }}
        disabled={loading || getTrainingStatus(row, tType) === "Pending"}
        date={getTrainingCompletionDate(tStatus, tType, row)}
        handler={(newDate) =>
          updateTrainingData(row, row.trainingStats, newDate)
        }
      />
    );
  };

  const columns = [
    {
      field: "firstName",
      headerName: t("table.firstName"),
      width: 150,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: t("table.lastName"),
      width: 150,
      sortable: false,
    },
    {
      field: "emailAddress",
      headerName: t("table.email"),
      width: 150,
      sortable: false,
    },
    {
      field: "role",
      valueGetter: (params) => getRolesFromAccess(params.row.access),
      headerName: t("table.role"),
      width: 200,
      sortable: false,
      renderCell: (params) => renderRoleTags(params.row.access),
      sortComparator: (v1, v2) => {
        for (let i = 0; i < v1.length && i < v2.length; i++) {
          let compareResult = gridStringOrNumberComparator(v1[i], v2[i]);
          if (compareResult != 0) return compareResult;
        }
        return gridStringOrNumberComparator(v1.length, v2.length);
      },
    },
    {
      field: "trainingType",
      headerName: t("table.trainingType"),
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return renderTrainingType(params.row);
      },
    },
    {
      field: "trainingStatus",
      headerName: t("table.trainingStatus"),
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return renderTrainingStatus(
          params.row,
          params?.row?.trainingType
            ? params?.row?.trainingType
            : "Site Crew Training"
        );
      },
    },
    {
      field: "completionDate",
      sortable: false,
      headerName: t("table.completionDate"),
      width: 300,
      renderCell: (params) =>
        renderTrainingCompletionDate(
          params.row,
          params.row.trainingType,
          params.row.trainingStatus
        ),
    },
    {
      field: "actions",
      headerName: t("table.actions"),
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <SecondaryButton
            label={t("general.edit")}
            alignment={"center"}
            style={{ height: "35px" }}
            onClick={() => {
              setEditModal({
                open: true,
                id: params.row.id,
                jobPosition: params.row.contractors[0]?.jobPosition,
                firstName: params.row.firstName,
                lastName: params.row.lastName,
                middleName: params.row.contractors[0]?.middleName,
                gender: params.row.contractors[0]?.gender
                  ? params.row.contractors[0].gender.toLowerCase()
                  : null,
                status: params.row.contractors[0]?.status,
                trainingRequired: params.row.contractors[0]?.trainingRequired,
                trainingType: "Site Crew Training",
                trainingStatus: params.row.siteCrewTrainingCompletedAt
                  ? "Completed"
                  : "Pending",
                leadershipTrainingStatus: params.row
                  .leadershipTrainingCompletedAt
                  ? true
                  : false,
                siteCrewTrainingStatus: params.row.siteCrewTrainingCompletedAt
                  ? true
                  : false,
                trainingCompletionStatus: params.row.trainingCompletedAt
                  ? true
                  : false,
                completionDate: params.row?.siteCrewTrainingCompletedAt ?? null,
                siteCrewTrainingCompletedAt:
                  params.row.siteCrewTrainingCompletedAt,
                // leadershipTrainingCompletedAt:
                //   params.row.leadershipTrainingCompletedAt,
                joiningDate:
                  params.row.contractors[0]?.joiningDate || new Date(),
                locationId: params.row.contractors[0]?.locationId || "",
                unitId: params.row.contractors[0]?.unitId || null,
                companyNumber: params.row.companyNumber || "",
                // trainingCompletedAt:
                //   params.row.trainingCompletedAt || new Date(),
                emailAddress: params.row.emailAddress,
                phoneNumber: params.row.phoneNumber
                  ? params.row.phoneNumber
                  : "",
                department: params.row.department || "",
                isFocalPoint:
                  ("CON_DIR_IMPL_PLN" in params.row.access &&
                    params.row.access["CON_DIR_IMPL_PLN"].access.some(
                      (ac) => ac.companyId == companyId
                    )) ||
                  false,
                isHSEManager: "HSE_MANAGER" in params.row.access || false,
                isSupervisor: "SUPERVISOR" in params.row.access || false,
                isVerifier: "VERIFIER" in params.row.access || false,
                userImported: params.row.contractors[0]?.userImported,
                azure: params.row.azure,
                active: params.row.active,
              });
            }}
            disabled={loading}
          />
          <SecondaryButton
            isRed
            label={t("remove")}
            alignment={"center"}
            style={{ height: "35px" }}
            onClick={() =>
              setDeleteDetails({
                open: true,
                id: params.row.id,
                name: `${params.row.firstName ?? ""} ${
                  params.row.lastName ?? ""
                }`,
              })
            }
            disabled={loading}
          />
        </Stack>
      ),
    },
  ];

  const tabsList = [
    {
      label: t("implementations.contractorsDirectory.tabs.addContractors"),
      value: 0,
      key: "addContractors",
    },
    {
      label: t("implementations.contractorsDirectory.tabs.uploadContractors"),
      value: 0,
      key: "uploadContractors",
    },
    {
      label: t("implementations.contractorsDirectory.tabs.viewContractors"),
      value: 0,
      key: "viewContractors",
    },
  ];

  const renderAddContractors = () => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <AutocompleteInput
            handler={(e, v) => addUserEntries(v)}
            keyPressHandler={handleSearchUser}
            placeholder={t(
              "implementations.contractorsDirectory.addOrImportContractors"
            )}
            loading={searchLoading}
            disabled={loading}
            options={userDropdown}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <SecondaryButton
            icon={<PersonAdd />}
            label={t("implementations.contractorsDirectory.importContractor")}
            alignment={"right"}
            onClick={importContractor}
            disabled={loading}
          />
        </Grid>
      </Grid>
      {entries.map((jump, index) => (
        <>
          <Grid paddingY={1}>
            <Card>
              <Grid padding={2} container key={"jump" + index}>
                <Grid container>
                  <Grid item sm={3} paddingX={1}>
                    <TextInput
                      autoFocus
                      disabled={loading || entries[index].importedContractor}
                      margin="dense"
                      label={t(
                        "implementations.contractorsDirectory.companyNumber"
                      )}
                      placeholder={t(
                        "implementations.contractorsDirectory.companyNumberText"
                      )}
                      value={entries[index].companyNumber}
                      handler={(e) =>
                        handleValueChange("companyNumber", index, e)
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={3} paddingX={1}>
                    <TextInput
                      margin="dense"
                      label={t("implementations.firstName")}
                      disabled={loading || entries[index].importedContractor}
                      placeholder={t("implementations.firstNamePlaceholder")}
                      value={entries[index].firstName}
                      handler={(e) => handleValueChange("firstName", index, e)}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={3} paddingX={1}>
                    <TextInput
                      margin="dense"
                      label={t("implementations.middleName")}
                      disabled={loading || entries[index].importedContractor}
                      placeholder={t("implementations.middleNamePlaceholder")}
                      value={entries[index].middleName}
                      handler={(e) => handleValueChange("middleName", index, e)}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={3} paddingX={1}>
                    <TextInput
                      margin="dense"
                      label={t("implementations.contractorsDirectory.lastName")}
                      placeholder={t("implementations.lastNamePlaceholder")}
                      disabled={loading || entries[index].importedContractor}
                      value={entries[index].lastName}
                      handler={(e) => handleValueChange("lastName", index, e)}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6} padding={1}>
                    <TextInput
                      margin="dense"
                      label={t("implementations.email")}
                      placeholder={t("implementations.enterEmailText")}
                      disabled={loading || entries[index].importedContractor}
                      value={entries[index].emailAddress}
                      handler={(e) =>
                        handleValueChange("emailAddress", index, e)
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6} padding={1}>
                    <TextInput
                      margin="dense"
                      label={t(
                        "implementations.contractorsDirectory.phoneNumber"
                      )}
                      placeholder={t("implementations.phoneNumberPlaceholder")}
                      disabled={loading || entries[index].importedContractor}
                      value={entries[index].phoneNumber}
                      handler={(e) =>
                        handleValueChange("phoneNumber", index, e)
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={4} padding={1}>
                    <Typography variant={"h6"}>
                      {t("implementations.contractorsDirectory.gender")}
                    </Typography>
                    <RadioSet
                      disabled={loading || entries[index].importedContractor}
                      label=""
                      value={entries[index].gender}
                      handleChange={(e) =>
                        handleValueChange("gender", index, e)
                      }
                      options={[
                        {
                          label: t(`implementations.female`),
                          value: "female",
                        },
                        {
                          label: t(`implementations.male`),
                          value: "male",
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item sm={4} padding={1}>
                    <Typography variant={"h6"}>
                      {t("implementations.contractorsDirectory.joiningDate")}
                    </Typography>
                    <DateInput
                      date={entries[index].joiningDate}
                      disabled={loading || entries[index].importedContractor}
                      handler={(e) =>
                        handleValueChange("joiningDate", index, e)
                      }
                    />
                  </Grid>

                  <Grid item sm={4} padding={1}>
                    <TextInput
                      margin="dense"
                      label={t(
                        "implementations.contractorsDirectory.jobPosition"
                      )}
                      placeholder={t(
                        "implementations.contractorsDirectory.jobPositionText"
                      )}
                      disabled={loading}
                      value={entries[index].jobPosition}
                      handler={(e) =>
                        handleValueChange("jobPosition", index, e)
                      }
                      fullWidth
                    ></TextInput>
                  </Grid>
                  <Grid item sm={6} padding={1}>
                    <SelectInput
                      value={entries[index].locationId}
                      handler={(e) => {
                        handleValueChange("locationId", index, e);
                      }}
                      options={locationsDropdown}
                      label={t("implementations.contractorsDirectory.location")}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item sm={6} padding={1}>
                    <SelectInput
                      optional
                      value={entries[index].unitId}
                      handler={(e) => handleValueChange("unitId", index, e)}
                      options={
                        unitsDropdown[entries[index].locationId]
                          ? unitsDropdown[entries[index].locationId]
                          : []
                      }
                      label={t("implementations.contractorsDirectory.unit")}
                      disabled={loading}
                    />
                  </Grid>
                  {/* <Grid item sm={12} padding={1}>
                    <Stack>
                      <Typography variant="h6">
                        {t(
                          "implementations.contractorsDirectory.selectTraining"
                        )}
                      </Typography>
                      <CheckboxSet
                        disabled={loading}
                        label="trainingRequired"
                        handler={(e) =>
                          handleValueChange(
                            "trainingRequired",
                            index,
                            e.target.checked
                          )
                        }
                        options={[
                          {
                            //   label: t(
                            //     "implementations.contractorsDirectory.hseManager"
                            //   ),
                            //   value: "isHSEManager",
                            //   checked: entries[index].isHSEManager,
                            // },
                            // {
                            //   label: t(
                            //     "implementations.contractorsDirectory.verifier"
                            //   ),
                            //   value: "isVerifier",
                            //   checked: entries[index].isVerifier,
                            // },
                            // {
                            label: t(
                              "implementations.contractorsDirectory.trainingRequiredFlag"
                            ),
                            value: "trainingRequired",
                            checked: entries[index].trainingRequired,
                          },
                          // {
                          //   label: t(
                          //     "implementations.contractorsDirectory.supervisor"
                          //   ),
                          //   value: "isSupervisor",
                          //   checked: entries[index].isSupervisor,
                          // },
                        ]}
                      />
                    </Stack>
                  </Grid> */}
                  {
                    <>
                      {/* <Grid item sm={4} padding={1}>
                        <SelectInput
                          value={entries[index].trainingType}
                          handler={(e) =>
                            handleValueChange("trainingType", index, e)
                          }
                          options={trainingTypeOptions}
                          label={t(
                            "implementations.contractorsDirectory.trainingTypes"
                          )}
                          disabled={loading}
                        />
                      </Grid> */}

                      <>
                        <Grid item sm={6} padding={1}>
                          <SelectInput
                            value={entries[index].trainingStatus}
                            handler={(e) =>
                              handleValueChange("trainingStatus", index, e)
                            }
                            options={trainingStatusOptions}
                            label={t(
                              "implementations.contractorsDirectory.trainingStatus"
                            )}
                            disabled={loading}
                          />
                        </Grid>
                        <Grid item sm={6} padding={1}>
                          <Typography variant={"h6"}>
                            {t(
                              "implementations.contractorsDirectory.trainingCompletionDate"
                            )}
                          </Typography>
                          <DateInput
                            disableFuture
                            disabled={
                              entries[index].trainingStatus === "Pending"
                                ? true
                                : false
                            }
                            date={
                              entries[index].trainingStatus === "Completed"
                                ? entries[index].completionDate
                                : null
                            }
                            handler={(e) =>
                              handleValueChange("completionDate", index, e)
                            }
                          />
                        </Grid>
                      </>
                    </>
                  }
                  {entries[index].importedContractor && (
                    <Grid
                      item
                      sm={12}
                      padding={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Stack>
                        <Typography variant="h6">
                          {t("implementations.contractorsDirectory.roles")}
                        </Typography>
                        <CheckboxSet
                          disabled={loading}
                          label="role_options"
                          handler={(e) =>
                            handleValueChange(
                              e.target.name,
                              index,
                              e.target.checked
                            )
                          }
                          options={[
                            {
                              //   label: t(
                              //     "implementations.contractorsDirectory.hseManager"
                              //   ),
                              //   value: "isHSEManager",
                              //   checked: entries[index].isHSEManager,
                              // },
                              // {
                              //   label: t(
                              //     "implementations.contractorsDirectory.verifier"
                              //   ),
                              //   value: "isVerifier",
                              //   checked: entries[index].isVerifier,
                              // },
                              // {
                              label: t(
                                "implementations.contractorsDirectory.focalPoint"
                              ),
                              value: "isFocalPoint",
                              checked: entries[index].isFocalPoint,
                            },
                            // {
                            //   label: t(
                            //     "implementations.contractorsDirectory.supervisor"
                            //   ),
                            //   value: "isSupervisor",
                            //   checked: entries[index].isSupervisor,
                            // },
                          ]}
                        />
                      </Stack>
                    </Grid>
                  )}

                  {/* <Grid item sm={4} padding={1}>
                    <TextInput
                      margin="dense"
                      label={t(
                        "implementations.contractorsDirectory.trainingRequired"
                      )}
                      placeholder={t(
                        "implementations.contractorsDirectory.trainingRequiredText"
                      )}
                      value={entries[index].trainingRequired}
                      handler={(e) =>
                        handleValueChange("trainingRequired", index, e)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4} padding={1}>
                    <TextInput
                      margin="dense"
                      label={t(
                        "implementations.contractorsDirectory.trainingStatus"
                      )}
                      placeholder={t(
                        "implementations.contractorsDirectory.trainingStatusText"
                      )}
                      value={entries[index].status}
                      handler={(e) => handleValueChange("status", index, e)}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={4} padding={1} paddingTop={2}>
                    <Typography variant={"h6"}>
                      {t("implementations.contractorsDirectory.completionDate")}
                    </Typography>
                    <DateInput
                      disabled={loading}
                      date={entries[index].completionDate}
                      handler={(e) =>
                        handleValueChange("completionDate", index, e)
                      }
                    />
                  </Grid> */}
                  {entries.length > 0 && (
                    <Grid
                      item
                      sm={12}
                      padding={1}
                      paddingTop={2}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <DeleteButton onClick={() => handleRemoveRow(index)}>
                        {t("implementations.contractorsDirectory.removeRow")}
                      </DeleteButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </>
      ))}

      <Grid paddingLeft={2}>
        <Button
          onClick={() => {
            const updatedArray = [...entries];
            updatedArray.push(defaultEntry);
            setEntries(updatedArray);
          }}
        >
          {t("general.addRow")}
        </Button>
      </Grid>
      <Grid sx={{ mt: 4 }}>
        <PrimaryButton
          label={t("save")}
          onClick={submitAddContractors}
          disabled={loading}
          alignment="left"
        />
      </Grid>
    </>
  );

  const renderUploadContractors = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1">
          {`${t("implementations.contractorsDirectory.fileDescription")} `}
          <a
            href="/static/user-directory/contractor-directory-template.xlsx"
            style={{
              testDecoration: "underline",
              color: theme.palette.primary.orange,
              cursor: "pointer",
            }}
            download
          >
            {t("implementations.contractorsDirectory.here")}
          </a>
          .
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FileUploader
          multiple={false}
          files={files}
          setFiles={setFiles}
          acceptedFormats={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          labelText={t("implementations.contractorsDirectory.labelText")}
        />
      </Grid>
      <Grid item xs={12}>
        <PrimaryButton
          disabled={files.length === 0}
          alignment="left"
          icon={<FileUpload />}
          label={t("general.upload")}
          onClick={submitUploadContractors}
        />
      </Grid>
    </Grid>
  );

  const renderViewContractors = () => (
    <Grid container sx={{ mb: 3 }}>
      <Grid item xs={8}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TableSearch
            searchTerm={search}
            handler={(e) => setSearch(e.target.value)}
            disabled={loading}
            submitSearch={submitSearch}
            clearable={filterParams.textSearch}
            clearHandler={() => {
              setSearch("");
              setFilterParams({ ...filterParams, pageNo: 1, textSearch: "" });
            }}
          />
          <Grid item xs={6} sx={{ pb: 1 }}>
            <MultiSelectInput
              disabled={false}
              options={locationUnitDropdown}
              handler={(newValue) => handleLocationUnitFilter(newValue)}
              values={locationUnitFilter}
            />
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={3} paddingRight={"20px"}>
        {tableInfo.totalCount > 0 ? (
          <ReactApexChart
            type="pie"
            series={[trainingStats.notTrained, trainingStats.trained]}
            options={chartOptions}
            height={height}
            width={"120%"}
          />
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <DefaultTable
          defaultLimit
          rows={rows}
          columns={columns}
          loading={loading}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          rowSelectable={false}
          tableInfo={tableInfo}
          topTextContent={
            <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
              {filterParams.searchTerm && (
                <Typography
                  variant="body2"
                  style={{
                    color: theme.palette.primary.grey,
                    fontWeight: "bold",
                  }}
                >{`${t("table.searchResults")} "${
                  filterParams.searchTerm
                }" `}</Typography>
              )}
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.primary.orange,
                  fontWeight: "bold",
                }}
              >{`${t("table.totalListed")} ${
                tableInfo.totalCount
              } `}</Typography>
            </Stack>
          }
        />
      </Grid>
    </Grid>
  );

  const renderDeleteModal = () => (
    <InfoBox
      disabled={loading}
      open={deleteDetails.open}
      title={`${t(`remove`)} ${deleteDetails.name}`}
      content={
        <Stack>
          <Typography variant="h6" style={{ color: theme.palette.primary.red }}>
            {t(`implementations.contractorsDirectory.deleteConfirm`)}
          </Typography>
        </Stack>
      }
      buttonLabel={t(`remove`)}
      handleClose={() => setDeleteDetails({ ...deleteDetails, open: false })}
      handleAction={submitRemoveContractor}
    />
  );

  const renderEditModal = () => (
    <InfoBox
      minWidth="80vw"
      disabled={loading}
      open={editModal.open}
      title={`${t(`general.edit`)} ${
        editModal.firstName ? editModal.firstName : ""
      } ${editModal.lastName ? editModal.lastName : ""}`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextInput
              disabled={loading || editModal.userImported}
              label={t("implementations.contractorsDirectory.companyNumber")}
              value={editModal.companyNumber}
              handler={(e) =>
                setEditModal({ ...editModal, companyNumber: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              disabled={loading || editModal.userImported}
              label={t("implementations.firstName")}
              value={editModal.firstName}
              handler={(e) =>
                setEditModal({ ...editModal, firstName: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              disabled={loading || editModal.userImported}
              label={t("implementations.middleName")}
              value={editModal.middleName}
              handler={(e) =>
                setEditModal({ ...editModal, middleName: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              disabled={loading || editModal.userImported}
              label={t("implementations.contractorsDirectory.lastName")}
              value={editModal.lastName}
              handler={(e) =>
                setEditModal({ ...editModal, lastName: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              disabled={loading || editModal.userImported}
              label={t("implementations.contractorsDirectory.emailAddress")}
              value={editModal.emailAddress}
              handler={(e) =>
                setEditModal({
                  ...editModal,
                  emailAddress: e.target.value,
                  active: e.target.value === "" ? false : editModal.active,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              disabled={loading || editModal.userImported}
              label={t("implementations.contractorsDirectory.phoneNumber")}
              value={editModal.phoneNumber}
              handler={(e) => {
                const re = /^[0-9]+$/;
                if (e.target.value === "" || e.target.value.match(re)) {
                  setEditModal({
                    ...editModal,
                    phoneNumber: e.target.value,
                    active: e.target.value == "" ? false : editModal.active,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <RadioSet
              disabled={loading || editModal.userImported}
              label={t("implementations.contractorsDirectory.gender")}
              value={editModal.gender}
              handleChange={(e) =>
                setEditModal({ ...editModal, gender: e.target.value })
              }
              options={[
                {
                  label: t(`implementations.female`),
                  value: "female",
                },
                {
                  label: t(`implementations.male`),
                  value: "male",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateInput
              label={t("implementations.contractorsDirectory.joiningDate")}
              date={editModal.joiningDate}
              disabled={loading}
              handler={(newDate) => {
                setEditModal({
                  ...editModal,
                  joiningDate: newDate,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              disabled={loading}
              label={t("implementations.contractorsDirectory.jobPosition")}
              value={editModal.jobPosition}
              handler={(e) =>
                setEditModal({ ...editModal, jobPosition: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectInput
              value={editModal.locationId}
              handler={(e) =>
                setEditModal({ ...editModal, locationId: e.target.value })
              }
              options={locationsDropdown}
              label={t("implementations.contractorsDirectory.location")}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectInput
              optional
              value={editModal.unitId}
              handler={(e) =>
                setEditModal({ ...editModal, unitId: e.target.value })
              }
              options={
                unitsDropdown[editModal.locationId]
                  ? unitsDropdown[editModal.locationId]
                  : []
              }
              label={t("implementations.contractorsDirectory.unit")}
              disabled={loading}
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <TextInput
              disabled={true}
              label={t("implementations.contractorsDirectory.trainingRequired")}
              value={editModal.trainingRequired}
            />
          </Grid> */}
          {/* <Grid item xs={12} md={3}>
            <SelectInput
              value={editModal.trainingType}
              handler={(e) => {
                setEditModal({ ...editModal, trainingType: e.target.value });
                setTrainingType(e.target.value);
              }}
              options={trainingTypeOptions}
              label={t("implementations.contractorsDirectory.trainingType")}
              disabled={loading}
            />
          </Grid> */}

          <>
            <Grid item xs={12} md={3}>
              <SelectInput
                value={editModal.trainingStatus}
                handler={(e) =>
                  setEditModal({
                    ...editModal,
                    trainingStatus: e.target.value,
                  })
                }
                options={trainingStatusOptions}
                label={t("implementations.contractorsDirectory.trainingStatus")}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DateInput
                label={t(
                  "implementations.contractorsDirectory.trainingCompletionDate"
                )}
                date={editModal.completionDate}
                // date={getTrainingCompletionDate(
                //   editModal.trainingStatus,
                //   editModal.trainingType,
                //   editModal
                // )}
                disabled={loading || editModal.trainingStatus === "Pending"}
                handler={(newDate) => {
                  setEditModal({
                    ...editModal,
                    completionDate: newDate,
                  });
                }}
              />
            </Grid>
          </>

          <Grid
            item
            xs={12}
            md={3}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            {/* <Stack>
              <Typography variant="h6">
                {t("implementations.contractorsDirectory.roles")}
              </Typography>
              <CheckboxSet
                disabled={loading}
                handler={(e) =>
                  setEditModal({
                    ...editModal,
                    [e.target.name]: e.target.checked,
                  })
                }
                options={[
                  {
                    label: t("implementations.contractorsDirectory.hseManager"),
                    value: "isHSEManager",
                    checked: editModal.isHSEManager,
                  },
                  {
                    label: t("implementations.contractorsDirectory.verifier"),
                    value: "isVerifier",
                    checked: editModal.isVerifier,
                  },
                  {
                    label: t("implementations.contractorsDirectory.focalPoint"),
                    value: "isFocalPoint",
                    checked: editModal.isFocalPoint,
                  },
                  {
                    label: t("implementations.contractorsDirectory.supervisor"),
                    value: "isSupervisor",
                    checked: editModal.isSupervisor,
                  },
                ]}
              />
            </Stack> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={editModal.isFocalPoint}
                  name={"isFocalPoint"}
                  onChange={(e) =>
                    setEditModal({
                      ...editModal,
                      [e.target.name]: e.target.checked,
                    })
                  }
                  sx={{
                    color: theme.palette.primary.orange,
                    "&.Mui-checked": {
                      color: theme.palette.primary.orange,
                    },
                    "&:hover": {
                      backgroundColor: "rgba(248,147,31,0.08)",
                    },
                  }}
                />
              }
              disabled={loading || !editModal.azure}
              label={t("implementations.contractorsDirectory.isFocalPoint")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <FormControlLabel
              key={`checkboxActiveUser`}
              control={
                <Checkbox
                  disabled={
                    !(
                      editModal.emailAddress &&
                      editModal.emailAddress != "" &&
                      editModal.phoneNumber &&
                      editModal.phoneNumber != ""
                    )
                  }
                  checked={editModal.active}
                  name={`PDOUSERCHECKBOX`}
                  onChange={(e) =>
                    setEditModal({
                      ...editModal,
                      active: !editModal.active,
                    })
                  }
                  sx={{
                    color: theme.palette.primary.orange,
                    "&.Mui-checked": { color: theme.palette.primary.orange },
                    "&:hover": {
                      backgroundColor: "rgba(248,147,31,0.08)",
                    },
                    "&.Mui-disabled": {
                      color: theme.palette.primary.disabled,
                    },
                  }}
                />
              }
              label={t("implementations.contractorsDirectory.isActive")}
            />
          </Grid>
        </Grid>
      }
      buttonLabel={t(`general.saveChanges`)}
      handleClose={() => setEditModal(defaultEdit)}
      handleAction={submitUpdateContractor}
    />
  );

  const handleValueChange = (name, index, e) => {
    if (name === "phoneNumber") {
      const re = /^[0-9]+$/;
      if (!(e.target.value === "" || e.target.value.match(re))) return;
    }
    const updatedObj = {
      ...entries[index],
      [name]:
        name === "completionDate" ||
        name === "joiningDate" ||
        name === "isHSEManager" ||
        name === "isVerifier" ||
        name === "isSupervisor" ||
        name === "isFocalPoint" ||
        name === "trainingRequired" ||
        name === "trainingDate"
          ? e
          : e.target.value,
    };
    const updatedArray = [...entries];
    updatedArray[index] = updatedObj;

    setEntries(updatedArray);
  };

  const handleRemoveRow = (index) => {
    const updatedArray = [...entries];
    pullAt(updatedArray, [index]);
    setEntries(updatedArray);
  };

  const submitAddContractors = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.contractorsDirectory.addLoading")
    );
    try {
      const contractorsPayload = [];

      //  Validation

      for (let index = 0; index < entries.length; index++) {
        const c = entries[index];

        if (
          !c.emailAddress ||
          c.emailAddress === "" ||
          validateEmail(c.emailAddress)
        ) {
          if (!c.companyNumber) {
            throw t(
              "implementations.contractorsDirectory.errors.enterCompanyNumber",
              {
                index: index + 1,
              }
            );
          }
          if (!c.firstName) {
            throw t(
              "implementations.contractorsDirectory.errors.enterFirstName",
              {
                index: index + 1,
              }
            );
          }
          if (!c.locationId) {
            throw t(
              "implementations.contractorsDirectory.errors.enterLocation",
              {
                index: index + 1,
              }
            );
          }
          if (!c.trainingType) {
            throw t(
              "implementations.contractorsDirectory.errors.enterTraining",
              {
                index: index + 1,
              }
            );
          }
          if (!c.trainingStatus) {
            throw t(
              "implementations.contractorsDirectory.errors.enterTrainingType",
              {
                index: index + 1,
              }
            );
          }
          if (c.trainingStatus === "Completed" && !c.completionDate) {
            throw t(
              "implementations.contractorsDirectory.errors.enterTrainingDate",
              {
                index: index + 1,
              }
            );
          }

          const finalPayload = {
            companyNumber: c.companyNumber,
            firstName: c.firstName,
            lastName: c.lastName || "",
            middleName: c.middleName || "",
            joiningDate: c.joiningDate
              ? moment(c.joiningDate).format("YYYY-MM-DD")
              : null,
            gender: c.gender || null,
            completionDate:
              c.trainingStatus === "Completed"
                ? c.completionDate
                  ? moment(c.completionDate).format("DD/MM/YYYY")
                  : null
                : null,
            jobPosition: c.jobPosition || null,
            status: c.status || null,
            emailAddress: c.emailAddress || null,
            phoneNumber: c.phoneNumber,
            locationId: c.locationId || null,
            department: departmentId || null,
            unitId: c.unitId || null,
            isHSEManager: c.isHSEManager,
            isVerifier: c.isVerifier,
            isFocalPoint: c.isFocalPoint,
            isSupervisor: c.isSupervisor,
            directorateId,
            importedContractor: c.importedContractor,
            userId: c.userId,
            trainingRequired: defaultEntry.trainingRequired,
            trainingType: c.trainingType,
            trainingStatus: c.trainingStatus,
          };

          contractorsPayload.push(finalPayload);
        } else {
          throw t("validation.email");
        }
      }

      const body = {
        activityTypeStub: stub,
        activityId,
        planId,
        companyId,
        contractors: contractorsPayload,
      };
      await manageActivityApi(body);
      displayToast(
        setLoading,
        "success",
        t(
          body.contractors.length > 1
            ? "implementations.contractorsDirectory.multipleUsersaddSuccess"
            : "implementations.contractorsDirectory.addSuccess"
        ),
        activeToast
      );
      setEntries([defaultEntry]);
      getContractors();
      setTab("viewContractors");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  const submitUploadContractors = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.contractorsDirectory.uploadLoading")
    );

    try {
      await uploadContractorsCSV({ attachments: files }, planId, companyId);
      displayToast(
        setLoading,
        "success",
        t("implementations.contractorsDirectory.uploadSuccess"),
        activeToast
      );
      getContractors();
      setFiles([]);
      setTab("viewContractors");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitRemoveContractor = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.contractorsDirectory.deleteLoading")
    );
    try {
      await deleteUserApi(deleteDetails.id, "contractors", planId);
      getContractors();
      setDeleteDetails(deleteDefault);
      displayToast(
        setLoading,
        "success",
        t("implementations.contractorsDirectory.deleteSuccess"),
        activeToast
      );
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  const submitUpdateContractor = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating")
    );
    try {
      if (editModal.emailAddress && !validateEmail(editModal.emailAddress)) {
        throw t("implementations.contractorsDirectory.errors.email");
      }
      if (!editModal.firstName) {
        throw t("implementations.contractorsDirectory.errors.firstName");
      }
      if (!editModal.companyNumber) {
        throw t("implementations.contractorsDirectory.errors.companyNumber");
      }
      if (
        editModal.trainingStatus === "Completed" &&
        !editModal.completionDate
      ) {
        throw t(
          "implementations.contractorsDirectory.errors.enterTrainingDate2"
        );
      }
      if (editModal.phoneNumber && editModal.phoneNumber.length < 7) {
        throw t("implementations.contractorsDirectory.errors.phoneNumber");
      }
      if (editModal.phoneNumber === "") {
        editModal.phoneNumber = null;
      }
      await updateContractorApi(planId, companyId, editModal.id, {
        ...editModal,
        directorateId,
        department: departmentId || "",
        trainingRequired: true,
        ...(editModal.userImported
          ? { active: undefined }
          : {
              active: editModal.active,
            }),
      });
      setEditModal(defaultEdit);
      displayToast(
        setLoading,
        "success",
        t("implementations.contractorsDirectory.updateSuccess"),
        activeToast
      );
      getContractors();
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  const getContractors = async () => {
    try {
      setLoading(true);
      const contractorData = await implementationContractorsApi({
        ...filterParams,
        companyId,
        planId,
      });
      const newData = contractorData.data.map((c) => {
        return {
          ...c,
          trainingType: c.siteCrewTrainingCompletedAt
            ? "Site Crew Training"
            : c.trainingCompletedAt
            ? "TTT"
            : c.leadershipTrainingCompletedAt
            ? "Leadership Workshop"
            : "Site Crew Training",
        };
      });
      setRows(newData);
      setTrainingStats({
        trained: contractorData.totalUsersCompletedSiteCrew,
        notTrained:
          contractorData.totalItems -
          contractorData.totalUsersCompletedSiteCrew,
      });
      setTableInfo({
        totalCount: contractorData.totalItems,
        totalPages: contractorData.totalPages,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getLocationsAndUnits = async () => {
    try {
      setLoading(true);
      const rawData = await getLocationsAndUnitsApi(planId);
      let locationUnits = [];
      let locationUnitMap = {};
      const locationOptions = rawData.locations.map((l) => {
        locationUnitMap[l.id] = rawData.units
          .filter((u) => u.locationId === l.id)
          .map((u) => ({ label: u.name, value: u.id }));
        locationUnits.push({
          label: l.name,
          value: `${l.name}--${l.id}--location`,
        });
        return {
          label: l.name,
          value: l.id,
        };
      });
      //const unitOptions =
      rawData.units.forEach((u) => {
        locationUnits.push({
          label: u.name,
          value: `${u.name}--${u.id}--unit`,
        });
        // return {
        //   label: u.name,
        //   value: u.id,
        // };
      });

      if (rawData.directorates[0]?.id) {
        setDirectorateId(rawData.directorates[0].id);
      }
      setUnitsDropdown(locationUnitMap);
      setLocationsDropdown(locationOptions);
      setLocationUnitDropdown(locationUnits);
      setDepartmentId(rawData.department?.id);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const azureUser = true;
          const result = await searchUsersApi(value, 1, 0, azureUser);
          const updatedResult = result.filter(
            (user) => !rows.some((u) => u.id === user.id)
          );

          setImportedUsers(updatedResult);

          const filteredDropdown = updatedResult.filter(
            (user) => !entries.some((entry) => entry.userId === user.id)
          );

          const finalDropdown = [];
          filteredDropdown.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                access: user.access,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const handleLocationUnitFilter = (val) => {
    setLocationUnitFilter(val);
    let locationIds = [];
    let unitIds = [];
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        const filter = val[i].value.split("--");
        if (filter && filter[2] === "location") {
          locationIds.push(filter[1]);
        } else {
          unitIds.push(filter[1]);
        }
      }
    }
    setFilterParams({
      ...filterParams,
      locationIds: locationIds,
      unitIds: unitIds,
    });
  };

  const handleTrainingStatus = () => {
    if (
      (trainingType === "TTT" && editModal.trainingCompletionStatus) ||
      (trainingType === "Site Crew Training" &&
        editModal.siteCrewTrainingStatus) ||
      (trainingType === "Leadership Workshop" &&
        editModal.leadershipTrainingStatus)
    ) {
      let finalCompletionDate = null;
      if (trainingType === "TTT")
        finalCompletionDate = editModal.trainingCompletedAt;
      if (trainingType === "Site Crew Training")
        finalCompletionDate = editModal.siteCrewTrainingCompletedAt;
      if (trainingType === "Leadership Workshop")
        finalCompletionDate = editModal.leadershipTrainingCompletedAt;

      setEditModal({
        ...editModal,
        trainingStatus: "Completed",
        completionDate: finalCompletionDate,
      });
    } else {
      setEditModal({ ...editModal, trainingStatus: "Pending" });
    }
  };

  const addUserEntries = (user) => {
    setImportedUserId(user?.id);
  };

  const submitSearch = async () => {
    try {
      setFilterParams({ ...filterParams, pageNo: 1, textSearch: search });
    } catch (err) {
      console.log(err);
    }
  };

  const importContractor = async () => {
    let updatedArray = [];
    const user = await getUserApi(importedUserId);
    const importedUser = {
      companyNumber: user.companyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user?.contractors?.middleName,
      trainingRequired: user?.contractors?.trainingRequired,
      joiningDate: user?.contractors?.joiningDate,
      gender: user?.contractors?.gender || null,
      completionDate: user?.contractors?.completionDate || "",
      jobPosition: user?.contractors?.jobPosition,
      status: user?.contractors?.status,
      emailAddress: user.emailAddress || "No email",
      importedContractor: true,
      userId: user.id,
      trainingStatus: user?.contractors?.siteCrewTrainingStatus
        ? "Completed"
        : "Pending",
      trainingType: "Site Crew Training",
      isFocalPoint: user?.access?.hasOwnProperty("CON_DIR_IMPL_PLN") || false,
    };
    if (entries.length === 1 && entries[0].emailAddress === "") {
      setEntries([importedUser]);
    } else {
      updatedArray = [...entries];
      updatedArray.splice(0, 0, importedUser);
      setEntries(updatedArray);
    }
    setUserDropdown([]);
    setImportedUsers([]);
  };

  const getTrainingCompletionDate = (tStatus, tType, contractor) => {
    if (tStatus) {
      return tStatus === "Completed" && tType === "Site Crew Training"
        ? contractor.siteCrewTrainingCompletedAt
        : tStatus === "Completed" && tType === "Leadership Workshop"
        ? contractor.leadershipTrainingCompletedAt
        : tStatus === "Completed" && tType === "TTT"
        ? contractor.trainingCompletedAt
        : tStatus === "Completed" && tType === "TTT"
        ? contractor.trainingCompletedAt
        : null;
    } else {
      return tType === "Site Crew Training"
        ? contractor.siteCrewTrainingCompletedAt
        : tType === "Leadership Workshop"
        ? contractor.leadershipTrainingCompletedAt
        : tType === "TTT"
        ? contractor.trainingCompletedAt
        : null;
    }
  };

  const getTrainingTypes = (contractor) => {
    return contractor?.siteCrewTrainingStatus
      ? "Site Crew Training"
      : contractor?.trainingCompletionStatus
      ? "TTT"
      : contractor?.leadershipTrainingStatus
      ? "Leadership Workshop"
      : "Site Crew Training";
  };

  const getTrainingStatus = (row, tType) => {
    let status = row.trainingStatus;
    const statusType =
      tType === "TTT"
        ? "trainingCompletedAt"
        : tType === "Leadership Workshop"
        ? "leadershipTrainingCompletedAt"
        : "siteCrewTrainingCompletedAt";
    return status ? status : row[statusType] ? "Completed" : "Pending";

    // const indexToReplace = rows.findIndex((item) => item.id === row.id);

    // if (indexToReplace !== -1) {
    //   const newArray = [
    //     ...rows.slice(0, indexToReplace),
    //     { ...rows[indexToReplace], trainingStatus: newStatus },
    //     ...rows.slice(indexToReplace + 1),
    //   ];
    //   setRows(newArray);
    // }
    // return newStatus;
  };

  const setCurrentTrainingData = (id, e) => {
    const indexToReplace = rows.findIndex((item) => item.id === id);

    if (indexToReplace !== -1) {
      const newArray = [
        ...rows.slice(0, indexToReplace),
        { ...rows[indexToReplace], trainingType: e.target.value },
        ...rows.slice(indexToReplace + 1),
      ];
      setRows(newArray);
    }
  };

  const setCompletionDate = (id, key, value) => {
    const indexToReplace = rows.findIndex((item) => item.id === id);

    if (indexToReplace !== -1) {
      const newArray = [
        ...rows.slice(0, indexToReplace),
        { ...rows[indexToReplace], [key]: value },
        ...rows.slice(indexToReplace + 1),
      ];
      setRows(newArray);
    }
  };

  const handleStatus = async (row, status) => {
    const indexToReplace = rows.findIndex((item) => item.id === row.id);

    if (indexToReplace !== -1) {
      const newArray = [
        ...rows.slice(0, indexToReplace),
        { ...rows[indexToReplace], trainingStatus: status },
        ...rows.slice(indexToReplace + 1),
      ];
      setRows(newArray);
    }
    if (status === "Pending") {
      await updateTrainingData(rows[indexToReplace], status);
    }
  };

  const updateTrainingData = async (row, tStatus, completionDate = null) => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating")
    );
    try {
      const indexToReplace = rows.findIndex((item) => item.id === row.id);

      if (indexToReplace !== -1) {
        const newArray = [
          ...rows.slice(0, indexToReplace),
          { ...rows[indexToReplace], completionDate: completionDate },
          ...rows.slice(indexToReplace + 1),
        ];
        setRows(newArray);
      }

      let payload = {};
      const tType = row.trainingType
        ? row.trainingType
        : await getTrainingTypes(row.contractors);
      const status = tStatus ? tStatus : await getTrainingStatus(row, tType);
      payload.trainingType = tType;
      payload.trainingStatus = status;
      payload.completionDate = completionDate;
      if (tType === "Site Crew Training") {
        if (status === "Completed") {
          // payload["siteCrewTrainingStatus"] = true;
          payload["siteCrewTrainingCompletedAt"] = completionDate;
          await setCompletionDate(
            row.id,
            "siteCrewTrainingCompletedAt",
            completionDate
          );
        } else {
          // payload["siteCrewTrainingStatus"] = false
          payload["siteCrewTrainingCompletedAt"] = null;
        }
      } else if (tType === "Leadership Workshop") {
        if (status === "Completed") {
          // payload["leadershipTrainingStatus"] = true;
          payload["leadershipTrainingCompletedAt"] = completionDate;
          await setCompletionDate(
            row.id,
            "leadershipTrainingCompletedAt",
            completionDate
          );
        } else {
          // payload["leadershipTrainingStatus"] = false;
          payload["leadershipTrainingCompletedAt"] = null;
        }
      } else if (tType === "TTT") {
        if (status === "Completed") {
          // payload["trainingCompletionStatus"] = true;
          payload["trainingCompletedAt"] = completionDate;
          await setCompletionDate(
            row.id,
            "trainingCompletedAt",
            completionDate
          );
        } else {
          // payload["trainingCompletionStatus"] = false
          payload["trainingCompletedAt"] = null;
        }
      }
      payload.directorateId = directorateId;
      await updateContractorApi(planId, companyId, row.id, payload);
      displayToast(
        setLoading,
        "success",
        t("implementations.contractorsDirectory.updateSuccess")
      );
      getContractors();
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  useEffect(() => {
    getLocationsAndUnits();
  }, []);

  useEffect(() => {
    getContractors();
  }, [filterParams]);

  useEffect(() => {
    handleTrainingStatus();
  }, [trainingType]);

  return (
    <Grid>
      <Grid paddingLeft={4} paddingRight={3}>
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {t(
                "implementations.contractorsDirectory.contractorsDirectoryTitle"
              )}
            </Typography>
          </div>
        </Box>
        <Stack sx={{ pb: 1 }} spacing={2}>
          <Typography variant="h5">
            {t("implementations.manageDetails")}
          </Typography>
          <Typography>
            {t("implementations.manageDetailsDescription")}
          </Typography>
        </Stack>

        <TabsBar
          tabs={tabsList}
          value={tab}
          handler={(e, v) => setTab(v)}
          sx={{ mb: 1 }}
        />
        {tab === "addContractors" && renderAddContractors()}
        {tab === "uploadContractors" && renderUploadContractors()}
        {tab === "viewContractors" && renderViewContractors()}
      </Grid>
      {renderDeleteModal()}
      {renderEditModal()}
    </Grid>
  );
};
