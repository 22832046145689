import React, { useState } from "react";
import TableButton from "../../../components/buttons/TableButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/ModeEditOutlined";
import { useNavigate, useParams } from "react-router-dom";
import DefaultTable from "../../../components/tables/DefaultTable";
import InfoBox from "../../../components/InfoBox";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
//Apis
import { getVisits, deleteVisit } from "../../../api/implementation";
import { useEffect } from "react";
import { displayToast } from "../../../utils/general";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SecondaryButton from "../../../components/buttons/SecondaryButton";

function VisitManagement(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { activityId, planId, companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [visitRows, setVisitRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterParams, setFilterParams] = useState({
    pageSize: 10,
    pageNo: 1,
    type: "ALL",
  });
  const [deleteDetails, setDeleteDetails] = useState({
    open: false,
    id: null,
  });

  const visitColumns = [
    {
      field: "visitId",
      headerName: t("table.id"),
      width: 100,
      sortable: false,
    },
    {
      field: "type",
      headerName: t("implementations.schedule.type"),
      width: 150,
      sortable: false,
    },
    {
      field: "visitAt",
      headerName: t("implementations.schedule.visitedAt"),
      width: 150,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: t("implementations.schedule.createdAt"),
      width: 150,
      sortable: false,
    },
    {
      field: "actions",
      headerName: t("table.actions"),
      width: 120,
      alignItems: "center",
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <TableButton
            disabled={loading}
            label={t("general.edit")}
            onClick={() => {
              navigate(
                `/implementations/visit-meeting/${props.type}/${planId}/${companyId}/${params.row.visitId}`
              );
            }}
            icon={<EditIcon />}
          />
          <TableButton
            red
            disabled={loading}
            label={t("general.delete")}
            onClick={() =>
              setDeleteDetails({
                open: true,
                id: params.row.visitId,
              })
            }
            icon={<DeleteIcon />}
          />
        </Stack>
      ),
    },
  ];

  const getAllVisits = async () => {
    try {
      setLoading(true);
      let query = `?activityId=${activityId}&page=${filterParams.pageNo}&pageSize=${filterParams.pageSize}&type=${props?.type}`;
      let tempPlans = await getVisits(query);

      let tempVisits = tempPlans?.result?.map((plan, index) => {
        return {
          id: index + 1,
          visitId: plan.id,
          type: t(plan.meetingType, { ns: "backend" }),
          visitAt: moment(plan.startDate).format("DD/MM/YYYY"),
          createdAt: moment(plan.createdAt).format("DD/MM/YYYY"),
        };
      });
      setVisitRows(tempVisits);
      setTotalCount(tempPlans.totalCount);
      setLoading(false);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.trainingListError"),
        "errorCalenderComponent"
      );
    }
  };

  const renderDeleteModal = () => (
    <InfoBox
      disabled={loading}
      minWidth="500px"
      open={deleteDetails.open}
      title={`${t(`remove`)} ${
        props?.type === "Leadership Visibility"
          ? t("implementations.schedule.leadership")
          : t("implementations.schedule.steeringCommittee")
      }
      `}
      content={
        <>
          <Typography
            variant="h6"
            style={{ color: theme.palette.primary.red, marginBottom: 30 }}
          >
            {props?.type === "Leadership Visibility"
              ? t(`implementations.schedule.deleteVisit`)
              : t(`implementations.schedule.deleteConfirm`)}
          </Typography>
          <Stack direction="row" spacing={1}>
            <SecondaryButton isRed label="Delete" onClick={submitDeleteVisit} />
            <SecondaryButton
              label="Cancel"
              onClick={() => setDeleteDetails({ open: false, id: null })}
            />
          </Stack>
        </>
      }
      handleClose={() => setDeleteDetails({ open: false, id: null })}
    />
  );

  const submitDeleteVisit = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      props?.type === "Leadership Visibility"
        ? t("implementations.schedule.deleteVisitLoading")
        : t("implementations.schedule.deleteLoading")
    );
    try {
      await deleteVisit(deleteDetails.id);
      setDeleteDetails({ open: false, id: null });
      getAllVisits();
      displayToast(
        setLoading,
        "success",
        props?.type === "Leadership Visibility"
          ? t("implementations.schedule.deleteVisitSuccess")
          : t("implementations.schedule.deleteSuccess"),
        activeToast
      );
    } catch (err) {
      displayToast(
        setLoading,
        "error",
        props?.type === "Leadership Visibility"
          ? t("implementations.schedule.deleteVisitFail")
          : t("implementations.schedule.deleteFail"),
        activeToast
      );
    }
  };

  useEffect(() => {
    getAllVisits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  return (
    <>
      <DefaultTable
        rows={visitRows}
        columns={visitColumns}
        loading={loading}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        tableInfo={{ totalPages: 1, totalCount: totalCount }}
      />
      {renderDeleteModal()}
    </>
  );
}

export default VisitManagement;
