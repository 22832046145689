import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hasAccess } from "../../utils/acl";
import useAuth from "../../context/useAuth";
import moment from "moment";
import { displayToast } from "../../utils/general";
//  COMPONENTS
import Page from "../../components/Page";
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  Stack,
} from "@mui/material";
import DefaultTable from "../../components/tables/DefaultTable";
import TabsBar from "../../components/navigation/TabsBar";
import OptOutIcon from "@mui/icons-material/DoNotDisturb";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoBox from "../../components/InfoBox";
import TableButton from "../../components/buttons/TableButton";
//  API
import {
  getMyScheduledReportsApi,
  deleteScheduledReportApi,
} from "../../api/reports";
import SecondaryButton from "../../components/buttons/SecondaryButton";

const filterDefaults = {
  pageNo: 1,
  pageSize: 10,
};

const modalDefaults = {
  open: false,
  id: null,
  optOut: false,
  reportType: "",
};

const selectedDefaults = {
  id: null,
  open: false,
  reportType: "",
  timeInterval: "",
  directorateIds: [],
  companyIds: [],
  locationIds: [],
  disciplineIds: [],
  unitIds: [],
  cardOwners: [],
  behaviorIds: [],
  groupBy: [],
  cardStatus: [],
  observationLinkedTo: [],
  lsrType: [],
  rootCause: [],
};

const getObsText = (text) => {
  switch (text) {
    case "safeBehavior":
      return "Safe Behaviour";
    case "unsafeBehavior":
      return "Unsafe Behaviour";
    case "safeCondition":
      return "Safe Condition";
    case "unsafeCondition":
      return "Unsafe Condition";
    default:
      return text;
  }
};

export default function ListScheduledReports() {
  const { t } = useTranslation();
  const { access } = useAuth();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("scheduled");
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterParams, setFilterParams] = useState(filterDefaults);
  const [triggerFlag, setTriggerFlag] = useState(false);
  const [reportRows, setReportRows] = useState([]);
  const [modalDetails, setModalDetails] = useState(modalDefaults);
  const [selectedReport, setSelectedReport] = useState(selectedDefaults);

  const tabs = [
    {
      label: t("reporting.scheduledForMe"),
      value: 0,
      key: "scheduled",
    },
    ...(hasAccess(access, "reports")
      ? [
          {
            label: t("reporting.scheduledByMe"),
            value: 0,
            key: "created",
          },
        ]
      : []),
  ];

  const columnDefaults = [
    { field: "id", headerName: t("table.id"), width: 100, sortable: false },
    {
      field: "reportType",
      headerName: t("reporting.scheduleColumns.reportType"),
      width: 300,
      sortable: false,
    },
    {
      field: "timeInterval",
      headerName: t("reporting.timeInterval"),
      width: 200,
      sortable: false,
    },
    {
      field: "startInterval",
      headerName: t("table.startDate"),
      width: 200,
      sortable: false,
    },
    {
      field: "actions",
      headerName: t("table.actions"),
      width: 200,
      sortable: false,
      renderCell: (param) => (
        <Stack direction="row">
          <TableButton
            disabled={loading}
            label={"Info"}
            onClick={() => handleReportInfo(param)}
            icon={<InfoIcon />}
          />
          <TableButton
            red={tabValue !== "scheduled"}
            disabled={loading}
            label={tabValue === "scheduled" ? "Opt Out" : "Delete"}
            onClick={() =>
              setModalDetails({
                open: true,
                optOut: tabValue === "scheduled",
                ...param.row,
              })
            }
            icon={tabValue === "scheduled" ? <OptOutIcon /> : <DeleteIcon />}
          />
        </Stack>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setFilterParams(filterDefaults);
  };

  const handleReportInfo = (param) => {
    try {
      setSelectedReport({
        id: param.id,
        reportType: param.row.reportType,
        timeInterval: param.row.timeInterval,
        open: true,
        ...param.row.params,
        ...(param.row.params.observationLinkedTo
          ? {
              observationLinkedTo: JSON.parse(
                param.row.params.observationLinkedTo
              ),
            }
          : {}),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getReportsList = async (createdByMe = false) => {
    try {
      setLoading(true);
      const response = await getMyScheduledReportsApi(
        filterParams,
        createdByMe
      );
      const parsedRows = response.data.map((row) => {
        return {
          id: row.id,
          reportType: row.reportType
            ? row.reportType
                .split("_")
                .map((l) => `${l.charAt(0).toUpperCase()}${l.slice(1)}`)
                .join(" ")
            : "-",
          timeInterval: row.timeInterval ? row.timeInterval.toUpperCase() : "-",
          startInterval: row.startInterval
            ? moment(row.startInterval).format("DD/MM/YYYY hh:mm a")
            : "-",
          params: row.params,
        };
      });
      setReportRows(parsedRows);
      setTableInfo({
        totalCount: response.totalItems,
        totalPages: response.totalPages,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submitDeleteScheduledReport = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      modalDetails.optOut
        ? t("reporting.optOutLoading")
        : t("reporting.deleting")
    );
    try {
      await deleteScheduledReportApi({
        id: modalDetails.id,
        optOut: modalDetails.optOut,
      });
      setModalDetails(modalDefaults);
      displayToast(
        setLoading,
        "success",
        modalDetails.optOut
          ? t("reporting.optOutSuccess")
          : t("reporting.deleteSuccess"),
        activeToast
      );
      if (modalDetails.optOut) {
        getReportsList();
      } else {
        getReportsList(true);
      }
    } catch (err) {
      displayToast(
        setLoading,
        "error",
        modalDetails.optOut
          ? t("reporting.optOutError")
          : t("reporting.deleteError"),
        activeToast
      );
    }
  };

  useEffect(() => {
    if (triggerFlag) {
      if (tabValue === "scheduled") {
        getReportsList();
      } else {
        getReportsList(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, triggerFlag, tabValue]);

  return (
    <Page title={t("reporting.myReports")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div>
            <Typography variant="h4">{t("reporting.myReports")}</Typography>
          </div>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <TabsBar value={tabValue} handler={handleTabChange} tabs={tabs} />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "12px" }}>
            <DefaultTable
              hiddenColumns={{
                id: false,
              }}
              rows={reportRows}
              columns={columnDefaults}
              loading={loading}
              tableInfo={tableInfo}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              triggerFunction={setTriggerFlag}
            />
          </Grid>
        </Grid>
      </Container>
      <InfoBox
        compact
        open={selectedReport.open}
        title={selectedReport.reportType}
        handleClose={() =>
          setSelectedReport({ ...selectedReport, open: false })
        }
        content={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h7">
                {t("reporting.scheduleColumns.timeInterval")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" color="textSecondary">
                {selectedReport.timeInterval.toUpperCase()}
              </Typography>
            </Grid>

            {selectedReport.directorateIds &&
              selectedReport.directorateIds.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">
                      {t("implementations.directorates")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.directorateIds.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-directorateItem`}
                      >
                        {d.name}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

            {selectedReport.companyIds &&
              selectedReport.companyIds.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">{t("table.companies")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.companyIds.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-contractorItem`}
                      >
                        {d.name}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

            {selectedReport.locationIds &&
              selectedReport.locationIds.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">{t("table.locations")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.locationIds.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-locationItem`}
                      >
                        {d.name}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

            {selectedReport.unitIds && selectedReport.unitIds.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h7">{t("table.units")}</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {selectedReport.unitIds.map((d) => (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${d.id}-unitItem`}
                    >
                      {d.name}
                    </Typography>
                  ))}
                </Grid>
              </>
            )}

            {selectedReport.disciplineIds &&
              selectedReport.disciplineIds.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">
                      {t("implementations.tabs.disciplines")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.disciplineIds.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-disciplineItem`}
                      >
                        {d.name}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

            {selectedReport.behaviorIds &&
              selectedReport.behaviorIds.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">
                      {t("reporting.behaviours")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.behaviorIds.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-behaviorItem`}
                      >
                        {d.name}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

            {selectedReport.cardOwners &&
              selectedReport.cardOwners.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">
                      {t("table.cardOwners")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.cardOwners.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-cardOwnerItem`}
                      >
                        {d.name}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

            {selectedReport.observationLinkedTo &&
              selectedReport.observationLinkedTo.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">
                      {t("reporting.observationLinkedTo")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.observationLinkedTo.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-observationLinkedItem`}
                      >
                        {getObsText(d)}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

            {selectedReport.lsrType && selectedReport.lsrType.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h7">{t("reporting.lsrType")}</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {selectedReport.lsrType.map((d) => (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${d.id}-lsrItem`}
                    >
                      {d.name}
                    </Typography>
                  ))}
                </Grid>
              </>
            )}

            {selectedReport.rootCause &&
              selectedReport.rootCause.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h7">
                      {t("reporting.rootCauses")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {selectedReport.rootCause.map((d) => (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        key={`${d.id}-rootCauseItem`}
                      >
                        {d.name}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}
          </Grid>
        }
      />
      <InfoBox
        compact
        title={
          modalDetails.optOut
            ? t("reporting.optOutTitle", {
                reportType: modalDetails.reportType,
              })
            : `${t("general.delete")} - ${modalDetails.reportType}`
        }
        content={
          <Stack spacing={1}>
            <Typography variant="body">
              {modalDetails.optOut
                ? t("reporting.optOutText")
                : t("reporting.deleteReportText")}
            </Typography>
            <SecondaryButton
              isRed
              disabled={loading}
              label={t("confirm")}
              onClick={submitDeleteScheduledReport}
            />
          </Stack>
        }
        open={modalDetails.open}
        handleClose={() => setModalDetails({ ...modalDetails, open: false })}
      />
    </Page>
  );
}
