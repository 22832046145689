import { Toaster } from "react-hot-toast";

const Toast = () => {
  return (
    <Toaster
      toastOptions={{
        style: {},
        duration: 5000,
      }}
    />
  );
};

export default Toast;
