/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
import { returnColourArray } from "../../../utils/general";
// material
import {
  Box,
  Container,
  Typography,
  Stack,
  Card,
  Grid,
  useTheme,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/SortOutlined";
// components
import Page from "../../../components/Page";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import TabsBar from "../../../components/navigation/TabsBar";
import KPICharts from "./KPICharts";
import GroupedBarChart from "../../../components/charts/GroupedBarChart";
import { getCompanyKPIs } from "../../../api/kpiDashboards";
import { displayToast } from "../../../utils/general";
import SelectInput from "../../../components/inputs/SelectInput";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import DateInput from "../../../components/inputs/DateInput";
import moment from "moment";
import { CloudDownload } from "@mui/icons-material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";
import AiAlert from "../../../components/AiAlert";
import PageLoader from "../../../components/PageLoader";
//  API
// ----------------------------------------------------------------------

const cardStyling = {
  height: 600,
  padding: "16px ",
  borderRadius: "6px",
};

export default function KPICompanyDashboard() {
  const { tab, companyId, directorateId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(tab || "postImplementation");
  const [implementationChartData, setImplementationChartData] = useState([]);
  const [implementationCompanies, setImplementationCompanies] = useState([]);
  const [companyWideData, setCompanyWideData] = useState([]);
  const [companyWideCategory, setCompanyWideCategory] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("year"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [filterStartDate, setFilterStartDate] = useState(
    moment().startOf("year")
  );
  const [filterEndDate, setFilterEndDate] = useState(moment().endOf("month"));
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showAudit, setShowAudit] = useState("false");
  const [auditData, setAuditData] = useState({});
  const [companyName, setCompanyName] = useState("");
  const pagesRef = useRef([]);

  const auditDropDown = [
    {
      label: t("general.yes"),
      value: "true",
    },
    {
      label: t("general.no"),
      value: "false",
    },
  ];

  const renderTabs = () => (
    <TabsBar
      value={tabValue}
      handler={(event, value) => setTabValue(value)}
      tabs={[
        {
          label: t("kpiDashboards.kpis"),
          value: 0,
          key: "postImplementation",
        },
        {
          label: t("kpiDashboards.overallPerformance"),
          value: 0,
          key: "kpis",
        },
      ]}
    />
  );

  const renderFilters = () => (
    <Grid container spacing={1} sx={{ mt: 1 }} alignItems="flex-end">
      <Grid item xs={12} sm={4} md={2}>
        <DateInput
          disabled={false}
          label={t("kpiDashboards.startDate")}
          date={startDate}
          handler={(newDate) => setStartDate(newDate)}
          monthView={true}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <DateInput
          disabled={false}
          label={t("kpiDashboards.endDate")}
          date={endDate}
          handler={(newDate) => setEndDate(newDate)}
          monthView={true}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2}>
        <SelectInput
          label={t(`kpi.includeAudit`)}
          disabled={false}
          value={showAudit}
          handler={(e) => setShowAudit(e.target.value)}
          options={auditDropDown}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2}>
        <SecondaryButton
          icon={<FilterIcon />}
          label={t("observations.viewObservations.filters.apply")}
          alignment="left"
          disabled={loading}
          onClick={() => applyFilters()}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <SecondaryButton
          icon={<CloudDownload />}
          label={t("general.downloadPdf")}
          disabled={loading}
          onClick={() => submitDownload()}
        />
      </Grid>
    </Grid>
  );

  const renderSummary = () => (
    <AiAlert
      sx={{ mb: 2 }}
      handler={null}
      labelComponent={
        <Typography variant="alertText">
          <Trans
            i18nKey="kpiDashboards.smartAlert"
            // defaults="The Computed maturity for this contractor is <1>{{totalProgress}}</1> and after audit it is <3>{{auditApplied}}</3>." // optional defaultValue
            values={{
              totalProgress: ` ${auditData?.totalProgress}% `,
              auditApplied: ` ${auditData?.auditApplied}%`,
            }}
            components={{
              1: (
                <Box
                  component="span"
                  fontWeight="bold"
                  color={theme.palette.primary.red}
                />
              ),
              3: (
                <Box
                  component="span"
                  fontWeight="bold"
                  color={theme.palette.primary.red}
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );

  const renderNoData = () => (
    <Stack
      spacing={1}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NoDataIcon />
      <Typography variant="body1">{t("general.noData")}</Typography>
    </Stack>
  );

  const RenderPostImplementation = () => {
    return (
      <>
        {renderFilters()}
        {/* {RenderDownloadButton()} */}
        <div
          ref={(el) => {
            pagesRef.current[0] = el;
          }}
        >
          {auditData &&
            auditData.totalProgress &&
            auditData.auditApplied &&
            renderSummary()}
          <Stack spacing={1}>
            <Card style={cardStyling}>
              <Stack justifyContent="space-between" direction="row">
                <Typography variant="h6">
                  {t("kpiDashboards.locationUnitWiseChart", {
                    companyName,
                  })}
                </Typography>
                <Typography variant="subtitle1">{`${moment(
                  filterStartDate
                ).format("MMM/YY")} - ${moment(filterEndDate).format(
                  "MMM/YY"
                )}`}</Typography>
              </Stack>

              {implementationChartData?.length > 0 ? (
                <GroupedBarChart
                  height="90%"
                  chartData={implementationChartData}
                  chartOptions={{
                    chart: {
                      type: "bar",
                      height: 350,
                      events: {
                        dataPointSelection: (event, chartContext, config) => {
                          // console.log(config?.w?.config?.xaxis?.categories[config?.dataPointIndex])
                          chartLocationClick(config?.dataPointIndex);
                        },
                      },
                      toolbar: {
                        show: false,
                      },
                    },
                    colors: returnColourArray(),
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        dataLabels: {
                          position: "top",
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      formatter: (val) => (val ? `${val}%` : ""),
                      offsetY: -20,
                      style: {
                        colors: ["#333"],
                        fontSize: "12px",
                      },
                    },
                    stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                    },
                    xaxis: {
                      categories: implementationCompanies,
                    },
                    yaxis: {
                      min: 0,
                      max: 100,
                      title: {
                        text: "KPI in %",
                      },
                      labels: {
                        formatter: (val) => `${val}%`,
                      },
                    },
                    fill: {
                      opacity: 1,
                    },
                    tooltip: {
                      y: {
                        formatter: (val) => `${val}%`,
                      },
                    },
                  }}
                />
              ) : (
                renderNoData()
              )}
            </Card>
            <Card style={cardStyling}>
              <Stack justifyContent="space-between" direction="row">
                <Typography variant="h6">
                  {t("kpiDashboards.companyWideChart", { companyName })}
                </Typography>
                <Typography variant="subtitle1">{`${moment(
                  filterStartDate
                ).format("MMM/YY")} - ${moment(filterEndDate).format(
                  "MMM/YY"
                )}`}</Typography>
              </Stack>
              {companyWideData?.length > 0 ? (
                <GroupedBarChart
                  height="90%"
                  chartData={companyWideData}
                  chartOptions={{
                    chart: {
                      type: "bar",
                      height: 350,
                      toolbar: {
                        show: false,
                      },
                    },
                    colors: returnColourArray(),
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        endingShape: "rounded",
                        dataLabels: {
                          position: "top",
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      formatter: (val) => (val ? `${val}%` : ""),
                      offsetY: -20,
                      style: {
                        colors: ["#333"],
                      },
                    },
                    stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                    },
                    xaxis: {
                      categories: companyWideCategory,
                    },
                    yaxis: {
                      min: 0,
                      max: 100,
                      title: {
                        text: "KPI in %",
                      },
                      labels: {
                        formatter: (val) => `${val}%`,
                      },
                    },
                    fill: {
                      // colors: ['#ffa229', '#ee9225', '#dd8221','#cc721d','#bd6319','#ac5315','#9a4110','#8a310c','#792108','#6c1405','#580000'],
                      opacity: 1,
                    },
                    tooltip: {
                      y: {
                        formatter: (val) => `${val}%`,
                      },
                    },
                  }}
                />
              ) : (
                renderNoData()
              )}
            </Card>
          </Stack>
        </div>
      </>
    );
  };

  const getCompanyKpisRequest = async () => {
    try {
      let query = `?companyId=${companyId}&directorateId=${directorateId}&`;
      if (startDate) {
        query += `startDate=${startDate.format("MM-YYYY")}`;
      }
      if (endDate) {
        query += `&endDate=${endDate.format("MM-YYYY")}`;
      }
      if (showAudit === "true") {
        query += `&audit=true`;
      }
      setLoading(true);
      let tempData = await getCompanyKPIs(query);
      setAuditData(tempData?.summary);
      setImplementationChartData(tempData?.data);
      setImplementationCompanies(tempData?.categories);
      setCompanyWideData(tempData?.companyWideData);
      setCompanyWideCategory(tempData?.companyWideCategory);
      setLocationOptions(tempData?.locationOptions);
      setCompanyName(tempData?.company);
      setLoading(false);
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
    } catch (e) {
      displayToast(setLoading, "error", e, "loadingCharts");
      setFilterStartDate(moment().startOf("year"));
      setFilterEndDate(moment().endOf("month"));
    }
  };

  const submitDownload = async () => {
    try {
      const activeToast = displayToast(
        setLoading,
        "loading",
        t("reporting.downloading")
      );
      const pdf = new jsPDF("portrait", "pt", "a4");
      let data = await html2canvas(pagesRef.current[0], {
        scale: 1.3,
      });
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      let pdfWidth = pdf.internal.pageSize.getWidth();
      let pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");

      pdf.save("ObservationDashboard.pdf");
      displayToast(
        setLoading,
        "success",
        t("reporting.downloadSuccess"),
        activeToast
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const RenderDownloadButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SecondaryButton
          style={{ display: "flex", width: "auto" }}
          icon={<CloudDownload />}
          label={t("general.downloadPdf")}
          disabled={loading}
          onClick={() => submitDownload()}
        />
      </div>
    );
  };

  const chartLocationClick = (indexValue) => {
    setSelectedLocation(locationOptions[indexValue]?.value);
    setTabValue("kpis");
  };

  const applyFilters = () => {
    getCompanyKpisRequest();
  };

  useEffect(() => {
    getCompanyKpisRequest();
  }, []);

  return (
    <Page title={`${t("kpiDashboards.kpiPageTitle")}`}>
      <Container maxWidth="xl">
        {companyName ? (
          <>
            <Box sx={{ pb: 2 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HeaderBackButton />
                <Typography variant="h4">
                  {t("dashboards.dashboards", { name: companyName })}
                </Typography>
              </div>
            </Box>
            <Stack spacing={2}>
              {renderTabs()}
              {tabValue === "postImplementation" && RenderPostImplementation()}
              {tabValue === "kpis" && (
                <KPICharts
                  locationOptions={locationOptions}
                  selectedLocation={selectedLocation}
                  companyName={companyName}
                />
              )}
            </Stack>
          </>
        ) : (
          <div
            style={{ display: "flex", height: "80vh", alignItems: "center" }}
          >
            <PageLoader />
          </div>
        )}
      </Container>
    </Page>
  );
}
