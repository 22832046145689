import { useEffect, useState, useRef, useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./styles.css";
import { addChartDetailsDefaults, gridDefaults } from "./defaults";
import {
  renderChart,
  submitAddChart,
  removeWidget,
  handleLayoutChange,
  cancelLayoutEdit,
  saveLayout,
  setEditMode,
  handleTabChange,
  getApiFunctions,
  downloadCSVFromJson,
  getChartDefaults,
} from "./utils";
// material
import {
  Box,
  Stack,
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/CancelOutlined";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PieChartIcon from "@mui/icons-material/PieChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import TocIcon from "@mui/icons-material/Toc";
import TableRowsIcon from "@mui/icons-material/TableRows";
// components
import Page from "../../components/Page";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import TabsBar from "../../components/navigation/TabsBar";
import InfoBox from "../../components/InfoBox";
import SelectInput from "../../components/inputs/SelectInput";
import HelpText from "../../components/HelpText";
// apis
import { getPreferencesAndFilter } from "../../api/pdoDashboards";
import {
  getDirectorateFiltersApi,
  getContractorFiltersApi,
} from "../../api/dashboards";
import useChartData from "./customHook";
import MultiSelectInput from "../../components/inputs/MultiSelectInput";
import NavigationIconButton from "../../components/buttons/NavigationIconButton";
import EditChart from "./EditChart";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PageLoader from "../../components/PageLoader";
import DateInput from "../../components/inputs/DateInput";
import DownloadReportIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import toast from "react-hot-toast";
// ----------------------------------------------------------------------

const ResponsiveGridLayout = WidthProvider(Responsive);

const iconStyles = {
  cursor: "pointer",
  height: "18px",
  width: "18px",
};

const blackListedChartTypes = [
  "behaviourNameCritical",
  "behaviourNameOther",
  "trainingStatus",
];

export default function ObservationsApp() {
  const { t } = useTranslation();
  const theme = useTheme();
  const today = new Date();
  const previewRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [layoutLoading, setLayoutLoading] = useState(true);
  const [tabValue, setTabValue] = useState("observation");
  const [chartIds, setChartIds] = useState({});
  const [mode, setMode] = useState("view");
  const [gridOptions, setGridOptions] = useState(gridDefaults);
  const [dashboardLayout, setDashboardLayout] = useState([]);
  const [oldLayout, setOldLayout] = useState([]);
  const [oldChartIds, setOldChartIds] = useState({});
  const [addChartDetails, setAddChartDetails] = useState(
    addChartDetailsDefaults
  );
  const [userPreferences, setUserPreferences] = useState(null);
  const [directorates, setDirectorates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [graphTypes, setGraphTypes] = useState([]);
  const [filterModal, setFilterModal] = useState(false);

  const [selectedChartId, setSelectedChartId] = useState("");
  const [selectedDirectorates, setSelectedDirectorates] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [graphType, setGraphType] = useState(null);
  const [supportedTypes, setSupportedTypes] = useState([]);
  const [selectedSupportedType, setSelectedSupportedType] = useState(0);
  const [dateEnabled, setDateEnabled] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  // const [ chartData,setChartData ] = useState({})
  const chartData = useChartData({});
  const pagesRef = useRef([]);

  const testGridCallback = (layout, oldItem, newItem, placeholder) => {
    // console.log(layout, oldItem, newItem, placeholder);
  };

  const renderTopNavigation = () => (
    <Grid container sx={{ mb: 3 }}>
      <Grid item xs={12} md={7}>
        <TabsBar
          value={tabValue}
          handler={(event, value) => handleTabChange(event, value, setTabValue)}
          tabs={[
            {
              label: t("dashboards.tabs.observationDashboard"),
              value: 0,
              key: "observation",
            },
            // {
            //   label: t("dashboards.tabs.ihtimamDashboard"),
            //   value: 0,
            //   key: "rollout",
            // },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {/* <SecondaryButton
              style={{ width: "max-content" }}
              icon={<CloudDownloadIcon />}
              label={t("dashboards.buttons.downloadPdf")}
              alignment={"left"}
              onClick={() =>
                {downloadAsPdf()}
              }
              disabled={loading}
            /> */}
          {mode === "edit" && (
            <NavigationIconButton
              icon={<AddCircleIcon />}
              label={t("dashboards.buttons.addChart")}
              path=""
              onClick={() =>
                setAddChartDetails({ ...addChartDetails, open: true })
              }
            />
            // <HelpText title={t("dashboards.buttons.addChart")}>
            //   <SecondaryButton
            //     icon={<AddCircleIcon />}
            //     alignment={"left"}
            //     onClick={() =>
            //       setAddChartDetails({ ...addChartDetails, open: true })
            //     }
            //     disabled={loading}
            //   />
            // </HelpText>
          )}
          {mode === "view" && (
            <>
              {/* <DateRangeInput
                disabled={loading}
                startDate={dateFilter.start}
                endDate={dateFilter.end}
                handler={(start, end) => setDateFilter({ start, end })}
                offOption
                dateEnabled={dateEnabled}
                setDateEnabled={setDateEnabled}
              /> */}
              <SecondaryButton
                style={{ width: "max-content" }}
                icon={<DownloadReportIcon />}
                label={t("reporting.download")}
                alignment={"left"}
                onClick={submitDownload}
                disabled={loading || dashboardLayout.length === 0}
              />
              <SecondaryButton
                style={{ width: "max-content" }}
                icon={<EditIcon />}
                label={t("dashboards.buttons.editLayout")}
                alignment={"left"}
                onClick={() =>
                  setEditMode(
                    gridOptions,
                    setGridOptions,
                    setMode,
                    dashboardLayout,
                    setOldLayout,
                    chartIds,
                    setOldChartIds
                  )
                }
                disabled={loading}
              />
            </>
          )}
          {mode === "edit" && (
            <>
              {/* <HelpText title={t("dashboards.buttons.saveLayout")}> */}
              <NavigationIconButton
                icon={<SaveIcon />}
                label={t("dashboards.buttons.saveLayout")}
                path=""
                onClick={() =>
                  saveLayout(
                    gridOptions,
                    setGridOptions,
                    setMode,
                    setLoading,
                    dashboardLayout,
                    chartIds,
                    userPreferences,
                    true
                  )
                }
              />
              <NavigationIconButton
                icon={<DeleteIcon />}
                label={t("dashboards.buttons.cancelLayout")}
                path=""
                onClick={() =>
                  cancelLayoutEdit(
                    oldLayout,
                    setDashboardLayout,
                    setMode,
                    oldChartIds,
                    setChartIds,
                    gridOptions,
                    setGridOptions
                  )
                }
              />
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );

  const submitDownload = () => {
    try {
      toast.remove();
      setLoading(true);
      toast.loading(t("reporting.downloading"), {
        duration: Infinity,
      });

      if (pagesRef.current.length > 0) {
        setTimeout(async () => {
          const pdf = new jsPDF("l", "pt", "a4", true);
          const width = pdf.internal.pageSize.getWidth();
          // const w = width - width * 0.1;
          const height = pdf.internal.pageSize.getHeight();
          // const h = height - height * 0.1;
          for (let index = 0; index < pagesRef.current.length; index++) {
            let ahtml2canvas = await html2canvas(pagesRef.current[index], {
              scale: 4,
            });
            let imgData = ahtml2canvas.toDataURL("image/png");
            // pdf.addImage(imgData, "JPEG", width * 0.05, 0, w, h);
            pdf.addImage(imgData, "PNG", 0, 0, width, height, "", "FAST");
            if (index + 1 !== pagesRef.current.length) {
              pdf.addPage();
            } else {
              pdf.save(`obs_dash_${today}.pdf`);
              setLoading(false);
              toast.remove();
              toast.success(t("reporting.downloadSuccess"));
            }
          }
        }, 1000);
      } else {
        setLoading(false);
        toast.remove();
        toast.error(t("dashboards.alerts.nothingToDownload"));
      }
    } catch (err) {
      toast.remove();
      toast.error(t("reporting.downloadError"));
      setLoading(false);
    }
  };

  const renderAddChart = () => (
    <InfoBox
      disabled={loading || !addChartDetails.chartId}
      open={addChartDetails.open}
      title={t(`dashboards.addCharts.title`)}
      minWidth="60vw"
      content={
        <Stack spacing={3}>
          {addChartDetails.chartId ? (
            <>
              <Card
                className="chartContainer"
                ref={previewRef}
                style={{
                  background:
                    "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                  borderRadius: 0,
                  border: "1px solid #C2C2C2",
                  minHeight: "50vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardHeader title={addChartDetails?.chartName} />
                <div style={{ flexGrow: 1 }}>
                  {renderChart(
                    `${addChartDetails?.chartId}${graphType}`,
                    null,
                    null,
                    true
                  )}
                </div>
              </Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tabs
                  value={selectedSupportedType}
                  onChange={(e, v) => {
                    setGraphType(supportedTypes[v]);
                    setSelectedSupportedType(v);
                  }}
                  aria-label="icon label tabs example"
                >
                  {supportedTypes.map((entry) => (
                    <Tab
                      icon={
                        entry === "Pie" ? (
                          <PieChartIcon />
                        ) : entry === "Column" ? (
                          <BarChartIcon />
                        ) : entry === "Stacked" ? (
                          <StackedBarChartIcon />
                        ) : entry === "Multiline" ? (
                          <SsidChartIcon />
                        ) : entry === "Counter" ? (
                          <TocIcon />
                        ) : (
                          <TableRowsIcon />
                        )
                      }
                      label={entry}
                    />
                  ))}
                </Tabs>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                borderRadius: 0,
                border: "1px solid #C2C2C2",
                minHeight: "50vh",
              }}
            >
              <Typography variant="h5">
                {t(`dashboards.addCharts.previewText`)}
              </Typography>
            </div>
          )}
          <SelectInput
            value={addChartDetails.chartId}
            handler={(e) => {
              handleSelectChart(e?.target?.value);
            }}
            options={graphTypes}
            label={t(`dashboards.addCharts.title`)}
            disabled={loading}
          />
        </Stack>
      }
      buttonLabel={t(`dashboards.addCharts.submit`)}
      handleClose={() => setAddChartDetails(addChartDetailsDefaults)}
      handleAction={() => {
        submitAddChart(
          dashboardLayout,
          setDashboardLayout,
          `${addChartDetails.chartId}${graphType}`,
          chartIds,
          setChartIds
        );
        setAddChartDetails(addChartDetailsDefaults);
        setSelectedSupportedType(0);
      }}
    />
  );

  const handleSelectChart = (e) => {
    let findIndex = graphTypes.findIndex((i) => i.value === e);
    let supportedTypes = graphTypes[findIndex]["supported"];
    const chartName = graphTypes[findIndex]["label"];
    setGraphType(supportedTypes[0]);
    setSupportedTypes(supportedTypes);
    setSelectedSupportedType(0);
    setAddChartDetails({
      ...addChartDetails,
      chartId: `${e}`,
      chartName,
    });
  };

  const downloadFunction = (chartId) => {
    downloadCSVFromJson(chartId);
  };

  const downloadAsPdf = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#downloadPdf"), {
      scale: 2,
      useCORS: true,
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("ObservationDashboard.pdf");
  };

  const returnFormattedDate = (dateString, kpiFlag) => {
    if (kpiFlag) {
      return moment(dateString, "MM-YYYY").format("MMM/YY");
    } else {
      return moment(dateString).format("MMM/YY");
    }
  };

  const DashboardGrid = ({ layout, pagesRefs }) => {
    const children = useMemo(() => {
      let chartObj = chartData.get();
      return layout.map((item, index) => (
        <div
          ref={(el) => (pagesRefs.current[index] = el)}
          key={item.i}
          style={{
            border: "1px solid #F7D5AE",
            background:
              "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
            borderRadius: 0,
            padding: "18px",
          }}
          data-grid={layout[index]}
        >
          <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h6">{chartIds[item.i].title}</Typography>
              {(chartIds[item.i].filters.datesEnabled ||
                chartIds[item.i].filters.dateEnabled) &&
                chartIds[item.i].filters.startDate &&
                chartIds[item.i].filters.endDate && (
                  <Typography variant="subtitle2">{`(${returnFormattedDate(
                    chartIds[item.i].filters.startDate,
                    chartIds[item.i].filters.datesEnabled
                  )} - ${returnFormattedDate(
                    chartIds[item.i].filters.endDate,
                    chartIds[item.i].filters.datesEnabled
                  )})`}</Typography>
                )}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <HelpText title={t("dashboards.helpText.edit")}>
                <EditIcon
                  onClick={() => {
                    setEditModal(true);
                    setSelectedChartId(chartObj[item.i]);
                  }}
                  style={{ ...iconStyles, color: theme.palette.primary.grey }}
                />
              </HelpText>
              <HelpText title={t("dashboards.helpText.download")}>
                <CloudDownloadIcon
                  onClick={() => {
                    let tempObj = chartData.get();
                    downloadFunction(tempObj?.[item.i]);
                  }}
                  style={{ ...iconStyles, color: theme.palette.primary.grey }}
                />
              </HelpText>
              {!(
                chartObj[item.i]?.chartObject?.api === "locationKpis" ||
                chartObj[item.i]?.chartObject?.api === "companyKpis" ||
                chartObj[item.i]?.chartObject?.api === "lessonLearntDetail" ||
                loading
              ) && (
                <HelpText title={t("dashboards.helpText.filter")}>
                  <FilterIcon
                    onClick={() => {
                      let tempObj = chartData.get();
                      if (tempObj?.[item.i]?.chartObject?.filters) {
                        setSelectedChartId(item.i);
                        setSelectedCompanies(
                          tempObj?.[item.i]?.chartObject?.filters?.companyIds
                        );
                        setSelectedDirectorates(
                          tempObj?.[item.i]?.chartObject?.filters
                            ?.directorateIds
                        );
                        setStartDate(
                          tempObj?.[item.i]?.chartObject?.filters?.startDate
                        );
                        setEndDate(
                          tempObj?.[item.i]?.chartObject?.filters?.endDate
                        );
                        setDateEnabled(
                          tempObj?.[item.i]?.chartObject?.filters?.dateEnabled
                        );
                      }
                      setFilterModal(true);
                    }}
                    style={{ ...iconStyles, color: theme.palette.primary.grey }}
                  />
                </HelpText>
              )}

              {mode === "edit" && (
                <HelpText title={t("dashboards.helpText.remove")}>
                  <DeleteIcon
                    style={{ ...iconStyles, color: theme.palette.primary.red }}
                    onClick={() =>
                      removeWidget(
                        item.i,
                        dashboardLayout,
                        setDashboardLayout,
                        chartIds,
                        setChartIds
                      )
                    }
                  />
                </HelpText>
              )}
            </Stack>
          </Stack>
          {chartIds[item.i]
            ? renderChart(
                chartIds[item.i].type,
                chartData.get(),
                item?.i,
                false,
                chartIds[item.i]?.filters?.showLabels
              )
            : "No id provided."}
        </div>
      ));
    }, [layout]);

    return (
      <ResponsiveGridLayout
        onLayoutChange={(newLayout) =>
          handleLayoutChange(newLayout, dashboardLayout, setDashboardLayout)
        }
        onResize={testGridCallback}
        {...gridOptions}
      >
        {children}
      </ResponsiveGridLayout>
    );
  };

  const handeDirectorateSelect = (selection) => {
    setSelectedDirectorates(selection);
  };

  const renderWidgetFilters = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MultiSelectInput
            label={t("dashboards.selectDirectorates")}
            disabled={false}
            options={directorates}
            handler={(newValue) => handeDirectorateSelect(newValue)}
            values={selectedDirectorates}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectInput
            label={t("dashboards.selectCompanies")}
            disabled={
              false ||
              companies.length === 0 ||
              selectedDirectorates.length === 0
            }
            options={companies}
            handler={(newValue) => setSelectedCompanies(newValue)}
            values={selectedCompanies}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={dateEnabled}
                name={"filterDates"}
                onChange={(e) => setDateEnabled(e.target.checked)}
                sx={{
                  color: theme.palette.primary.orange,
                  "&.Mui-checked": {
                    color: theme.palette.primary.orange,
                  },
                  "&:hover": {
                    backgroundColor: "rgba(248,147,31,0.08)",
                  },
                }}
              />
            }
            disabled={loading}
            label={t(`observations.viewObservations.filters.dateRange`)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            label={t(`observations.viewObservations.filters.startDate`)}
            date={startDate}
            disabled={loading || !dateEnabled}
            handler={(newDate) => setStartDate(newDate)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            label={t(`observations.viewObservations.filters.endDate`)}
            date={endDate}
            disabled={loading || !dateEnabled}
            handler={(newDate) => setEndDate(newDate)}
          />
        </Grid>
      </Grid>
    );
  };

  const getUserPreferences = async () => {
    try {
      setLoading(true);
      let callApi = await getPreferencesAndFilter();
      //  We are disabling progress-report charts manually
      const backedGraphTypes = callApi?.data?.graphs || [];
      const finalGraphTypes = backedGraphTypes.filter(
        (entry) => !blackListedChartTypes.includes(entry.value)
      );
      setGraphTypes(finalGraphTypes);
      let preferences = JSON.parse(callApi?.data?.userPreferences?.configJSON);
      if (preferences) {
        setUserPreferences(preferences);
        if (
          preferences &&
          preferences.dashboardDetails &&
          preferences.dashboardDetails.layout
        ) {
          setDashboardLayout(preferences.dashboardDetails.layout);
          setLayoutLoading(false);
        }
        if (
          preferences &&
          preferences.dashboardDetails &&
          preferences.dashboardDetails.chartIds
        ) {
          setChartIds(preferences.dashboardDetails.chartIds);
        }
      } else {
        setUserPreferences({});
      }
      setEditModal(false);
      setLoading(false);
      setLayoutLoading(false);
    } catch (err) {
      setLayoutLoading(false);
      setLoading(false);
      setEditModal(false);
    }
  };

  const getContractorFilters = async () => {
    try {
      setLoading(true);
      if (selectedDirectorates.length > 0) {
        const directorateIds = selectedDirectorates.map((entry) => entry.value);
        const payload = JSON.stringify(directorateIds);
        const contractorFilters = await getContractorFiltersApi(payload);
        //  Remove the invalid ones from the selected contractors list
        const newContractorSelection = selectedCompanies.filter((element) =>
          contractorFilters.find(
            (contractor) => element.value === contractor.value
          )
        );
        console.log(newContractorSelection);
        setSelectedCompanies(newContractorSelection);
        setCompanies(contractorFilters);
      } else {
        setSelectedCompanies([]);
      }
      setLoading(false);
    } catch (err) {
      setCompanies([]);
      setLoading(false);
    }
  };

  const findAddedCharts = () => {
    let apiToCall = [];
    for (let entry in chartIds) {
      apiToCall.push({
        chartId: entry,
        filters: chartIds[entry]?.filters
          ? chartIds[entry]?.filters
          : { companyIds: [], directorateIds: [] },
        api: chartIds[entry]?.api,
        type: chartIds[entry]?.type,
      });
    }
    return apiToCall;
  };

  const apiData = async (calls) => {
    for (let i = 0; i < calls.length; i++) {
      await getApiFunctions(calls[i], setFunction);
    }
  };

  const setFunction = async (chartObject, data) => {
    let tempChart = chartData.get();
    let tempObject = { chartObject, ...data };
    tempChart[chartObject.chartId] = tempObject;
    chartData.set({ ...tempChart });
  };

  const submitFilterPreferences = async () => {
    try {
      setLoading(true);
      let tempObj = { ...chartIds };
      tempObj[selectedChartId].filters = {
        companyIds: selectedCompanies,
        directorateIds: selectedDirectorates,
        dateEnabled,
        ...(startDate
          ? {
              startDate: moment(startDate).startOf("day").toISOString(),
            }
          : {}),
        ...(endDate
          ? {
              endDate: moment(endDate).endOf("day").toISOString(),
            }
          : {}),
      };
      let saveApi = await saveLayout(
        gridOptions,
        setGridOptions,
        setMode,
        setLoading,
        dashboardLayout,
        tempObj,
        userPreferences,
        true
      );
      if (saveApi) {
        getUserPreferences();
      }
      setLoading(false);
      setFilterModal(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleEditChart = async (
    chartData,
    editData,
    tableColumns,
    tableType
  ) => {
    let tempLayout = [...dashboardLayout];
    let tempObj = { ...chartIds };
    if (tableColumns?.length > 0) {
      let labelNames = tableColumns.map((entry) => entry.label);
      let tempFilters = tempObj[chartData?.chartObject?.chartId]?.filters;
      tempFilters = { ...tempFilters, showLabels: labelNames };
      tempObj[chartData?.chartObject?.chartId]["filters"] = tempFilters;
    } else if (tableType && tableColumns?.length === 0) {
      let labelNames = tableColumns.map((entry) => entry.label) || [];
      let tempFilters = tempObj[chartData?.chartObject?.chartId]?.filters;
      tempFilters = { ...tempFilters, showLabels: labelNames };
      tempObj[chartData?.chartObject?.chartId]["filters"] = tempFilters;
    } else {
      let findIndex = dashboardLayout
        .map((entry) => entry.i)
        .indexOf(chartData?.chartObject?.chartId);
      let getDefaults = getChartDefaults(
        `${chartData?.chartObject?.api}${editData}`
      );
      tempObj[chartData?.chartObject?.chartId]["type"] = getDefaults?.chartType;
      tempObj[chartData?.chartObject?.chartId]["title"] = getDefaults?.title;
      tempLayout[findIndex]["w"] = getDefaults?.minW;
      tempLayout[findIndex]["h"] = getDefaults?.minH;
    }
    let saveApi = await saveLayout(
      gridOptions,
      setGridOptions,
      setMode,
      setLoading,
      tempLayout,
      tempObj,
      userPreferences,
      true
    );
    if (saveApi) {
      getUserPreferences();
    }
  };

  const handleActionLocationKpi = async (chartData, editData) => {
    try {
      setLoading(true);
      let getDefaults = {};
      let tempObj = { ...chartIds };
      let tempLayout = [...dashboardLayout];
      let findIndex = dashboardLayout
        .map((entry) => entry.i)
        .indexOf(chartData?.chartObject?.chartId);
      if (chartData?.chartObject?.api === "locationKpis") {
        getDefaults = getChartDefaults(`locationKpisMultiline`);
      } else if (chartData?.chartObject?.api === "companyKpis") {
        getDefaults = getChartDefaults(`companyKpisKpiColumn`);
      }

      tempObj[chartData?.chartObject?.chartId]["filters"] = editData;
      tempObj[chartData?.chartObject?.chartId]["type"] = getDefaults?.chartType;
      tempObj[chartData?.chartObject?.chartId]["title"] = getDefaults?.title;
      tempLayout[findIndex]["w"] = getDefaults?.minW;
      tempLayout[findIndex]["h"] = getDefaults?.minH;
      let saveApi = await saveLayout(
        gridOptions,
        setGridOptions,
        setMode,
        setLoading,
        tempLayout,
        tempObj,
        userPreferences,
        true
      );
      if (saveApi) {
        getUserPreferences();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserPreferences();
  }, []);

  useEffect(() => {
    chartData.set({});
    let apiCalls = findAddedCharts();
    apiData(apiCalls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartIds]);

  useEffect(() => {
    getContractorFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDirectorates]);

  useEffect(() => {
    const getDirectorateFilters = async () => {
      try {
        setLoading(true);
        //  Get filters for directorates
        const directorateFilters = await getDirectorateFiltersApi();
        setDirectorates(directorateFilters);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getDirectorateFilters();
  }, []);

  return (
    <Page title={t("dashboards.tabs.observationDashboard")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t("dashboards.welcome")}</Typography>
        </Box>
        {renderTopNavigation()}
        <div
          style={{
            maxWidth: "100%",
            // height: "70vh",
            overflowY: "auto",
          }}
        >
          {dashboardLayout &&
          dashboardLayout.length > 0 &&
          chartIds &&
          Object.keys(chartIds).length > 0 ? (
            <div id="downloadPdf">
              <DashboardGrid layout={dashboardLayout} pagesRefs={pagesRef} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30vh",
              }}
            >
              {layoutLoading ? (
                <PageLoader />
              ) : (
                <Typography
                  variant="h5"
                  style={{
                    width: "50vw",
                    textAlign: "center",
                    textShadow: "none",
                    color: theme.palette.primary.hoverGrey,
                  }}
                >
                  {t("dashboards.placeholderText")}
                </Typography>
              )}
            </div>
          )}
        </div>
      </Container>
      {renderAddChart()}
      <InfoBox
        overflowV
        open={filterModal}
        disabled={loading}
        title={t("observations.viewObservations.filters.title")}
        content={renderWidgetFilters()}
        buttonLabel={t("general.applyFilters")}
        handleClose={() => setFilterModal(false)}
        handleAction={() => submitFilterPreferences()}
        minWidth="550px"
      />
      <InfoBox
        // overflowV
        open={editModal}
        title={t("dashboards.editChart")}
        content={
          <EditChart
            onAction={handleEditChart}
            onActionLocationKpi={handleActionLocationKpi}
            data={selectedChartId}
          />
        }
        // buttonLabel="Edit Chart"
        handleClose={() => setEditModal(false)}
        minWidth="650px"
      />
    </Page>
  );
}
