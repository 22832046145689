import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { displayToast } from "../../utils/general";
import toast from "react-hot-toast";
//  MUI
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
//  Components
import Page from "../../components/Page";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import SelectInput from "../../components/inputs/SelectInput";
import MultiSelectInput from "../../components/inputs/MultiSelectWithAll";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ViewReport from "./ViewReport";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import InfoBox from "../../components/InfoBox";
//  Static data
import {
  getCompanyFiltersForReports,
  getDirectorateFiltersForReports,
  getDisciplinesFiltersForReports,
  getFiltersForReports,
  getLocationUnitFiltersForReports,
  getReportData,
  getBehaviorFiltersApi,
} from "../../api/reports";
import { searchUsersApi } from "../../api/users";
import DateInput from "../../components/inputs/DateInput";
import moment from "moment";
import { requiredFields } from "./constants";

export default function CreateReport() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const theme = useTheme();
  const timeout = useRef();
  const directorateTimeout = useRef();
  const companyTimeout = useRef();
  const locationTimeout = useRef();
  const disciplineTimeout = useRef();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [companyNumber, setCompanyNumber] = useState("");
  const [chartInfo, setChartInfo] = useState(null);
  const [page, setPage] = useState(1);
  const [reportType, setReportType] = useState("");
  const [reportSubType, setReportSubType] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [confirmModal, setConfirmModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  const reportTypesDefault = [
    // {label: "Select Report Type", value: ""},
    {
      label: t(`reporting.types.mainTypes.observation`),
      value: "observation_reports",
    },
    {
      label: t(`reporting.types.mainTypes.performance`),
      value: "performance_reports",
    },
    // { label: "% Knowledge Reports", value: "percent_knowledge_reports" },
    // {
    //     label: "Ihtimam Total Performance Reports",
    //     value: "ihtimam_total_performance",
    // },
    // {label: "Action Close Out Reports", value: "action_close_out_reports"},
    { label: t(`reporting.types.mainTypes.lsr`), value: "lsr_reports" },
    {
      label: t(`reporting.types.mainTypes.rootCause`),
      value: "root_cause_reports",
    },
    // {label: "Directorate Reports", value: "directorate_reports"}
  ];

  //TODO: need to add business unit from the backend
  const [businessUnit, setBusinessUnit] = useState([
    { label: "BU Name", value: "BU Name" },
    { label: "# Employees", value: "# Employees" },
    { label: "# Cards", value: "# Cards" },
  ]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);

  const [directorate, setDirectorate] = useState([]);
  const [selectedDirectorate, setSelectedDirectorate] = useState([]);

  const [cardVerifier, setCardVerifier] = useState([]);
  const [selectedCardVerifier, setSelectedCardVerifier] = useState([]);

  const [contractorCompanies, setContractorCompanies] = useState([]);
  const [selectedContractorCompanies, setSelectedContractorCompanies] =
    useState([]);

  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const [unit, setUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);

  const [discipline, setDiscipline] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);

  const [includeActionItem, setIncludeActionItem] = useState(false);
  const [includeChild, setIncludeChild] = useState(false);

  //TODO: need to add card owner from the backend
  const [cardOwner, setCardOwner] = useState([
    { label: "Card Owner A", value: "BU Name" },
    { label: "Card Owner B", value: "# Employees" },
    { label: "Card Owner C", value: "# Cards" },
  ]);
  const [selectedCardOwner, setSelectedCardOwner] = useState([]);

  const [timeInterval, setTimeInterval] = useState([]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState([]);

  const [behaviours, setBehaviours] = useState([]);
  const [selectedBehaviours, setSelectedBehaviours] = useState([]);

  const [behaviourType, setBehaviourType] = useState([]);
  const [selectedBehaviourType, setSelectedBehaviourType] = useState([]);

  //TODO: need to add observationLinkedTo from the backend
  const [observationLinkedTo, setObservationLinkedTo] = useState([
    { label: "Safe behavior", value: "safeBehavior" },
    { label: "Unsafe behavior", value: "unsafeBehavior" },
    { label: "Safe condition", value: "safeCondition" },
    { label: "Unsafe condition", value: "unsafeCondition" },
  ]);
  const [selectedObservationLinkedTo, setSelectedObservationLinkedTo] =
    useState([]);

  //TODO: need to add subType from the backend
  const [subType, setSubType] = useState([
    { label: "Sub Type A", value: "BU Name" },
    { label: "Sub Type B", value: "# Employees" },
    { label: "Sub Type C", value: "# Cards" },
  ]);
  const [selectedSubType, setSelectedSubType] = useState([]);

  //TODO: need to add lsrType from the backend
  const [lsrType, setLsrType] = useState([]);
  const [selectedLsrType, setSelectedLsrType] = useState([]);

  const [rootCause, setRootCause] = useState([]);
  const [selectedRootCause, setSelectedRootCause] = useState([]);

  const [groupBy, setGroupBy] = useState([]);
  const [groupByOptions, setGroupByOptions] = useState({});
  const [groupByDropdown, setGroupByDropdown] = useState([]);
  const [selectedGroupBy, setSelectedGroupBy] = useState([]);

  const [observerType, setObserverType] = useState([]);
  const [selectedObserverType, setSelectedObserverType] = useState([]);

  //  TODO: need to add bypassedNonBypassed from the backend
  const [bypassedNonBypassed, setBypassedNonBypassed] = useState([
    { label: "Bypassed", value: "bypassed" },
    { label: "Non Bypassed", value: "nonBypassed" },
  ]);
  const [selectedBypassedNonBypassed, setSelectedBypassedNonBypassed] =
    useState([]);

  const [cardStatus, setCardStatus] = useState([]);
  const [selectedCardStatus, setSelectedCardStatus] = useState([]);

  const [selectedRaisedByUsers, setSelectedRaisedByUsers] = useState([]);

  const cleanupFunction = () => {
    setPage(3);
    toast.remove();
  };

  const getBehavioursList = async () => {
    try {
      const directorateIds =
        selectedDirectorate.length > 0
          ? JSON.stringify(selectedDirectorate?.map((item) => item.value))
          : undefined;

      const companyIds =
        selectedContractorCompanies.length > 0
          ? JSON.stringify(
              selectedContractorCompanies?.map((item) => item.value)
            )
          : undefined;

      const locationIds =
        selectedLocation?.length > 0
          ? JSON.stringify(selectedLocation?.map((item) => item.value))
          : undefined;

      const unitIds =
        selectedUnit?.length > 0
          ? JSON.stringify(selectedUnit?.map((item) => item.value))
          : undefined;

      const disciplineIds =
        selectedDiscipline?.length > 0
          ? JSON.stringify(selectedDiscipline?.map((item) => item.value))
          : undefined;

      const bList = await getBehaviorFiltersApi(
        directorateIds,
        companyIds,
        locationIds,
        unitIds,
        disciplineIds
      );

      setBehaviours(bList);
    } catch (err) {
      setBehaviours([]);
      setSelectedBehaviours([]);
    }
  };

  useEffect(() => {
    setReportSubType("");
  }, [reportType]);

  useEffect(() => {
    setSelectedBusinessUnit([]);
    setSelectedContractorCompanies([]);
    setIncludeActionItem(false);
    setIncludeChild(false);
    setSelectedLocation([]);
    setSelectedDiscipline([]);
    setSelectedUnit([]);
    setSelectedCardOwner([]);
    setSelectedTimeInterval([]);
    setSelectedBehaviours([]);
    setSelectedBehaviourType([]);
    setSelectedObservationLinkedTo([]);
    setSelectedSubType([]);
    setSelectedLsrType([]);
    setSelectedRootCause([]);
    setSelectedGroupBy([]);
    setSelectedObserverType([]);
    setSelectedBypassedNonBypassed([]);
    setSelectedCardStatus([]);
    setSelectedDirectorate([]);

    setContractorCompanies([]);

    setLocation([]);
    setUnit([]);
  }, [reportType, reportSubType]);

  useEffect(() => {
    getDirectorateFiltersForReports()
      .then((res) => {
        res?.length > 0 ? setDirectorate(res) : setDirectorate([]);
      })
      .catch((err) => {
        // console.log(err)
        setDirectorate([]);
      });
  }, []);

  useEffect(() => {
    const directorateIds =
      selectedDirectorate?.length > 0
        ? JSON.stringify(selectedDirectorate?.map((item) => item.value))
        : undefined;

    const getCompanies = async () => {
      if (directorateIds) {
        clearTimeout(directorateTimeout.current);
        directorateTimeout.current = setTimeout(async () => {
          try {
            const result = await getCompanyFiltersForReports(directorateIds);
            if (result && result.length > 0) {
              setContractorCompanies(result);
            } else {
              setContractorCompanies([]);
            }
          } catch (err) {
            setContractorCompanies([]);
          }
        }, 1000);
      } else {
        setContractorCompanies([]);
        setLocation([]);
        setUnit([]);
      }
    };

    getCompanies();
  }, [selectedDirectorate]);

  useEffect(() => {
    let directorateIds;
    let companyIds;
    if (
      reportSubType === "observation_target" ||
      reportSubType === "ithimam_total_performance_reports"
    ) {
      directorateIds = [selectedDirectorate.value];
      companyIds = [selectedContractorCompanies.value];
    } else {
      directorateIds =
        selectedDirectorate?.length > 0
          ? JSON.stringify(selectedDirectorate?.map((item) => item.value))
          : undefined;
      companyIds =
        selectedContractorCompanies?.length > 0
          ? JSON.stringify(
              selectedContractorCompanies?.map((item) => item.value)
            )
          : undefined;

      companyIds =
        selectedContractorCompanies?.length > 0
          ? JSON.stringify(
              selectedContractorCompanies?.map((item) => item.value)
            )
          : undefined;
    }

    const getLocations = async () => {
      if (directorateIds && companyIds) {
        clearTimeout(companyTimeout.current);
        companyTimeout.current = setTimeout(async () => {
          try {
            const res = await getLocationUnitFiltersForReports(
              directorateIds,
              companyIds
            );
            if (res?.locations?.length > 0) {
              setLocation(res.locations);
            } else {
              setLocation([]);
            }
          } catch (err) {
            setLocation([]);
            setUnit([]);
          }
        }, 1000);
      } else {
        setLocation([]);
        setUnit([]);
      }
    };

    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContractorCompanies]);

  //  If the user picks a location, need to call an endpoint to get the units
  useEffect(() => {
    const getUnits = async (directorateIds, companyIds, locationIds) => {
      if (directorateIds && companyIds && locationIds) {
        clearTimeout(locationTimeout.current);
        locationTimeout.current = setTimeout(async () => {
          try {
            const res = await getLocationUnitFiltersForReports(
              directorateIds,
              companyIds,
              locationIds
            );
            if (res?.units?.length > 0) {
              setUnit(res.units);
            } else {
              setUnit([]);
            }
          } catch (err) {
            setUnit([]);
          }
        }, 1000);
      } else {
        setUnit([]);
      }
    };

    if (selectedLocation.length > 0) {
      const directorateIds =
        selectedDirectorate?.length > 0
          ? JSON.stringify(selectedDirectorate?.map((item) => item.value))
          : undefined;

      const companyIds =
        selectedContractorCompanies?.length > 0
          ? JSON.stringify(
              selectedContractorCompanies?.map((item) => item.value)
            )
          : undefined;

      const locationIds =
        selectedLocation?.length > 0
          ? JSON.stringify(selectedLocation?.map((item) => item.value))
          : undefined;

      getUnits(directorateIds, companyIds, locationIds);
    } else {
      setSelectedUnit([]);
      setUnit([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  useEffect(() => {
    let companyIds;
    if (
      reportSubType === "observation_target" ||
      reportSubType === "ithimam_total_performance_reports"
    ) {
      companyIds = [selectedContractorCompanies.value];
    } else {
      companyIds =
        selectedContractorCompanies?.length > 0
          ? JSON.stringify(
              selectedContractorCompanies?.map((item) => item.value)
            )
          : undefined;
    }
    let unitIds =
      selectedUnit?.length > 0
        ? JSON.stringify(selectedUnit?.map((item) => item.value))
        : undefined;
    let locationIds =
      selectedLocation?.length > 0
        ? JSON.stringify(selectedLocation?.map((item) => item.value))
        : undefined;

    let directorateIds =
      selectedDirectorate.length > 0
        ? JSON.stringify(selectedDirectorate?.map((item) => item.value))
        : undefined;

    const getDisciplines = async () => {
      clearTimeout(disciplineTimeout.current);
      disciplineTimeout.current = setTimeout(async () => {
        try {
          const res = await getDisciplinesFiltersForReports(
            companyIds,
            unitIds,
            locationIds,
            directorateIds
          );
          if (res?.length > 0) {
            setDiscipline(res);
          } else {
            setDiscipline([]);
          }
        } catch (err) {
          setDiscipline([]);
        }
      }, 1000);
    };

    if (companyIds && locationIds && directorateIds) {
      getDisciplines();
    } else {
      setDiscipline([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedContractorCompanies,
    selectedUnit,
    selectedLocation,
    selectedDirectorate,
  ]);

  // Behaviour useEffect
  useEffect(() => {
    //  If it's a behavior related report only call the behaviour endpoint
    if (
      reportType === "observation_reports" ||
      reportType === "performance_reports"
    ) {
      setSelectedBehaviours([]);
      if (
        selectedDirectorate.length > 0 &&
        selectedContractorCompanies.length > 0 &&
        selectedLocation.length > 0 &&
        selectedDiscipline.length > 0
      ) {
        getBehavioursList();
      } else {
        setBehaviours([]);
        setSelectedBehaviours([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedContractorCompanies,
    selectedUnit,
    selectedLocation,
    selectedDirectorate,
    selectedDiscipline,
  ]);

  useEffect(() => {
    getFiltersForReports()
      .then((res) => {
        const dropdowns = res?.dropdowns;
        const companyNum = res?.companyNumber || "";

        dropdowns?.timeInterval?.length > 0
          ? setTimeInterval(dropdowns.timeInterval)
          : setTimeInterval([]);
        //  We are now using dedicated endpoint for Behaviours
        // dropdowns?.behaviors?.length > 0
        //   ? setBehaviours(dropdowns.behaviors)
        //   : setBehaviours([]);
        dropdowns?.behaviorType?.length > 0
          ? setBehaviourType(dropdowns.behaviorType)
          : setBehaviourType([]);
        dropdowns?.rootCauses?.length > 0
          ? setRootCause(dropdowns.rootCauses)
          : setRootCause([]);

        setGroupByOptions(dropdowns.groupBy);

        dropdowns?.observerType?.length > 0
          ? setObserverType(dropdowns.observerType)
          : setObserverType([]);

        if (dropdowns?.cardStatus?.length > 0) {
          if (currentLanguage === "ar") {
            const arLabels = dropdowns.cardStatus.map((item) => {
              return {
                label: t(item.value, { ns: "backend" }),
                value: item.value,
              };
            });

            setCardStatus(arLabels);
          } else {
            setCardStatus(dropdowns.cardStatus);
          }
        } else {
          setCardStatus([]);
        }

        dropdowns?.lsrType?.length > 0
          ? setLsrType(dropdowns.lsrType)
          : setLsrType([]);

        setCompanyNumber(companyNum);
      })
      .catch((err) => console.log(err));

    return cleanupFunction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReportType = () => (
    <>
      <Grid item xs={6}>
        <SelectInput
          value={reportType}
          handler={(e) => setReportType(e.target.value)}
          options={reportTypesDefault}
          label={t(`reporting.selectReportType`)}
          disabled={loading}
        />
      </Grid>
    </>
  );

  const reportSubTypesOptions = () => {
    const obsReport = [
      // {label: "Observation Reports", value: "observation_reports"},

      // { label: "Observation Feedback", value: "observation_feedback" },
      //   {
      //     label: "Observer Type",
      //     value: "observer_type",
      //   },
      // { label: "Total Observation Report", value: "total_observation_report" },
      {
        label: t(`reporting.types.subTypes.observationSummary`),
        value: "observation_summary",
      },
      {
        label: t(`reporting.types.subTypes.completeObservation`),
        value: "complete_observation",
      },
      {
        label: t(`reporting.types.subTypes.behavioralTrend`),
        value: "behavioral_trend",
      },
    ];

    const perfReport = [
      // {label: "Observation Target", value: "observation_target"},
      //   Unit performance is now KPI repoert
      {
        label: t(`reporting.types.perfReport.unitPerformance`),
        value: "unit_performance",
      },
      // { label: "IHTIMAM KPI Report", value: "kpi_report" },
      {
        label: t(`reporting.types.perfReport.actionCloseOut`),
        value: "action_close_out_reports",
      },
      {
        label: t(`reporting.types.perfReport.totalPerformance`),
        value: "ihtimam_total_performance",
      },
    ];

    const percentKnowledgeReport = [
      // { label: "% Knowledge Reports", value: "percent_knowledge_reports" },
      // { label: "Test report", value: "test_report" },
    ];

    const ihtimamTotalPerformanceReport = [
      // {
      //   label: "Ihtimam Total Performance Reports",
      //   value: "ihtimam_total_performance",
      // },
    ];

    const actionCloseOutReport = [];

    const lsrReport = [
      // {label: "LSR Reports", value: "lsr_reports"},
      {
        label: t(`reporting.types.lsrReport.total`),
        value: "total_lsr_report",
      },
      {
        label: t(`reporting.types.lsrReport.voilation`),
        value: "lsr_violation_report",
      },
      {
        label: t(`reporting.types.lsrReport.behaLinked`),
        value: "behavior_linked_lsr_report",
      },
      // {label: "LSR Per BU", value: "lsr_per_bu"},
      {
        label: t(`reporting.types.lsrReport.classification`),
        value: "lsr_type_classification_report",
      },
    ];

    const rootCauseReport = [
      {
        label: t(`reporting.types.rootCauseReport.againstObs`),
        value: "root_cause_against_observation",
      },
      {
        label: t(`reporting.types.rootCauseReport.trend`),
        value: "root_cause_trend",
      },
      // {
      //   label: "Root Cause With Behaviour",
      //   value: "root_cause_with_behaviour",
      // },
    ];

    const directorateReport = [
      // {label: "Card Count", value: "card_count"},
      // {label: "Participation Percentage", value: "participation_percentage"},
      // {label: "Employee Card Count", value: "employee_card_count"},
      { label: "Directorate Report", value: "directorate_report" },
    ];

    switch (reportType) {
      case "observation_reports":
        return obsReport;
      case "performance_reports":
        return perfReport;
      case "percent_knowledge_reports":
        return percentKnowledgeReport;
      case "ihtimam_total_performance":
        return ihtimamTotalPerformanceReport;
      case "action_close_out_reports":
        return actionCloseOutReport;
      case "lsr_reports":
        return lsrReport;
      case "root_cause_reports":
        return rootCauseReport;
      case "directorate_reports":
        return directorateReport;
      default:
        return [];
    }
  };

  const renderReportSubType = () => (
    <>
      <Grid item xs={6}>
        <SelectInput
          value={reportSubType}
          handler={(e) => {
            setReportSubType(e.target.value);
            if (e.target.value in groupByOptions) {
              setGroupByDropdown(groupByOptions[e.target.value]);
            } else {
              setGroupByDropdown(groupByOptions.default);
            }
          }}
          options={reportSubTypesOptions()}
          label={t(`reporting.selectReportSubType`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || reportType === ""}
        />
      </Grid>
    </>
  );

  const renderFromToPeriod = (required = false) => (
    <>
      <Grid item xs={6}>
        <DateInput
          ukForm
          required={required}
          label={t(`reporting.fromDate`)}
          date={fromDate}
          disabled={loading}
          handler={(newDate) => {
            setFromDate(newDate);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          ukForm
          required={required}
          label={t(`reporting.toDate`)}
          date={toDate}
          disabled={loading}
          handler={(newDate) => {
            setToDate(newDate);
          }}
        />
      </Grid>
    </>
  );

  const renderBusinessUnit = (isMulti = false, required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.selectBusinessUnit`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || businessUnit.length < 1}
          options={businessUnit}
          handler={(newValue) => setSelectedBusinessUnit(newValue)}
          values={selectedBusinessUnit}
          isMulti={isMulti} // this should be conditional depending upon the report type
        />
      </Grid>
    </>
  );

  const renderDirectorate = (isMulti = true, required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          values={selectedDirectorate}
          handler={(newValue) => {
            if (isMulti === true && newValue.length === 0) {
              setSelectedContractorCompanies([]);
              setSelectedLocation([]);
              setSelectedUnit([]);
              setSelectedDiscipline([]);
            }
            setSelectedDirectorate(newValue);
          }}
          options={directorate}
          label={t(`reporting.directorate`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || directorate.length < 1}
          isMulti={isMulti} // this should be conditional depending upon the report type
        />
      </Grid>
    </>
  );

  const renderCardVerifier = (required = false) => (
    <>
      <Grid item xs={6}>
        <AutocompleteInput
          multiple
          value={selectedCardVerifier}
          handler={(e, v) => setSelectedCardVerifier(v)}
          keyPressHandler={handleSearchUser}
          label={t("reporting.cardVerifier")}
          placeholder={t("reporting.cardVerifier")}
          loading={searchLoading}
          disabled={loading}
          options={cardVerifier}
        />
      </Grid>
    </>
  );

  const renderContractorCompany = (isMulti = true, required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.selectContractorCompany`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || contractorCompanies.length < 1}
          options={contractorCompanies}
          handler={(newValue) => {
            if (isMulti === true && newValue.length === 0) {
              setSelectedLocation([]);
              setSelectedUnit([]);
              setSelectedDiscipline([]);
            }
            setSelectedContractorCompanies(newValue);
          }}
          values={selectedContractorCompanies}
          isMulti={isMulti} // this should be conditional depending upon the report type
        />
      </Grid>
    </>
  );

  const renderLocation = (isMulti = true, required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.selectLocation`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || location.length < 1}
          options={location}
          handler={(newValue) => {
            if (isMulti === true && newValue.length === 0) {
              setSelectedUnit([]);
              setSelectedDiscipline([]);
            }
            setSelectedLocation(newValue);
          }}
          values={selectedLocation}
          isMulti={isMulti} // this should be conditional depending upon the report type
        />
      </Grid>
    </>
  );

  const renderUnit = (isMulti = true, required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.selectUnit`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || unit.length < 1}
          options={unit}
          handler={(newValue) => {
            if (isMulti === true && newValue.length === 0) {
              setSelectedDiscipline([]);
            }
            setSelectedUnit(newValue);
          }}
          values={selectedUnit}
          isMulti={isMulti} // this should be conditional depending upon the report type
        />
      </Grid>
    </>
  );

  const renderDiscipline = (isMulti = true, required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.selectDiscipline`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || discipline.length < 1}
          options={discipline}
          handler={(newValue) => setSelectedDiscipline(newValue)}
          values={selectedDiscipline}
          isMulti={isMulti} // this should be conditional depending upon the report type
        />
      </Grid>
    </>
  );

  const renderCardOwner = (required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.selectCardOwner`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || cardOwner.length < 1}
          options={cardOwner}
          handler={(newValue) => setSelectedCardOwner(newValue)}
          values={selectedCardOwner}
        />
      </Grid>
    </>
  );

  const renderRaisedByUsers = (required = false) => (
    <>
      <Grid item xs={6}>
        <AutocompleteInput
          multiple
          value={selectedRaisedByUsers}
          handler={(e, v) => setSelectedRaisedByUsers(v)}
          keyPressHandler={handleSearchUser}
          label={t(`reporting.selectRaisedBy`)}
          placeholder={t(`reporting.selectRaisedBy`)}
          loading={searchLoading}
          disabled={loading}
          options={cardVerifier}
        />
      </Grid>
    </>
  );

  const renderTimeInterval = (required = false) => (
    <>
      <Grid item xs={6}>
        <SelectInput
          optional={!required}
          required={required}
          value={selectedTimeInterval.value}
          handler={(e) =>
            setSelectedTimeInterval({
              ...selectedTimeInterval,
              value: e.target.value,
            })
          }
          options={timeInterval}
          label={t(`reporting.timeInterval`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading}
        />
      </Grid>
    </>
  );

  const renderBehaviours = (required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.behaviours`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || behaviours.length < 1}
          options={behaviours}
          handler={(newValue) => setSelectedBehaviours(newValue)}
          values={selectedBehaviours}
        />
      </Grid>
    </>
  );

  const renderBehaviourType = (required = false) => (
    <>
      <Grid item xs={6}>
        <SelectInput
          required={required}
          value={selectedBehaviourType.value}
          handler={(e) =>
            setSelectedBehaviourType({
              ...selectedBehaviourType,
              value: e.target.value,
            })
          }
          options={behaviourType}
          label={t(`reporting.behaviourType`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading}
        />
      </Grid>
    </>
  );

  const renderObservationLinkedTo = (required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.observationLinkedTo`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || observationLinkedTo.length < 1}
          options={observationLinkedTo}
          handler={(newValue) => setSelectedObservationLinkedTo(newValue)}
          values={selectedObservationLinkedTo}
        />
      </Grid>
    </>
  );

  const renderSubType = (required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.subType`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || subType.length < 1}
          options={subType}
          handler={(newValue) => setSelectedSubType(newValue)}
          values={selectedSubType}
        />
      </Grid>
    </>
  );

  const renderLSRType = (required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.lsrType`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || lsrType.length < 1}
          options={lsrType}
          handler={(newValue) => setSelectedLsrType(newValue)}
          values={selectedLsrType}
        />
      </Grid>
    </>
  );

  const renderRootCause = (required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.rootCause`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || rootCause.length < 1}
          options={rootCause}
          handler={(newValue) => setSelectedRootCause(newValue)}
          values={selectedRootCause}
        />
      </Grid>
    </>
  );

  const renderCardStatus = (required = false) => (
    <>
      <Grid item xs={6}>
        <MultiSelectInput
          required={required}
          label={t(`reporting.cardStatus`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading || cardStatus.length < 1}
          options={cardStatus}
          handler={(newValue) => setSelectedCardStatus(newValue)}
          values={selectedCardStatus}
        />
      </Grid>
    </>
  );

  const renderGroupBy = (required = false) => (
    <>
      <Grid item xs={6}>
        <SelectInput
          required={required}
          value={selectedGroupBy.value}
          handler={(e) =>
            setSelectedGroupBy({
              ...selectedGroupBy,
              value: e.target.value,
              label:
                e.target.value === "company" ? "contractor" : e.target.value,
            })
          }
          options={groupByDropdown}
          label={t(`reporting.groupBy`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading}
        />
      </Grid>
    </>
  );

  const renderObserverType = (required = false) => (
    <>
      <Grid item xs={6}>
        <SelectInput
          required={required}
          value={selectedObserverType.value}
          handler={(e) =>
            setSelectedObserverType({
              ...selectedObserverType,
              value: e.target.value,
            })
          }
          options={observerType}
          label={t(`reporting.observerType`)}
          disabled={loading}
        />
      </Grid>
    </>
  );

  const renderBypassedNonBypassed = (required = false) => (
    <>
      <Grid item xs={6}>
        <SelectInput
          required={required}
          value={selectedBypassedNonBypassed.value}
          handler={(e) =>
            setSelectedBypassedNonBypassed({
              ...selectedBypassedNonBypassed,
              value: e.target.value,
            })
          }
          options={bypassedNonBypassed}
          label={t(`reporting.bypassedNonBypassed`)}
          placeholder={t(`reporting.selectPlaceholder`)}
          disabled={loading}
        />
      </Grid>
    </>
  );

  const renderIncludeItemActionHistory = () => (
    <>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeActionItem}
              name={"includeActionItems"}
              onChange={() => {
                setIncludeActionItem(!includeActionItem);
              }}
              sx={{
                color: theme.palette.primary.orange,
                "&.Mui-checked": {
                  color: theme.palette.primary.orange,
                },
                "&:hover": {
                  backgroundColor: "rgba(248,147,31,0.08)",
                },
              }}
            />
          }
          disabled={loading}
          label={t("reporting.includeActionItem")}
        />
      </Grid>
    </>
  );

  const renderIncludeChild = () => (
    <>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeChild}
              name={"includeChild"}
              onChange={() => {
                setIncludeChild(!includeChild);
              }}
              sx={{
                color: theme.palette.primary.orange,
                "&.Mui-checked": {
                  color: theme.palette.primary.orange,
                },
                "&:hover": {
                  backgroundColor: "rgba(248,147,31,0.08)",
                },
              }}
            />
          }
          disabled={loading}
          label={t("reporting.includeChild")}
        />
      </Grid>
    </>
  );

  const renderNoReportSelected = () => (
    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
      <Typography variant="h6" color="textSecondary">
        {t("reporting.noReportSelected")}
      </Typography>
    </Grid>
  );

  const renderFilterSetA = ({ required = [], extra = [] }) => (
    <>
      {renderFromToPeriod(required.includes("fromTo"))}
      {/*{renderBusinessUnit(false, true)}*/}
      {renderDirectorate(true, required.includes("directorate"))}
      {renderContractorCompany(true, required.includes("contractorCompany"))}
      {renderLocation(true, required.includes("location"))}
      {renderUnit(true, required.includes("unit"))}
      {renderDiscipline(true, required.includes("discipline"))}
      {extra.includes("cardStatus") &&
        renderCardStatus(required.includes("cardStatus"))}
      {renderCardVerifier(required.includes("cardVerifier"))}
      {renderTimeInterval(required.includes("timeInterval"))}
      {/* {renderCardOwner(required.includes("cardOwner"))} */}
      {renderRaisedByUsers(required.includes("raisedByUsers"))}
      {renderBehaviours(required.includes("behavior"))}
      {renderGroupBy(required.includes("groupBy"))}
    </>
  );

  const renderFilterSetB = ({ required = [], extra = [] }) => (
    <>
      {renderFromToPeriod(required.includes("fromTo"))}
      {renderDirectorate(true, required.includes("directorate"))}
      {renderContractorCompany(true, required.includes("contractorCompany"))}
      {renderLocation(true, required.includes("location"))}
      {renderUnit(true, required.includes("unit"))}
      {renderDiscipline(true, required.includes("discipline"))}
      {renderCardVerifier(required.includes("cardVerifier"))}
      {renderTimeInterval(required.includes("timeInterval"))}
      {/* {renderCardOwner(required.includes("cardOwner"))} */}
      {renderRaisedByUsers(required.includes("raisedByUsers"))}
      {renderGroupBy(required.includes("groupBy"))}
    </>
  );

  const renderFilterSetC = ({ required = [], extra = [] }) => (
    <>
      {renderFromToPeriod(required.includes("fromTo"))}
      {renderDirectorate(true, required.includes("directorate"))}
      {renderContractorCompany(true, required.includes("contractorCompany"))}
      {renderLocation(true, required.includes("location"))}
      {renderUnit(true, required.includes("unit"))}
      {renderDiscipline(true, required.includes("discipline"))}
      {renderCardVerifier(required.includes("cardVerifier"))}
      {renderTimeInterval(required.includes("timeInterval"))}
      {/* {renderCardOwner(required.includes("cardOwner"))} */}
      {renderRaisedByUsers(required.includes("raisedByUsers"))}
      {renderObservationLinkedTo(required.includes("observationLinkedTo"))}
      {/* {renderSubType(required.includes("subType"))} */}
      {renderLSRType(required.includes("lsrType"))}
      {renderGroupBy(required.includes("groupBy"))}
    </>
  );

  const renderFilterSetD = ({ required = [], extra = [] }) => (
    <>
      {renderFromToPeriod(required.includes("fromTo"))}
      {renderDirectorate(true, required.includes("directorate"))}
      {renderContractorCompany(true, required.includes("contractorCompany"))}
      {renderLocation(true, required.includes("location"))}
      {renderUnit(true, required.includes("unit"))}
      {renderDiscipline(true, required.includes("discipline"))}
      {renderCardVerifier(required.includes("cardVerifier"))}
      {renderTimeInterval(required.includes("timeInterval"))}
      {/* {renderCardOwner(required.includes("cardOwner"))} */}
      {renderRaisedByUsers(required.includes("raisedByUsers"))}
      {renderRootCause(required.includes("rootCause"))}
      {renderGroupBy(required.includes("groupBy"))}
    </>
  );

  const renderTotalObservation = () => {
    return renderFilterSetA(requiredFields["total_observation_report"]);
  };

  const renderObservationFeedback = () => {
    return renderFilterSetA();
  };

  const renderObservationType = () => (
    <Grid container item spacing={3}>
      {renderFromToPeriod(true)}
      {/*{renderBusinessUnit(false, true)}*/}
      {renderDirectorate(true, true)}
      {renderContractorCompany(true, false)}
      {renderLocation()}
      {renderUnit()}
      {renderDiscipline()}
      {/*{renderCardOwner()}*/}
      {renderObserverType()}
      {/*{renderTimeInterval(true)}*/}
      {renderGroupBy(true)}
    </Grid>
  );

  const renderObservationSummary = () => {
    return renderFilterSetA(requiredFields["observation_summary"]);
  };

  const renderCompleteObservation = () => {
    return renderFilterSetA(requiredFields["complete_observation"]);
  };

  //    Part of KPIs now
  const renderObservationTarget = () => {
    return renderFilterSetA({
      required: ["fromTo"],
      extra: [],
    });
  };

  const renderBehavioralTrend = () => {
    return renderFilterSetA(requiredFields["behavioral_trend"]);
  };

  const renderKpiReport = () => {
    return renderFilterSetA();
  };

  const renderUnitPerformance = () => {
    return renderFilterSetA(requiredFields["unit_performance"]);
  };

  const renderPercentKnowledgeReports = () => (
    <Grid container item spacing={3}>
      {renderFromToPeriod(true)}
      {renderContractorCompany(true, true)}
      {/*{renderLocation()}*/}
      {/*{renderDiscipline()}*/}
      {/*{renderUnit()}*/}
      {/*{renderTimeInterval(true)}*/}
      {/*{renderGroupBy()}*/}
    </Grid>
  );

  const renderIhtimamTotalPerformanceReports = () => {
    return renderFilterSetA(requiredFields["ihtimam_total_performance"]);
  };

  const renderActionCloseOutReports = () => {
    return renderFilterSetA(requiredFields["action_close_out_reports"]);
  };

  const renderTotalLSR = () => {
    return renderFilterSetC(requiredFields["total_lsr_report"]);
  };

  const renderLSRViolation = () => {
    return renderFilterSetC(requiredFields["lsr_violation_report"]);
  };

  const renderBehaviourLinkedLSR = () => {
    return renderFilterSetC(requiredFields["behavior_linked_lsr_report"]);
  };

  const renderLSRPerBU = () => (
    <Grid container item spacing={3}>
      {renderFromToPeriod(true)}
      {/*{renderBusinessUnit(false, true)}*/}
      {renderContractorCompany(true, true)}
      {renderLocation()}
      {renderUnit()}
      {renderDiscipline()}
      {/* {renderCardOwner()} */}
      {renderObservationLinkedTo()}
      {renderSubType()}
      {renderLSRType()}
      {renderTimeInterval()}
      {renderGroupBy()}
    </Grid>
  );

  const renderLSRTypeClassification = () => {
    return renderFilterSetC(requiredFields["lsr_type_classification_report"]);
  };

  const renderRootCauseAgainstObservation = () => {
    return renderFilterSetD(requiredFields["root_cause_against_observation"]);
  };

  const renderRootCauseTrend = () => {
    return renderFilterSetD(requiredFields["root_cause_trend"]);
  };

  const renderRootCauseWithBehaviour = () => {
    return renderFilterSetD(requiredFields["root_cause_with_behaviour"]);
  };

  const renderCardCount = () => (
    <Grid container item spacing={3}>
      {renderFromToPeriod(true)}
      {renderDirectorate(false, true)}
      {/*{renderBusinessUnit(true, false)}*/}
      {renderIncludeChild()}
    </Grid>
  );

  const renderParticipationPercentage = () => (
    <Grid container item spacing={3}>
      {renderFromToPeriod(true)}
      {renderDirectorate(false, true)}
      {/*{renderBusinessUnit(true, false)}*/}
      {renderIncludeChild()}
    </Grid>
  );

  const renderEmployeeCardCount = () => (
    <Grid container item spacing={3}>
      {renderFromToPeriod(true)}
      {renderDirectorate(false, true)}
      {/*{renderBusinessUnit(true, false)}*/}
      {renderIncludeChild()}
    </Grid>
  );

  const renderDirectorateReport = () => {
    return renderFilterSetB(requiredFields["directorate_report"]);
  };

  const renderSubmitButton = () => (
    <Grid item xs={2} style={{ display: "flex", alignItems: "flex-end" }}>
      <PrimaryButton
        label={t(`general.submit`)}
        alignment="left"
        onClick={() => {
          if (
            reportSubType === "complete_observation" ||
            reportSubType === "ihtimam_total_performance"
          ) {
            let groupByLength = 0;
            switch (selectedGroupBy.value) {
              case "directorate":
                groupByLength = selectedDirectorate.length;
                break;
              case "company":
                groupByLength = selectedContractorCompanies.length;
                break;
              case "location":
                groupByLength = selectedLocation.length;
                break;
              case "unit":
                groupByLength = selectedUnit.length;
                break;
              case " discipline":
                groupByLength = selectedDiscipline.length;
                break;

              default:
                break;
            }
            if (groupByLength > 1) {
              setConfirmModal(true);
            } else {
              submitGetChartData();
            }
          } else if (selectedBehaviours.length === behaviours.length) {
            setWarningModal(true);
          } else {
            submitGetChartData();
          }
        }}
        disabled={loading}
      />
    </Grid>
  );

  const renderPage2 = () => (
    <ViewReport
      companyNumber={companyNumber}
      reportType={reportType}
      chartInfo={chartInfo}
      fromDate={moment(fromDate).format("DD-MM-YYYY")}
      toDate={moment(toDate).format("DD-MM-YYYY")}
      contractorCompanies={selectedContractorCompanies}
      businessUnits={selectedBusinessUnit}
      includeActionItem={includeActionItem}
      includeChild={includeChild}
      locations={selectedLocation}
      disciplines={selectedDiscipline}
      units={selectedUnit}
      cardOwner={selectedCardOwner}
      timeIntervalCreate={selectedTimeInterval}
      behaviours={selectedBehaviours}
      behaviourTypes={selectedBehaviourType}
      observationLinkedTo={selectedObservationLinkedTo}
      subType={reportSubType}
      lsrType={selectedLsrType}
      rootCause={selectedRootCause}
      groupBy={selectedGroupBy}
      observerType={selectedObserverType}
      byPassedNonBypassed={selectedBypassedNonBypassed}
      cardStatus={selectedCardStatus}
      directorates={selectedDirectorate}
    />
  );

  const renderCreationView = () => (
    <Grid container spacing={2}>
      {renderReportType()}
      {renderReportSubType()}
      {reportSubType === "" && renderNoReportSelected()}
      {reportSubType === "total_observation_report" && renderTotalObservation()}
      {/* {reportSubType === "observation_feedback" && renderObservationFeedback()} */}
      {/* {reportSubType === "observer_type" && renderObservationType()} */}
      {reportSubType === "observation_summary" && renderObservationSummary()}
      {reportSubType === "complete_observation" && renderCompleteObservation()}
      {/* {reportSubType === "observation_target" && renderObservationTarget()} */}
      {reportSubType === "behavioral_trend" && renderBehavioralTrend()}
      {reportSubType === "unit_performance" && renderUnitPerformance()}
      {reportSubType === "kpi_report" && renderKpiReport()}
      {/* {reportSubType === "percent_knowledge_reports" &&
        renderPercentKnowledgeReports()} */}
      {reportSubType === "ihtimam_total_performance" &&
        renderIhtimamTotalPerformanceReports()}
      {reportSubType === "action_close_out_reports" &&
        renderActionCloseOutReports()}
      {reportSubType === "total_lsr_report" && renderTotalLSR()}
      {reportSubType === "lsr_violation_report" && renderLSRViolation()}
      {reportSubType === "behavior_linked_lsr_report" &&
        renderBehaviourLinkedLSR()}
      {/* {reportSubType === "lsr_per_bu" && renderLSRPerBU()} */}
      {reportSubType === "lsr_type_classification_report" &&
        renderLSRTypeClassification()}
      {reportSubType === "root_cause_against_observation" &&
        renderRootCauseAgainstObservation()}
      {reportSubType === "root_cause_trend" && renderRootCauseTrend()}
      {reportSubType === "root_cause_with_behaviour" &&
        renderRootCauseWithBehaviour()}
      {/* {reportSubType === "card_count" && renderCardCount()} */}
      {/* {reportSubType === "participation_percentage" &&
        renderParticipationPercentage()} */}
      {/* {reportSubType === "employee_card_count" && renderEmployeeCardCount()} */}
      {reportSubType === "directorate_report" && renderRootCauseTrend()}
      {reportSubType !== "" && renderSubmitButton()}
    </Grid>
  );

  const renderConfirmModal = () => (
    <InfoBox
      disabled={false}
      open={confirmModal}
      title={t("confirm")}
      content={
        <Typography
          variant="body1"
          style={{ color: theme.palette.primary.red, fontWeight: "bold" }}
        >
          {t(`reporting.warning`)}
        </Typography>
      }
      minWidth="40vw"
      handleClose={() => setConfirmModal(false)}
      buttonLabel={t("confirm")}
      handleAction={() => {
        setConfirmModal(false);
        submitGetChartData();
      }}
    />
  );

  const renderWarningModal = () => (
    <InfoBox
      disabled={false}
      open={warningModal}
      title={t("confirm")}
      content={
        <Typography
          variant="body1"
          style={{ color: theme.palette.primary.red, fontWeight: "bold" }}
        >
          {t(`reporting.warningBehaviour`)}
        </Typography>
      }
      minWidth="40vw"
      handleClose={() => setWarningModal(false)}
      buttonLabel={t("confirm")}
      handleAction={() => {
        setWarningModal(false);
        submitGetChartData();
      }}
    />
  );

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1, null);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setCardVerifier(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setCardVerifier([]);
    }
  };

  const submitGetChartData = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("reporting.createLoading")
    );

    try {
      if (!requiredFields[reportSubType]) {
        // eslint-disable-next-line no-throw-literal
        throw "Please provide a valid report type.";
      }

      if (!moment(fromDate).isValid()) {
        throw t("reporting.errors.invalidFrom");
      }

      if (!moment(toDate).isValid()) {
        throw t("reporting.errors.invalidTo");
      }

      if (requiredFields[reportSubType].required.includes("fromTo")) {
        if (!toDate || !fromDate) {
          throw t("reporting.errors.noDate");
        }

        if (toDate < fromDate) {
          throw t("reporting.errors.dateError");
        }
      }

      const input_reportType = reportSubType;
      const input_fromDate = moment(fromDate).startOf("day").toISOString();
      const input_toDate = moment(toDate).endOf("day").toISOString();
      const input_businessUnit =
        selectedBusinessUnit?.length > 0
          ? JSON.stringify(selectedBusinessUnit?.map((item) => item.value))
          : [];
      // const input_directorate= reportSubType === "behavioral_trend" || reportSubType === "unit_performance" ? [selectedDirectorate.value] : selectedDirectorate?.length > 0 ? JSON.stringify(selectedDirectorate?.map((item) => item.value)) : undefined;
      const input_directorate =
        selectedDirectorate?.length > 0
          ? JSON.stringify(selectedDirectorate?.map((item) => item.value))
          : [];
      const input_contractorCompanies =
        reportSubType === "observation_target" ||
        reportSubType === "ithimam_total_performance_reports"
          ? [selectedContractorCompanies.value]
          : selectedContractorCompanies?.length > 0
          ? JSON.stringify(
              selectedContractorCompanies?.map((item) => item.value)
            )
          : [];
      const input_location =
        selectedLocation?.length > 0
          ? JSON.stringify(selectedLocation?.map((item) => item.value))
          : [];
      const input_unit =
        selectedUnit?.length > 0
          ? JSON.stringify(selectedUnit?.map((item) => item.value))
          : [];
      const input_discipline =
        selectedDiscipline?.length > 0
          ? JSON.stringify(selectedDiscipline?.map((item) => item.value))
          : [];
      const input_includeActionItem = includeActionItem;
      const input_includeChild = includeChild;
      const input_cardOwner = selectedCardOwner;
      const input_timeInterval = selectedTimeInterval?.value || null;
      const input_behaviours =
        selectedBehaviours?.length > 0
          ? JSON.stringify(selectedBehaviours?.map((item) => item.value))
          : undefined;
      const input_behaviourType = selectedBehaviourType;
      const input_observationLinkedTo = JSON.stringify(
        selectedObservationLinkedTo?.map((item) => item.value)
      );
      const input_subType = selectedSubType;
      const input_lsrType =
        selectedLsrType?.length > 0
          ? JSON.stringify(selectedLsrType?.map((item) => item.value))
          : [];
      const input_rootCause =
        selectedRootCause?.length > 0
          ? JSON.stringify(selectedRootCause?.map((item) => item.value))
          : [];
      const input_groupBy = selectedGroupBy?.value
        ? JSON.stringify([selectedGroupBy.value])
        : [];
      const input_observerType = selectedObserverType.value;
      const input_bypassedNonBypassed = selectedBypassedNonBypassed;
      const input_cardStatus =
        selectedCardStatus?.length > 0
          ? JSON.stringify(selectedCardStatus?.map((item) => item.value))
          : [];
      const input_cardVerifiers =
        selectedCardVerifier?.length > 0
          ? JSON.stringify(selectedCardVerifier?.map((item) => item.id))
          : [];
      const input_raisedByUsers =
        selectedRaisedByUsers?.length > 0
          ? JSON.stringify(selectedRaisedByUsers?.map((item) => item.id))
          : [];

      //  VALIDATION ENGINE
      //  DIRECTORATE CHECK
      if (
        requiredFields[input_reportType].required.includes("directorate") &&
        (input_directorate?.length === 0 || input_directorate === undefined)
      ) {
        throw t("reporting.errors.directorate");
      }

      //  CARD STATUS CHECK
      if (
        requiredFields[input_reportType].required.includes("cardStatus") &&
        (input_cardStatus?.length === 0 || input_cardStatus === undefined)
      ) {
        throw t("reporting.errors.cardStatus");
      }

      //  TIME INTERVAL CHECK
      if (
        requiredFields[input_reportType].required.includes("timeInterval") &&
        !input_timeInterval
      ) {
        throw t("reporting.errors.timeInterval");
      }

      //  BEHAVIOR CHECK
      if (
        requiredFields[input_reportType].required.includes("behavior") &&
        (input_behaviours?.length === 0 || input_behaviours === undefined)
      ) {
        throw t("reporting.errors.behavior");
      }

      let filterObj = {
        reportType: input_reportType,
        startDate: input_fromDate,
        endDate: input_toDate,
        buId: input_businessUnit,
        directorateIds: input_directorate,
        companyIds: input_contractorCompanies,
        locationIds: input_location,
        unitIds: input_unit,
        disciplineIds: input_discipline,
        includeActionItem: input_includeActionItem,
        includeChild: input_includeChild,
        cardOwners: input_cardOwner,
        cardVerifiers: input_cardVerifiers,
        timeInterval: input_timeInterval,
        behaviorIds: input_behaviours,
        behaviorType: input_behaviourType,
        observationLinkedTo: input_observationLinkedTo,
        subType: input_subType,
        lsrType: input_lsrType,
        rootCauseIds: input_rootCause,
        groupBy: input_groupBy,
        observerType: input_observerType,
        bypassedNonBypassed: input_bypassedNonBypassed,
        cardStatus: input_cardStatus,
        raisedByUsers: input_raisedByUsers,
      };

      const data = await getReportData(reportSubType, filterObj);
      const addChartType = { ...data, type: reportSubType };
      setChartInfo(addChartType);
      setPage(2);
    } catch (err) {
      if (err != "confirmModal") {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  return (
    <Page title={t("reporting.createReport")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {page === 2 && (
              <HeaderBackButton
                backFunction={() => {
                  toast.remove();
                  setPage(1);
                  setLoading(false);
                }}
              />
            )}
            <Typography variant="h4">{t("reporting.createReport")}</Typography>
          </div>
        </Box>
        {page === 1 && renderCreationView()}
        {page === 2 && renderPage2()}
        {page === 3 && null}
        {renderConfirmModal()}
        {renderWarningModal()}
      </Container>
    </Page>
  );
}
