export const defaultObservationAttr = "observer+observedAt+status+location";

export const observationColumnValueDefaults = [
  {
    label: "Observer",
    value: "observer",
  },
  {
    label: "Observation date",
    value: "observedAt",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Location",
    value: "location",
  },
];

export const tasksColumnDefaults = [
  { field: "id", headerName: "ID", width: 200 },
  { field: "title", headerName: "Name", width: 200 },
  { field: "type", headerName: "Type", width: 300 },
  { field: "description", headerName: "Description", width: 400 },
  {
    field: "taskDate",
    headerName: "Task date",
    width: 200,
  },
];

export const filterParamDefaults = {
  pageSize: 10,
  pageNo: 1,
  searchTerm: "",
  sort: null,
  myObservations: {
    type: "ALL",
    startTargetDate: null,
    endTargetDate: null,
    observationType: "UNSAFE",
    attr: defaultObservationAttr,
    columnValues: [],
  },
  allObservations: {
    type: "ALL",
    startTargetDate: null,
    endTargetDate: null,
    observationType: "UNSAFE",
    attr: defaultObservationAttr,
    columnValues: [],
  },
  myActions: {
    type: "ALL",
    startTargetDate: null,
    endTargetDate: null,
    observationType: "UNSAFE",
    attr: defaultObservationAttr,
    columnValues: [],
  },
  myTasks: {
    type: "ALL",
    startTargetDate: null,
    endTargetDate: null,
    observationType: "UNSAFE",
    attr: defaultObservationAttr,
    columnValues: [],
  },
};

const today = new Date();
export const filterModalDefaults = {
  open: false,
  tab: "myObservations",
  myObservations: {
    type: "ALL",
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "UNSAFE",
    attr: defaultObservationAttr,
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
  allObservations: {
    type: "ALL",
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "UNSAFE",
    attr: defaultObservationAttr,
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
  myActions: {
    type: "ALL",
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "UNSAFE",
    attr: "",
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
  myTasks: {
    type: "ALL",
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "UNSAFE",
    attr: "",
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
};

export const observationFilterTypesDefault = [
  { label: "OBSERVATION SUBMITTED", value: "OBSERVATION SUBMITTED" },
  { label: "OBSERVATION RESUBMITTED", value: "OBSERVATION RESUBMITTED" },
  { label: "CLOSED BECAUSE SAFE", value: "CLOSED BECAUSE SAFE" },
  {
    label: "SUPERVISOR CLOSED NO ACTION",
    value: "SUPERVISOR CLOSED NO ACTION",
  },
  { label: "RETURNED TO SUPERVISOR", value: "RETURNED TO SUPERVISOR" },
  { label: "ACTION ASSIGNED", value: "ACTION ASSIGNED" },
  { label: "MARKED AS NEAR MISS", value: "MARKED AS NEAR MISS" },
  { label: "OBSERVATION CLOSED", value: "OBSERVATION CLOSED" },
  { label: "ACTION COMPLETE", value: "ACTION COMPLETE" },
  { label: "RETURNED TO OBSERVER", value: "RETURNED TO OBSERVER" },
  { label: "SUPERVISOR REJECTED", value: "SUPERVISOR REJECTED" },
  { label: "ACTION RE-ASSIGNED", value: "ACTION RE-ASSIGNED" },
  { label: "ACTION PERFORMED REJECTED", value: "ACTION PERFORMED REJECTED" },
  { label: "ALL", value: "ALL" },
];

export const actionFilterTypesDefault = [
  // { label: "REJECTED", value: "REJECTED" },
  { label: "RETURNED", value: "RETURNED" },
  { label: "RE-ASSIGNED", value: "RE-ASSIGNED" },
  // { label: "COMPLETED", value: "COMPLETED" },
  { label: "PENDING", value: "PENDING" },
  { label: "APPROVED", value: "APPROVED" },
  { label: "ALL", value: "ALL" },
];

export const defaultFiltersPayload = {
  myObservationsFilterType: "ALL",
  allObservationsFilterType: "ALL",
  myActionsFilterType: "ALL",
  myObservationsFilterObsType: "UNSAFE",
  allObservationsFilterObsType: "UNSAFE",
  myActionsFilterObsType: "UNSAFE",
  myObservationsFilterDateFlag: false,
  allObservationsFilterDateFlag: false,
  myActionsFilterDateFlag: false,
  myObservationsFilterDateStart: today,
  allObservationsFilterDateStart: today,
  myActionsFilterDateStart: today,
  myObservationsFilterDateEnd: today,
  allObservationsFilterDateEnd: today,
  myActionsFilterDateEnd: today,
  myObservationsAttr: defaultObservationAttr,
  allObservationsAttr: defaultObservationAttr,
  myActionsAttr: defaultObservationAttr,
  myObservationsFilterLocation: "",
  myObservationsFilterDirectorate: "",
  myObservationsFilterUnit: "",
  myObservationsFilterCompany: "",
  allObservationsFilterLocation: "",
  allObservationsFilterDirectorate: "",
  allObservationsFilterUnit: "",
  allObservationsFilterCompany: "",
  myActionsFilterLocation: "",
  myActionsFilterDirectorate: "",
  myActionsFilterUnit: "",
  myActionsFilterCompany: "",
};
