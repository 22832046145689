import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { returnPositive } from "../../utils/general";
import { pxToRem } from "../../theme/typography";
import { recolor } from "../../utils/general";
import { useTheme } from "@mui/material/styles";
//  MUI
import { Grid, Stack, Typography, Box, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import QualityIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import LSRIcon from "@mui/icons-material/SupportOutlined";
import PeopleIcon from "@mui/icons-material/AccessibilityNewOutlined";
import BehaviorIcon from "@mui/icons-material/SportsHandballOutlined";
import LocationIcon from "@mui/icons-material/PlaceOutlined";
import ToolsIcon from "@mui/icons-material/BuildOutlined";
//  COMPONENTS
import DefaultTable from "../../components/tables/DefaultTable";
import SelectInput from "../../components/inputs/SelectInput";
import {
  IconButtonStyled,
  IconStyled,
} from "../observation/ListObservations/styledComponents";
//  API
import {
  getSmartAlertsApi,
  getSmartAlertsTodayApi,
} from "../../api/ai_dashboard";
import {
  getCompanyFilters,
  getLocationsAndUnitsFilters,
  getDisciplineFilters,
} from "./utils";

const StyledLabelCard = styled(Stack)(
  ({ theme, hasicon, isred, color, active, disabled }) => ({
    height: 80,
    padding: "16px",
    borderRadius: "6px",
    cursor: disabled ? "wait" : "pointer",
    background: disabled ? recolor(color, 20) : color,
    "&:hover": {
      background: recolor(color, 20),
    },
    border: active ? `4px solid ${recolor(color, -50)}` : "none",
  })
);

const textStyling = {
  lineHeight: 1.5,
  fontSize: pxToRem(15),
  padding: "12px 0",
};

const filterLabels = [
  "QUALITY",
  "LSR",
  "PEOPLE",
  "BEHAVIOUR",
  "LOCATION",
  "TOOLS",
];

const defaultAlertsDictionary = {
  QUALITY: 0,
  LSR: 0,
  PEOPLE: 0,
  BEHAVIOUR: 0,
  LOCATION: 0,
  TOOLS: 0,
};

const filterParamDefaults = {
  pageSize: 10,
  pageNo: 1,
};

export default function SmartAlerts({ directorateFilters }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  //  Filters
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [directorateFilter, setDirectorateFilter] = useState("all");
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyFilter, setCompanyFilter] = useState("all");
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [locationFilter, setLocationFilter] = useState("all");
  const [unitFilter, setUnitFilter] = useState("all");
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [activeLabel, setActiveLabel] = useState("LSR");
  const [alertsDictionary, setAlertsDictionary] = useState(
    defaultAlertsDictionary
  );
  const [rows, setRows] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterParams, setFilterParams] = useState(filterParamDefaults);

  const columnDefaults = [
    {
      field: "category",
      headerName: "Category",
      width: 100,
      sortable: false,
    },
    {
      field: "companyName",
      headerName: "Contractor",
      width: 130,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,
      sortable: false,
      renderCell: (params) => moment(params.row.createdAt).format("DD/MM/YYYY"),
    },
    {
      field: "alert",
      headerName: "Alert",
      width: "20vw",
      sortable: false,
      renderCell: (params) => returnText(params.row),
    },
  ];

  const bigIconStyle = {
    height: 24,
    width: 24,
    fill: theme.palette.primary.white,
  };

  const iconStyle = {
    height: 25,
    width: 25,
    fill: theme.palette.primary.white,
  };

  const iconContainerStyling = {
    height: 45,
    width: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.orange,
    borderRadius: "6px",
  };

  const renderLabelCards = () => (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      {filterLabels.map((label) => (
        <Grid item xs={12} sm={2} id={`alert-card-${label}`}>
          <StyledLabelCard
            disabled={loading || tableLoading}
            color={returnColor(label)}
            direction="row"
            alignItems="center"
            spacing={2}
            onClick={
              loading || tableLoading ? null : () => handleSetLabel(label)
            }
            active={activeLabel === label}
          >
            {returnIcon(label, "big")}
            <Stack direction="column">
              <Typography variant="h6plain" color={theme.palette.primary.white}>
                {t(`dashboards.alerts.${label}`)}
              </Typography>
              <Typography variant="body1" color={theme.palette.primary.white}>
                {t("dashboards.alerts.alerts", {
                  number: alertsDictionary[label],
                })}
              </Typography>
            </Stack>
          </StyledLabelCard>
        </Grid>
      ))}
    </Grid>
  );

  const handleDirectorate = async (directorate) => {
    if (directorate !== directorateFilter) {
      setDirectorateFilter(directorate);
      setCompanyFilter("all");
      setLocationFilter("all");
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const handleCompany = async (company) => {
    if (company !== companyFilter) {
      setCompanyFilter(company);
      setLocationFilter("all");
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const handleLocation = async (location) => {
    if (location !== locationFilter) {
      setLocationFilter(location);
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const renderFilters = () => (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12} sm={2} md={2} style={{ paddingLeft: 0 }}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.directorateTitle")}
          disabled={loading || directorateDropdown.length < 1}
          value={directorateFilter}
          handler={(e) => handleDirectorate(e.target.value)}
          options={directorateDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.companyTitle")}
          disabled={
            loading || companyDropdown.length < 1 || directorateFilter === "all"
          }
          value={companyFilter}
          handler={(e) => handleCompany(e.target.value)}
          options={companyDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.locationTitle")}
          disabled={
            loading || locationDropdown.length < 1 || companyFilter === "all"
          }
          value={locationFilter}
          handler={(e) => handleLocation(e.target.value)}
          options={locationDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.unitTitle")}
          disabled={
            loading || unitDropdown.length < 1 || locationFilter === "all"
          }
          value={unitFilter}
          handler={(e) => setUnitFilter(e.target.value)}
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.disciplineTitle")}
          disabled={
            loading || disciplineDropdown.length < 1 || locationFilter === "all"
          }
          value={disciplineFilter}
          handler={(e) => setDisciplineFilter(e.target.value)}
          options={disciplineDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} sx={{ pt: 0 }}>
        <Tooltip title={t("general.applyFilters")}>
          <IconButtonStyled
            disabled={loading}
            aria-label={"Apply filters"}
            onClick={getAllAlerts}
          >
            <IconStyled />
          </IconButtonStyled>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderAlertsTable = () => (
    <DefaultTable
      autoRowHeight
      rows={rows}
      columns={columnDefaults}
      loading={tableLoading}
      tableInfo={tableInfo}
      filterParams={filterParams}
      setFilterParams={setFilterParams}
      onRowClick={(a) => handleRowClick(a.row)}
    />
  );

  const handleRowClick = (a) => {
    switch (a.category) {
      case "QUALITY":
        navigate(`/dashboard/company-dashboard/quality/${a.companyId}`, {
          state: {
            alertStartDate: a.createdAt,
            companyName: a.companyName,
            tab: returnQualityTab(a.parameterContributor),
            directorateName: a.directorateName,
            directorateId: a.directorateId,
          },
        });
        break;

      case "LSR":
        navigate(`/dashboard/company-dashboard/lsrs/${a.companyId}`, {
          state: {
            alertStartDate: a.createdAt,
            companyName: a.companyName,
            directorateName: a.directorateName,
            directorateId: a.directorateId,
          },
        });
        break;

      case "PEOPLE":
      case "BEHAVIOUR":
      case "TOOLS":
      case "LOCATION":
        navigate(
          `/dashboard/directorate-dashboard/smartTrends/${a.directorateId}`,
          {
            state: {
              alertStartDate: a.createdAt,
              companyId: a.companyId,
              directorateName: a.directorateName,
              directorateId: a.directorateId,
            },
          }
        );
        break;

      default:
        break;
    }
  };

  const returnColor = (type) => {
    switch (type) {
      case "QUALITY":
        return "#067BC2";

      case "LSR":
        return "#84BCDA";

      case "PEOPLE":
        return "#F7A072";

      case "BEHAVIOUR":
        return "#F37748";

      case "TOOLS":
        return "#D56062";

      case "LOCATION":
        return "#57A773";

      default:
        return "#067BC2";
    }
  };

  const returnIcon = (type, size) => {
    if (size === "big") {
      switch (type) {
        case "QUALITY":
          return <QualityIcon style={bigIconStyle} />;

        case "LSR":
          return <LSRIcon style={bigIconStyle} />;

        case "PEOPLE":
          return <PeopleIcon style={bigIconStyle} />;

        case "BEHAVIOUR":
          return <BehaviorIcon style={bigIconStyle} />;

        case "TOOLS":
          return <ToolsIcon style={bigIconStyle} />;

        case "LOCATION":
          return <LocationIcon style={bigIconStyle} />;

        default:
          return null;
      }
    } else {
      switch (type) {
        case "QUALITY":
          return (
            <div
              style={{ ...iconContainerStyling, background: returnColor(type) }}
            >
              <QualityIcon style={iconStyle} />
            </div>
          );

        case "LSR":
          return (
            <div
              style={{ ...iconContainerStyling, background: returnColor(type) }}
            >
              <LSRIcon style={iconStyle} />
            </div>
          );

        case "PEOPLE":
          return (
            <div
              style={{ ...iconContainerStyling, background: returnColor(type) }}
            >
              <PeopleIcon style={iconStyle} />
            </div>
          );

        case "BEHAVIOUR":
          return (
            <div
              style={{ ...iconContainerStyling, background: returnColor(type) }}
            >
              <BehaviorIcon style={iconStyle} />
            </div>
          );

        case "TOOLS":
          return (
            <div
              style={{ ...iconContainerStyling, background: returnColor(type) }}
            >
              <ToolsIcon style={iconStyle} />
            </div>
          );

        case "LOCATION":
          return (
            <div
              style={{ ...iconContainerStyling, background: returnColor(type) }}
            >
              <LocationIcon style={iconStyle} />
            </div>
          );

        default:
          return null;
      }
    }
  };

  const returnText = (a) => {
    switch (a.category) {
      case "QUALITY":
        return (
          <Typography style={textStyling}>
            <Trans
              i18nKey="dashboards.alerts.quality"
              values={{
                companyName: a.companyName,
                scoreChange: returnPositive(a.qualityScoreChange),
                scoreChangeCrement:
                  a.qualityScoreChange > 0 ? "increased" : "decreased",
                contributorValue: returnPositive(a.parameterContributorValue),
                contributingCrement:
                  a.parameterContributorValue > 0 ? "increase" : "decrease",
                parameterContributor: a.parameterContributor,
              }}
              components={{
                1: <Box component="span" fontWeight="bold" />,
                2: (
                  <Box
                    component="span"
                    fontWeight="bold"
                    color={theme.palette.primary.red}
                  />
                ),
                3: (
                  <Box
                    component="span"
                    fontWeight="bold"
                    color={theme.palette.primary.red}
                  />
                ),
                4: <Box component="span" fontWeight="bold" />,
              }}
            />
          </Typography>
        );

      case "LSR":
        return (
          <Typography style={textStyling}>
            <Trans
              i18nKey={
                a.TotalCountChange
                  ? "dashboards.alerts.lsrs"
                  : "dashboards.alerts.lsrsNull"
              }
              values={{
                companyName: a.companyName,
                scoreChange: returnPositive(a.TotalCountChange),
                scoreChangeCrement:
                  a.TotalCountChange > 0 ? "increased" : "decreased",
                countDifference: returnPositive(a.LabelCountDifference),
                labels: a.Labels,
              }}
              components={{
                1: <Box component="span" fontWeight="bold" />,
                2: (
                  <Box
                    component="span"
                    fontWeight="bold"
                    color={theme.palette.primary.red}
                  />
                ),
                3: (
                  <Box
                    component="span"
                    fontWeight="bold"
                    color={theme.palette.primary.red}
                  />
                ),
                4: <Box component="span" fontWeight="bold" />,
              }}
            />
          </Typography>
        );

      case "PEOPLE":
      case "BEHAVIOUR":
      case "TOOLS":
      case "LOCATION":
        return (
          <Typography style={textStyling}>
            <Trans
              i18nKey={
                a.LabelCountChange
                  ? "dashboards.alerts.trends"
                  : "dashboards.alerts.trendsNull"
              }
              values={{
                companyName: a.companyName,
                texts: a.Texts,
                countChange: returnPositive(a.LabelCountChange),
                countDifference: returnPositive(a.LabelCountDifferenceNER),
                countChangeCrement:
                  a.LabelCountChange > 0 ? "increased" : "decreased",
                countDiffCrement:
                  a.LabelCountDifferenceNER > 0 ? "more" : "less",
              }}
              components={{
                1: <Box component="span" fontWeight="bold" />,
                2: <Box component="span" fontWeight="bold" />,
                3: (
                  <Box
                    component="span"
                    fontWeight="bold"
                    color={theme.palette.primary.red}
                  />
                ),
                4: (
                  <Box
                    component="span"
                    fontWeight="bold"
                    color={theme.palette.primary.red}
                  />
                ),
              }}
            />
          </Typography>
        );

      default:
        return null;
    }
  };

  const returnQualityTab = (p) => {
    switch (p) {
      case "Feedback and Behavior":
        return "feedbackBehavior";

      case "Feedback and suggested Rootcause":
        return "feedbackRoot";

      case "Appreciation":
        return "incidentPrevention";

      case "Low Effort Observation":
        return "lowEffort";

      default:
        return "overview";
    }
  };

  const getAllAlerts = async () => {
    try {
      setTableLoading(true);
      const rawData = await getSmartAlertsApi({
        pageSize: filterParams.pageSize,
        pageNo: filterParams.pageNo,
        type: activeLabel,
        ...(directorateFilter &&
          directorateFilter !== "all" && { directorateId: directorateFilter }),
        ...(companyFilter &&
          companyFilter !== "all" && { companyId: companyFilter }),
        ...(locationFilter &&
          locationFilter !== "all" && { locationId: locationFilter }),
        ...(unitFilter && unitFilter !== "all" && { unitId: unitFilter }),
        ...(disciplineFilter &&
          disciplineFilter !== "all" && { disciplineId: disciplineFilter }),
      });
      if (rawData && rawData.data) {
        const rawAlerts = rawData.data.map((r) => {
          return {
            ...r,
            companyName: r.Company?.name || "No contractor",
            directorateName: r.Directorate?.name || "No directorate",
          };
        });

        setRows(rawAlerts);
        setTableInfo({
          totalPages: rawData.totalPages,
          totalCount: rawData.totalItems,
        });
      } else {
        setRows([]);
        setTableInfo({ totalPages: 0, totalCount: 0 });
      }
      setTableLoading(false);
    } catch (err) {
      setRows([]);
      setTableLoading(false);
    }
  };

  const getAlertsToday = async () => {
    try {
      const rawData = await getSmartAlertsTodayApi();
      setAlertsDictionary({ ...defaultAlertsDictionary, ...rawData });
    } catch (err) {}
  };
  const handleSetLabel = (val) => {
    if (activeLabel === val) {
      setActiveLabel(null);
    } else {
      setActiveLabel(val);
    }
  };

  useEffect(() => {
    getAlertsToday();
  }, []);

  useEffect(() => {
    getAllAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLabel, filterParams.pageSize, filterParams.pageNo]);

  useEffect(() => {
    setDirectorateDropdown(directorateFilters);
    setLoading(false);
  }, [directorateFilters]);

  useEffect(() => {
    if (directorateFilter && directorateFilter !== "all") {
      setCompanyFilter("all");
      getCompanyFilters({
        setLoading,
        directorateFilter,
        setCompanyDropdown,
      });
    }
  }, [directorateFilter]);

  useEffect(() => {
    if (companyFilter && companyFilter !== "all") {
      setLocationFilter("all");
      getLocationsAndUnitsFilters({
        setLoading,
        companyFilter,
        setLocationDropdown,
        setUnitDropdown,
      });
    }
  }, [companyFilter]);

  useEffect(() => {
    if (
      companyFilter &&
      companyFilter !== "all" &&
      locationFilter &&
      locationFilter !== "all"
    ) {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter,
        locationFilter,
        unitFilter,
        setDisciplineDropdown,
      });
    }
  }, [companyFilter, locationFilter, unitFilter]);

  return (
    <Stack spacing={1} direction="column">
      {renderLabelCards()}
      {renderFilters()}
      {renderAlertsTable()}
    </Stack>
  );
}
