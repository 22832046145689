import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import {
  getTraineeUser,
  getTrainingPlans,
  optForTraining,
} from "../../api/implementation";
import moment from "moment";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PageLoader from "../../components/PageLoader";
import { displayToast } from "../../utils/general";

function TrainingDetails() {
  const { t } = useTranslation();
  const { trainingId } = useParams();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [data, setData] = useState({});
  const [userData, setUserData] = useState(null);
  const handleOptFor = async () => {
    let tempBody = {
      planId: data.planId,
      TrainingPlansId: data.id,
    };
    try {
      const optForRequest = await optForTraining(tempBody);
      if (optForRequest) {
        displayToast(setLoading, "success", optForRequest.data , "errorLoadingTrainingDetails");
        getTrainingById();
      }
    } catch (e) {
      displayToast(setLoading, "error", e, "errorLoadingTrainingDetails");
    }
  };

  const getTrainingById = async () => {
    setLoading(true);
    try {
      let queryUser = `trainingPlanId=${trainingId}`;
      const user = await getTraineeUser(queryUser);
      if (user !== null) {
        setUserData(user);
      }
      let query = `Id=${trainingId}`;
      let getTraining = await getTrainingPlans(query);
      if (getTraining.result?.length > 0) {
        setData(getTraining.result[0]);
      }
      setLoading(false);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.trainingPlanDetails"),
        "errorLoadingTrainingDetails"
      );
    }
  };

  useEffect(() => {
    getTrainingById();
    // getUsersByCompanyId();
  }, []);

  return !loading ? (
    <>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">{t("training.trainingList")}</Typography>
          </div>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Stack
              spacing={4}
              style={{
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                border: "1px solid #C2C2C2",
                padding: "18px",
                borderRadius: "4px",
              }}
            >
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingType`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.TrainingType?.name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingPeriod`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {`${moment(data?.startDate).format(
                      "DD/MM/YYYY"
                    )} to ${moment(data?.endDate).format("DD/MM/YYYY")}`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t("training.tableHeaders.location")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.location?.name ?? '-'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t("training.tableHeaders.totalSeats")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.numTotal}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t("training.tableHeaders.waitingSeats")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.numAwaiting}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t("training.tableHeaders.availableSeats")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.numTotal - data?.attendingCount}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingDescription`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.description}
                  </Typography>
                </Stack>
              </Grid>
              {userData && <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                  {t("training.userStatus")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {!data?.concluded && userData?.isOpted && userData?.isWaiting && userData.userStatus==='pending' && userData?.markedDate ==null && 'User in waiting list'}
                    {!data?.concluded && userData?.isOpted && userData?.isWaiting===null && userData.userStatus==='pending' && userData?.markedDate ==null && 'Requested to register for this training'}
                    {!data?.concluded && userData?.isOpted===null && userData?.isWaiting===null && userData.userStatus==='pending' && userData?.markedDate ==null && 'User already invited'}
                    {!data?.concluded && userData?.userStatus==='Accepted' && userData?.markedDate ==null && 'User accepted training'}
                    {!data?.concluded && userData?.userStatus==='Rejected' && userData?.markedDate ==null && 'User rejected training'}
                    {data?.concluded && userData?.markedDate && 'Training concluded and user has been marked qualified'}
                    {data?.concluded && userData?.markedDate === null && 'Training concluded and marking qualification pending'}
                    {/* {userData===null && ' - '} */}
                  </Typography>
                </Stack>
              </Grid>}
            </Stack>
          </Grid>
          {!userData && !data?.concluded && (
            <Grid item xs={12}>
              <PrimaryButton
                disabled={false}
                alignment="left"
                label={t("training.optTraining")}
                icon={<CheckCircleOutlineIcon />}
                onClick={() => handleOptFor()}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  ) : (
    <PageLoader />
  );
}

export default TrainingDetails;
