/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _, { set } from "lodash";
import moment from "moment";
import { displayToast } from "../../../utils/general";
import useAuth from "../../../context/useAuth";
import {
  observationDetailsDefault,
  additionalDetailsDefault,
  behaviorDetailsDefault,
  infoBoxDefaults,
} from "./defaults";

// material
import {
  Box,
  Grid,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// components
import Page from "../../../components/Page";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import RadioSet from "../../../components/buttons/RadioSet";
import CheckboxSet from "../../../components/buttons/CheckboxSet";
import FormDivider from "../../../components/FormDivider";
import TextInput from "../../../components/inputs/TextInput";
import SelectInput from "../../../components/inputs/SelectInput";
import DateTimeInput from "../../../components/inputs/DateTimeInput";
import BehaviorInput from "../../../components/inputs/BehaviorInput";
import FileUploader from "../../../components/inputs/FileUploader";
import RedCheckbox from "../../../components/buttons/RedCheckbox";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import UnsafeConditionInput from "../../../components/inputs/UnsafeConditionInput";
import DoneIcon from "@mui/icons-material/Done";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
import InfoBox from "../../../components/InfoBox";

// api
import { getCompaniesUsingLocationApi } from "../../../api/company";
import { getLocationsForObsApi } from "../../../api/location";
import {
  getObsCriticalBehaviorsApi,
  getObsOtherBehaviorsApi,
  getBehaviorCategoriesApi,
} from "../../../api/behaviors";
import { getUnitsTiedApi } from "../../../api/unit";
import {
  getObsDisciplinesApi,
  getCompanyDisciplinesApi,
  getObsOtherDisciplinesApi,
} from "../../../api/disciplines";
import { getObsMainConditionsApi } from "../../../api/unsafeConditions";
import {
  editObservationApi,
  getObservationDetailsApi,
  submitObservationApi,
} from "../../../api/observation";
import { getCurrentUserApi } from "../../../api/auth";
import { searchUsersApi } from "../../../api/users";
import { hasAccess } from "../../../utils/acl";

// ----------------------------------------------------------------------

export default function ObservationApp() {
  const { id } = useParams();
  const { access } = useAuth();
  const { t } = useTranslation();
  const timeout = useRef();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [infoBoxDetails, setInfoBoxDetails] = useState(infoBoxDefaults);

  //  User details
  const [userDetails, setUserDetails] = useState(null);
  const [behalfId, setBehalfId] = useState("");
  const [editBehalfDetails, setEditBehalfDetails] = useState({
    id: null,
    label: "",
  });
  const [userDropdown, setUserDropdown] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  // Payload details
  const [observerDetails, setObserverDetails] = useState({
    submissionOptions: {
      anonymous: false,
      behalf: false,
    },
    observerName: "",
    observerType: hasAccess(access, "isSupervisor")
      ? "SUPERVISOR"
      : "NON-SUPERVISOR",
    company: "",
    location: "",
    unit: "",
  });
  const [observationDetails, setObservationDetails] = useState({
    ...observationDetailsDefault,
    date: new Date(),
  });
  const [additionalDetails, setAdditionalDetails] = useState(
    additionalDetailsDefault
  );

  const [behaviorDetails, setBehaviorDetails] = useState(
    behaviorDetailsDefault
  );
  const [selectOtherBehaviors, setSelectOtherBehaviors] = useState(false);
  const [criticalBehaviors, setCriticalBehaviors] = useState({ data: [] });
  const [unsafeConditions, setUnsafeConditions] = useState([]);
  const [safeObservation, setSafeObservation] = useState(true);

  //  Dropdown options
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [otherBehaviorDropdown, setOtherBehaviorDropdown] = useState([]);
  const [rawUnsafeConditions, setRawUnsafeConditions] = useState([]);
  const [categoriesDropdown, setCategoriesDropdown] = useState([]);

  const [obsCompanyDropdown, setObsCompanyDropdown] = useState([]);
  const [obsDirectorateDropdown, setObsDirectorateDropdown] = useState([]);
  const [obsUnitDropdown, setObsUnitDropdown] = useState([]);

  //  Edit observation states
  const [validEdit, setValidEdit] = useState(false);
  const [oldDetails, setOldDetails] = useState(null);

  const [successBox, setSuccessBox] = useState({
    open: false,
    observationId: null,
  });

  const limitChar = 1000;

  const roleDropdownList = [
    {
      label: t("observations.observationApp.visitor"),
      value: "VISITOR",
    },
    {
      label: t("observations.observationApp.nonSupervisor"),
      value: "NON-SUPERVISOR",
    },
    ...(hasAccess(access, "isSupervisor")
      ? [
          {
            label: t("observations.observationApp.supervisor"),
            value: "SUPERVISOR",
          },
        ]
      : []),
  ];

  const handleTextInput = (e, field) => {
    if (e.target.value.toString().length <= limitChar) {
      setAdditionalDetails({
        ...additionalDetails,
        [field]: e.target.value,
      });
    }
  };

  const renderBehalfForm = () => (
    <>
      <Grid item xs={12} md={6}>
        <AutocompleteInput
          handler={handleSelectAUser}
          keyPressHandler={handleSearchUser}
          label={t(`observations.observationApp.observerName`)}
          loading={searchLoading}
          disabled={loading}
          options={userDropdown}
          {...(validEdit
            ? {
                value: editBehalfDetails,
              }
            : {})}
        />
      </Grid>
      {/* No longer using observer details */}
      {/* <Grid item xs={12} sm={6} md={6}>
        <SelectInput
          label={t(`observations.observationApp.observerType`)}
          value={observerDetails.observerType}
          disabled={loading}
          handler={(e) =>
            setObserverDetails({
              ...observerDetails,
              observerType: e.target.value,
            })
          }
          options={roleDropdownList}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SelectInput
          label={t(`observations.observationApp.location`)}
          value={observerDetails.location}
          disabled={loading}
          handler={(e) => handleLocationSelect("observer", e)}
          options={locationsDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SelectInput
          label={t(`observations.observationApp.companyDepartment`)}
          value={observerDetails.company}
          disabled={loading}
          handler={(e) => handleCompanySelect("observer", e)}
          options={obsCompanyDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SelectInput
          label={t(`observations.observationApp.directorate`)}
          value={observerDetails.directorate}
          disabled={loading}
          handler={(e) => handleDirectorateSelect("observer", e)}
          options={obsDirectorateDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SelectInput
          label={t(`observations.observationApp.unit`)}
          disabled={loading}
          value={observerDetails.unit}
          handler={(e) => handleUnitSelect("observer", e)}
          options={obsUnitDropdown}
          optional
        />
      </Grid> */}
    </>
  );

  const handleSubmissionOptions = (event) => {
    const observerDetailsTemp = { ...observerDetails };
    if (event.target.name === "anonymous") {
      if (event.target.checked) {
        setInfoBoxDetails({
          open: true,
          title: t("observations.observationApp.anonTitle"),
          content: t("observations.observationApp.anonMessage"),
          buttonLabel: t("confirm"),
          handleClose: () =>
            setInfoBoxDetails({ ...infoBoxDetails, open: false }),
          handleAction: () => {
            setObserverDetails({
              ...observerDetailsTemp,
              submissionOptions: {
                ...observerDetailsTemp.submissionOptions,
                anonymous: true,
              },
            });
            setInfoBoxDetails({ ...infoBoxDetails, open: false });
          },
        });
      } else {
        setObserverDetails({
          ...observerDetailsTemp,
          submissionOptions: {
            ...observerDetailsTemp.submissionOptions,
            anonymous: false,
          },
        });
      }
    } else {
      //  Behalf
      if (event.target.checked) {
        setObserverDetails({
          ...observerDetailsTemp,
          submissionOptions: {
            ...observerDetailsTemp.submissionOptions,
            [event.target.name]: event.target.checked,
          },
        });
      } else {
        //  Revert back to the old user details
        handlePrepopulateUser();
      }
    }
  };

  const handleGetCriticalBehavior = async (
    companyId,
    disciplineId,
    locationId,
    unitId,
    directorateId
  ) => {
    try {
      if (companyId && disciplineId) {
        const criticalBehaviorsData = await getObsCriticalBehaviorsApi(
          companyId,
          disciplineId,
          locationId,
          unitId,
          directorateId
        );

        if (criticalBehaviorsData) {
          if (validEdit && oldDetails) {
            const criticalBehaviorsListed = [];
            criticalBehaviorsData.forEach((b) => {
              const foundOldValue = oldDetails.behaviors.find(
                (f) => f.id === b.id
              );

              criticalBehaviorsListed.push({
                id: b.id,
                label: b.name,
                labelAR: b.nameAR,
                safe: foundOldValue
                  ? foundOldValue.ObservationBehaviors.safe
                  : 0,
                unsafe: foundOldValue
                  ? foundOldValue.ObservationBehaviors.unsafe
                  : 0,
              });
            });
            setCriticalBehaviors({ data: criticalBehaviorsListed });
          } else {
            const criticalBehaviorsListed = criticalBehaviorsData.map((b) => {
              return {
                id: b.id,
                label: b.name,
                labelAR: b.nameAR,
                safe: null,
                unsafe: null,
                type: "CRITICAL",
              };
            });
            setCriticalBehaviors({ data: criticalBehaviorsListed });
          }
        } else {
          setCriticalBehaviors({ data: [] });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeObservationType = (e) => {
    setBehaviorDetails(behaviorDetailsDefault);
    setUnsafeConditions([]);
    setObservationDetails({
      ...observationDetails,
      observationType: e.target.value,
    });
  };

  const handleGetOtherBehaviors = async (categoryId) => {
    setOtherBehaviorDropdown([]);
    const otherBehaviorsDataRaw = await getObsOtherBehaviorsApi(categoryId);
    const otherBehaviorsData = otherBehaviorsDataRaw.data;
    const currentOtherIds = behaviorDetails.observedBehaviors.map((obj) => {
      return obj.id;
    });

    const currentCriticalIds = criticalBehaviors.data.map((obj) => {
      return obj.id;
    });

    const existingBehaviorIds = [...currentCriticalIds, ...currentOtherIds];

    const otherBehaviorOptions = _.filter(otherBehaviorsData, (obj, i) => {
      return !existingBehaviorIds.includes(obj.id);
    }).map((opt) => {
      return {
        label: opt.name,
        value: opt.id,
        labelAR: opt.nameAR,
        type: "OTHER",
      };
    });

    setOtherBehaviorDropdown(otherBehaviorOptions);
    return otherBehaviorOptions;
  };

  const handleAddNewBehavior = (newB) => {
    if (newB) {
      const toBeAdded = {
        id: newB.value,
        label: newB.label,
        labelAR: newB.labelAR,
        safe: null,
        unsafe: null,
        type: newB.type,
      };

      const updatedArray = [...behaviorDetails.observedBehaviors];
      updatedArray.push(toBeAdded);

      const updatedOptions = _.filter(otherBehaviorDropdown, (b, i) => {
        return b.value !== newB.value;
      });

      setOtherBehaviorDropdown(updatedOptions);

      setBehaviorDetails({
        ...behaviorDetails,
        observedBehaviors: updatedArray,
      });
    }
  };

  const handleRemoveBehavior = (row) => {
    const updatedBehaviors = _.filter(
      [...behaviorDetails.observedBehaviors],
      (b, i) => {
        return b.id !== row.id;
      }
    );

    const updatedDropdown = otherBehaviorDropdown;
    updatedDropdown.push({
      label: row.label,
      value: row.id,
      labelAR: row.labelAR,
    });

    setBehaviorDetails({
      ...behaviorDetails,
      observedBehaviors: updatedBehaviors,
    });
    setOtherBehaviorDropdown(updatedDropdown);
  };

  const handleBehaviorNumberChange = (key, type, num) => {
    const tempBehaviors = [...behaviorDetails.observedBehaviors];
    let safeFlag = true;
    tempBehaviors.forEach((b, index) => {
      if (
        (b.unsafe && b.unsafe > 0) ||
        (type === "unsafe" && parseInt(num) > 0)
      ) {
        safeFlag = false;
      }
      if (b.id === key) {
        tempBehaviors[index] = {
          ...tempBehaviors[index],
          [type]: parseInt(num),
        };
      }
    });

    setBehaviorDetails({
      ...behaviorDetails,
      observedBehaviors: tempBehaviors,
    });
    setSafeObservation(safeFlag);
  };

  const handleCriticalBehaviorChange = (key, type, num) => {
    const tempBehaviors = [...criticalBehaviors.data];
    let safeFlag = true;
    tempBehaviors.forEach((b, index) => {
      if (
        (b.unsafe && b.unsafe > 0) ||
        (type === "unsafe" && parseInt(num) > 0)
      ) {
        safeFlag = false;
      }
      if (b.id === key) {
        tempBehaviors[index] = {
          ...tempBehaviors[index],
          [type]: parseInt(num),
        };
      }
    });

    setCriticalBehaviors({
      ...criticalBehaviors,
      data: tempBehaviors,
    });
    setSafeObservation(safeFlag);
  };

  const handleOthersToggle = (checked) => {
    if (!checked) {
      if (validEdit) {
        setBehaviorDetails(behaviorDetails);
      } else {
        setBehaviorDetails(behaviorDetailsDefault);
      }
    }
    setSelectOtherBehaviors(checked);
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const handleSelectAUser = (event, value) => {
    if (value) {
      if (validEdit) {
        setEditBehalfDetails({
          id: value.id,
          label: value.label,
        });
      } else {
        setObserverDetails({
          ...observerDetails,
          observerName: value.label,
        });
        setBehalfId(value.id);
      }
    }
  };

  const handleObservationSubType = (event) => {
    if (
      event.target.value === "PLANNED" &&
      !observerDetails.trainingCompleted
    ) {
      setInfoBoxDetails({
        open: true,
        title: t("observations.observationApp.qualification"),
        content: t("observations.observationApp.tttMessage"),
        buttonLabel: t("confirm"),
        handleClose: () =>
          setInfoBoxDetails({ ...infoBoxDetails, open: false }),
        handleAction: () =>
          setInfoBoxDetails({ ...infoBoxDetails, open: false }),
      });
    } else {
      setObserverDetails({
        ...observerDetails,
        submissionOptions: {
          anonymous: false,
          behalf: false,
        },
      });
      setObservationDetails({
        ...observationDetails,
        observationSubType: event.target.value,
      });
    }
  };

  const handleLocationSelect = async (type, e) => {
    setLoading(true);
    if (type === "observer") {
      //  Reset obsCompany, obsDirectorate, obsUnit
      setObsUnitDropdown([]);
      setObsDirectorateDropdown([]);
      let updatedCompanyId = "";
      let updatedUnitId = "";
      let updatedDirectorateId = "";
      const companyOptions = await getCompanies(e.target.value);
      setObsCompanyDropdown(companyOptions);
      if (companyOptions.length === 1) {
        updatedCompanyId = companyOptions[0].value;
        const updatedDirectorates = await getDirectorates(
          companyOptions[0].value,
          companyOptions
        );
        setObsDirectorateDropdown(updatedDirectorates);
        if (updatedDirectorates.length >= 1 && updatedDirectorates[0].value) {
          updatedDirectorateId = updatedDirectorates[0].value;
          const updatedUnits = await getUnits(
            updatedDirectorates[0].value,
            e.target.value,
            companyOptions[0].value
          );
          setObsUnitDropdown(updatedUnits);
          if (updatedUnits.length === 1) {
            updatedUnitId = updatedUnits[0].value;
          }
        }
      }
      setObserverDetails({
        ...observerDetails,
        location: e.target.value,
        company: updatedCompanyId,
        unit: updatedUnitId,
        directorate: updatedDirectorateId,
      });
    } else if (type === "observation") {
      //  Reset company, directorate, units and disciplines
      setDirectorateDropdown([]);
      setUnitDropdown([]);
      setDisciplineDropdown([]);
      setCriticalBehaviors({ data: [] });

      //  Get new companies, if there is just one company, add it as the selected option, get it's directorates
      const companyOptions = await getCompanies(e.target.value);
      setCompanyDropdown(companyOptions);
      let updatedDirectorateId = "";
      let updatedCompanyId = "";
      let updatedUnitId = "";
      if (companyOptions.length === 1) {
        updatedCompanyId = companyOptions[0].value;
        //  Set the directorates if there's one company and set units too
        const updatedDirectorates = await getDirectorates(
          companyOptions[0].value,
          companyOptions
        );
        setDirectorateDropdown(updatedDirectorates);

        if (updatedDirectorates.length >= 1 && updatedDirectorates[0].value) {
          updatedDirectorateId = updatedDirectorates[0].value;
          const updatedUnits = await getUnits(
            updatedDirectorates[0].value,
            e.target.value,
            companyOptions[0].value
          );
          setUnitDropdown(updatedUnits);
          if (updatedUnits.length === 1) {
            updatedUnitId = updatedUnits[0].value;
          }
        }
      }

      setObservationDetails({
        ...observationDetails,
        location: e.target.value,
        company: updatedCompanyId,
        directorate: updatedDirectorateId,
        unit: updatedUnitId,
        discipline: null,
      });
    }
    setLoading(false);
  };

  const handleCompanySelect = async (type, e) => {
    setLoading(true);
    if (type === "observer") {
      setObsDirectorateDropdown([]);
      const updatedDirectorates = await getDirectorates(
        e.target.value,
        obsCompanyDropdown
      );

      setObsDirectorateDropdown(updatedDirectorates);
      let updatedUnitId = "";
      if (updatedDirectorates.length >= 1 && updatedDirectorates[0].value) {
        //  Get units
        const updatedUnits = await getUnits(
          updatedDirectorates[0].value,
          observerDetails.location,
          e.target.value
        );

        setObsUnitDropdown(updatedUnits);
        //  If just one unit
        if (updatedUnits.length === 1) {
          updatedUnitId = updatedUnits[0].value;
        }
      }

      setObserverDetails({
        ...observerDetails,
        company: e.target.value,
        unit: updatedUnitId,
        directorate:
          updatedDirectorates.length === 1 ? updatedDirectorates[0].value : "",
      });
    } else if (type === "observation") {
      //  Reset directorates, units and disciplines
      setDirectorateDropdown([]);
      setUnitDropdown([]);
      setDisciplineDropdown([]);
      setCriticalBehaviors({ data: [] });

      const updatedDirectorates = await getDirectorates(
        e.target.value,
        companyDropdown
      );
      setDirectorateDropdown(updatedDirectorates);

      let updatedUnitId = "";
      if (updatedDirectorates.length >= 1 && updatedDirectorates[0].value) {
        const updatedUnits = await getUnits(
          updatedDirectorates[0].value,
          observationDetails.location,
          e.target.value
        );
        setUnitDropdown(updatedUnits);
        if (updatedDirectorates.length === 1 && updatedUnits.length === 1) {
          updatedUnitId = updatedUnits[0].value;
        }
      }

      setObservationDetails({
        ...observationDetails,
        company: e.target.value,
        directorate:
          updatedDirectorates.length === 1 ? updatedDirectorates[0].value : "",
        unit: updatedUnitId,
        discipline: null,
      });
    }
    setLoading(false);
  };

  const handleDirectorateSelect = async (type, e) => {
    setLoading(true);

    if (type === "observer") {
      let updatedUnitId = "";
      const updatedUnits = await getUnits(
        e.target.value,
        observerDetails.location,
        observerDetails.company
      );
      setObsUnitDropdown(updatedUnits);
      if (updatedUnits.length === 1) {
        updatedUnitId = updatedUnits[0].value;
      }

      setObserverDetails({
        ...observerDetails,
        directorate: e.target.value,
        unit: updatedUnitId,
      });
    } else if (type === "observation") {
      //  Have to get and populate units
      let updatedUnitId = "";
      const updatedUnits = await getUnits(
        e.target.value,
        observationDetails.location,
        observationDetails.company
      );
      setUnitDropdown(updatedUnits);
      if (updatedUnits.length === 1) {
        updatedUnitId = updatedUnits[0].value;
      }

      setObservationDetails({
        ...observationDetails,
        directorate: e.target.value,
        unit: updatedUnitId,
      });
    }
    setLoading(false);
  };

  const handleUnitSelect = async (type, e) => {
    if (type === "observer") {
      setObserverDetails({
        ...observerDetails,
        unit: e.target.value,
      });
    } else if (type === "observation") {
      //  Remove selected discipline and critical behaviors
      setCriticalBehaviors({ data: [] });
      setObservationDetails({
        ...observationDetails,
        unit: e.target.value,
        discipline: null,
      });
    }
  };

  //  Get dropdown options
  const handleGetFormOptions = async () => {
    setLoading(true);
    try {
      //  Discipline
      const disciplineData = await getObsDisciplinesApi();
      const discliplineOptions = disciplineData.data.map((d) => {
        return {
          label: d.name,
          value: d.id,
          labelAR: d.disciplineAR,
        };
      });
      setDisciplineDropdown(discliplineOptions);

      // const categoryData = await getObsOtherDisciplinesApi();
      const categoryData = await getBehaviorCategoriesApi();
      const categoryOptions = await categoryData.map((c) => {
        return {
          label: c.name,
          value: c.id,
          labelAR: c.nameAR,
        };
      });
      setCategoriesDropdown(categoryOptions);

      //  Unsafe conditions
      const unsafeConditionsData = await getObsMainConditionsApi();
      setRawUnsafeConditions(unsafeConditionsData);

      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", "Unable to get dropdown options");
      console.log("Test", err);
    }
  };

  //  Return location options array
  const getLocations = async () => {
    setLoading(true);
    try {
      const locationData = await getLocationsForObsApi();
      const locationOptions = locationData.map((c) => {
        return {
          label: c.name,
          value: c.id,
        };
      });

      return locationOptions;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  };

  //  Return companies options array based on locationId
  const getCompanies = async (locationId) => {
    try {
      setLoading(true);
      const companyData = await getCompaniesUsingLocationApi(locationId);

      const companyOptions = companyData.map((c) => {
        return {
          label: c.name,
          value: c.id,
          directorates: c.directorates,
        };
      });

      setLoading(false);
      return companyOptions;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  };

  //  Return directorates options array based on companyDropdown and searched using companyId
  const getDirectorates = async (companyId, companyList) => {
    setLoading(true);
    let finalDArray = [];
    if (companyId) {
      const targetCompany = companyList.find((c) => c.value === companyId);
      if (targetCompany && targetCompany.directorates) {
        finalDArray = targetCompany.directorates.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });
      }
    }

    setLoading(false);
    return finalDArray;
  };

  // Return units options array based on locationId and companyId
  const getUnits = async (directorateId, locationId, companyId) => {
    try {
      setLoading(true);
      const unitData = await getUnitsTiedApi(
        directorateId,
        locationId,
        companyId
      );

      const unitOptions = unitData.map((u) => {
        return {
          label: u.name,
          value: u.id,
        };
      });

      setLoading(false);
      return unitOptions;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  };

  //  Return specific discipline options array based on company, location and unit
  const getDisciplines = async (
    directorateId,
    companyId,
    locationId,
    unitId
  ) => {
    try {
      setLoading(true);
      const disciplineData = await getCompanyDisciplinesApi(
        directorateId,
        companyId,
        locationId,
        unitId
      );

      const disciplineOptions = disciplineData.map((d) => {
        return {
          label: d.name,
          value: d.id,
          labelAR: d.disciplineAR,
        };
      });

      setLoading(false);
      return disciplineOptions;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  };

  const handlePrepopulateUser = async () => {
    setLoading(true);
    try {
      setValidEdit(false);
      //  Establish user values
      const user = await getCurrentUserApi();
      setUserDetails(user);

      //  Prepopulate location
      const locationOptions = await getLocations();
      setLocationsDropdown(locationOptions);

      //  Prepopulate companies
      if (user.Location?.id) {
        const companyOptions = await getCompanies(user.Location.id);
        setCompanyDropdown(companyOptions);
        setObsCompanyDropdown(companyOptions);

        if (user.company?.id) {
          //  Prepopulate directorates
          const directorateOptions = await getDirectorates(
            user.company.id,
            companyOptions
          );
          setDirectorateDropdown(directorateOptions);
          setObsDirectorateDropdown(directorateOptions);
        }
      }
      let userUnitIsValid = true;
      //  Preopopulate units & disciplines
      if (user.Directorate?.id && user.Location?.id && user.company?.id) {
        const unitOptions = await getUnits(
          user.Directorate.id,
          user.Location.id,
          user.company.id
        );
        setUnitDropdown(unitOptions);
        setObsUnitDropdown(unitOptions);
        userUnitIsValid =
          unitOptions.filter((unit) => unit.value === user.Unit?.id).length > 0;
        const disciplineOptions = await getDisciplines(
          user.Directorate.id,
          user.company.id,
          user.Location.id,
          userUnitIsValid ? user.Unit?.id : null
        );
        setDisciplineDropdown(disciplineOptions);
      }

      //  Update observer details after new dropdown options are there
      setObserverDetails({
        ...observerDetails,
        observerName: `${user.firstName} ${user.lastName}`,
        observerType: hasAccess(access, "isSupervisor")
          ? "SUPERVISOR"
          : "NON-SUPERVISOR",
        company: user.company?.id,
        location: user.Location?.id,
        directorate: user.Directorate?.id,
        unit: user.Unit?.id,
        trainingCompleted: user.trainingCompletedAt || false,
        submissionOptions: {
          ...observerDetails.submissionOptions,
          behalf: false,
        },
      });

      setObservationDetails({
        ...observationDetails,
        company: user.company?.id,
        location: user.Location?.id,
        unit: userUnitIsValid ? user.Unit?.id : null,
        directorate: user.Directorate?.id,
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handlePrepopulateEdit = async () => {
    setLoading(true);
    try {
      const oldDetails = await getObservationDetailsApi(id);
      setValidEdit(true);
      setOldDetails(oldDetails);

      //  Prepopulate observer details
      setEditBehalfDetails({
        id: oldDetails.observer?.id,
        label: `${oldDetails.observer?.firstName} ${oldDetails.observer?.lastName}`,
      });
      setObserverDetails({
        ...observerDetails,
        observerName: `${oldDetails.observer?.firstName} ${oldDetails.observer?.lastName}`,
        submissionOptions: {
          ...observerDetails.submissionOptions,
          behalf:
            oldDetails.submittedBy !== oldDetails.observedBy ? true : false,
          anonymous: oldDetails.isAnonymous,
        },
      });

      //  Prepopulate location
      const locationOptions = await getLocations();
      setLocationsDropdown(locationOptions);

      //  Prepopulate companies
      if (oldDetails.location?.id) {
        const companyOptions = await getCompanies(oldDetails.location.id);
        setCompanyDropdown(companyOptions);
        setObsCompanyDropdown(companyOptions);

        if (oldDetails.company?.id) {
          //  Prepopulate directorates
          const directorateOptions = await getDirectorates(
            oldDetails.company.id,
            companyOptions
          );
          setDirectorateDropdown(directorateOptions);
        }
      }

      //  Preopopulate units & disciplines
      if (
        oldDetails.location?.id &&
        oldDetails.company?.id &&
        oldDetails.unit?.id
      ) {
        const unitOptions = await getUnits(
          oldDetails.directorate.id,
          oldDetails.location.id,
          oldDetails.company.id
        );
        setUnitDropdown(unitOptions);
        setObsUnitDropdown(unitOptions);

        const disciplineOptions = await getDisciplines(
          oldDetails.directorate.id,
          oldDetails.company.id,
          oldDetails.location.id,
          oldDetails.unit.id
        );
        setDisciplineDropdown(disciplineOptions);
      }

      //  Observation details
      setObservationDetails({
        observationType: oldDetails.observationType,
        company: oldDetails.company ? oldDetails.company.id : "",
        directorate: oldDetails.directorate ? oldDetails.directorate.id : "",
        location: oldDetails.location ? oldDetails.location.id : "",
        unit: oldDetails.unit ? oldDetails.unit.id : "",
        discipline: oldDetails.discipline
          ? {
              label: oldDetails.discipline.name,
              value: oldDetails.discipline.id,
              labelAR: oldDetails.discipline.disciplineAR,
            }
          : null,
        date: moment(oldDetails.observedAt),
        unsafeConditions: [],
        observationSubType: oldDetails.observationSubType,
      });

      //  Behavior details
      if (oldDetails.behaviors) {
        const formattedCriticalBehaviors = [];
        const formattedOtherBehaviors = [];
        oldDetails.behaviors.forEach((b, i) => {
          setSafeObservation(!b.ObservationBehaviors.unsafe > 0);
          if (b.ObservationBehaviors.behaviorType === "CRITICAL") {
            formattedCriticalBehaviors.push({
              id: b.id,
              label: b.name,
              labelAR: b.disciplineAR,
              safe: b.ObservationBehaviors.safe,
              unsafe: b.ObservationBehaviors.unsafe,
            });
          } else {
            setSelectOtherBehaviors(true);
            formattedOtherBehaviors.push({
              id: b.id,
              label: b.name,
              labelAR: b.disciplineAR,
              safe: b.ObservationBehaviors.safe,
              unsafe: b.ObservationBehaviors.unsafe,
            });
          }
        });

        //  Stop default calling of other behaviors
        // const otherBehaviorData = await handleGetOtherBehaviors();
        // const finalDropdown = otherBehaviorData.filter(
        //   ({ value: id1 }) =>
        //     !formattedOtherBehaviors.some(({ id: id2 }) => id2 === id1)
        // );
        // setOtherBehaviorDropdown(finalDropdown);
        setCriticalBehaviors({ data: formattedCriticalBehaviors });
        setBehaviorDetails({
          observedNumber: oldDetails.peopleObserved,
          observedBehaviors: formattedOtherBehaviors,
        });
      }

      //  Unsafe conditions
      if (oldDetails.conditions && oldDetails.conditions.length > 0) {
        const oldUnsafeConditions = oldDetails.conditions.map((c) => {
          return {
            label: c.unsafeCondition.name,
            value: c.unsafeCondition.id,
            mainConditionId: c.category.id,
            unsafeConditionId: c.unsafeCondition.id,
          };
        });
        setUnsafeConditions(oldUnsafeConditions);
      }

      //  Additional details
      setAdditionalDetails({
        feedback: oldDetails.feedback,
        rootCause: oldDetails.rootCause,
        actionToPrevent: oldDetails.actionToPrevent,
        suggestions: oldDetails.suggestions,
        isEscalated: oldDetails.isEscalated,
      });

      //  Attachments
      const finalAttachments = oldDetails.attachments.map((f, i) => {
        return {
          ...f,
          name: f.originalFileName,
          oldFile: true,
        };
      });
      setFiles(finalAttachments);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submitObservation = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("observations.observationApp.toasterSubmitting")
    );

    setLoading(true);

    try {
      if (!observationDetails.company) {
        throw t(`errorHandling.companyError`);
      }
      if (!observationDetails.location) {
        throw t(`errorHandling.locationError`);
      }
      if (!observationDetails.directorate) {
        throw t(`errorHandling.directorateError`);
      }

      if (!observationDetails.discipline) {
        throw t(`errorHandling.disciplineError`);
      }
      if (!observationDetails.date) {
        throw t(`errorHandling.observationDateError`);
      }
      const obsDate = moment(observationDetails.date);
      if (!obsDate.isValid()) {
        throw t(`errors.dateValidError`);
      }
      if (obsDate.isAfter(moment())) {
        throw t(`errors.dateValidError`);
      }

      const ot = observationDetails.observationType;
      const finalBehaviors = [];
      let totalObserved = 0;
      let totalOther = 0;

      if (ot !== "CONDITION") {
        criticalBehaviors.data.forEach((b) => {
          const finalSafe = b.safe || 0;
          const finalUnsafe = b.unsafe || 0;

          totalObserved += finalSafe + finalUnsafe;
          finalBehaviors.push({
            behaviorId: b.id,
            safe: finalSafe,
            unsafe: finalUnsafe,
            type: "CRITICAL",
          });
        });

        behaviorDetails.observedBehaviors.forEach((b) => {
          const finalSafe = b.safe || 0;
          const finalUnsafe = b.unsafe || 0;

          totalObserved += finalSafe + finalUnsafe;
          totalOther += finalSafe + finalUnsafe;

          finalBehaviors.push({
            behaviorId: b.id,
            safe: finalSafe,
            unsafe: finalUnsafe,
            type: "OTHER",
          });
        });
        if (!finalBehaviors.length > 0) {
          throw t(`errorHandling.behaviorError`);
        }
        if (!additionalDetails.rootCause && !safeObservation) {
          throw t(`errorHandling.rootCauseError`);
        }
      }

      const finalConditions = [];

      unsafeConditions.forEach((c) => {
        finalConditions.push({
          unsafeConditionId: c.value,
          mainConditionId: c.mainConditionId,
        });
      });

      if (ot === "CONDITION" && !finalConditions.length > 0) {
        throw t(`errorHandling.conditionError`);
      }

      //  For edit, need to check difference in files.
      let finalFiles = [];
      if (validEdit) {
        finalFiles = files.filter((f) => !f.oldFile);
      } else {
        finalFiles = files;
      }

      if (
        totalObserved === 0 &&
        ot !== "CONDITION" &&
        finalBehaviors.length > 0
      ) {
        throw t(`observations.observationApp.errors.empty`);
      }

      if (
        totalOther === 0 &&
        ot !== "CONDITION" &&
        behaviorDetails.observedBehaviors.length > 0
      ) {
        throw t(`observations.observationApp.errors.emptyOther`);
      }

      if (!additionalDetails.feedback) {
        throw t(`errorHandling.feedbackError`);
      }

      const finalId = validEdit
        ? editBehalfDetails?.id
        : userDetails && !observerDetails.submissionOptions.behalf
        ? userDetails.id
        : behalfId;

      const payload = {
        safeObservation: ot === "CONDITION" ? false : safeObservation,
        observationType: ot,
        observationSubType: observationDetails.observationSubType,
        observedAt: obsDate.toISOString(),
        observedBy: finalId,
        observerType: observerDetails.observerType,
        isAnonymous: observerDetails.submissionOptions.anonymous || false,
        companyId: observationDetails.company,
        directorateId: observationDetails.directorate,
        locationId: observationDetails.location,
        unitId: observationDetails.unit,
        disciplineId: observationDetails.discipline?.value || null,
        peopleObserved: totalObserved,
        feedback: additionalDetails.feedback,
        rootCause: additionalDetails.rootCause,
        actionToPrevent: additionalDetails.actionToPrevent
          ? additionalDetails.actionToPrevent
          : additionalDetailsDefault.actionToPrevent,
        suggestions: additionalDetails.suggestions
          ? additionalDetails.suggestions
          : additionalDetailsDefault.suggestions,
        behaviors: ot === "BEHAVIOR" ? JSON.stringify(finalBehaviors) : null,
        unsafeConditions:
          ot === "CONDITION" ? JSON.stringify(finalConditions) : null,
        isEscalated: additionalDetails.isEscalated,
        attachments: finalFiles,
        attachmentRemovals: JSON.stringify(removedFiles),
      };

      if (validEdit) {
        const editRes = await editObservationApi(payload, id);
        displayToast(
          setLoading,
          "success",
          t("observations.observationApp.toasterEditing"),
          activeToast
        );
        setSuccessBox({ open: true, observationId: editRes.observationId });
      } else {
        const submissionRes = await submitObservationApi(payload);
        displayToast(
          setLoading,
          "success",
          t("observations.observationApp.toasterSuccess"),
          activeToast
        );
        setBehaviorDetails(behaviorDetailsDefault);
        setSuccessBox({
          open: true,
          observationId:
            typeof submissionRes === "object"
              ? submissionRes?.observationId
              : "",
        });
      }
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const handleObservationTypeChange = async () => {
    if (observationDetails.observationType === "CONDITION") {
      setObservationDetails({
        ...observationDetails,
        observationSubType: observationDetailsDefault.observationSubType,
      });
      // if(observerDetails.submissionOptions.anonymous){
      //   setObserverDetails({
      //     ...observerDetails,
      //     submissionOptions: {
      //       ...observerDetails.submissionOptions,
      //       anonymous: false,
      //     },
      //   });
      // }
    }

    //  Disabled the reset
    // setObserverDetails({
    //   ...observerDetails,
    //   submissionOptions: {
    //     ...observerDetails.submissionOptions,
    //     anonymous: false,
    //     behalf: false,
    //   },
    // });
  };

  //  If it is an edit, prepopulate from existing values, otherwise prepopulate from user defaults
  useEffect(() => {
    //  1. Get all the form options that are not dependent
    handleGetFormOptions();
    if (id) {
      //  2. Get options from existing observation
      handlePrepopulateEdit();
    } else {
      //  3. Get options from the user's defaults
      handlePrepopulateUser();
    }
  }, [id]);

  useEffect(() => {
    handleObservationTypeChange();
  }, [
    observationDetails.observationType,
    observationDetails.observationSubType,
  ]);

  //  Get disciplines based on company, location and unit change
  useEffect(() => {
    const setDisciplines = async () => {
      try {
        const companyId = observationDetails.company;
        const locationId = observationDetails.location;
        const unitId = observationDetails.unit;
        const directorateId = observationDetails.directorate;

        if (directorateId && companyId && locationId) {
          const disciplineOptions = await getDisciplines(
            directorateId,
            companyId,
            locationId,
            unitId
          );
          setDisciplineDropdown(disciplineOptions);
        }
      } catch (err) {
        console.log(err);
      }
    };

    setDisciplines();
  }, [
    observationDetails.directorate,
    observationDetails.company,
    observationDetails.location,
    observationDetails.unit,
  ]);

  //  Get critical behaviors based on company, discipline, location and unit
  useEffect(() => {
    const companyId = observationDetails.company;
    const disciplineId = observationDetails.discipline?.value;
    const locationId = observationDetails.location;
    const unitId = observationDetails.unit;
    const directorateId = observationDetails.directorate;

    if (companyId && locationId && disciplineId) {
      handleGetCriticalBehavior(
        companyId,
        disciplineId,
        locationId,
        unitId,
        directorateId
      );
    }
  }, [
    observationDetails.company,
    observationDetails.discipline,
    observationDetails.location,
    observationDetails.unit,
    observationDetails.directorate,
  ]);

  return (
    <Page title={t(`observations.observationApp.titleSubmit`)}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {validEdit
                ? t(`observations.observationApp.titleEdit`)
                : t(`observations.observationApp.titleSubmit`)}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5">
              {t(`observations.observationApp.observingWhat`)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <RadioSet
              disabled={loading}
              label=""
              value={observationDetails.observationType}
              handleChange={handleChangeObservationType}
              options={[
                {
                  label: t(`observations.observationApp.peopleBehavior`),
                  value: "BEHAVIOR",
                },
                {
                  label: t(`observations.observationApp.unsafeCondition`),
                  value: "CONDITION",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormDivider />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5">
              {t(`observations.observationApp.observerDetails`)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <CheckboxSet
              disabled={loading}
              label="submission_options"
              handler={handleSubmissionOptions}
              options={
                observationDetails.observationSubType === "INTERVENTION"
                  ? [
                      {
                        label: t(
                          `observations.observationApp.submitAnonymously`
                        ),
                        value: "anonymous",
                        checked: observerDetails.submissionOptions.anonymous,
                      },
                      // Renabled submit behalf
                      {
                        label: t(`observations.observationApp.submitBehalf`),
                        value: "behalf",
                        checked: observerDetails.submissionOptions.behalf,
                      },
                    ]
                  : [
                      // Renabled submit behalf
                      {
                        label: t(`observations.observationApp.submitBehalf`),
                        value: "behalf",
                        checked: observerDetails.submissionOptions.behalf,
                      },
                    ]
              }
            />
          </Grid>

          {observerDetails.submissionOptions.behalf ? (
            renderBehalfForm()
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={t(`observations.observationApp.observerName`)}
                  disabled
                  value={observerDetails.observerName}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <SelectInput
                  label={t(`observations.observationApp.observerType`)}
                  value={observerDetails.observerType}
                  disabled={loading}
                  handler={(e) =>
                    setObserverDetails({
                      ...observerDetails,
                      observerType: e.target.value,
                    })
                  }
                  options={roleDropdownList}
                />
              </Grid> */}
            </>
          )}
          {userDetails?.trainingCompletedAt && (
            <Grid item xs={12} sm={12} md={12}>
              <RadioSet
                disabled={loading}
                label={t(`observations.observationApp.observationType`)}
                value={observationDetails.observationSubType}
                handleChange={(e) => handleObservationSubType(e)}
                options={
                  observationDetails.observationType === "CONDITION"
                    ? [
                        {
                          label: t(
                            `observations.observationApp.interventionObservation`
                          ),
                          value: "INTERVENTION",
                        },
                      ]
                    : [
                        {
                          label: t(
                            `observations.observationApp.plannedObservation`
                          ),
                          value: "PLANNED",
                        },
                        {
                          label: t(
                            `observations.observationApp.interventionObservation`
                          ),
                          value: "INTERVENTION",
                        },
                      ]
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <FormDivider />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5">
              {t(`observations.observationApp.observationDetails`)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <SelectInput
              label={t(`observations.observationApp.observationLocation`)}
              placeholder={t(
                `observations.observationApp.observationLocationPlaceholder`
              )}
              value={observationDetails.location}
              disabled={
                loading || !locationsDropdown || locationsDropdown.length < 1
              }
              handler={(e) => handleLocationSelect("observation", e)}
              options={locationsDropdown}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <SelectInput
              label={t(`observations.observationApp.observationCompany`)}
              placeholder={t(
                `observations.observationApp.observationCompanyPlaceholder`
              )}
              value={observationDetails.company}
              disabled={
                loading || !companyDropdown || companyDropdown.length < 1
              }
              handler={(e) => handleCompanySelect("observation", e)}
              options={companyDropdown}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <SelectInput
              label={t(`observations.observationApp.observationDirectorate`)}
              placeholder={t(
                `observations.observationApp.observationDirectoratePlaceholder`
              )}
              value={observationDetails.directorate}
              disabled={
                loading ||
                !directorateDropdown ||
                directorateDropdown.length < 1
              }
              handler={(e) => handleDirectorateSelect("observation", e)}
              options={directorateDropdown}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <SelectInput
              optional
              label={t(`observations.observationApp.observationUnit`)}
              placeholder={t(
                `observations.observationApp.observationUnitPlaceholder`
              )}
              value={observationDetails.unit}
              disabled={loading || !unitDropdown}
              handler={(e) => handleUnitSelect("observation", e)}
              options={unitDropdown}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AutocompleteInput
              disableClearable
              label={t(`observations.observationApp.observationDiscipline`)}
              placeholder={t(
                `observations.observationApp.observationDisciplinePlaceolder`
              )}
              disabled={
                loading ||
                !(
                  observationDetails.location &&
                  observationDetails.directorate &&
                  observationDetails.company
                )
              }
              value={observationDetails.discipline}
              handler={(e, v) => {
                setObservationDetails({
                  ...observationDetails,
                  discipline: v,
                });
              }}
              options={disciplineDropdown}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <DateTimeInput
              maxDateTime
              label={t(`observations.observationApp.observationDate`)}
              date={observationDetails.date}
              disabled={loading}
              handler={(newDate) => {
                setObservationDetails({
                  ...observationDetails,
                  date: newDate,
                });
              }}
            />
          </Grid>
          {observationDetails.observationType === "BEHAVIOR" ? (
            observationDetails.discipline ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: "16px" }}
                >
                  <Typography variant="h6">
                    {t(`observations.observationApp.criticalBehaviors`)}
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "20px" }}>
                    {t(`observations.observationApp.criticalBehaviorsSubText`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <BehaviorInput
                    disabled={loading}
                    behaviors={criticalBehaviors.data}
                    handleNumberChange={handleCriticalBehaviorChange}
                    mandatory
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectOtherBehaviors}
                        name={"otherBehaviorsCheckbox"}
                        onChange={(e) => handleOthersToggle(e.target.checked)}
                        sx={{
                          color: theme.palette.primary.orange,
                          "&.Mui-checked": {
                            color: theme.palette.primary.orange,
                          },
                          "&:hover": {
                            backgroundColor: "rgba(248,147,31,0.08)",
                          },
                        }}
                      />
                    }
                    disabled={loading}
                    label={t(`observations.observationApp.addOtherBehaviors`)}
                  />
                </Grid>
                {selectOtherBehaviors && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ paddingTop: "16px" }}
                    >
                      <Typography variant="h6">
                        {t(`observations.observationApp.otherBehaviors`)}
                      </Typography>
                      <Typography variant="body1" style={{ marginTop: "20px" }}>
                        {t(`observations.observationApp.otherBehaviorsSubtext`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <BehaviorInput
                        disabled={loading}
                        behaviors={behaviorDetails.observedBehaviors}
                        behaviorOptions={otherBehaviorDropdown}
                        categoryOptions={categoriesDropdown}
                        handleNewBehavior={handleAddNewBehavior}
                        handleNumberChange={handleBehaviorNumberChange}
                        handleRemoveBehavior={handleRemoveBehavior}
                        handleGetOtherBehaviors={handleGetOtherBehaviors}
                      />
                    </Grid>
                  </>
                )}
              </>
            ) : null
          ) : (
            <Grid item xs={12} md={12}>
              <UnsafeConditionInput
                disabled={loading}
                unsafeConditions={unsafeConditions}
                rawData={rawUnsafeConditions}
                setUnsafeConditions={setUnsafeConditions}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <FormDivider />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextInput
              disabled={loading}
              label={t(`observations.observationApp.feedback`)}
              value={additionalDetails.feedback}
              placeholder={t(`observations.observationApp.feedbackPlaceholder`)}
              handler={(e) => handleTextInput(e, "feedback")}
              helpText={t(`observations.observationApp.infoText.feedback`)}
            />
          </Grid>
          {observationDetails.observationType === "BEHAVIOR" ? (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  disabled={loading}
                  label={
                    safeObservation
                      ? t(`observations.observationApp.rootCauseSafe`)
                      : t(`observations.observationApp.rootCause`)
                  }
                  placeholder={t(
                    `observations.observationApp.rootCausePlaceholder`
                  )}
                  value={additionalDetails.rootCause}
                  handler={(e) => handleTextInput(e, "rootCause")}
                  helpText={t(`observations.observationApp.infoText.rootCause`)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextInput
                  disabled={loading}
                  label={t(`observations.observationApp.actionBehavior`)}
                  placeholder={t(
                    `observations.observationApp.actionBehaviorPlaceholder`
                  )}
                  value={additionalDetails.actionToPrevent}
                  handler={(e) => handleTextInput(e, "actionToPrevent")}
                  helpText={t(
                    `observations.observationApp.infoText.actionToPrevent`
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <TextInput
                disabled={loading}
                label={t(`observations.observationApp.actionCondition`)}
                placeholder={t(
                  `observations.observationApp.actionConditionPlaceholder`
                )}
                value={additionalDetails.actionToPrevent}
                handler={(e) => handleTextInput(e, "actionToPrevent")}
                helpText={t(
                  `observations.observationApp.infoText.actionToPrevent`
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <TextInput
              disabled={loading}
              label={t(`observations.observationApp.suggestions`)}
              placeholder={t(
                `observations.observationApp.suggestionsPlaceholder`
              )}
              value={additionalDetails.suggestions}
              handler={(e) => handleTextInput(e, "suggestions")}
              helpText={t(`observations.observationApp.infoText.suggestions`)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FileUploader
              files={files}
              setFiles={setFiles}
              disabled={loading}
              removedFiles={removedFiles}
              setRemovedFiles={setRemovedFiles}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12}>
            <RedCheckbox
              disabled={loading}
              label={t(`observations.observationApp.escalate`)}
              checked={additionalDetails.isEscalated}
              handler={() => {
                additionalDetails.isEscalated
                  ? setAdditionalDetails({
                      ...additionalDetails,
                      isEscalated: false,
                    })
                  : setEscalationFlag(true);
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: "40px" }}>
            <PrimaryButton
              label={
                validEdit
                  ? t(`observations.observationApp.submitEdited`)
                  : t(`observations.observationApp.submitObservation`)
              }
              alignment="left"
              onClick={submitObservation}
              loading={true}
            />
          </Grid>
        </Grid>
      </Container>
      {/* <InfoBox
        open={escalationFlag}
        title={t(`observations.observationApp.escalate`)}
        content={t(`observations.observationApp.escalateSubText`)}
        buttonLabel={t(`observations.observationApp.escalate`)}
        handleClose={() => setEscalationFlag(false)}
        handleAction={() => {
          setAdditionalDetails({ ...additionalDetails, isEscalated: true });
          setEscalationFlag(false);
        }}
      /> */}
      <InfoBox
        open={successBox.open}
        noClose
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DoneIcon
              style={{
                height: "100px",
                width: "100px",
                color: theme.palette.primary.green,
                marginBottom: "30px",
              }}
            />
            <Typography variant="h5">{successBox.observationId}</Typography>
            <Typography variant="h5">
              {t(`observations.observationApp.submitSuccess`)}
            </Typography>
          </div>
        }
        buttonLabel={t(`observations.observationApp.viewObservations`)}
        handleAction={() => navigate("/observations/list")}
      />
      <InfoBox
        open={infoBoxDetails.open}
        title={infoBoxDetails.title}
        content={infoBoxDetails.content}
        buttonLabel={infoBoxDetails.buttonLabel}
        handleClose={infoBoxDetails.handleClose}
        handleAction={infoBoxDetails.handleAction}
      />
    </Page>
  );
}
