import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import useAuth from "../../context/useAuth";
import { displayToast } from "../../utils/general";
import { useTranslation } from "react-i18next";
// material
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ReturnToObserverIcon from "@mui/icons-material/KeyboardReturnOutlined";
import ReassignIcon from "@mui/icons-material/Sync";
import CompleteIcon from "@mui/icons-material/Verified";
import StatusIcon from "@mui/icons-material/PendingActionsOutlined";
import ObservationTypeIcon from "@mui/icons-material/AssignmentLateOutlined";
import LocationIcon from "@mui/icons-material/FmdGoodOutlined";
import CompanyIcon from "@mui/icons-material/BusinessOutlined";
import DisciplineIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import ApproveIcon from "@mui/icons-material/ThumbUpAlt";
import RejectIcon from "@mui/icons-material/ThumbDownAlt";
//  components
import Page from "../../components/Page";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import InfoBox from "../../components/InfoBox";
import AttachmentViewer from "../../components/AttachmentViewer";
import FormDivider from "../../components/FormDivider";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import TextInput from "../../components/inputs/TextInput";
import FileUploader from "../../components/inputs/FileUploader";
import BehaviorInput from "../../components/inputs/BehaviorInput";
import UnsafeConditionInput from "../../components/inputs/UnsafeConditionInput";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import Timeline from "../../components/Timeline";
//  api
import {
  getActionDetailsApi,
  returnToSupervisorApi,
  completeActionApi,
  reassignActionApi,
  approveActionApi,
  rejectActionApi,
} from "../../api/actions";
import { getAttachmentsApi } from "../../api/attachments";
import { searchUsersApi } from "../../api/users";
import { getActionPermissionsApi } from "../../api/acl";
import { trackObservationApi } from "../../api/observation";

const validationChecks = {
  all: ["ACTION ASSIGNED", "ACTION RE-ASSIGNED"],
};

const returnDetailsDefault = {
  open: false,
  comment: "",
  attachments: [],
  newActionPartyId: "",
  requiredSOPChanges: false,
};

const actionRoleDefault = {
  permitted: false,
  role: [],
};

const timelineStepDefault = {
  activeStep: "",
  steps: [
    {
      label: "OBSERVATION SUBMITTED",
      icon: "observation_submitted",
      comment: "Example comment.",
    },
  ],
};

export default function ActionDetails() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { userId } = useAuth();
  const navigate = useNavigate();
  const timeout = useRef();
  const { oId, aId } = useParams();
  const [actionRole, setActionRole] = useState(actionRoleDefault);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [timelineSteps, setTimelineSteps] = useState(timelineStepDefault);
  const [rowDetails, setRowDetails] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [returnDetails, setReturnDetails] = useState(returnDetailsDefault);
  const [reassignDetails, setReassignDetails] = useState(returnDetailsDefault);
  const [completeDetails, setCompleteDetails] = useState(returnDetailsDefault);
  const [rejectDetails, setRejectDetails] = useState(returnDetailsDefault);
  const [attachmentLinks, setAttachmentLinks] = useState([]);

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1, undefined, true);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const submitReassign = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("actions.reAssignLoading")
    );
    try {
      if (!reassignDetails.comment)
        throw t("observations.observationDetails.errors.comment");
      if (!reassignDetails.newActionPartyId)
        throw t("observations.observationDetails.errors.actionParty");
      await reassignActionApi(reassignDetails, oId, aId);
      displayToast(
        setLoading,
        "successLoading",
        t("actions.reAssignSuccessful"),
        activeToast
      );
      navigate("/actions/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitReturn = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("actions.returnToSupervisor")
    );
    try {
      await returnToSupervisorApi(returnDetails, oId, aId);
      displayToast(
        setLoading,
        "successLoading",
        t("actionReturnedSuccess"),
        activeToast
      );
      navigate("/actions/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitCompleteAction = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("actions.closingAction")
    );
    try {
      if (!completeDetails.comment) {
        throw t("observations.observationDetails.errors.comment");
      }
      await completeActionApi(completeDetails, oId, aId);
      displayToast(
        setLoading,
        "successLoading",
        t("actions.closedSuccess"),
        activeToast
      );
      navigate("/actions/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitApproveAction = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("actions.approvingAction")
    );
    try {
      await approveActionApi(oId, aId);
      displayToast(
        setLoading,
        "successLoading",
        t("actions.actionApproved"),
        activeToast
      );
      navigate("/actions/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitRejectAction = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("actions.actionRejecting")
    );
    try {
      await rejectActionApi(rejectDetails, oId, aId);
      displayToast(
        setLoading,
        "successLoading",
        t("actions.actionRejected"),
        activeToast
      );
      navigate("/actions/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  useEffect(() => {
    const handleGetDetails = async () => {
      setLoading(true);
      try {
        const behaviorRows = [];
        const actionRoleData = await getActionPermissionsApi(oId, aId);
        setActionRole(actionRoleData);
        const detailsData = await getActionDetailsApi(oId, aId);
        const timelineDetailsRaw = await trackObservationApi(oId);
        let activeStep = 0;
        const finalSteps = timelineDetailsRaw.map((d, index) => {
          if (d.eventName === detailsData.observation.status) {
            activeStep = index;
          }

          return {
            icon: d.icon,
            label: d.eventName,
            comment: d.comment,
            name: d.name,
            role: d.role,
            attachments: d.attachments,
          };
        });
        setTimelineSteps({
          activeStep,
          steps: finalSteps,
        });

        if (detailsData.observation.observationType === "BEHAVIOR") {
          detailsData.observation.behaviors &&
            detailsData.observation.behaviors.forEach((d) => {
              behaviorRows.push({
                name: d.name,
                label: d.name,
                safe: d.ObservationBehaviors.safe,
                unsafe: d.ObservationBehaviors.unsafe,
              });
            });
        } else if (detailsData.observation.observationType === "CONDITION") {
          detailsData.observation.conditions &&
            detailsData.observation.conditions.forEach((c) => {
              behaviorRows.push({
                label: c.unsafeCondition.name,
                value: c.unsafeCondition.id,
              });
            });
        }

        const attachmentIds = [];
        detailsData.observation.attachments.forEach((a) => {
          attachmentIds.push(a.id);
        });
        detailsData.confirmationAttachments.forEach((a) => {
          attachmentIds.push(a.id);
        });
        if (attachmentIds && attachmentIds.length > 0) {
          const attachmentsData = await getAttachmentsApi(attachmentIds);
          setAttachmentLinks(attachmentsData);
        }
        setDetails(detailsData);
        setRowDetails(behaviorRows);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    handleGetDetails();
  }, [oId, aId]);

  return (
    <Page>
      <Container>
        {!details ? (
          t("general.loading")
        ) : !actionRole.permitted ? (
          t("observations.observationDetails.noPermissions")
        ) : details && details.status !== "COMPLETED" ? (
          <>
            <Box sx={{ pb: 5 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HeaderBackButton />
                <Typography variant="h4">
                  {t("actions.assignedAction")}
                </Typography>
              </div>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {details.generatedActionId}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "12px",
                    color: "#7D7D7D",
                    textShadow: "none",
                  }}
                >
                  {t("observations.observationDetails.submittedByText", {
                    name: ` ${details.observation.observer?.firstName ?? ""} ${
                      details.observation.observer?.lastName ?? ""
                    } `,
                    date: moment(details.observation?.observedAt).format(
                      "DD/MM/YY  hh:mm a"
                    ),
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Timeline
                  steps={timelineSteps.steps}
                  activeStep={timelineSteps.activeStep}
                  attachmentLinks={attachmentLinks}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  spacing={4}
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    border: "1px solid #C2C2C2",
                    padding: "18px",
                    borderRadius: "4px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{
                        color: theme.palette.primary.red,
                        textShadow: "none",
                      }}
                    >
                      {t("observations.observationDetails.actionToPerform")}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#7D7D7D",
                        textShadow: "none",
                      }}
                    >
                      {details.actionRequired}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{
                        textShadow: "none",
                      }}
                    >
                      {t("observations.observationDetails.priority")}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#7D7D7D",
                        textShadow: "none",
                      }}
                    >
                      {details.priority}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{
                        textShadow: "none",
                      }}
                    >
                      {t("observations.observationDetails.targetDate")}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#7D7D7D",
                        textShadow: "none",
                      }}
                    >
                      {moment(details.targetDate).format("DD/MM/YY  hh:mm a")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AttachmentViewer
                      disabled={loading}
                      attachments={details.observation.attachments}
                      attachmentLinks={attachmentLinks}
                    />
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <FormDivider />
              </Grid>
              {actionRole.role.includes("ACTION-PARTY") &&
                details.status === "PENDING" && (
                  <>
                    <Grid item xs={12} md={6}>
                      <PrimaryButton
                        disabled={
                          loading ||
                          !validationChecks.all.includes(
                            details.observation.status
                          )
                        }
                        alignment="left"
                        label={t("actions.return")}
                        icon={<ReturnToObserverIcon />}
                        onClick={() =>
                          setReturnDetails({ ...returnDetails, open: true })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PrimaryButton
                        disabled={
                          loading ||
                          !validationChecks.all.includes(
                            details.observation.status
                          )
                        }
                        alignment="left"
                        label={t("actions.reassign")}
                        icon={<ReassignIcon />}
                        onClick={() =>
                          setReassignDetails({
                            ...reassignDetails,
                            open: true,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PrimaryButton
                        disabled={
                          loading ||
                          !validationChecks.all.includes(
                            details.observation.status
                          )
                        }
                        alignment="left"
                        label={t("actions.complete")}
                        icon={<CompleteIcon />}
                        onClick={() =>
                          setCompleteDetails({ ...completeDetails, open: true })
                        }
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </>
        ) : (
          <>
            <Box sx={{ pb: 5 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HeaderBackButton />
                <Typography variant="h4">
                  {t("actions.completedAction")}
                </Typography>
              </div>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={9}>
                <Typography variant="h5">
                  {details.observation.observationId}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: "12px",
                    color: "#7D7D7D",
                    textShadow: "none",
                  }}
                >
                  {t("observations.observationDetails.submittedByText", {
                    name: ` ${details.observation.observer?.firstName ?? ""} ${
                      details.observation.observer?.lastName ?? ""
                    } `,
                    date: moment(details.observedAt).format(
                      "DD/MM/YY  hh:mm a"
                    ),
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h6"
                  style={{ color: theme.palette.primary.red }}
                >
                  {t(`observations.observationDetails.unsafeObservation`)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={5} sx={{ mt: 2 }}>
              <Grid item xs={12} md={12}>
                <Stack direction="row" alignItems="top">
                  <StatusIcon style={{ color: "#7D7D7D" }} sx={{ mt: 1 }} />
                  <Stack direction="column" sx={{ ml: 3 }}>
                    <Typography
                      variant="h6"
                      style={{ color: "#7D7D7D", textShadow: "none" }}
                    >
                      Status: {details.observation.status}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      The action has been completed by the action party.
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ mt: 6 }}
                      style={{
                        color: theme.palette.primary.green,
                        textShadow: "none",
                      }}
                    >
                      Action completed
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ mb: 6 }}
                      style={{
                        color: "#7D7D7D",
                        textShadow: "none",
                      }}
                    >
                      {details.actionRequired}
                    </Typography>
                    <AttachmentViewer
                      disabled={loading}
                      customLabel={"Confirmation attachments"}
                      attachments={details.confirmationAttachments}
                      attachmentLinks={attachmentLinks}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                <Stack direction="row" alignItems="center">
                  <ObservationTypeIcon style={{ color: "#7D7D7D" }} />
                  <Stack direction="column" sx={{ ml: 3 }}>
                    <Typography
                      variant="h6"
                      style={{ color: "#7D7D7D", textShadow: "none" }}
                    >
                      Type:
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ mt: 1 }}
                      style={{ color: "#4C4C4E", textShadow: "none" }}
                    >
                      {`${details.observation.observationType}, ${details.observation.observationSubType}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row" alignItems="center">
                  <LocationIcon style={{ color: "#7D7D7D" }} />
                  <Stack direction="column" sx={{ ml: 3 }}>
                    <Typography
                      variant="h6"
                      style={{ color: "#7D7D7D", textShadow: "none" }}
                    >
                      Location:
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ mt: 1 }}
                      style={{ color: "#4C4C4E", textShadow: "none" }}
                    >
                      {details.observation.location.name}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                <Stack direction="row" alignItems="center">
                  <CompanyIcon style={{ color: "#7D7D7D" }} />
                  <Stack direction="column" sx={{ ml: 3 }}>
                    <Typography
                      variant="h6"
                      style={{ color: "#7D7D7D", textShadow: "none" }}
                    >
                      Contractor/Department:
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ mt: 1 }}
                      style={{ color: "#4C4C4E", textShadow: "none" }}
                    >
                      {details.observation.name}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              {details.observation.discipline && (
                <Grid item xs={12} md={6}>
                  <Stack direction="row" alignItems="center">
                    <DisciplineIcon style={{ color: "#7D7D7D" }} />
                    <Stack direction="column" sx={{ ml: 3 }}>
                      <Typography
                        variant="h6"
                        style={{ color: "#7D7D7D", textShadow: "none" }}
                      >
                        Discipline:
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ mt: 1 }}
                        style={{ color: "#4C4C4E", textShadow: "none" }}
                      >
                        {details.observation.discipline.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormDivider />
              </Grid>
              {details &&
                details.observation.observationType === "BEHAVIOR" &&
                details.observation.behaviors && (
                  <>
                    <Grid container sx={{ pl: "40px", pr: "40px", mt: "30px" }}>
                      <Grid xs={12} sx={{ mb: "20px" }}>
                        <Typography variant="h5">Observed Behavior</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <BehaviorInput
                          disabled={loading}
                          behaviors={rowDetails}
                          mandatory
                          displayOnly
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FormDivider />
                    </Grid>
                  </>
                )}
              {details &&
                details.observation.observationType === "CONDITION" &&
                details.observation.conditions && (
                  <>
                    <Grid container sx={{ pl: "40px", pr: "40px", mt: "30px" }}>
                      <Grid xs={12} sx={{ mb: "20px" }}>
                        <Typography variant="h5">Unsafe conditions</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <UnsafeConditionInput
                          disabled={loading}
                          unsafeConditions={rowDetails}
                          displayOnly
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FormDivider />
                    </Grid>
                  </>
                )}
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    Feedback
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {details.observation && details.observation.feedback}
                  </Typography>
                </Stack>
              </Grid>
              {details.rootCause && (
                <Grid item xs={12} md={12}>
                  <Stack direction="column">
                    <Typography variant="h6" style={{ textShadow: "none" }}>
                      Root cause
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mt: 1 }}
                      style={{ color: theme.palette.primary.grey }}
                    >
                      {details.observation && details.observation.rootCause}
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    Action taken / required
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {details.observation && details.observation.actionToPrevent}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    Suggestion
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {details.observation && details.observation.suggestions}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <FormDivider />
              </Grid>
              <Grid item xs={12}>
                <AttachmentViewer
                  disabled={loading}
                  attachments={details.observation.attachments}
                  attachmentLinks={attachmentLinks}
                />
              </Grid>
              {actionRole.role.includes("APPROVER") &&
                details.status === "COMPLETED" && (
                  <>
                    <Grid item xs={12}>
                      <FormDivider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PrimaryButton
                        disabled={
                          loading ||
                          details.observation.status !== "ACTION COMPLETE"
                        }
                        alignment="left"
                        icon={<ApproveIcon />}
                        label="Approve"
                        onClick={submitApproveAction}
                        isgreen
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PrimaryButton
                        disabled={
                          loading ||
                          details.observation.status !== "ACTION COMPLETE"
                        }
                        alignment="left"
                        icon={<RejectIcon />}
                        label="Reject"
                        onClick={() =>
                          setRejectDetails({ ...rejectDetails, open: true })
                        }
                        isred
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </>
        )}
      </Container>
      <InfoBox
        disabled={loading}
        open={returnDetails.open}
        title={t("actions.return")}
        content={
          <Stack spacing={3}>
            <TextInput
              disabled={loading}
              label={t("observations.observationDetails.comment")}
              value={returnDetails.comment}
              handler={(e) =>
                setReturnDetails({
                  ...returnDetails,
                  comment: e.target.value,
                })
              }
            />
            <FileUploader
              files={returnDetails.attachments}
              setFiles={(files) =>
                setReturnDetails({ ...returnDetails, attachments: files })
              }
              disabled={loading}
            />
          </Stack>
        }
        buttonLabel={t("actions.return")}
        handleClose={() => setReturnDetails(returnDetailsDefault)}
        handleAction={submitReturn}
      />
      <InfoBox
        disabled={loading}
        open={reassignDetails.open}
        title={t("actions.reassign")}
        content={
          <Stack spacing={3}>
            <TextInput
              disabled={loading}
              label={t("observations.observationDetails.comment")}
              value={reassignDetails.comment}
              handler={(e) =>
                setReassignDetails({
                  ...reassignDetails,
                  comment: e.target.value,
                })
              }
            />
            <AutocompleteInput
              handler={(e, v) =>
                v.id
                  ? setReassignDetails({
                      ...reassignDetails,
                      newActionPartyId: v.id,
                    })
                  : setReassignDetails({
                      ...reassignDetails,
                      newActionPartyId: "",
                    })
              }
              keyPressHandler={handleSearchUser}
              label={t("observations.observationDetails.actionParty")}
              loading={searchLoading}
              disabled={loading}
              options={userDropdown}
            />
            <FileUploader
              files={reassignDetails.attachments}
              setFiles={(files) =>
                setReassignDetails({ ...reassignDetails, attachments: files })
              }
              disabled={loading}
            />
          </Stack>
        }
        buttonLabel={t("actions.reassign")}
        handleClose={() => setReassignDetails(returnDetailsDefault)}
        handleAction={submitReassign}
      />
      <InfoBox
        disabled={loading}
        open={completeDetails.open}
        title={t("actions.complete")}
        content={
          <Stack spacing={3}>
            <TextInput
              disabled={loading}
              label={t("observations.observationDetails.comment")}
              value={completeDetails.comment}
              handler={(e) =>
                setCompleteDetails({
                  ...completeDetails,
                  comment: e.target.value,
                })
              }
            />
            <FileUploader
              files={completeDetails.attachments}
              setFiles={(files) =>
                setCompleteDetails({ ...completeDetails, attachments: files })
              }
              disabled={loading}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={completeDetails.requiredSOPChanges}
                  name={"requiredSOPChanges"}
                  onChange={() =>
                    setCompleteDetails({
                      ...completeDetails,
                      requiredSOPChanges: !completeDetails.requiredSOPChanges,
                    })
                  }
                  sx={{
                    color: theme.palette.primary.orange,
                    "&.Mui-checked": {
                      color: theme.palette.primary.orange,
                    },
                    "&:hover": {
                      backgroundColor: "rgba(248,147,31,0.08)",
                    },
                  }}
                />
              }
              disabled={loading}
              label={t("actions.changeSOP")}
            />
          </Stack>
        }
        buttonLabel={t("actions.complete")}
        handleClose={() => setCompleteDetails(returnDetailsDefault)}
        handleAction={submitCompleteAction}
      />
      <InfoBox
        disabled={loading}
        open={rejectDetails.open}
        title={"Reject action"}
        content={
          <Stack spacing={3}>
            <TextInput
              disabled={loading}
              label="Why are you rejecting this action?"
              value={rejectDetails.comment}
              handler={(e) =>
                setRejectDetails({
                  ...rejectDetails,
                  comment: e.target.value,
                })
              }
            />
            <FileUploader
              files={rejectDetails.attachments}
              setFiles={(files) =>
                setRejectDetails({ ...rejectDetails, attachments: files })
              }
              disabled={loading}
            />
          </Stack>
        }
        buttonLabel={"Reject action"}
        handleClose={() => setRejectDetails(returnDetailsDefault)}
        handleAction={submitRejectAction}
      />{" "}
    </Page>
  );
}
