/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import PageLoader from "../PageLoader";

BarChart.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
  data: PropTypes.object,
  blankLoading: PropTypes.bool,
};

export default function BarChart(props) {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.data && props?.demo === false) {
      let tempChartOptions = {
        stroke: { colors: ["#FEFEFE"] },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: props?.data?.labels.map((entry) => {
            if (entry === null) return "Not Defined";
            return entry;
          }),
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = [
        {
          name: "Count",
          data: props?.data?.values.map((entry) => entry),
        },
      ];
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    } else if (props.blankLoading) {
    } else {
      let tempChartOptions = {
        stroke: { colors: ["#FEFEFE"] },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: ["Label 1", "Label 2", "Label 3"],
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = [
        {
          name: "Count",
          data: [600, 350, 700],
        },
      ];
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    }
  }, []);

  useEffect(() => {
    if (props.data) {
      let tempChartOptions = {
        stroke: { colors: ["#FEFEFE"] },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: props?.data?.labels.map((entry) => {
            if (entry === null) return "Not Defined";
            return entry;
          }),
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = [
        {
          name: "Count",
          data: props?.data?.values.map((entry) => entry),
        },
      ];
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    } else if (props.chartData) {
      setChartData(props.chartData);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.chartData) {
      setChartData(props.chartData);
    }
    if (props.chartOptions) {
      setChartOptions(props.chartOptions);
    }
  }, [props.chartData, props.chartOptions]);

  if (chartData && chartOptions) {
    return (
      <div
        style={{
          height: props.height || "94%",
          width: "100%",
          direction: "ltr",
        }}
      >
        <ReactApexChart
          type="bar"
          options={chartOptions}
          series={chartData}
          height={"100%"}
          width={"100%"}
        />
      </div>
    );
  } else {
    return <PageLoader />;
  }
}
