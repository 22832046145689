import "./styles.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
// material
import {
  Grid,
  Stack,
  Card,
  Typography,
  Tooltip,
  Box,
  Collapse,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
import { returnPositive } from "../../../utils/general";
// components
import SelectInput from "../../../components/inputs/SelectInput";
import HeatmapChart from "../../../components/charts/HeatmapChart";
import BarChart from "../../../components/charts/BarChart";
import PageLoader from "../../../components/PageLoader";
import PopperBox from "../../../components/PopperBox";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
import AiAlert from "../../../components/AiAlert";
import {
  IconButtonStyled,
  IconStyled,
  MoreIconStyled,
} from "../../observation/ListObservations/styledComponents";
import HelpIcon from "../../../components/HelpIcon";
//  API
import {
  getLSRHeatmapDirectoratesApi,
  getLSRBreakdownDirectorateApi,
  getTopicFiltersApi,
  getLSRAlertsApi,
} from "../../../api/lsrDashboard";
import {
  getCompanyFilters,
  getLocationsAndUnitsFilters,
  getDisciplineFilters,
} from "../utils";

// ----------------------------------------------------------------------
import { barChartOptions } from "./constants";

const cardStyling = {
  height: 600,
  padding: "16px ",
  borderRadius: "6px",
};

const loaderStyling = {
  height: "90%",
  display: "flex",
  alignItems: "center",
};

export default function ImplementationLSRView({ directorateFilters }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [directorateFilter, setDirectorateFilter] = useState("all");
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [topicFilters, setTopicFilters] = useState([]);
  const [topicDropdown, setTopicDropdown] = useState([]);
  const [companyFilter, setCompanyFilter] = useState("all");
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [unitFilter, setUnitFilter] = useState("all");
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [locationFilter, setLocationFilter] = useState("all");
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [lsrCountData, setLsrCountData] = useState([]);
  const [lsrBreakdownOptions, setLsrBreakdownOptions] = useState(null);
  const [lsrBreakdownData, setLsrBreakdownData] = useState([]);
  const [directorateNames, setDirectorateNames] = useState([]);
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [countDataLoaded, setCountDataLoaded] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [alertsLoaded, setAlertsLoaded] = useState(false);

  const heatMapOptions = {
    chart: {
      fontFamily: "Cairo, sans-serif",
      events: {
        click: (event, chartContext, config) => {
          if (
            event.toElement?.nextSibling?.innerHTML &&
            event.target.parentNode.classList.contains(
              "lsr-count-heatmap-yaxis-label"
            )
          ) {
            const targetName = event.toElement?.nextSibling?.innerHTML;
            const finalText = targetName.replace(/&amp;/g, "&");
            const targetInList = directorateNames.find(
              (dObj) => dObj.name === finalText
            );
            if (targetInList) {
              navigate(
                `/dashboard/directorate-dashboard/lsrs/${targetInList.id}`,
                {
                  state: {
                    directorateName: finalText,
                  },
                }
              );
            }
          }
        },
      },
    },
    colors: ["#FF3131"],
    plotOptions: {
      heatmap: {
        enableShades: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#1bc262",
            },
          ],
          min: 1,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val,
    },
    tooltip: {
      y: {
        formatter: (val) => val,
      },
    },
    xaxis: {
      type: "category",
      position: "top",
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: true,
    },
    legend: {
      show: false,
      position: "bottom",
    },
    yaxis: {
      labels: {
        style: {
          cssClass: "lsr-count-heatmap-yaxis-label",
        },
      },
    },
  };

  const handleDirectorate = async (directorate) => {
    if (directorate !== directorateFilter) {
      setDirectorateFilter(directorate);
      setCompanyFilter("all");
      setLocationFilter("all");
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const handleCompany = async (company) => {
    if (company !== companyFilter) {
      setCompanyFilter(company);
      setLocationFilter("all");
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };
  const handleLocation = async (location) => {
    if (location !== locationFilter) {
      setLocationFilter(location);
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const renderFilters = () => (
    <Grid container spacing={1} sx={{ mt: 1 }} alignItems="flex-end">
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.directorateTitle")}
          disabled={loading || directorateDropdown.length < 1}
          value={directorateFilter}
          handler={(e) => handleDirectorate(e.target.value)}
          options={directorateDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.companyTitle")}
          disabled={
            loading || companyDropdown.length < 1 || directorateFilter === "all"
          }
          value={companyFilter}
          handler={(e) => handleCompany(e.target.value)}
          options={companyDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.locationTitle")}
          disabled={
            loading || locationDropdown.length < 1 || companyFilter === "all"
          }
          value={locationFilter}
          handler={(e) => handleLocation(e.target.value)}
          options={locationDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.unitTitle")}
          disabled={
            loading || unitDropdown.length < 1 || locationFilter === "all"
          }
          value={unitFilter}
          handler={(e) => setUnitFilter(e.target.value)}
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.disciplineTitle")}
          disabled={
            loading || disciplineDropdown.length < 1 || locationFilter === "all"
          }
          value={disciplineFilter}
          handler={(e) => setDisciplineFilter(e.target.value)}
          options={disciplineDropdown}
        />
      </Grid>
      <Grid item xs={1} sm={1}>
        <Stack spacing={1} direction="row">
          <Tooltip title={t("general.moreFilters")}>
            <IconButtonStyled
              aria-label={"More filters"}
              onClick={(e) => setPopperAnchor(e.currentTarget)}
            >
              <MoreIconStyled />
            </IconButtonStyled>
          </Tooltip>
          <Tooltip title={t("general.applyFilters")}>
            <IconButtonStyled
              aria-label={t("general.applyFilters")}
              onClick={submitFilters}
            >
              <IconStyled />
            </IconButtonStyled>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderMoreFilters = () => (
    <PopperBox
      filters
      overflowV
      disabled={loading}
      popperAnchor={popperAnchor}
      minWidth="30vw"
      content={
        <MultiSelectInput
          placeholder={t("general.all")}
          label={t("observations.viewObservations.filters.topics")}
          disabled={loading || topicDropdown.length < 1}
          options={topicDropdown}
          handler={(newValue) => setTopicFilters(newValue)}
          values={topicFilters}
        />
      }
      handlePopperClose={() => setPopperAnchor(null)}
    />
  );

  const renderLSRCountChart = () => (
    <Card style={{ ...cardStyling, height: 700 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.lsrCount")}
        </Typography>
        <HelpIcon text={t("dashboards.tooltips.overall.lsrHeatmap")} />
      </Stack>
      {!countDataLoaded ? (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      ) : lsrCountData.length > 0 ? (
        <HeatmapChart
          height="100%"
          chartData={lsrCountData}
          chartOptions={heatMapOptions}
        />
      ) : (
        <Stack
          spacing={1}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoDataIcon />
          <Typography variant="body1">{t("general.noData")}</Typography>
        </Stack>
      )}
    </Card>
  );

  const renderLSRBreakdownChart = () => (
    <Card style={cardStyling}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.lsrCountBreakdown")}
        </Typography>
        <HelpIcon text={t("dashboards.tooltips.overall.lsrBreakdown")} />
      </Stack>
      {lsrBreakdownOptions ? (
        <BarChart
          blankLoading
          height="95%"
          chartData={lsrBreakdownData}
          chartOptions={lsrBreakdownOptions}
        />
      ) : (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      )}
    </Card>
  );

  const renderAlerts = () => (
    <Stack>
      <Collapse in={alertsLoaded}>
        {alertData.map((a, index) => (
          <AiAlert
            handler={() => {
              navigate(`/dashboard/company-dashboard/lsrs/${a.companyId}`, {
                state: {
                  alertStartDate: a.createdAt,
                  companyName: a.companyName,
                  directorateName: a.directorateName,
                  directorateId: a.directorateId,
                },
              });
            }}
            mt={index > 0 ? 1 : 0}
            labelComponent={
              <Typography variant="alertText">
                <Trans
                  i18nKey={
                    a.TotalCountChange
                      ? "dashboards.alerts.lsrs"
                      : "dashboards.alerts.lsrsNull"
                  }
                  values={{
                    companyName: a.companyName,
                    scoreChange: returnPositive(a.TotalCountChange),
                    scoreChangeCrement:
                      a.TotalCountChange > 0 ? "increased" : "decreased",
                    countDifference: returnPositive(a.LabelCountDifference),
                    labels: a.Labels,
                  }}
                  components={{
                    1: <Box component="span" fontWeight="bold" />,
                    2: (
                      <Box
                        component="span"
                        fontWeight="bold"
                        color={theme.palette.primary.red}
                      />
                    ),
                    3: (
                      <Box
                        component="span"
                        fontWeight="bold"
                        color={theme.palette.primary.red}
                      />
                    ),
                    4: <Box component="span" fontWeight="bold" />,
                  }}
                />
              </Typography>
            }
          />
        ))}
      </Collapse>
    </Stack>
  );

  const getLsrCountData = async () => {
    try {
      setLoading(true);
      const rawData = await getLSRHeatmapDirectoratesApi(
        directorateFilter === "all" ? null : directorateFilter,
        companyFilter === "all" ? null : companyFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        locationFilter === "all" ? null : locationFilter,
        topicFilters.length === 0 ? null : topicFilters.map((t) => t.value)
      );
      setLsrCountData(rawData);
      const dNames = rawData.map((d) => {
        return {
          name: d.name,
          id: d.id,
        };
      });
      setDirectorateNames(dNames);
      setCountDataLoaded(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getLsrBreakdownData = async () => {
    try {
      setLoading(true);
      const rawData = await getLSRBreakdownDirectorateApi(
        directorateFilter === "all" ? null : directorateFilter,
        locationFilter === "all" ? null : locationFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        companyFilter === "all" ? null : companyFilter,
        topicFilters.length === 0 ? null : topicFilters.map((t) => t.value)
      );

      const tempCats = Object.keys(rawData);
      const chartCats = tempCats.map((c) => {
        const words = c.split(" ");
        return words;
      });

      const chartData = tempCats.map((cat) => {
        return parseFloat(rawData[cat]).toFixed(2) || 0;
      });
      setLsrBreakdownOptions({
        ...barChartOptions,
        xaxis: {
          ...barChartOptions.xaxis,
          categories: chartCats,
        },
      });
      setLsrBreakdownData([{ name: "LSR Violation Count", data: chartData }]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getLSRAlerts = async () => {
    try {
      setLoading(true);
      setAlertsLoaded(false);
      const lAlerts = await getLSRAlertsApi({
        directorateId: directorateFilter === "all" ? null : directorateFilter,
        companyId: companyFilter === "all" ? null : companyFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
      });
      const finalAlerts = lAlerts.map((q) => {
        return {
          ...q,
          companyName: q.Company?.name || "No contractor",
          directorateName: q.Directorate?.name || "No directorate",
        };
      });
      setAlertData(finalAlerts);
      setAlertsLoaded(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitFilters = () => {
    getLSRAlerts();
    getLsrCountData();
    getLsrBreakdownData();
  };

  useEffect(() => {
    const getTopicFilters = async () => {
      try {
        setLoading(true);
        const topicsRaw = await getTopicFiltersApi();
        setTopicDropdown(topicsRaw);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getTopicFilters();
    getLSRAlerts();
    getLsrCountData();
    getLsrBreakdownData();
  }, []);

  useEffect(() => {
    setDirectorateDropdown(directorateFilters);
  }, [directorateFilters]);

  useEffect(() => {
    if (directorateFilter && directorateFilter !== "all") {
      setCompanyFilter("all");
      getCompanyFilters({
        setLoading,
        directorateFilter,
        setCompanyDropdown,
      });
    }
  }, [directorateFilter]);

  useEffect(() => {
    if (companyFilter && companyFilter !== "all") {
      setLocationFilter("all");
      getLocationsAndUnitsFilters({
        setLoading,
        companyFilter,
        setLocationDropdown,
        setUnitDropdown,
      });
    }
  }, [companyFilter]);

  useEffect(() => {
    if (
      companyFilter &&
      companyFilter !== "all" &&
      locationFilter &&
      locationFilter !== "all"
    ) {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter,
        locationFilter,
        unitFilter,
        setDisciplineDropdown,
      });
    }
  }, [companyFilter, locationFilter, unitFilter]);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {renderAlerts()}
      {renderLSRCountChart()}
      {renderLSRBreakdownChart()}
      {renderMoreFilters()}
    </Stack>
  );
}
