import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getConfigurations,
  getOptions,
  saveConfiguration,
} from "../../../api/kpiConfigurations";
import SelectInput from "../../../components/inputs/SelectInput";
import Page from "../../../components/Page";
import ActionCloseOut from "../KpiConfigurationComponents/ActionCloseOut";
import Knowledge from "../KpiConfigurationComponents/Knowledge";
import LeadershipVisibility from "../KpiConfigurationComponents/LeadershipVisibility";
import NumberInterventionObservers from "../KpiConfigurationComponents/NumberInterventionObservers";
import NumberObservations from "../KpiConfigurationComponents/NumberObservations";
import SteeringCommittee from "../KpiConfigurationComponents/SteeringComittee";
import TrainingCompletion from "../KpiConfigurationComponents/TrainingCompletion";
import PageLoader from "../../../components/PageLoader";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SaveIcon from "@mui/icons-material/Save";
import { displayToast } from "../../../utils/general";
import QualtiyObservation from "../KpiConfigurationComponents/QualityObservation";
import SafeScore from "../KpiConfigurationComponents/SafeScore";
import AuditRetiredBehaviors from "../KpiConfigurationComponents/AuditRetiredBehaviors";
import Audit from "../KpiConfigurationComponents/Audit";
import CBIncident from "../KpiConfigurationComponents/CBIncident";
import AccessControl from "../../../components/AccessControl";

function KpiConfigurations(props) {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [optionsDropdown, setOptionsDropdown] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [actionCloseout, setActionCloseOut] = useState({
    kpiType: "ACT_CLOSE_OUTS",
  });
  const [numberObservation, setNumberObservation] = useState({
    kpiType: "NUM_PLANNED_OBS",
  });
  const [numberInterventionObservers, setNumberIntvObservation] = useState({
    kpiType: "INTV_OBS",
  });
  const [trainingCompletion, setTrainingCompletion] = useState({
    kpiType: "TRAIN_COMPLETION",
  });
  const [leadershipvisibility, setLeadershipVisiblity] = useState({
    kpiType: "LDRSHP_VIS",
  });
  const [steeringCommittee, setSteeringCommittee] = useState({
    kpiType: "STEER_CMITE",
  });
  const [knowledge, setKnowledge] = useState({ kpiType: "KNOWLEDGE" });

  const [qualityObservation, setQualityObservation] = useState({
    kpiType: "QUALITY_OBS",
  });

  const [safeScore, setSafeScore] = useState({
    kpiType: "SAFE_SCORE",
  });

  const [auditRetiredBehaviors, setAuditRetiredBehaviors] = useState({
    kpiType: "RETIRED_BEHAVIORS",
  });

  const [audit, setAudit] = useState({
    kpiType: "AUDIT",
  });

  const [cbIncident, setCbIncident] = useState({
    kpiType: "CB_TO_INCIDENT",
  });

  const [loading, setLoading] = useState(false);
  const getOptionsFunc = async () => {
    setLoading(true);
    try {
      if (
        props?.type === "company" ||
        props?.type === "quality" ||
        props?.type === "knowledge"
      ) {
        getConfigurationsFunc();
      } else {
        let response = await getOptions(companyId);
        setOptionsDropdown(response.options);
        if (response) {
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const resetValues = () => {
    setActionCloseOut({
      kpiType: "ACT_CLOSE_OUTS",
      param1: 0,
      param2: 0,
      param3: 0,
      param4: 0,
    });
    setNumberObservation({
      kpiType: "NUM_PLANNED_OBS",
      param1: 0,
      param2: 0,
      param3: 0,
      param4: 0,
    });
    setNumberIntvObservation({
      kpiType: "INTV_OBS",
      param1: 0,
      param2: 0,
      param3: 0,
      param4: 0,
    });
    setTrainingCompletion({
      kpiType: "TRAIN_COMPLETION",
      param1: 0,
      param2: 0,
      param3: 0,
      param4: 0,
    });
    setLeadershipVisiblity({
      kpiType: "LDRSHP_VIS",
      param1: 0,
      param2: 0,
      param3: 0,
      param4: 0,
    });
    setSteeringCommittee({
      kpiType: "STEER_CMITE",
      param1: 0,
      param2: 0,
      param3: 0,
      param4: 0,
    });
    // setKnowledge({
    //   kpiType: "KNOWLEDGE",
    //   param1: 0,
    //   param2: 0,
    //   param3: 0,
    //   param4: 0,
    // });
    // setQualityObservation({
    //   kpiType: "QUALITY_OBS",
    //   param1: 0,
    //   param2: 0,
    //   param3: 0,
    //   param4: 0,
    // });
  };

  const getConfigurationsFunc = async () => {
    try {
      resetValues();
      let response = await getConfigurations(selectedOption, companyId);
      if (response) {
        response.forEach((entry) => {
          switch (entry.kpiType) {
            case "ACT_CLOSE_OUTS":
              setActionCloseOut(entry);
              break;
            case "NUM_PLANNED_OBS":
              setNumberObservation(entry);
              break;
            case "INTV_OBS":
              setNumberIntvObservation(entry);
              break;
            case "TRAIN_COMPLETION":
              setTrainingCompletion(entry);
              break;
            case "LDRSHP_VIS":
              setLeadershipVisiblity(entry);
              break;
            case "STEER_CMITE":
              setSteeringCommittee(entry);
              break;
            case "KNOWLEDGE":
              setKnowledge(entry);
              break;
            case "QUALITY_OBS":
              setQualityObservation(entry);
              break;
            case "SAFE_SCORE":
              setSafeScore(entry);
              break;
            case "RETIRED_BEHAVIORS":
              setAuditRetiredBehaviors(entry);
              break;
            case "AUDIT":
              setAudit(entry);
              break;
            case "CB_TO_INCIDENT":
              setCbIncident(entry);
              break;
            default:
              console.log(`no entry matched`, entry.kpiType);
          }
        });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSaveConfig = async () => {
    try {
      setLoading(true);
      let tempData = [];
      if (props?.type === "company") {
        tempData = [
          steeringCommittee,
          leadershipvisibility,
          safeScore,
          auditRetiredBehaviors,
          audit,
        ];
      } else if (props?.type === "quality") {
        tempData = [qualityObservation];
      } else if (props?.type === "knowledge") {
        tempData = [knowledge];
      } else {
        tempData = [
          actionCloseout,
          numberObservation,
          numberInterventionObservers,
          trainingCompletion,
        ];
      }
      let response = await saveConfiguration(selectedOption, companyId, {
        data: tempData,
      });
      if (response) {
        displayToast(
          setLoading,
          "success",
          "KPI Config saved successfully",
          "successSavingConfig"
        );
      }
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        "Error saving KPI Config",
        "errorSavingConfig"
      );
    }
  };

  useEffect(() => {
    getOptionsFunc();
  }, []);

  useEffect(() => {
    if (selectedOption) {
      getConfigurationsFunc();
    }
  }, [selectedOption]);

  return (
    <Page title={t("kpi.configurations")}>
      <Grid container spacing={1}>
        {props?.type === "locationunit" && (
          <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
            <SelectInput
              value={selectedOption}
              handler={(e) => {
                setLoading(true);
                setSelectedOption(e.target.value);
              }}
              options={optionsDropdown}
              label={t("kpi.locationunit")}
              disabled={loading}
            />
          </Grid>
        )}
      </Grid>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PageLoader />
        </div>
      ) : (
        <>
          {selectedOption && (
            <>
              {props?.allowed?.includes("KPI_ACT_CLOSE_OUTS") && (
                <ActionCloseOut
                  data={actionCloseout}
                  setData={(e) => setActionCloseOut(e)}
                />
              )}
              {props?.allowed?.includes("KPI_NUM_PLANNED_OBS") && (
                <NumberObservations
                  data={numberObservation}
                  setData={(e) => setNumberObservation(e)}
                />
              )}
              {props?.allowed?.includes("KPI_INTV_OBS") && (
                <NumberInterventionObservers
                  data={numberInterventionObservers}
                  setData={(e) => setNumberIntvObservation(e)}
                />
              )}
              {props?.allowed?.includes("KPI_TRAIN_COMPLETION") && (
                <TrainingCompletion
                  data={trainingCompletion}
                  setData={(e) => setTrainingCompletion(e)}
                />
              )}
              {/* {props?.allowed?.includes("KPI_CB_TO_INCIDENT") && (
                <CBIncident data={cbIncident} />
              )} */}

              <Grid style={{ marginTop: "35px" }} item xs={12} sm={12} md={12}>
                <div>
                  <PrimaryButton
                    icon={<SaveIcon />}
                    label={t("kpi.save")}
                    alignment="left"
                    disabled={loading}
                    onClick={() => handleSaveConfig()}
                  />
                </div>
              </Grid>
            </>
          )}
          {props?.type === "company" && (
            <>
              {props?.allowed?.includes("KPI_LDRSHP_VIS") && (
                <LeadershipVisibility
                  data={leadershipvisibility}
                  setData={(e) => setLeadershipVisiblity(e)}
                />
              )}

              {props?.allowed?.includes("KPI_STEER_CMITE") && (
                <SteeringCommittee
                  data={steeringCommittee}
                  setData={(e) => setSteeringCommittee(e)}
                />
              )}

              {/* Disabled because only param is kpi weightage which has been moved */}
              {/* {props?.allowed?.includes("KPI_SAFE_SCORE") && (
                <SafeScore data={safeScore} setData={(e) => setSafeScore(e)} />
              )}

              {props?.allowed?.includes("KPI_RETIRED_BEHAVIORS") && (
                <AuditRetiredBehaviors
                  data={auditRetiredBehaviors}
                  setData={(e) => setAuditRetiredBehaviors(e)}
                />
              )}

              {props?.allowed?.includes("KPI_AUDIT") && (
                <Audit data={audit} setData={(e) => setAudit(e)} />
              )} */}

              {/* <Knowledge data={knowledge} setData={(e) => setKnowledge(e)} />
              <QualtiyObservation data={qualityObservation} setData={(e) => setQualityObservation(e)}/> */}
              <Grid style={{ marginTop: "35px" }} item xs={12} sm={12} md={12}>
                <div>
                  <PrimaryButton
                    icon={<SaveIcon />}
                    label={t("kpi.save")}
                    alignment="left"
                    disabled={loading}
                    onClick={() => handleSaveConfig()}
                  />
                </div>
              </Grid>
            </>
          )}
          {props?.type === "quality" && (
            <>
              {props?.allowed?.includes("KPI_QUALITY_OBS") && (
                <QualtiyObservation
                  data={qualityObservation}
                  setData={(e) => setQualityObservation(e)}
                />
              )}

              <Grid style={{ marginTop: "35px" }} item xs={12} sm={12} md={12}>
                <div>
                  <PrimaryButton
                    icon={<SaveIcon />}
                    label={t("kpi.save")}
                    alignment="left"
                    disabled={loading}
                    onClick={() => handleSaveConfig()}
                  />
                </div>
              </Grid>
            </>
          )}
          {props?.type === "knowledge" && (
            <>
              {props?.allowed?.includes("KPI_KNOWLEDGE") && (
                <Knowledge data={knowledge} setData={(e) => setKnowledge(e)} />
              )}

              <Grid style={{ marginTop: "35px" }} item xs={12} sm={12} md={12}>
                <div>
                  <PrimaryButton
                    icon={<SaveIcon />}
                    label={t("kpi.save")}
                    alignment="left"
                    disabled={loading}
                    onClick={() => handleSaveConfig()}
                  />
                </div>
              </Grid>
            </>
          )}
        </>
      )}
    </Page>
  );
}

export default KpiConfigurations;
