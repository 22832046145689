import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import "./quillOverride.css";
import useAuth from "../../context/useAuth";
//  MUI
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  Stack,
  Card,
  CardHeader,
  Tabs,
  Tab,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEditOutlined";
//  Components
import Page from "../../components/Page";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import RadioSet from "../../components/buttons/RadioSet";
import Logo from "../../components/Logo";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import InfoBox from "../../components/InfoBox";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import Layout2x2Icon from "./chart_icons/icon_2x2.svg";
import LayoutStackedIcon from "./chart_icons/icon_stacked.svg";
import Layout2and3Icon from "./chart_icons/icon_column_row.svg";
//  Charts
import PieChartIcon from "@mui/icons-material/PieChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import TocIcon from "@mui/icons-material/Toc";
import TableRowsIcon from "@mui/icons-material/TableRows";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import FileUpload from "@mui/icons-material/FileUpload";

import HelpText from "../../components/HelpText";
import {
  getDirectorateFiltersApi,
  getContractorFiltersApi,
} from "../../api/dashboards";
import {
  getDirectorateFilters,
  getCompanyFilters,
} from "../../api/kpiDashboards";
import { createCreatorReport, getCreatorReport } from "../../api/reports";
import {
  renderChart,
  getApiFunctions,
  renderChartAdapted,
  htmlDecode,
} from "../dashboard/utils";
import MultiSelectInput from "../../components/inputs/MultiSelectInput";
import DateInput from "../../components/inputs/DateInput";
import DefaultTable from "../../components/tables/DefaultTable";

import toast from "react-hot-toast";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import ColorPicker from "../../components/inputs/ColorPicker";
import Dropzone from "react-dropzone";
import { getAttachmentsApi } from "../../api/attachments";
import { displayToast } from "../../utils/general";

const iconStyles = {
  cursor: "pointer",
  height: "18px",
  width: "18px",
};

const pageDimensions = {
  //  In cm
  width: 29.7,
  minHeight: 21,
  margin: 1,
  header: {
    maxHeight: 2.97,
  },
};

const defaultUnit = {
  type: "",
};

const addDefaults = {
  header: {
    text: "",
    type: "h2",
    backgroundColor: "#FFF3E4",
    textColor: "#000000",
  },
  name: "",
  pages: [
    {
      type: "2x2",
      noOfUnits: 4,
      units: Array(4).fill(defaultUnit),
    },
  ],
};

const headerModalDefaults = {
  open: false,
  type: "h2",
  text: "",
  backgroundColor: "#FFF3E4",
  textColor: "#000000",
};

const contentModalDefaults = {
  type: "",
  open: false,
  boxId: 0,
  pageIndex: 0,
  chartType: "",
};

const fontDropdown = [
  {
    label: "Large",
    value: "h2",
  },
  {
    label: "Medium",
    value: "h3",
  },
  {
    label: "Small",
    value: "24",
  },
];

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ direction: "rtl" }],
    [{ color: [] }],
  ],
};

export default function ReportCreator() {
  const today = new Date();
  const { access } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const previewRef = useRef(null);
  const pagesRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const [textEditor, setTextEditor] = useState("");
  const [page, setPage] = useState(1);
  const [addDetails, setAddDetails] = useState(addDefaults);
  const [headerModal, setHeaderModal] = useState(headerModalDefaults);
  const [contentModal, setContentModal] = useState(contentModalDefaults);
  //  Need to restrict this according to VIEW_DASH and VIEW_DASH_KPI permissions
  //  Also need to stop the directorate filters dashboard api from being called and also change the one for kpi to directorate filters for kpi
  const [graphTypes, setGraphTypes] = useState([
    ...(access.includes("VIEW_DASH_KPI")
      ? [
          {
            label: "Directorate KPIs",
            value: "directorateKpis",
            api: "directorateKpis",
            supported: ["heatmap"],
          },
        ]
      : []),
    ...(access.includes("VIEW_DASH")
      ? [
          {
            label: "Top Reported Unsafe Behaviours",
            value: "topReportedUnsafeBehaviours",
            api: "topUnsafeBehavior",
            supported: ["Column"],
          },
          {
            label: "Behaviour Name",
            value: "behaviourName",
            api: "behaviorNames",
            supported: ["Column"],
          },
          {
            label: "Behaviour Name (Critical)",
            value: "behaviourNameCritical",
            api: "behaviorNamesCritical",
            supported: ["Column"],
          },
          {
            label: "Behaviour Name (Other)",
            value: "behaviourNameOther",
            api: "behaviorNamesOther",
            supported: ["Column"],
          },
          {
            label: "Observation Type",
            value: "behaviourType",
            api: "behaviorType",
            supported: ["Pie"],
          },
        ]
      : []),
  ]);
  const [graphType, setGraphType] = useState(null);
  const [supportedTypes, setSupportedTypes] = useState([]);
  const [selectedSupportedType, setSelectedSupportedType] = useState(0);
  const [filterModal, setFilterModal] = useState({
    open: false,
    page: 0,
    unit: 0,
    chartId: "",
  });
  const [directorates, setDirectorates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedDirectorates, setSelectedDirectorates] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [dateEnabled, setDateEnabled] = useState(false);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [reports, setReports] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [selectedImage, setSelectedImage] = useState(null);
  const [filterParams, setFilterParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [previewModal, setPreviewModal] = useState({
    open: false,
  });
  const [previewLink, setPreviewLink] = useState();
  const [openReportId, setOpenReportId] = useState(null);
  const [selectedLimit, setSelectedLimit] = useState();

  const reportsColumns = [
    {
      field: "name",
      headerName: t("table.name"),
      width: "400",
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: t("table.creationDate"),
      width: "400",
      sortable: false,
      renderCell: (params) => {
        return moment(params.row.createdAt).format("DD/MM/YY  hh:mm a");
      },
    },
  ];

  const layoutTypes = [
    {
      name: "2x2",
      icon: Layout2x2Icon,
      label: t("reporting.layoutTypes.2x2"),
      alt: t("reporting.layoutTypes.2x2alt"),
      idsToGenerate: 4,
    },
    {
      name: "stacked",
      icon: LayoutStackedIcon,
      label: t("reporting.layoutTypes.stacked"),
      alt: t("reporting.layoutTypes.stackedAlt"),
      idsToGenerate: 6,
    },
    {
      name: "columnsRows",
      icon: Layout2and3Icon,
      label: t("reporting.layoutTypes.columnsRows"),
      alt: t("reporting.layoutTypes.columnsRowsAlt"),
      idsToGenerate: 5,
    },
  ];

  const pageStyling = {
    width: `${pageDimensions.width}cm`,
    minHeight: `${pageDimensions.minHeight}cm`,
    margin: `${pageDimensions.margin}cm auto`,
    border: `2px solid ${theme.palette.primary.orange}`,
  };

  const headerStyling = {
    // backgroundColor: theme.palette.primary.translucentOrange,
    maxHeight: `${pageDimensions.header.maxHeight}cm`,
  };

  const fourGridBlock = {
    backgroundColor: theme.palette.primary.translucentOrange,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 1 0",
  };

  const editIconContainerStyling = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const editIconStyling = {
    fill: theme.palette.primary.orange,
    height: "0.5cm",
    width: "0.5cm",
    marginRight: "0.2cm",
  };

  const editTextStyling = {
    color: theme.palette.primary.orange,
    // fontWeight: "bold",
    // textDecoration: "underline",
  };

  const previewStyling = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.orange}`,
    minHeight: "50vh",
  };

  const getReports = async () => {
    try {
      setLoading(true);
      let reports = await getCreatorReport(filterParams);
      setReports(reports.reports);
      setTableInfo({
        totalCount: reports.totalItems,
        totalPages: reports.totalPages,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getDirectorates = async (chartId) => {
    try {
      setLoading(true);
      //  Get filters for directorates
      let directorateFilters = [];
      if (chartId === "directorateKpis") {
        const result = await getDirectorateFilters();
        if (result.directorates) {
          directorateFilters = result.directorates;
        }
      } else {
        directorateFilters = await getDirectorateFiltersApi();
      }
      setDirectorates(directorateFilters);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getContractorFilters = async () => {
    try {
      setLoading(true);
      if (selectedDirectorates.length > 0) {
        const directorateIds = selectedDirectorates.map((entry) => entry.value);
        const payload = JSON.stringify(directorateIds);
        let contractorFilters = [];
        if (filterModal.chartId === "directorateKpis") {
          const result = await getCompanyFilters(directorateIds);
          if (result?.companies) {
            contractorFilters = result.companies;
          }
        } else {
          contractorFilters = await getContractorFiltersApi(payload);
        }
        //  Remove the invalid ones from the selected contractors list
        const newContractorSelection = selectedCompanies.filter((element) =>
          contractorFilters.find(
            (contractor) => element.value === contractor.value
          )
        );
        setSelectedCompanies(newContractorSelection);
        setCompanies(contractorFilters);
      } else {
        setSelectedCompanies([]);
      }
      setLoading(false);
    } catch (err) {
      setCompanies([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getContractorFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDirectorates]);

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    if (!selectedImage) {
      setPreviewLink(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewLink(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const renderCreationView = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextInput
          disabled={loading}
          handler={(e) =>
            setAddDetails({ ...addDetails, name: e.target.value })
          }
          label={t("reporting.inputName")}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">{t("reporting.layoutText")}</Typography>
      </Grid>

      {layoutTypes.map((l, i) => (
        <Grid item xs={12} md={4}>
          <Stack spacing={1}>
            <img src={l.icon} alt={l.alt} />
            <FormControlLabel
              key={`report-creator-layout-type-${l.name}`}
              disabled={loading}
              control={
                <Radio
                  sx={{
                    color: theme.palette.primary.orange,
                    "&.Mui-checked": { color: theme.palette.primary.orange },
                    "&:hover": {
                      backgroundColor: "rgba(248,147,31,0.08)",
                    },
                  }}
                  checked={addDetails.pages[0].type === l.name}
                  onChange={() => {
                    const pages = addDetails.pages;
                    pages[0].type = l.name;
                    pages[0].noOfUnits = l.idsToGenerate;
                    setAddDetails({ ...addDetails, pages: [...pages] });
                  }}
                />
              }
              label={l.label}
              sx={{
                justifyContent: "center",
              }}
            />
          </Stack>
        </Grid>
      ))}

      <Grid item xs={12}>
        <PrimaryButton
          onClick={() => {
            const firstPage = { ...addDetails.pages[0] };
            firstPage.units = Array(firstPage.noOfUnits).fill(defaultUnit);
            setOpenReportId(null);
            setSelectedImage(null);
            setPreviewLink(undefined);
            setAddDetails({
              ...addDetails,
              pages: [firstPage],
              header: addDefaults.header,
            });
            setPage(2);
          }}
          disabled={!addDetails.name || loading}
          label={t("general.next")}
          alignment="left"
        />
      </Grid>
      <Box sx={{ pb: 2, pt: 2 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4">{t("reporting.myReports")}</Typography>
        </div>
      </Box>
      <Grid item xs={12}>
        <DefaultTable
          rows={reports}
          columns={reportsColumns}
          loading={loading}
          tableInfo={tableInfo}
          filterParams={filterParams}
          setFilterParams={(e) => {
            setFilterParams(e);
          }}
          minHeight="65vh"
          onRowClick={async (r) => {
            if (r.row.attachment) {
              const attachment = await getAttachmentsApi([r.row.attachment.id]);
              setPreviewLink(attachment[0].url);
            }
            setOpenReportId(r.row.id);
            setAddDetails(JSON.parse(htmlDecode(r.row.reportJson)));
            setPage(2);
          }}
        />
      </Grid>
    </Grid>
  );

  const renderEditorView = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("reporting.layoutEditor")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("reporting.editorDescription")}
        </Typography>
      </Grid>
      {addDetails.pages?.map((p, index) => (
        <Grid item xs={12}>
          <div style={pageStyling}>
            <div
              style={{
                ...headerStyling,
                backgroundColor: addDetails.header.backgroundColor,
                color: addDetails.header.textColor,
              }}
            >
              <Stack
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: "0.5cm" }}
                direction="row"
              >
                <Logo src={previewLink} />
                <Typography variant={addDetails.header.type || "h2"}>
                  {addDetails.header.text ||
                    `[${t("reporting.reportTitlePlaceholder")}]`}
                </Typography>
                <div
                  style={editIconContainerStyling}
                  onClick={() =>
                    setHeaderModal({
                      ...headerModal,
                      open: true,
                      text: addDetails.header.text,
                      type: addDetails.header.type,
                      textColor: addDetails.header.textColor,
                      backgroundColor: addDetails.header.backgroundColor,
                    })
                  }
                >
                  <EditIcon style={editIconStyling} />
                  <Typography variant="body2" style={editTextStyling}>
                    {t("reporting.headerModal")}
                  </Typography>
                </div>
              </Stack>
            </div>
            {renderLayout(index)}
          </div>
        </Grid>
      ))}
      <Grid item xs={6}>
        <PrimaryButton
          onClick={() => {
            setLoading(true);
            const activeToast = displayToast(
              setLoading,
              "loading",
              t("reporting.loadingSaveReport")
            );
            delete addDetails.header.logo;
            const reqObj = {
              name: addDetails.name,
              report: JSON.stringify(addDetails),
              attachments: [selectedImage],
            };
            if (openReportId) reqObj.id = openReportId;
            createCreatorReport(reqObj)
              .then((resp) => {
                setOpenReportId(resp.id);
                displayToast(
                  setLoading,
                  "success",
                  t("reporting.reportSaved"),
                  activeToast
                );
                return getReports();
              })
              .catch((err) => {
                setLoading(false);
                displayToast(setLoading, "error", err, activeToast);
              });
          }}
          disabled={loading}
          label={t("save")}
          alignment="left"
        />
      </Grid>
      <Grid item xs={6}>
        <SecondaryButton
          onClick={() => {
            setPreviewModal({ open: true });
          }}
          disabled={loading}
          label={t("reporting.preview")}
          alignment="left"
        />
      </Grid>
    </Grid>
  );

  const renderPreviewView = () => (
    <Grid container spacing={2}>
      {addDetails.pages?.map((p, index) => (
        <Grid item xs={12} ref={(el) => (pagesRef.current[index] = el)}>
          <div style={pageStyling}>
            <div
              style={{
                ...headerStyling,
                backgroundColor: addDetails.header.backgroundColor,
                color: addDetails.header.textColor,
              }}
            >
              <Stack
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: "0.5cm" }}
                direction="row"
              >
                <Logo src={previewLink} />
                <div
                  style={{ display: "flex", justifyContent: "center", flex: 1 }}
                >
                  <Typography
                    style={{ marginLeft: "-90px" }}
                    variant={addDetails.header.type || "h2"}
                  >
                    {addDetails.header.text}
                  </Typography>
                </div>
              </Stack>
            </div>
            {renderLayout(index, true)}
          </div>
        </Grid>
      ))}
    </Grid>
  );

  const renderHeaderModal = () => (
    <InfoBox
      disabled={loading || !headerModal.text}
      minWidth="50vw"
      open={headerModal.open}
      title={t("reporting.headerModal")}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Stack
                direction="row"
                style={{ paddingBottom: "4px" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" style={{ color: "#4C4C4E" }}>
                  <div style={{ display: "flex" }}>
                    {t("reporting.headerLogo")}
                  </div>
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap="5rem">
                <Box
                  component="img"
                  alt={t("reporting.headerLogo")}
                  src={previewLink || "/static/ihtimam_pdo_logo.svg"}
                  sx={{
                    mt: 3,
                    maxWidth: "200px",
                    borderRadius: "10px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    boxShadow: "0px 2px 9px 0px #0000001C",
                    padding: "30px",
                  }}
                />
                <Dropzone
                  accept={["image/png", "image/jpeg"]}
                  filesLimit={1}
                  onDrop={(acceptedImage) => {
                    setSelectedImage(acceptedImage[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <SecondaryButton
                        disabled={loading}
                        label={t("reporting.uploadLogo")}
                        alignment="left"
                        icon={<FileUpload />}
                      />
                    </div>
                  )}
                </Dropzone>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t("reporting.headerTitle")}
              disabled={loading}
              value={headerModal.text}
              handler={(e) =>
                setHeaderModal({ ...headerModal, text: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              disabled={loading}
              value={headerModal.type}
              label={t("reporting.textEdit")}
              handler={(e) =>
                setHeaderModal({ ...headerModal, type: e.target.value })
              }
              options={fontDropdown}
            />
          </Grid>
          <Grid item xs={6}>
            <ColorPicker
              label={t("reporting.bgColor")}
              labelColor="#4C4C4E"
              value={headerModal.backgroundColor}
              onChangeComplete={(color, event) => {
                setHeaderModal({ ...headerModal, backgroundColor: color.hex });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ColorPicker
              label={t("reporting.textColor")}
              labelColor="#4C4C4E"
              value={headerModal.textColor}
              onChangeComplete={(color, event) => {
                setHeaderModal({ ...headerModal, textColor: color.hex });
              }}
            />
          </Grid>
        </Grid>
      }
      handleClose={() => setHeaderModal(headerModalDefaults)}
      buttonLabel={t("general.saveChanges")}
      handleAction={() => {
        setHeaderModal({ ...headerModal, open: false });
        setAddDetails({
          ...addDetails,
          header: {
            text: headerModal.text,
            type: headerModal.type,
            backgroundColor: headerModal.backgroundColor,
            textColor: headerModal.textColor,
            logo: selectedImage,
          },
        });
      }}
    />
  );

  const renderEditContentIcon = (pageIndex, boxId, type) => {
    if (type === "new") {
      return (
        <div
          style={editIconContainerStyling}
          onClick={() => handleEditClick(type, pageIndex, boxId)}
        >
          <EditIcon style={editIconStyling} />
          <Typography variant="body2" style={editTextStyling}>
            {t("reporting.editContent")}
          </Typography>
        </div>
      );
    } else {
      return (
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => handleEditClick(type, pageIndex, boxId)}
        >
          <EditIcon
            style={{
              fill: theme.palette.primary.orange,
              height: "0.3cm",
              width: "0.3cm",
              marginRight: "0.2cm",
            }}
          />
          <Typography variant="caption" style={editTextStyling}>
            {t("general.edit")}
          </Typography>
        </div>
      );
    }
  };

  const renderContentModal = () => (
    <InfoBox
      disabled={loading}
      minWidth="60vw"
      minHeight="90vh"
      open={contentModal.open}
      title={t("reporting.editContent")}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioSet
              disabled={loading}
              label={t("reporting.contentType")}
              value={contentModal.type}
              handleChange={(e) => handleContentTypeChange(e)}
              options={[
                {
                  label: t("reporting.chart"),
                  value: "chart",
                },
                {
                  label: t("reporting.text"),
                  value: "text",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            {contentModal.type === "chart" && contentModal.chartId ? (
              <>
                <Card
                  className="chartContainer"
                  ref={previewRef}
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                    borderRadius: 0,
                    border: "1px solid #C2C2C2",
                    minHeight: "50vh",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardHeader title={contentModal?.chartName} />
                  <div style={{ overflow: "auto", flexGrow: 1 }}>
                    {renderChart(
                      `${contentModal?.chartId}${graphType}`,
                      null,
                      null,
                      true
                    )}
                  </div>
                </Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tabs
                    value={selectedSupportedType}
                    onChange={(e, v) => {
                      setGraphType(supportedTypes[v]);
                      setSelectedSupportedType(v);
                      setContentModal({
                        ...contentModal,
                        chartType: `${contentModal.chartId}${supportedTypes[v]}`,
                      });
                    }}
                    aria-label="icon label tabs example"
                  >
                    {supportedTypes.map((entry) => (
                      <Tab
                        icon={
                          entry === "Pie" ? (
                            <PieChartIcon />
                          ) : entry === "Column" ? (
                            <BarChartIcon />
                          ) : entry === "Stacked" ? (
                            <StackedBarChartIcon />
                          ) : entry === "Multiline" ? (
                            <SsidChartIcon />
                          ) : entry === "Counter" ? (
                            <TocIcon />
                          ) : (
                            <TableRowsIcon />
                          )
                        }
                        label={entry}
                      />
                    ))}
                  </Tabs>
                </div>
              </>
            ) : contentModal.type === "chart" ? (
              <div style={previewStyling}>
                <Typography
                  variant="body1"
                  style={{ color: theme.palette.primary.orange }}
                >
                  {t(`dashboards.addCharts.previewText`)}
                </Typography>
              </div>
            ) : (
              <div style={{ height: "50vh" }}>
                <ReactQuill
                  theme="snow"
                  value={textEditor}
                  onChange={setTextEditor}
                  modules={quillModules}
                />
              </div>
            )}
          </Grid>
          {contentModal.type === "chart" && (
            <Grid item xs={12} md={10}>
              <SelectInput
                value={contentModal.chartId}
                handler={(e) => {
                  handleSelectChart(e?.target?.value);
                }}
                options={graphTypes}
                label={t(`dashboards.addCharts.title`)}
                disabled={loading || graphTypes.length === 0}
              />
              {graphTypes.length === 0 && (
                <Typography variant="caption" color="red">
                  {t("dashboards.noChartsError")}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      }
      handleClose={() => setContentModal(contentModalDefaults)}
      buttonLabel={t("general.submit")}
      handleAction={submitAddWidget}
    />
  );

  const renderPreviewModal = () => (
    <InfoBox
      minWidth={`31cm`}
      disabled={loading}
      open={previewModal.open}
      title={t("reporting.preview")}
      content={renderPreviewView()}
      handleClose={() => setPreviewModal({ ...previewModal, open: false })}
      buttonLabel={t("general.downloadPdf")}
      handleAction={() => {
        submitDownload();
      }}
    />
  );

  const submitDownload = () => {
    try {
      toast.remove();
      setLoading(true);
      toast.loading(t("reporting.downloading"), {
        duration: Infinity,
      });

      setTimeout(async () => {
        const pdf = new jsPDF("l", "pt", "a4", true);
        const width = pdf.internal.pageSize.getWidth();
        // const w = width - width * 0.1;
        const height = pdf.internal.pageSize.getHeight();
        // const h = height - height * 0.1;
        for (let index = 0; index < pagesRef.current.length; index++) {
          let ahtml2canvas = await html2canvas(pagesRef.current[index], {
            scale: 4,
            useCORS: true,
          });
          let imgData = ahtml2canvas.toDataURL("image/png");
          // pdf.addImage(imgData, "JPEG", width * 0.05, 0, w, h);
          pdf.addImage(imgData, "PNG", 0, 0, width, height, "", "FAST");
          if (index + 1 !== pagesRef.current.length) {
            pdf.addPage();
          } else {
            pdf.save(`${addDetails.name}_${today}.pdf`);
            setLoading(false);
            toast.remove();
            toast.success(t("reporting.downloadSuccess"));
            setPreviewModal({ ...previewModal, open: false });
          }
          // html2canvas(pagesRef.current[index], {scale: 2}).then((canvas) => {
          //     const imgData = canvas.toDataURL("image/png");
          //     console.log(imgData)
          //     pdf.addImage(imgData, "JPEG", width * 0.05, 0, w, h);
          //     console.log("ShowMeHere",)
          //     if (index + 1 !== pagesRef.current.length) {
          //         pdf.addPage();

          //     } else {
          //         pdf.save(`${chartInfo.title}_${today}.pdf`);
          //         displayToast(setLoading, "downloadDone", "Report downloaded successfully");
          //     }
          // }).catch(err => {
          //     setLoading(false);
          //     displayToast(setLoading, "downloadError", "Error in downloading report");
          //     console.log(err)
          // });
        }
      }, 1000);
    } catch (err) {
      toast.remove();
      toast.error(t("reporting.downloadError"));
      setPreviewModal({ ...previewModal, open: false });
      setLoading(false);
    }
  };

  const handleSelectChart = (e) => {
    let findIndex = graphTypes.findIndex((i) => i.value === e);
    let supportedTypes = graphTypes[findIndex]["supported"];
    const chartName = graphTypes[findIndex]["label"];
    setGraphType(supportedTypes[0]);
    setSupportedTypes(supportedTypes);
    setSelectedSupportedType(0);
    setContentModal({
      ...contentModal,
      chartId: `${e}`,
      chartName,
      chartType: `${e}${supportedTypes[0]}`,
      api: graphTypes[findIndex].api,
    });
  };

  const renderLayout = (pageIndex, preview) => {
    switch (addDetails.pages[pageIndex].type) {
      case "2x2":
        return (
          <>
            <Stack
              direction="row"
              style={{
                height: `${
                  (pageDimensions.minHeight - pageDimensions.header.maxHeight) /
                  2
                }cm`,
                justifyContent: "space-between",
                gap: "8px",
                marginTop: "8px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              {renderUnit(
                addDetails.pages[pageIndex].units[0],
                0,
                pageIndex,
                preview
              )}
              {renderUnit(
                addDetails.pages[pageIndex].units[1],
                1,
                pageIndex,
                preview
              )}
            </Stack>
            <Stack
              direction="row"
              style={{
                height: `${
                  (pageDimensions.minHeight - pageDimensions.header.maxHeight) /
                  2
                }cm`,
                justifyContent: "space-between",
                gap: "8px",
                marginTop: "8px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              {renderUnit(
                addDetails.pages[pageIndex].units[2],
                2,
                pageIndex,
                preview
              )}
              {renderUnit(
                addDetails.pages[pageIndex].units[3],
                3,
                pageIndex,
                preview
              )}
            </Stack>
          </>
        );

      case "stacked":
        return (
          <>
            <Stack
              direction="row"
              style={{
                height: `${
                  (pageDimensions.minHeight - pageDimensions.header.maxHeight) /
                  2
                }cm`,
                justifyContent: "space-between",
                gap: "8px",
                marginTop: "8px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              {renderUnit(
                addDetails.pages[pageIndex].units[0],
                0,
                pageIndex,
                preview
              )}

              <Stack
                direction="column"
                style={{
                  height: `${
                    (pageDimensions.minHeight -
                      pageDimensions.header.maxHeight) /
                    2
                  }cm`,
                  flex: "1 1 0",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                <div style={{ height: "50%", display: "flex" }}>
                  {renderUnit(
                    addDetails.pages[pageIndex].units[1],
                    1,
                    pageIndex,
                    preview
                  )}
                </div>
                <div style={{ height: "50%", display: "flex" }}>
                  {renderUnit(
                    addDetails.pages[pageIndex].units[2],
                    2,
                    pageIndex,
                    preview
                  )}
                </div>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              style={{
                height: `${
                  (pageDimensions.minHeight - pageDimensions.header.maxHeight) /
                  2
                }cm`,
                justifyContent: "space-between",
                gap: "8px",
                marginTop: "8px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              <Stack
                direction="column"
                style={{
                  height: `${
                    (pageDimensions.minHeight -
                      pageDimensions.header.maxHeight) /
                    2
                  }cm`,
                  flex: "1 1 0",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                <div style={{ height: "50%", display: "flex" }}>
                  {renderUnit(
                    addDetails.pages[pageIndex].units[3],
                    3,
                    pageIndex,
                    preview
                  )}
                </div>
                <div style={{ height: "50%", display: "flex" }}>
                  {renderUnit(
                    addDetails.pages[pageIndex].units[4],
                    4,
                    pageIndex,
                    preview
                  )}
                </div>
              </Stack>
              {renderUnit(
                addDetails.pages[pageIndex].units[5],
                5,
                pageIndex,
                preview
              )}
            </Stack>
          </>
        );

      case "columnsRows":
        return (
          <>
            <Stack
              direction="row"
              style={{
                height: `${
                  (pageDimensions.minHeight - pageDimensions.header.maxHeight) /
                  2
                }cm`,
                justifyContent: "space-between",
                gap: "8px",
                marginTop: "8px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              {renderUnit(
                addDetails.pages[pageIndex].units[0],
                0,
                pageIndex,
                preview
              )}
              {renderUnit(
                addDetails.pages[pageIndex].units[1],
                1,
                pageIndex,
                preview
              )}
            </Stack>
            <Stack
              direction="row"
              style={{
                height: `${
                  (pageDimensions.minHeight - pageDimensions.header.maxHeight) /
                  2
                }cm`,
                justifyContent: "space-between",
                gap: "8px",
                marginTop: "8px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              {renderUnit(
                addDetails.pages[pageIndex].units[2],
                2,
                pageIndex,
                preview
              )}
              {renderUnit(
                addDetails.pages[pageIndex].units[3],
                3,
                pageIndex,
                preview
              )}
              {renderUnit(
                addDetails.pages[pageIndex].units[4],
                4,
                pageIndex,
                preview
              )}
            </Stack>
          </>
        );
      default:
        return "Invalid layout found.";
    }
  };

  const renderUnit = (unit, index, pageIndex, preview) => {
    if (unit) {
      if (unit.type === "") {
        return (
          <div
            style={
              preview
                ? { ...fourGridBlock, backgroundColor: "#FFFFF" }
                : fourGridBlock
            }
          >
            {!preview && renderEditContentIcon(pageIndex, index, "new")}
          </div>
        );
      } else if (unit.type === "chart") {
        return (
          <div
            style={{
              // margin: "8px 4px 4px 8px",
              // width: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              flex: "1 1 0px",
            }}
          >
            <Stack
              sx={{ mb: 0 }}
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                {
                  addDetails.pages[pageIndex].units[index].chartDetails
                    .chartName
                }
              </Typography>
              {!preview && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {!(
                    unit.chartDetails?.api === "locationKpis" ||
                    unit.chartDetails?.api === "companyKpis" ||
                    unit.chartDetails?.api === "lessonLearntDetail"
                  ) && (
                    <HelpText title={t("dashboards.helpText.filter")}>
                      <FilterIcon
                        onClick={() => onFilterClick(unit, index, pageIndex)}
                        style={{
                          ...iconStyles,
                          color: theme.palette.primary.grey,
                        }}
                      />
                    </HelpText>
                  )}
                </Stack>
              )}
            </Stack>
            <div style={{ flexGrow: 1, overflow: "hidden" }}>
              {renderChartAdapted(
                unit.chartDetails.type,
                unit.chartDetails.data,
                false,
                []
              )}
            </div>
            {!preview && renderEditContentIcon(pageIndex, index, "edit")}
          </div>
        );
      } else {
        return (
          <div
            style={{
              // margin: "8px 4px 4px 8px",
              // width: "50%",
              flex: "1 1 0",
              height: "95%",
            }}
          >
            <ReactQuill
              className={preview ? "ql-preview" : "ql-edit-mode"}
              value={unit.textDetails.data}
              readOnly={true}
              modules={{
                toolbar: false,
              }}
            />
            {!preview && renderEditContentIcon(pageIndex, index, "edit")}
          </div>
        );
      }
    }
  };

  const handleEditClick = (type, pageIndex, boxId) => {
    if (type === "new") {
      setContentModal({
        open: true,
        type: "chart",
        boxId,
        pageIndex,
      });
    } else {
      const widgetType = addDetails.pages[pageIndex].units[boxId].type;
      if (widgetType === "chart") {
        setContentModal({
          open: true,
          type: widgetType,
          api: addDetails.pages[pageIndex].units[boxId].chartDetails.api,
          chartType: addDetails.pages[pageIndex].units[boxId].chartDetails.type,
          chartId:
            addDetails.pages[pageIndex].units[boxId].chartDetails.chartId,
          boxId,
          pageIndex,
        });
      } else if (widgetType === "text") {
        setTextEditor(
          addDetails.pages[pageIndex].units[boxId].textDetails.data
        );
        setContentModal({
          open: true,
          type: "text",
          boxId,
          pageIndex,
        });
      }
    }
  };

  const handleContentTypeChange = (e) => {
    if (e.target.value === "chart") {
      setContentModal({ ...contentModal, type: e.target.value });
    } else if (e.target.value === "text") {
      setContentModal({ ...contentModal, type: e.target.value, chartType: "" });
    }
  };

  const onFilterClick = async (unit, index, pageIndex) => {
    try {
      //  If chart is observation then load observation filters, else load kpi filters
      getDirectorates(
        addDetails.pages[pageIndex]?.units[index]?.chartDetails?.chartId
      );
      setFilterModal({
        open: true,
        page: pageIndex,
        unit: index,
        chartId:
          addDetails.pages[pageIndex]?.units[index]?.chartDetails?.chartId,
        limitFilter:
          unit.chartDetails?.api === "behaviorNames" ||
          unit.chartDetails?.api === "behaviorNamesCritical" ||
          unit.chartDetails?.api === "behaviorNamesOther",
        monthView: unit.chartDetails?.api === "directorateKpis",
      });
      setSelectedDirectorates([
        ...addDetails.pages[pageIndex]?.units[index]?.chartDetails?.filters
          ?.directorateIds,
      ]);
      setSelectedCompanies([
        ...addDetails.pages[pageIndex]?.units[index]?.chartDetails?.filters
          ?.companyIds,
      ]);
      setDateEnabled(
        addDetails.pages[pageIndex]?.units[index]?.chartDetails?.filters
          ?.dateEnabled
      );
      setStartDate(
        addDetails.pages[pageIndex]?.units[index]?.chartDetails?.filters
          ?.startDate
      );
      setEndDate(
        addDetails.pages[pageIndex]?.units[index]?.chartDetails?.filters
          ?.endDate
      );
      setSelectedLimit(
        addDetails.pages[pageIndex]?.units[index]?.chartDetails?.filters?.limit
      );
    } catch (err) {
      console.log(err);
    }
  };

  const submitAddWidget = async () => {
    setLoading(true);
    const { boxId, pageIndex } = contentModal;
    let newLayoutItem = {};

    if (contentModal.type === "chart") {
      newLayoutItem = {
        type: contentModal.type,
        chartDetails: {
          type: contentModal.chartType,
          chartId: contentModal.chartId,
          chartName: contentModal.chartName,
          api: contentModal.api,
          filters: { companyIds: [], directorateIds: [] },
        },
      };
      await getApiFunctions(
        newLayoutItem.chartDetails,
        (chartDetails, data) => {
          newLayoutItem.chartDetails.data = data;
        }
      );
    } else {
      newLayoutItem = {
        type: contentModal.type,
        textDetails: {
          data: textEditor,
        },
      };
      setTextEditor("");
    }
    const newDetails = { ...addDetails, pages: [...addDetails.pages] };
    const units = [...newDetails.pages[pageIndex].units];
    units[boxId] = newLayoutItem;
    newDetails.pages[pageIndex].units = units;

    setAddDetails(newDetails);
    setContentModal(contentModalDefaults);
    setLoading(false);
  };

  const renderWidgetFilters = (limitFilters = false, dateMonthView = false) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MultiSelectInput
            label={t("dashboards.selectDirectorates")}
            disabled={false}
            options={directorates}
            handler={(newValue) => setSelectedDirectorates(newValue)}
            values={selectedDirectorates ? selectedDirectorates : []}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectInput
            label={t("dashboards.selectCompanies")}
            disabled={
              false ||
              companies.length === 0 ||
              selectedDirectorates.length === 0
            }
            options={companies}
            handler={(newValue) => setSelectedCompanies(newValue)}
            values={selectedCompanies ? selectedCompanies : []}
          />
        </Grid>
        {limitFilters && (
          <Grid item xs={12}>
            <SelectInput
              label={t("general.limit")}
              disabled={false}
              options={[
                { label: "Top 10", value: 10 },
                { label: "Top 20", value: 20 },
                { label: "Top 30", value: 30 },
                { label: "Top 40", value: 40 },
                { label: "Top 50", value: 50 },
              ]}
              handler={(e) => setSelectedLimit(e.target.value)}
              value={selectedLimit}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={dateEnabled}
                name={"filterDates"}
                onChange={(e) => {
                  setStartDate(today);
                  setEndDate(today);
                  setDateEnabled(e.target.checked);
                }}
                sx={{
                  color: theme.palette.primary.orange,
                  "&.Mui-checked": {
                    color: theme.palette.primary.orange,
                  },
                  "&:hover": {
                    backgroundColor: "rgba(248,147,31,0.08)",
                  },
                }}
              />
            }
            disabled={loading}
            label={t(`observations.viewObservations.filters.dateRange`)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            label={t(`observations.viewObservations.filters.startDate`)}
            date={startDate ? startDate : today}
            disabled={loading || !dateEnabled}
            handler={(newDate) => setStartDate(newDate.toDate())}
            monthView={dateMonthView}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            label={t(`observations.viewObservations.filters.endDate`)}
            date={endDate ? endDate : today}
            disabled={loading || !dateEnabled}
            handler={(newDate) => setEndDate(newDate.toDate())}
            monthView={dateMonthView}
          />
        </Grid>
      </Grid>
    );
  };

  const applyFilters = async () => {
    try {
      setLoading(true);
      const tempObj = {
        ...addDetails,
        pages: Object.assign([...addDetails.pages], {
          [filterModal.page]: {
            ...addDetails.pages[filterModal.page],
            units: Object.assign(
              [...addDetails.pages[filterModal.page].units],
              {
                [filterModal.unit]: {
                  ...addDetails.pages[filterModal.page].units[filterModal.unit],
                  chartDetails: {
                    ...addDetails.pages[filterModal.page].units[
                      filterModal.unit
                    ].chartDetails,
                    filters: {
                      companyIds: selectedCompanies,
                      directorateIds: selectedDirectorates,
                      dateEnabled,
                      startDate,
                      endDate,
                      limit: selectedLimit,
                    },
                  },
                },
              }
            ),
          },
        }),
      };
      const error = await getApiFunctions(
        tempObj.pages[filterModal.page].units[filterModal.unit].chartDetails,
        (chartDetails, data) => {
          tempObj.pages[filterModal.page].units[
            filterModal.unit
          ].chartDetails.data = data;
          setAddDetails(tempObj);
          setLoading(false);
          setFilterModal({ ...filterModal, open: false });
        }
      );

      if (error && error === "error") {
        setFilterModal({ ...filterModal, open: false });
        setLoading(false);
      }
    } catch (e) {
      setFilterModal({ ...filterModal, open: false });
      setLoading(false);
    }
  };

  return (
    <Page title={t("reporting.title")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {page === 2 && (
              <HeaderBackButton
                backFunction={() => {
                  setAddDetails(addDefaults);
                  setPage(1);
                }}
              />
            )}
            <Typography variant="h4">{t("reporting.title")}</Typography>
          </div>
        </Box>
        {page === 1 && renderCreationView()}
        {page === 2 && renderEditorView()}
      </Container>
      {renderHeaderModal()}
      {renderContentModal()}
      {renderPreviewModal()}
      <InfoBox
        overflowV
        open={filterModal.open}
        disabled={loading}
        title={t("observations.viewObservations.filters.title")}
        content={renderWidgetFilters(
          filterModal.limitFilter,
          filterModal.monthView
        )}
        buttonLabel={t("general.applyFilters")}
        handleClose={() => setFilterModal({ ...filterModal, open: false })}
        handleAction={applyFilters}
        minWidth="550px"
      />
    </Page>
  );
}
