import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
//  Material UI
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Stack, Rating } from "@mui/material";
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from "@mui/icons-material";

LikertInput.propTypes = {
  disabled: PropTypes.bool,
  responses: PropTypes.object,
  handler: PropTypes.func,
};

const gridStyle = {
  cursor: "pointer",
};

export default function LikertInput({ disabled, activeValue = 3, languageString, handler }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const setValue = (value) => {
    if (handler) {
      handler(value);
    }
  };

  let languageSuffix = '';
  if(languageString === 'ar'){
    languageSuffix = "AR"
  }else if(languageString === "hi"){
    languageSuffix = "HI"
  }

  return (
    <Grid container>
      <Grid item xs={12} md={2} onClick={() => setValue(1)} sx={gridStyle}>
        <Stack direction="row" spacing={1}>
          <SentimentVeryDissatisfied
            style={{
              color:
                !disabled && activeValue === 1
                  ? theme.palette.error.dark
                  : theme.palette.action.disabled,
            }}
          />
          <Typography
            variant="body1"
            style={{
              "&:hover": {
                color: "red",
              },
            }}
          >
            {t(`reviews.likert.sDisagree${languageSuffix}`)}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2} onClick={() => setValue(2)} sx={gridStyle}>
        <Stack direction="row" spacing={1}>
          <SentimentDissatisfied
            style={{
              color:
                !disabled && activeValue === 2
                  ? theme.palette.error.main
                  : theme.palette.action.disabled,
            }}
          />
          <Typography variant="body1">
            {t(`reviews.likert.disagree${languageSuffix}`)}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2} onClick={() => setValue(3)} sx={gridStyle}>
        <Stack direction="row" spacing={1}>
          <SentimentSatisfied
            style={{
              color:
                !disabled && activeValue === 3
                  ? theme.palette.warning.main
                  : theme.palette.action.disabled,
            }}
          />
          <Typography variant="body1">{t(`reviews.likert.neutral${languageSuffix}`)}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2} onClick={() => setValue(4)} sx={gridStyle}>
        <Stack direction="row" spacing={1}>
          <SentimentSatisfiedAlt
            style={{
              color:
                !disabled && activeValue === 4
                  ? theme.palette.success.main
                  : theme.palette.action.disabled,
            }}
          />
          <Typography variant="body1">{t(`reviews.likert.agree${languageSuffix}`)}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2} onClick={() => setValue(5)} sx={gridStyle}>
        <Stack direction="row" spacing={1}>
          <SentimentVerySatisfied
            style={{
              color:
                !disabled && activeValue === 5
                  ? theme.palette.success.dark
                  : theme.palette.action.disabled,
            }}
          />
          <Typography variant="body1">{t(`reviews.likert.sAgree${languageSuffix}`)}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
