import moment from "moment";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import { displayToast } from "../../utils/general";
// material
import { styled, useTheme } from "@mui/material/styles";
import {
  Badge,
  IconButton,
  Typography,
  Stack,
  List,
  ListItem,
  BottomNavigationAction,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import HollowButton from "../../components/buttons/HollowButton";
import TabsBar from "../../components/navigation/TabsBar";
import { pxToRem, responsiveFontSizes } from "../../theme/typography";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import InfoBox from "../../components/InfoBox";
//  API
import {
  getNotificationsApi,
  getNotificationPreferencesDefaultApi,
  getNotificationPreferencesApi,
  saveNotificationPreferencesApi,
  updateReadApi,
  clearNotifications,
} from "../../api/notifications";
import PageLoader from "../../components/PageLoader";
// ----------------------------------------------------------------------

NotificationsPopover.propTypes = {
  parentNotifications: PropTypes.object,
  isMobile: PropTypes.bool,
};

const unreadDefault = {
  TOTAL: 0,
  ALERT: 0,
  OBSERVATION: 0,
  ACTION: 0,
};

const infoBoxDefaults = {
  open: false,
  message: "",
  id: "",
};

const IconButtonStyled = styled(IconButton)({
  height: "50px",
  width: "50px",
  borderRadius: "10px",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.11)",
});

const CloseIconStyled = styled(CancelOutlinedIcon)(({ theme }) => ({
  height: "22px",
  width: "22px",
  color: theme.palette.primary.red,
  cursor: "pointer",
}));

const BackButtonStyled = styled(ArrowBackIcon)(({ isMobile }) => ({
  cursor: "pointer",
  fontSize: pxToRem(isMobile ? 22 : 12),
  marginRight: "8px",
  ...responsiveFontSizes({ sm: 13, md: 15, lg: 28 }),
}));

const NotificationItem = styled(ListItem)(({ theme }) => ({
  marginBottom: "12px",
  boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.11)",
  borderRadius: "10px",
  padding: "16px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgb(250,250,251)",
  },
}));

export default function NotificationsPopover({
  parentNotifications,
  isMobile = false,
  clearData,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [defaultSettings, setDefaultSettings] = useState([]);
  const [settingsData, setSettingsData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [view, setView] = useState("main");
  const [unreadNumber, setUnreadNumber] = useState(unreadDefault);
  const [notificationChannel, setNotificationChannel] = useState(null);
  const [infoDetails, setInfoDetails] = useState(infoBoxDefaults);

  const iconStyled = {
    color: theme.palette.primary.black,
    width: 20,
    height: 20,
  };

  const settingsButtonStyled = {
    color: theme.palette.primary.orange,
    width: 16,
    height: 16,
  };

  const handleTabChange = async (event, value) => {
    try {
      setLoading(true);
      let searchParam = "";
      switch (value) {
        case 1:
          searchParam = "ALERT";
          break;

        case 2:
          searchParam = "OBSERVATION";
          break;

        case 3:
          searchParam = "ACTION";
          break;
        case 4:
          searchParam = "READ";
          break;

        default:
          break;
      }
      setTabValue(value);
      let newList = {};
      if (searchParam === "READ") {
        newList = await getNotificationsApi("", 1, { pageNo: 1, pageSize: 10 });
      } else {
        newList = await getNotificationsApi(searchParam);
      }
      setNotifications(newList.notifications);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleNotificationClick = async (n) => {
    try {
      setLoading(true);
      //  Update notification if not read
      if (!n.readAt) {
        await updateReadApi(n.id);
        const searchParam =
          tabValue === 1
            ? "ALERT"
            : tabValue === 2
            ? "OBSERVATION"
            : tabValue === 3
            ? "ACTION"
            : "";

        const newList = await getNotificationsApi(searchParam);
        setNotifications(newList.notifications);
        setUnreadNumber({
          ...unreadNumber,
          TOTAL: newList.totalUnRead,
          ALERT: newList.totalAlertsUnRead,
          OBSERVATION: newList.totalObservationUnRead,
          ACTION: newList.totalActionUnread,
        });
      }
      //  Redirect to relevant page
      const eventName = n.eventName || "";
      let link = "";

      switch (eventName) {
        case "ACTION_ASSIGNED":
        case "REASSIGNED":
        case "ACTION_RETURNED_SUP":
          if (n.observation && n.resourceId) {
            link = `/actions/details/${n.observation}/${n.resourceId}`;
          }
          break;

        case "CLOSE_NO_ACTION":
        case "OBSERVATION_SUBMITTED":
        case "RETURNED_TO_OBSERVER":
        case "SUP_REJECTED":
          if (n.resourceId) {
            link = `/observations/details/${n.resourceId}`;
          }
          break;

        case "TRAINING_INVITE":
          if (n.resourceId && n.taskId && n.userId) {
            link = `/training/invite/${n.taskId}/${n.resourceId}/${n.userId}`;
          }
          break;

        case "SUSTAINABILITY_REVIEW_ASSIGNED":
          if (n.resourceId) {
            const usableId = n.resourceId.split("#");
            if (usableId[0]) {
              link = `/review/view/${usableId[0]}`;
            }
          }
          break;

        default:
          break;
      }
      if (
        eventName === "LESSON_LEARNT" &&
        n.lessonLearntDescription &&
        n.observationId
      ) {
        setInfoDetails({
          open: true,
          message: n.lessonLearntDescription,
          id: n.observationId,
        });
        setLoading(false);
      } else if (link) {
        setOpen(false);
        navigate(link);
        setLoading(false);
      } else {
        displayToast(
          setLoading,
          "pureError",
          t("navBar.notifications.linkError")
        );
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleClearAllClick = async () => {
    setLoading(true);
    try {
      await clearNotifications();
      clearData();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const renderNotificationsView = () => (
    <>
      <TabsBar
        value={tabValue}
        handler={handleTabChange}
        sx={{ p: 1 }}
        tabs={[
          {
            label: t("navBar.notifications.tab1"),
            value: unreadNumber["TOTAL"],
          },
          {
            label: t("navBar.notifications.tab2"),
            value: unreadNumber["ALERT"],
          },
          {
            label: t("navBar.notifications.tab3"),
            value: unreadNumber["OBSERVATION"],
          },
          {
            label: t("navBar.notifications.tab4"),
            value: unreadNumber["ACTION"],
          },
          {
            label: t("navBar.notifications.tab5"),
            value: "",
          },
        ]}
        dense
      />
      {loading ? (
        <div
          style={{
            height: "20vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PageLoader />
        </div>
      ) : (
        <>
          <Scrollbar>
            <List sx={{ p: 1 }} style={{ maxHeight: "50vh", overflow: "auto" }}>
              {notifications && notifications.length > 0 ? (
                notifications.map((n, i) => (
                  <NotificationItem
                    key={`notification-${n.id}`}
                    onClick={() => handleNotificationClick(n)}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: n.readAt ? "normal" : "bold" }}
                        >
                          {n.filledMessage}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          {n.createdAt
                            ? moment(n.createdAt).format("DD MMM h:mm A")
                            : "Invalid date"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </NotificationItem>
                ))
              ) : (
                <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                  {t("navBar.notifications.none")}
                </Typography>
              )}
            </List>
          </Scrollbar>
          {tabValue < 3 ? (
            <SecondaryButton
              label={t("navBar.notifications.markAll")}
              alignment="center"
              onClick={handleClearAllClick}
              disabled={unreadNumber["TOTAL"] === 0}
            />
          ) : (
            <SecondaryButton
              label={t("navBar.notifications.viewAll")}
              alignment="center"
              onClick={() => {
                navigate("/notifications/list");
              }}
              disabled={false}
            />
          )}
        </>
      )}
    </>
  );

  const renderNotificationsSettings = () => {
    if (!settingsData || defaultSettings.length < 1) {
      return (
        <Typography variant="body1">
          {t("navBar.notifications.noSettings")}
        </Typography>
      );
    } else {
      return (
        <>
          <Stack
            spacing={2}
            style={{ overflowX: "hidden", overflowY: "auto", height: "60vh" }}
          >
            {defaultSettings.map((s, index) => (
              <Stack key={`notification-setting-${index}`}>
                <Typography variant="h8">{s.title}</Typography>
                <FormControlLabel
                  key={`checkboxEmail`}
                  control={
                    <Checkbox
                      checked={
                        settingsData[index].selectedOption === "email" ||
                        settingsData[index].selectedOption === "both"
                      }
                      name={`EMAILACTIVE`}
                      onChange={(e) => {
                        const tempData = [...settingsData];
                        if (e.target.checked) {
                          if (settingsData[index].selectedOption === "push") {
                            tempData[index].selectedOption = "both";
                          } else {
                            tempData[index].selectedOption = "email";
                          }
                        } else if (!e.target.checked) {
                          if (settingsData[index].selectedOption === "push") {
                            tempData[index].selectedOption = "push";
                          } else if (
                            settingsData[index].selectedOption === "both"
                          ) {
                            tempData[index].selectedOption = "push";
                          } else {
                            tempData[index].selectedOption = "none";
                          }
                        }
                        setSettingsData(tempData);
                      }}
                      // onChange={(e) => setEditDetails({...editDetails, active: e.target.checked})}
                      disabled={loading}
                      sx={{
                        color: theme.palette.primary.orange,
                        "&.Mui-checked": {
                          color: theme.palette.primary.orange,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(248,147,31,0.08)",
                        },
                      }}
                    />
                  }
                  label={t("navBar.notifications.optionEmail")}
                />
                <FormControlLabel
                  key={`checkboxPush`}
                  control={
                    <Checkbox
                      checked={
                        settingsData[index].selectedOption === "push" ||
                        settingsData[index].selectedOption === "both"
                      }
                      name={`PUSHACTIVE`}
                      onChange={(e) => {
                        const tempData = [...settingsData];
                        if (e.target.checked) {
                          if (settingsData[index].selectedOption === "email") {
                            tempData[index].selectedOption = "both";
                          } else {
                            tempData[index].selectedOption = "push";
                          }
                        } else if (!e.target.checked) {
                          if (settingsData[index].selectedOption === "email") {
                            tempData[index].selectedOption = "email";
                          } else if (
                            settingsData[index].selectedOption === "both"
                          ) {
                            tempData[index].selectedOption = "email";
                          } else {
                            tempData[index].selectedOption = "none";
                          }
                        }
                        setSettingsData(tempData);
                      }}
                      // onChange={(e) => setEditDetails({...editDetails, active: e.target.checked})}
                      disabled={loading}
                      sx={{
                        color: theme.palette.primary.orange,
                        "&.Mui-checked": {
                          color: theme.palette.primary.orange,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(248,147,31,0.08)",
                        },
                      }}
                    />
                  }
                  label={t("navBar.notifications.optionPush")}
                />
                {/* <RadioSet
                  disabled={loading}
                  label=""
                  value={settingsData[index].selectedOption}
                  handleChange={(e) => {
                    const tempData = [...settingsData]
                    tempData[index].selectedOption = e.target.value;
                    setSettingsData(tempData)
                  }
                  }
                  options={[
                    {
                      label: t("navBar.notifications.optionEmail"),
                      value: "email",
                    },
                    {
                      label: t("navBar.notifications.optionPush"),
                      value: "push",
                    },
                    {
                      label: t("navBar.notifications.optionBoth"),
                      value: "both",
                    },
                  ]}
                /> */}
              </Stack>
            ))}
          </Stack>
          <div style={{ paddingTop: "12px" }}>
            <SecondaryButton
              icon={<SaveIcon />}
              label={t("general.saveChanges")}
              alignment={"left"}
              disabled={loading}
              onClick={submitUpdatePreferences}
            />
          </div>
        </>
      );
    }
  };

  const getSettingsInfo = async () => {
    try {
      setLoading(true);
      const defaultData = await getNotificationPreferencesDefaultApi();
      setDefaultSettings(defaultData);
      const notificationSettings = await getNotificationPreferencesApi();
      setSettingsData(notificationSettings.preferences);
      setNotificationChannel(notificationSettings.notificationChannel);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitUpdatePreferences = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("navBar.notifications.saveLoading")
    );
    try {
      await saveNotificationPreferencesApi({
        data: settingsData,
        notificationChannel: notificationChannel,
      });

      displayToast(
        setLoading,
        "success",
        t("navBar.notifications.saveSuccess"),
        activeToast
      );
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  useEffect(() => {
    setNotifications(parentNotifications.notifications);
    setUnreadNumber({
      ...unreadNumber,
      TOTAL: parentNotifications.totalUnRead,
      ALERT: parentNotifications.totalAlertsUnRead,
      OBSERVATION: parentNotifications.totalObservationUnRead,
      ACTION: parentNotifications.totalActionUnread,
    });
  }, [parentNotifications]);

  useEffect(() => {
    if (view === "settings") {
      getSettingsInfo();
    }
  }, [view]);

  return (
    <>
      {!isMobile ? (
        <Tooltip title={t("navBar.notifications.title")}>
          <IconButtonStyled
            ref={anchorRef}
            size="large"
            color={open ? "primary" : "default"}
            onClick={() => setOpen(true)}
          >
            <Badge badgeContent={unreadNumber["TOTAL"]} color="error">
              <NotificationsIcon style={iconStyled} />
            </Badge>
          </IconButtonStyled>
        </Tooltip>
      ) : (
        <BottomNavigationAction
          onClick={() => setOpen(true)}
          icon={
            <Badge badgeContent={unreadNumber["TOTAL"]} color="error">
              <NotificationsIcon />
            </Badge>
          }
        />
      )}
      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{
          width: isMobile ? "99vw" : 550,
          pt: "18px",
          pb: "18px",
          pl: "24px",
          pr: "24px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Stack direction="row">
            {view !== "main" && (
              <BackButtonStyled
                isMobile={isMobile}
                onClick={() => setView("main")}
              />
            )}
            <Typography variant="h5">
              {view === "main" && t("navBar.notifications.title")}
              {view === "settings" && t("navBar.notifications.settingsTitle")}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            {view === "main" && (
              <HollowButton
                disabled={loading}
                label={t("navBar.notifications.settings")}
                icon={<SettingsIcon style={settingsButtonStyled} />}
                onClick={() => setView("settings")}
              />
            )}
            <CloseIconStyled
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </Stack>
        </Stack>
        {view === "main" && renderNotificationsView()}
        {view === "settings" && renderNotificationsSettings()}
      </MenuPopover>
      <InfoBox
        open={infoDetails.open}
        compact
        title={infoDetails.id}
        handleClose={() => setInfoDetails(infoBoxDefaults)}
        content={
          <Stack>
            <Typography variant="h6" style={{ whiteSpace: "pre-wrap" }}>
              {`${t("table.lessonLearnt")}:`}
            </Typography>
            <Typography variant="body" style={{ whiteSpace: "pre-wrap" }}>
              {infoDetails.message}
            </Typography>
          </Stack>
        }
        handleAction={() => setInfoDetails(infoBoxDefaults)}
        buttonLabel={t("general.close")}
      />
    </>
  );
}
