import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { displayToast } from "../../utils/general";

// material
import {
  Grid,
  Container,
  Typography,
  Button,
  Stack,
  Box,
  Tooltip,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import CloseOutIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

// components
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import ActivityTable from "../../components/tables/ActivityTable";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ManageActivity from "./Management/ManageActivity";
import ExecuteActivity from "./Execution/ExecuteActivity";
import InfoBox from "../../components/InfoBox";
import TableButton from "../../components/buttons/TableButton";
import TextInput from "../../components/inputs/TextInput";
import DateInput from "../../components/inputs/DateInput";
import SelectInput from "../../components/inputs/SelectInput";
import TabsBar from "../../components/navigation/TabsBar";
import AccessControl from "../../components/AccessControl";

//  api
import { getById, getLocationsAndUnitsApi } from "../../api/implementation";
import { getPlanById } from "../../api/implementation";
import {
  closeOutActivityApi,
  deleteCustomActivityApi,
  getActivityTypesApi,
  updateActivityApi,
  addActivityApi,
} from "../../api/activities";

// ----------------------------------------------------------------------

const filterParamDefaults = {
  pageSize: 100,
  pageNo: 1,
};

const editDefaults = {
  open: false,
  activityName: "",
  startDate: new Date(),
  endDate: new Date(),
  parentActivityId: "",
  originalName: '"',
  stub: "",
};

const addDefaults = {
  open: false,
  activityName: "",
  stub: "",
  parentActivityId: "",
  startDate: new Date(),
  endDate: new Date(),
  weightage: null,
};

const ACTIVITIES_WITH_REDIRECTS = [
  "KickOffMeeting",
  "IdentifyCriticalBehaviors",
  "UploadContractorDirectoryToAegis",
  "SustainabilityReviews",
  "SiteEngagement",
  "Meeting",
  "PreSiteEngagement",
  "TrainingSchedule",
  "IdentifyFocalPoints",
  "SteeringCommittee",
  "LeadershipVisibility",
];

const CUSTOM_ACTIVITIES = ["Meeting", "SiteEngagement"];

const ACTIVITIES_TO_SKIP_TEMP = [
  "TranslationOfBehaviors",
  "LeadershipWorkshopCourse",
  "TTT",
  "OngoingCoachingAtSite",
  "SupervisorAndCrewTraining",
  // "TrainingSchedule",
];

const firstActivity = "Kick off Meeting";

const ACTIVITIES_HIDDEN = ["IdentifyFocalPoints"];

export default function ActivityDetails() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { planId, companyId } = useParams();
  const [tab, setTab] = useState("activities");
  const [rows, setRows] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const [rowsLoading, setRowsLoading] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);
  const [luRows, setLuRows] = useState([]);
  const [directorateName, setDirectorateName] = useState("");
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  const [tableInfo, setTableInfo] = useState({ totalPages: 1, totalCount: 10 });
  const [ManageActivityOpen, setManageActivityOpen] = useState(false);
  const [ExecuteActivityOpen, setExecuteActivityOpen] = useState(false);
  const [CloseOutActivityOpen, setCloseOutActivityOpen] = useState(false);
  const [editDetails, setEditDetails] = useState(editDefaults);
  const [addDetails, setAddDetails] = useState(addDefaults);
  const [deleteDetails, setDeleteDetails] = useState({
    open: false,
    id: "",
    name: "",
  });
  const [closeOutText, setCloseOutText] = useState("");
  const [userAccess, setUserAccess] = useState("");
  const navigate = useNavigate();

  const columns = [
    {
      field: "status",
      headerName: t("table.status"),
      width: 5,
      renderCell: (param) => (
        <Grid paddingLeft={2} style={{ display: "flex", alignItems: "center" }}>
          <CircleIcon style={{ color: getStatus(param.row) }} />
        </Grid>
      ),
    },
    {
      field: "name",
      headerName: t("table.name"),
      width: 40,
      renderCell: (param) => {
        return i18n.exists(`planActivityNamesEnglish.${param.row.stub}`)
          ? t(`planActivityNamesEnglish.${param.row.stub}`)
          : param.row.name;
      },
    },
    {
      field: "startDate",
      headerName: t("table.startDate"),
      width: 10,
    },
    {
      field: "endDate",
      headerName: t("table.endDate"),
      width: 10,
    },
    {
      field: "closedAt",
      headerName: t("table.closedAt"),
      width: 10,
      renderCell: (param) => {
        const closed = param.row.closedAt;
        return closed ? moment(closed).format("DD/MM/YY") : "N/A";
      },
    },
    {
      field: "actions",
      headerName: t("table.actions"),
      width: 20,
      renderCell: (param) => renderActions(param.row),
    },
  ];

  const LuRow = styled("div")(({ theme, borderColor, disabled }) => ({
    color: disabled
      ? theme.palette.primary.disabledFont
      : theme.palette.primary.black,
    background:
      "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
    boxShadow: " 0px 4px 9px rgba(0, 0, 0, 0.11)",
    border: `1px solid #F1F3F4`,
    ":before": {
      backgroundColor: theme.palette.primary.white,
    },
    marginBottom: "8px",
    padding: "8px",
  }));

  const renderDeleteModal = () => (
    <InfoBox
      disabled={loading}
      open={deleteDetails.open}
      title={`${t(`general.delete`)} ${deleteDetails.name}`}
      content={
        <Stack>
          <Typography variant="h6" style={{ color: theme.palette.primary.red }}>
            {t(`implementations.customDelete`)}
          </Typography>
        </Stack>
      }
      buttonLabel={t(`general.delete`)}
      handleClose={() => setDeleteDetails({ ...deleteDetails, open: false })}
      handleAction={submitDeleteActivity}
    />
  );

  const renderEditModal = () => (
    <InfoBox
      disabled={loading}
      open={editDetails.open}
      title={`${t("general.edit")} ${editDetails.originalName}`}
      content={
        <Stack spacing={2}>
          {CUSTOM_ACTIVITIES.includes(editDetails.stub) && (
            <TextInput
              disabled={loading}
              label={t("implementations.addActivityName")}
              placeholder={t("implementations.addActivityNamePlaceholder")}
              value={editDetails.activityName}
              handler={(e) =>
                setEditDetails({ ...editDetails, activityName: e.target.value })
              }
            />
          )}
          <DateInput
            disabled={loading}
            label={t("implementations.startDate")}
            date={editDetails.startDate}
            handler={(newDate) =>
              setEditDetails({ ...editDetails, startDate: newDate })
            }
          />
          <DateInput
            disabled={loading}
            label={t("implementations.endDate")}
            date={editDetails.endDate}
            handler={(newDate) =>
              setEditDetails({ ...editDetails, endDate: newDate })
            }
          />
        </Stack>
      }
      handleClose={() => setEditDetails(editDefaults)}
      buttonLabel={t("general.saveChanges")}
      handleAction={submitUpdate}
    />
  );

  const renderAddModal = () => (
    <InfoBox
      disabled={loading || !addDetails.stub || !addDetails.activityName}
      open={addDetails.open}
      title={t("implementations.addCustom")}
      content={
        <Stack spacing={2}>
          <TextInput
            disabled={loading}
            label={t("implementations.addActivityName")}
            placeholder={t("implementations.addActivityNamePlaceholder")}
            value={addDetails.activityName}
            handler={(e) =>
              setAddDetails({ ...addDetails, activityName: e.target.value })
            }
          />
          <DateInput
            disabled={loading}
            label={t("implementations.startDate")}
            date={addDetails.startDate}
            handler={(newDate) =>
              setAddDetails({ ...addDetails, startDate: newDate })
            }
          />
          <DateInput
            disabled={loading}
            label={t("implementations.endDate")}
            date={addDetails.endDate}
            handler={(newDate) =>
              setAddDetails({ ...addDetails, endDate: newDate })
            }
          />
          <SelectInput
            label={t("Activity.activityType")}
            options={activityTypes}
            value={addDetails.stub}
            disabled={loading}
            handler={(e) =>
              setAddDetails({ ...addDetails, stub: e.target.value })
            }
          />
        </Stack>
      }
      handleClose={() => setAddDetails(addDefaults)}
      buttonLabel={t("implementations.addCustom")}
      handleAction={submitCustomActivity}
    />
  );

  const renderActions = (row) => {
    const manageText = t("implementationsList.manage");
    const closeOut = t("implementationsList.closeOut");
    const editText = t("general.edit");
    const deleteText = t("general.delete");

    return (
      <Stack spacing={1} direction="row" style={{ flex: 1 }}>
        {row.stub === "UploadContractorDirectoryToAegis" ||
        row.stub === "TrainingSchedule" ||
        row.stub === "LeadershipVisibility" ||
        row.stub === "SteeringCommittee" ? (
          <AccessControl
            accessNames={{
              type: "OR",
              features: ["CRUD_IMPL_PLN", "CON_DIR_IMPL_PLN"],
            }}
          >
            {userAccess === "CON_DIR_IMPL_PLN" ||
            userAccess === "CRUD_IMPL_PLN" ? (
              <TableButton
                disabled={ACTIVITIES_TO_SKIP_TEMP.includes(row.stub) || loading}
                label={manageText}
                onClick={(event) => handleManageActivity(event, row)}
                icon={<SettingsOutlinedIcon />}
              />
            ) : null}
          </AccessControl>
        ) : (
          <AccessControl accessName="CRUD_IMPL_PLN">
            {userAccess === "CRUD_IMPL_PLN" && (
              <TableButton
                disabled={ACTIVITIES_TO_SKIP_TEMP.includes(row.stub) || loading}
                label={manageText}
                onClick={(event) => handleManageActivity(event, row)}
                icon={<SettingsOutlinedIcon />}
              />
            )}
          </AccessControl>
        )}
        {row.closedAt === null && (
          <AccessControl accessName="CRUD_IMPL_PLN">
            {userAccess === "CRUD_IMPL_PLN" && (
              <TableButton
                disabled={ACTIVITIES_TO_SKIP_TEMP.includes(row.stub) || loading}
                label={editText}
                onClick={() =>
                  setEditDetails({
                    open: true,
                    activityName: row.name,
                    startDate: row.start,
                    endDate: row.end,
                    parentActivityId: null,
                    originalName: row.name,
                    stub: row.stub,
                    id: row.id,
                  })
                }
                icon={<EditIcon />}
              />
            )}
          </AccessControl>
        )}
        {row.closedAt === null && CUSTOM_ACTIVITIES.includes(row.stub) && (
          <AccessControl accessName="CRUD_IMPL_PLN">
            {userAccess === "CRUD_IMPL_PLN" && (
              <TableButton
                red
                disabled={ACTIVITIES_TO_SKIP_TEMP.includes(row.stub) || loading}
                label={deleteText}
                onClick={() =>
                  setDeleteDetails({
                    open: true,
                    activityId: row.id,
                    name: row.name,
                    planId,
                  })
                }
                icon={<DeleteIcon />}
              />
            )}
          </AccessControl>
        )}
        {row.closedAt === null && (
          <AccessControl accessName="CRUD_IMPL_PLN">
            {userAccess === "CRUD_IMPL_PLN" && (
              <TableButton
                disabled={ACTIVITIES_TO_SKIP_TEMP.includes(row.stub) || loading}
                label={closeOut}
                onClick={(event) => handleCloseOutActivity(event, row)}
                icon={<CloseOutIcon />}
                primary
              />
            )}
          </AccessControl>
        )}
        {/* {row.closedAt === null &&
          !NON_EXECUTABLE_ACTIVITIES.includes(row.stub) && (
            <SecondaryButton
              disabled={ACTIVITIES_TO_SKIP_TEMP.includes(row.stub) || loading}
              style={{ height: "35px", maxWidth: "150px" }}
              label={execute}
              onClick={(event) => handleExecuteActivity(event, row)}
            />
          )} */}
      </Stack>
    );
  };

  const renderAdditionalDetails = () => (
    <Stack spacing={2}>
      <Typography variant="h7">{`${t("implementations.directorate")}: ${
        directorateName || "No directorate found."
      }`}</Typography>
      <Typography variant="h7">
        {t("implementations.locationsAndUnits")}
      </Typography>
      <Stack
        spacing={2}
        direction="row"
        style={{ display: "flex", alignItems: "center" }}
      >
        <PushPinIcon
          sx={{
            color: theme.palette.primary.blue,
            height: 14,
            width: 14,
          }}
        />
        <Typography variant="body1">{t("implementations.location")}</Typography>
        <BusinessOutlinedIcon
          sx={{
            color: theme.palette.primary.purple,
            height: 14,
            width: 14,
          }}
        />
        <Typography variant="body1">{t("implementations.unit")}</Typography>
      </Stack>
      {luRows && luRows.length < 1
        ? t("implementations.noLus")
        : luRows.map((row) => (
            <LuRow>
              <Stack
                spacing={1}
                direction="row"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Tooltip title={t(`implementations.${row.type}`)}>
                  {row.type === "location" ? (
                    <PushPinIcon
                      sx={{
                        color: theme.palette.primary.blue,
                        height: 14,
                        width: 14,
                      }}
                    />
                  ) : (
                    <BusinessOutlinedIcon
                      sx={{
                        color: theme.palette.primary.purple,
                        height: 14,
                        width: 14,
                      }}
                    />
                  )}
                </Tooltip>
                <Typography variant="h8">{row.name}</Typography>
              </Stack>
            </LuRow>
          ))}
    </Stack>
  );

  const getStatus = (row) => {
    const closedAt = row.closedAt
      ? new Date(row.closedAt).setUTCHours(0, 0, 0)
      : null;
    const endDate = new Date(row.end).setUTCHours(0, 0, 0);

    if (closedAt) {
      const differenceInMilliseconds = endDate - closedAt;
      if (differenceInMilliseconds >= 0) return "green";
      else {
        const days = differenceInMilliseconds / (1000 * 3600 * 24);
        return days >= -5 ? "orange" : "red";
      }
    } else {
      const remainingMilliseconds = endDate - new Date().setUTCHours(0, 0, 0);
      if (remainingMilliseconds >= 0) return "grey";
      else {
        const days = remainingMilliseconds / (1000 * 3600 * 24);
        return days < -5 ? "red" : "orange";
      }
    }
  };

  const handleManageActivity = (event, row) => {
    setCurrentRow({ ...row, planId, companyId });
    if (!ACTIVITIES_WITH_REDIRECTS.includes(row.stub)) {
      setManageActivityOpen(true);
    } else {
      switch (row.stub) {
        case "KickOffMeeting":
          navigate(
            `/implementations/manage-kick-off/${planId}/${row.id}/${row.stub}/${row.activityTypeId}/${companyId}`
          );
          break;

        case "IdentifyFocalPoints":
          navigate(
            `/implementations/identify-focal-points/${planId}/${companyId}`
          );
          break;

        case "UploadContractorDirectoryToAegis":
          navigate(
            `/implementations/contractor-directory/${planId}/${companyId}/${row.stub}/${row.id}`
          );
          break;

        case "IdentifyCriticalBehaviors":
          navigate(
            `/implementations/identify-critical-behavior/${row.stub}/${companyId}/${planId}`
          );
          break;

        case "SustainabilityReviews":
          navigate(
            `/implementations/sustainability-reviews/${companyId}/${planId}`
          );
          break;

        case "Meeting":
          navigate(
            `/implementations/custom-activity/${planId}/${row.id}/${row.stub}/${row.activityTypeId}/${companyId}`
          );
          break;

        case "PreSiteEngagement":
          navigate(
            `/implementations/custom-activity/${planId}/${row.id}/${row.stub}/${row.activityTypeId}/${companyId}`
          );
          break;

        case "SiteEngagement":
          navigate(
            `/implementations/custom-activity/${planId}/${row.id}/${row.stub}/${row.activityTypeId}/${companyId}`
          );
          break;

        case "TrainingSchedule":
          navigate(
            `/implementations/training-schedule/${planId}/${companyId}/${row.id}/${row.stub}/${row.activityTypeId}`
          );
          break;

        case "SteeringCommittee":
          navigate(
            `/implementations/steering-commitee/${planId}/${companyId}/${row.id}`
          );
          break;

        case "LeadershipVisibility":
          navigate(
            `/implementations/leadership-visibility/${planId}/${companyId}/${row.id}`
          );
          break;

        default:
          return;
      }
    }
  };

  const handleCloseOutActivity = (event, row) => {
    setCurrentRow({ ...row, planId, companyId });
    setCloseOutActivityOpen(true);
  };

  const submitCloseOut = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.closeOut.loading")
    );

    try {
      const body = {
        activityId: currentRow.id,
        closedAt: new Date(),
        activityTypeStub: currentRow.stub,
        planId: currentRow.planId,
        companyId: currentRow.companyId,
        comments: closeOutText,
      };

      switch (currentRow.stub) {
        case "IdentifyFocalPoints":
          //  Find out what roleJump is
          body.focalPoints = [].map((fp) => {
            return {
              roleId: fp.role,
              responsibility: fp.responsibility,
            };
          });
          break;

        case "PreSiteEngagement":
          break;

        default:
          break;
      }
      await closeOutActivityApi(body);

      setCloseOutActivityOpen(false);
      setCurrentRow({});
      displayToast(
        setLoading,
        "success",
        t("implementations.closeOut.success"),
        activeToast
      );
      getImplementationList();
      setLoading(false);
      setCloseOutText("");
    } catch (err) {
      setLoading(false);
      displayToast(setLoading, "error", err, activeToast);
      setCloseOutText("");
      console.log(err);
    }
  };

  const submitUpdate = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.update.loading")
    );

    try {
      if (
        !moment(editDetails.endDate).isValid() ||
        !moment(editDetails.startDate).isValid()
      ) {
        throw t("errors.dateValidError");
      }

      if (moment(editDetails.endDate) < moment(editDetails.startDate)) {
        throw t("errors.dateError");
      }

      const body = {
        activityName: editDetails.activityName,
        startDate: editDetails.startDate,
        endDate: editDetails.endDate,
        ...(editDetails.parentActivityId
          ? { parentActivityId: editDetails.parentActivityId }
          : {}),
      };

      await updateActivityApi(planId, editDetails.id, body);
      setEditDetails(editDefaults);
      getImplementationList();
      displayToast(
        setLoading,
        "success",
        t("implementations.update.success"),
        activeToast
      );
    } catch (err) {
      setLoading(false);
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  const submitDeleteActivity = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.deleteLoading")
    );
    try {
      await deleteCustomActivityApi(planId, deleteDetails.activityId);
      setDeleteDetails({ open: false, name: "" });
      getImplementationList();
      displayToast(
        setLoading,
        "success",
        t("implementations.deleteSuccess"),
        activeToast
      );
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }
  };

  const submitCustomActivity = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.customLoading")
    );
    try {
      if (addDetails.endDate < addDetails.startDate) {
        throw t("errors.dateError");
      }

      const payload = {
        activityName: addDetails.activityName,
        stub: addDetails.stub,
        startDate: moment(addDetails.startDate).startOf('day').toISOString(),
        endDate: moment(addDetails.endDate).endOf('day').toISOString(),
        parentActivityId: null,
      };

      await addActivityApi(payload, planId);
      setAddDetails(addDefaults);
      getImplementationList();
      displayToast(setLoading, "success", t("implementations.customSuccess"));
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const getImplementationList = async () => {
    setLoading(true);
    try {
      const activities = (await getById({ id: planId })) || [];
      const activityRowsList = [];
      setUserAccess(activities.access);
      await activities.data.forEach((activity) => {
        if (!ACTIVITIES_HIDDEN.includes(activity.activityType.stub)) {
          const act = {
            key: activity.id,
            id: activity.id,
            name: activity.activityName,
            start: activity.startDate,
            end: activity.endDate,
            startDate: moment(activity.startDate).format("DD/MM/YY"),
            endDate: moment(activity.endDate).format("DD/MM/YY"),
            stub: activity.activityType?.stub || "",
            closedAt: activity.closedAt,
            activityTypeId: activity.activityTypeId,
          };
          if (activity.activityName === firstActivity) {
            activityRowsList.unshift(act);
          } else {
            activityRowsList.push(act);
          }
        }
      });
      setRows(activityRowsList);
      setTableInfo({ totalPages: 1, totalCount: activityRowsList.length });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getPlan = async () => {
    try {
      setRowsLoading(true);
      const data = await getPlanById(planId);
      setPlan(data);
      setRowsLoading(false);
    } catch (err) {
      console.log(err);
      setRowsLoading(false);
    }
  };

  const getActivityTypes = async () => {
    try {
      setLoading(true);
      const typesResponse = await getActivityTypesApi();
      const types = typesResponse
        .filter(
          (activity) =>
            !activity.isDefaultActivity && activity.stub !== "Training"
        )
        .map((type) => {
          return {
            label: type.name,
            value: type.stub,
          };
        });
      setActivityTypes(types);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getLocationsAndUnits = async () => {
    try {
      setLoading(true);
      const rawData = await getLocationsAndUnitsApi(planId);
      const finalLu = [];
      if (rawData.directorates && rawData.directorates.length > 0) {
        setDirectorateName(rawData.directorates[0].name);
      }
      rawData.locations.forEach((l) =>
        finalLu.push({
          id: l.id,
          name: l.name,
          type: "location",
        })
      );
      rawData.units.forEach((u) =>
        finalLu.push({
          id: u.id,
          name: u.name,
          type: "unit",
        })
      );

      setLuRows(finalLu);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlan();
    getImplementationList();
  }, [filterParams, planId]);

  useEffect(() => {
    getActivityTypes();
    getLocationsAndUnits();
  }, []);

  return (
    <Container maxWidth="xl">
      {Object.keys(currentRow).length > 0 && (
        <>
          <ManageActivity
            isOpen={ManageActivityOpen}
            onClose={() => setManageActivityOpen(false)}
            titleJustifyContent="center"
            actionButton={
              <Grid sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setManageActivityOpen(false);
                  }}
                >
                  Close
                </Button>
              </Grid>
            }
            row={currentRow}
          ></ManageActivity>

          <ExecuteActivity
            isOpen={ExecuteActivityOpen}
            onClose={() => setExecuteActivityOpen(false)}
            titleJustifyContent="center"
            actionButton={
              <Grid sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setExecuteActivityOpen(false);
                  }}
                >
                  Close
                </Button>
              </Grid>
            }
            row={currentRow}
          ></ExecuteActivity>
        </>
      )}

      <Box sx={{ pb: 2 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <HeaderBackButton />
          <Typography variant="h4">
            {t("implementations.companyActivityDetails", { name: plan.name })}
          </Typography>
        </div>
      </Box>
      <TabsBar
        value={tab}
        handler={(event, value) => setTab(value)}
        style={{ marginBottom: "14px" }}
        tabs={[
          {
            label: t("implementations.activities"),
            value: 0,
            key: "activities",
          },
          {
            label: t("implementations.additionalDetails"),
            value: 0,
            key: "additionalDetails",
          },
        ]}
      />
      {tab === "activities" ? (
        <>
          <ActivityTable
            rows={rows}
            columns={columns}
            loading={rows.length === 0}
            tableInfo={tableInfo}
          />
          <AccessControl accessName={"CRUD_IMPL_PLN"}>
            <PrimaryButton
              label={t("implementations.addCustomActivity")}
              icon={<AddIcon />}
              alignment="left"
              onClick={() => setAddDetails({ ...addDefaults, open: true })}
              disabled={loading}
              style={{ marginTop: "12px" }}
            />
          </AccessControl>
        </>
      ) : (
        renderAdditionalDetails()
      )}

      <InfoBox
        disabled={loading}
        open={CloseOutActivityOpen}
        title={t(
          "implementationsList.closeOutActivityApi.closeOutDefaultTitle"
        )}
        content={
          <>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextInput
                  label={t(
                    "implementationsList.closeOutActivityApi.closeOutText"
                  )}
                  placeholder={t(
                    "implementationsList.closeOutActivityApi.closeOutPlaceholder"
                  )}
                  handler={(e) => {
                    setCloseOutText(e.target.value);
                  }}
                  disabled={false}
                  value={closeOutText}
                />
              </Grid>
            </Grid>
          </>
        }
        handleClose={() => setCloseOutActivityOpen(false)}
        buttonLabel={t("general.submit")}
        handleAction={submitCloseOut}
      />
      {renderDeleteModal()}
      {renderEditModal()}
      {renderAddModal()}
    </Container>
  );
}
