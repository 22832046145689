import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.grey,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverGrey,
  },
}));

export const IconStyled = styled(FilterIcon)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
