import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// material
import { Stack, Typography, useTheme } from "@mui/material";
import { displayToast } from "../../../utils/general";
//  components
import TextInput from "../../inputs/TextInput";
import PrimaryButton from "../../buttons/PrimaryButton";
import AlertBox from "../../AlertBox";
// ----------------------------------------------------------------------
// API
import { resetPasswordApi, checkOtpValidtedApi } from "../../../api/users";

export default function ResetPasswordForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  let newPassword;
  let confirmPassword;
  const [details, setDetails] = useState({
    newPassword,
    confirmPassword,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFormEntry = (e, type) => {
    switch (type) {
      case "enter":
        if (e.code === "Enter") {
          resetPassword(details);
        }
        break;

      case "newPassword":
        setDetails({ ...details, newPassword: e.target.value });
        break;

      case "confirmPassword":
        setDetails({ ...details, confirmPassword: e.target.value });
        break;

      default:
        console.log("Please enter a valid password.");
    }
  };

  const resetPassword = async (formDetails) => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("resetPassword.onResetLoading")
    );
    try {
      await resetPasswordApi(formDetails, token);
      displayToast(
        setLoading,
        "success",
        t("resetPassword.onResetSuccess"),
        activeToast
      );
      navigate("/login");
    } catch (err) {
      if (err.errors) {
        displayToast(setLoading, "error", err.errors[0], activeToast);
        setError(err.errors[0]);
      } else {
        displayToast(setLoading, "error", err, activeToast);
        setError(err);
      }
    }
  };

  const checkOtpValidated = async () => {
    try {
      if (!token) {
        displayToast(setLoading, "error", t("otp.tokenNotProvided"));
        navigate("/login");
      } else {
        const response = await checkOtpValidtedApi(token);
        if (response.message === "Otp is not validated") {
          displayToast(setLoading, "error", t("otp.noOtpAuthentication"));
          navigate("/login");
        }
        if (response.message === "Otp Expired. Please get a new one") {
          displayToast(setLoading, "error", t("otp.otpExpired"));
          navigate("/login");
        }
      }
    } catch (err) {
      setLoading(false);
      if (err.errors) {
        setError(err.errors[0]);
      } else {
        setError(err);
      }
    }
  };

  useEffect(() => {
    checkOtpValidated();
  }, []);

  return (
    <>
      <Stack sx={{ mb: 10 }}>
        <Typography variant="h4" gutterBottom sx={{ color: "primary.white" }}>
          {t(`resetPassword.reset`)}
        </Typography>
      </Stack>
      <Stack spacing={3}>
        <AlertBox open={error ? true : false} type="error" message={error} />
        <TextInput
          label={t(`resetPassword.newPassword`)}
          type="password"
          placeholder={t(`resetPassword.newPasswordPlaceholder`)}
          value={details.newPassword}
          disabled={loading}
          handler={(e) => handleFormEntry(e, "newPassword")}
          keyPressHandler={(e) => handleFormEntry(e, "enter")}
          labelColor={theme.palette.primary.white}
        />
        <TextInput
          label={t(`resetPassword.confirmPassword`)}
          type="password"
          placeholder={t(`resetPassword.confirmPasswordPlaceholder`)}
          value={details.confirmPassword}
          disabled={loading}
          handler={(e) => handleFormEntry(e, "confirmPassword")}
          keyPressHandler={(e) => handleFormEntry(e, "enter")}
          labelColor={theme.palette.primary.white}
        />
        <Typography
          variant="caption"
          sx={{
            borderRadius: 1,
            p: 1,
            color: "primary.white",
            background: theme.palette.primary.grey,
          }}
        >
          {t(`general.passwordCriteria`)}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <PrimaryButton
          label={t(`resetPassword.reset`)}
          alignment="left"
          disabled={loading}
          onClick={() => resetPassword(details)}
        />
      </Stack>
    </>
  );
}
