import apiHelper from "./apiHelper";

export const getCommonFiltersApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/commonFilters`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTotalObservationOverviewApi = (
  view_by = "location",
  location = "",
  discipline = "",
  card_type = "Safe",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/totalObservationOverview?view_by=${view_by}&location=${location}&discipline=${discipline}&card_type=${card_type}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getPercentTotalsApi = (
  view_by = "action",
  location = "",
  discipline = "Catering",
  card_type = "Safe",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/percentTotals?view_by=${view_by}&location=${location}&discipline=${discipline}&card_type=${card_type}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getQualityIndex = (
  discipline = "",
  card_type = "Safe",
  view_by = "location",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/qualityIndex?discipline=${discipline}&card_type=${card_type}&view_by=${view_by}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getSentimentByLocation = (
  location = "",
  discipline = "",
  card_type = "",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/sentimentByLocation?location=${location}&discipline=${discipline}&card_type=${card_type}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTopBottomBehaviourBySentiment = (
  location = "",
  discipline = "",
  card_type = "Safe",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/topAndBottomBehaviorBySentiment?location=${location}&discipline=${discipline}&card_type=${card_type}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getWordcloudTextAnalysis = (
  location = "Amal",
  discipline = "Catering",
  card_type = "",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/wordCloud?location=${location}&discipline=${discipline}&card_type=${card_type}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getNGrams = (
  location = "Amal",
  discipline = "Catering",
  card_type = "Safe",
  view_by = "trigram",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/nGramsBarChart?location=${location}&discipline=${discipline}&card_type=${card_type}&view_by=${view_by}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getFeedbackSentiment = (
  location = "",
  discipline = "",
  card_type = "",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/topAndBottomFeedbackBySentiment?location=${location}&discipline=${discipline}&card_type=${card_type}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getRiskHeatmap = (card_type = "", directorate = "") => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/riskHeatMap?card_type=${card_type}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getRiskBarGraph = (
  discipline = "",
  location = "",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/riskBarGraph?discipline=${discipline}&location=${location}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTopBehavioursRisk = (
  card_type = "Safe",
  discipline = "",
  location = "",
  directorate = ""
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/ai/dashboard/topBehaviors?card_type=${card_type}&location=${location}&discipline=${discipline}&directorate=${directorate}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getDirectorateFiltersApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/directorateFilters`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyFiltersApi = (directorateId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/companyFilters`, {
        params: {
          directorateId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationsAndUnitsFiltersApi = (companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/locationsAndUnitsFilter`, {
        params: {
          companyId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getDisciplineFiltersApi = (companyId, locationId, unitId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/disciplineFilter`, {
        params: {
          companyId,
          locationId,
          unitId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getSmartAlertsApi = ({
  pageSize = 10,
  pageNo = 1,
  type,
  directorateId,
  locationId,
  unitId,
  disciplineId,
  companyId,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/smartAlertsAll`, {
        params: {
          pageNo,
          pageSize,
          type,
          directorateId,
          locationId,
          unitId,
          disciplineId,
          companyId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getSmartAlertsTodayApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/smartAlertsToday`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
