import apiHelper from "./apiHelper";

export const getPreferencesAndFilter = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/fetchFilters`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTotalObservations = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/totalObservations`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTotalObservationsPersonal = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/totalObservationsPersonal`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getBehaviourTypes = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/behaviorType`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObservationStatus = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/observationStatus`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getActionStatus = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/actionStatus`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getBehaviorNames = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/behaviorNames`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};


export const getTrainingStatuses = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/trainingStatus`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getDisciplineDistribution = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/disciplineDistribution`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTopUnsafeBehavior = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/topUnsafeBehavior`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObservationsPerYear = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/observationsPerYear`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCardCount = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/cardCount`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const lessonLearntCard = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/lessonLearntCard`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const lessonLearntDetail = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/lessonLearntDetail`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const saveDashboardPreferences = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/dashboard/saveLayout`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObservationTrends = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/observationTrends`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationKpisTrends = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/locationKpis`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyKpis = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/companyKpis`, {
        params: filters,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationKpiFilters = (filters) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/dashboard/locationKpisFilters?${filters}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
