export const parseChartData = (type, chartInfo) => {
  let categories = [];
  let data = [];

  try {
    switch (type) {
      case "simpleCategory":
      case "intervalCategory":
      case "interval":
        categories = chartInfo.categories;
        data = chartInfo.data;
        break;

      case "simple":
        const insideValues = [];
        chartInfo.data.forEach((info) => {
          categories.push(info.name);
          insideValues.push(info.data);
        });
        data = [
          {
            name: "Count",
            data: insideValues,
          },
        ];
        break;

      default:
        categories = chartInfo.categories;
        data = chartInfo.data;
        break;
    }

    return [categories, data];
  } catch (err) {
    return [categories, data];
  }
};
