import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

const CheckboxSet = ({ label, options, handler, disabled }) => {
  const theme = useTheme();

  return (
    <FormControl component="fieldset">
      <FormGroup row aria-label={label} name={label}>
        {options.map((o, i) => (
          <FormControlLabel
            key={`checkboxSet-${o.value}-${i}`}
            control={
              <Checkbox
                checked={o.checked}
                name={o.value}
                onChange={handler}
                disabled={disabled}
                sx={{
                  color: theme.palette.primary.orange,
                  "&.Mui-checked": { color: theme.palette.primary.orange },
                  "&:hover": {
                    backgroundColor: "rgba(248,147,31,0.08)",
                  },
                }}
              />
            }
            label={o.label}
            sx={{
              marginRight: "35px",
            }}
            {...o.optionProps}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

CheckboxSet.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handler: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CheckboxSet;
