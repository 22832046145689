import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";

import { useTranslation } from "react-i18next";

WordcloudChart.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
};

export default function WordcloudChart(props) {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.chartData) {
      setChartData(props.chartData);
    }
  }, [props.chartData]);

  if (chartData) {
    return (
      <div style={{ width: "100%", height: props.height || "88%" }}>
        Chart not supported
      </div>
    );
  } else {
    return t("general.loading");
  }
}
