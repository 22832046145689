module.exports = {
  version: "1.0.9",
  DEFAULT_ACTIVITY_DURATION_IN_DAYS: {
    KickOffMeeting: { startsIn: 3, duration: 5 },
    PreSiteEngagement: { startsIn: 10, duration: 14 },
    IdentifyFocalPoints: { startsIn: 5, duration: 5 },
    MasterDataSheet: { startsIn: 24, duration: 15 },
    ImplementationPlan: { startsIn: 24, duration: 15 },
    TrainingSchedule: { startsIn: 24, duration: 15 },
    IdentifyCriticalBehaviors: { startsIn: 39, duration: 20 },
    SupervisorAndCrewTraining: { startsIn: 59, duration: 45 },
    OngoingCoachingAtSite: { startsIn: 157, duration: 20 },
    HealthChecks: { startsIn: 157, duration: 20 },
    SustainabilityReviews: { startsIn: 157, duration: 20 },
    TranslationOfBehaviors: { startsIn: 59, duration: 20 },
    TTT: { startsIn: 82, duration: 30 },
    LeadershipWorkshopCourse: { startsIn: 82, duration: 30 },
    UploadContractorDirectoryToAegis: { startsIn: 0, duration: 3 },
    SteeringCommittee: { startsIn: 40, duration: 45 },
    LeadershipVisibility: { startsIn: 40, duration: 45 },
  },
  DEFAULT_ACTIVITY_STUBS: [
    "KickOffMeeting",
    "PreSiteEngagement",
    "IdentifyFocalPoints",
    "MasterDataSheet",
    "ImplementationPlan",
    "TrainingSchedule",
    "IdentifyCriticalBehaviors",
    "TTT",
    "LeadershipWorkshopCourse",
    "SupervisorAndCrewTraining",
    "OngoingCoachingAtSite",
    "HealthChecks",
    "SustainabilityReviews",
    "TranslationOfBehaviors",
    "UploadContractorDirectoryToAegis",
    "SteeringCommittee",
    "LeadershipVisibility",
  ],
  taskTypes: {
    ACTION_ASSIGNED: "ACTION",
    SUPERVISOR_ATTENTION_NEEDED: "OBSERVATION",
    REQUIRES_EDIT: "OBSERVATION ",
    TRAINING_INVITE: "TRAINING",
    SUSTAINABILITY_REVIEW_ASSIGNED: "SUSTAINIBILITY",
    APPROVAL_REQUIRED: "OBSERVATION ",
  },
  KPI_TYPES: {
    NUM_PLANNED_OBS: "Planned Observations",
    INTV_OBS: "Intervention Observations",
    ACT_CLOSE_OUTS: "Action Close out",
    TRAIN_COMPLETION: "Training completion",
    CB_TO_INCIDENT: "CB contributing to incident",
    RETIRED_BEHAVIORS: "Retired Behavior",
    SAFE_SCORE: "Safe score",
    LDRSHP_VIS: "Leadership Visibility",
    STEER_CMITE: "Steering Committee",
    KNOWLEDGE: "Knowledge",
    QUALITY_OBS: "Quality Observation",
    AUDIT: "Audit",
  },
  KPI_ALL: [
    "NUM_PLANNED_OBS",
    "INTV_OBS",
    "ACT_CLOSE_OUTS",
    "TRAIN_COMPLETION",
    "CB_TO_INCIDENT",
    "RETIRED_BEHAVIORS",
    "SAFE_SCORE",
    "LDRSHP_VIS",
    "STEER_CMITE",
    "KNOWLEDGE",
    "QUALITY_OBS",
    "AUDIT",
  ],
  KPI_COMPANY_WIDE: [
    "LDRSHP_VIS",
    "STEER_CMITE",
    "KNOWLEDGE",
    "QUALITY_OBS",
    "AUDIT",
  ],
  KPI_UNIT_WISE: [
    "NUM_PLANNED_OBS",
    "INTV_OBS",
    "ACT_CLOSE_OUTS",
    "TRAIN_COMPLETION",
    "CB_TO_INCIDENT",
    "RETIRED_BEHAVIORS",
    "SAFE_SCORE",
  ],
};
