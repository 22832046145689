/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import { returnPositive } from "../../../utils/general";
import { categoryDropdown } from "./constants";
import { safeUnsafeOptions } from "../directorateDashboard/constants";
// material
import {
  Grid,
  Stack,
  Card,
  Typography,
  Tooltip,
  Box,
  Collapse,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
// components
import SelectInput from "../../../components/inputs/SelectInput";
import BubbleChart from "../../../components/charts/BubbleChart";
import TableChart from "../../../components/charts/TableChart";
import PageLoader from "../../../components/PageLoader";
import { bubbleChartOptions } from "./constants";
import {
  IconButtonStyled,
  IconStyled,
  MoreIconStyled,
} from "../../observation/ListObservations/styledComponents";
import AiAlert from "../../../components/AiAlert";
import HelpIcon from "../../../components/HelpIcon";
import PopperBox from "../../../components/PopperBox";
import DateRangeInput from "../../../components/inputs/DateRangeInput";
import TextInput from "../../../components/inputs/TextInput";
//  API
import {
  smartTrendsTableDirectoratesApi,
  smartTrendsBubbleApi,
  getTrendsAlertsApi,
} from "../../../api/smartTrendsDashboard";
import {
  getCompanyFilters,
  getLocationsAndUnitsFilters,
  getDisciplineFilters,
} from "../utils";

const cardStyling = {
  height: 400,
  padding: "16px ",
  borderRadius: "6px",
};

const loaderStyling = {
  height: "90%",
  display: "flex",
  alignItems: "center",
};

export default function ImplementationTrendsView({ directorateFilters }) {
  const yearToDate = new Date(new Date().getFullYear(), 0, 1);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [directorateFilter, setDirectorateFilter] = useState("all");
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [locationFilter, setLocationFilter] = useState("all");
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [unitFilter, setUnitFilter] = useState("all");
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [obsType, setObsType] = useState("all");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [filterParams, setFilterParams] = useState({ pageSize: 10, pageNo: 1 });
  const [tableInfo, setTableInfo] = useState({ totalPages: 1, totalCount: 10 });
  const [rows, setRows] = useState([]);
  const [peopleBubbleData, setPeopleBubbleData] = useState(null);
  const [locationBubbleData, setLocationBubbleData] = useState(null);
  const [equipmentBubbleData, setEquipmentBubbleData] = useState(null);
  const [behaviorBubbleData, setBehaviorBubbleData] = useState(null);
  const [companyFilter, setCompanyFilter] = useState("all");
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [alertsLoaded, setAlertsLoaded] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [dateEnabled, setDateEnabled] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    start: yearToDate,
    end: new Date(),
  });
  const [search, setSearch] = useState("");

  const tableColumns = [
    {
      headerName: t("table.obsCardNo"),
      field: "observationId",
    },
    {
      headerName: t("table.category"),
      field: "feedbackLabels",
    },
    {
      headerName: t("table.subCategory"),
      field: "feedbackText",
    },
    {
      headerName: t("table.safeUnsafe"),
      field: "safeObservation",
      renderCell: (params) => {
        return params.row.safeObservation
          ? t("behaviorInput.safe")
          : t("behaviorInput.unsafe");
      },
    },
    {
      headerName: t("table.sentiment"),
      field: "sentimentFeedback",
      renderCell: (params) => {
        return params.row.sentimentFeedback
          ? `${(params.row.sentimentFeedback * 100).toFixed(0)}%`
          : "0%";
      },
    },
    {
      headerName: t("table.observation"),
      field: "feedback",
    },
  ];

  const renderNoData = () => (
    <Stack
      spacing={1}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NoDataIcon />
      <Typography variant="body1">{t("general.noData")}</Typography>
    </Stack>
  );

  const handleDirectorate = async (directorate) => {
    if (directorate !== directorateFilter) {
      setDirectorateFilter(directorate);
      setCompanyFilter("all");
      setLocationFilter("all");
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const handleCompany = async (company) => {
    if (company !== companyFilter) {
      setCompanyFilter(company);
      setLocationFilter("all");
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const handleLocation = async (location) => {
    if (location !== locationFilter) {
      setLocationFilter(location);
      setUnitFilter("all");
      setDisciplineFilter("all");
    }
  };

  const renderFilters = () => (
    <Grid container spacing={1} sx={{ mt: 1 }} alignItems="flex-end">
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.directorateTitle")}
          disabled={loading || directorateDropdown.length < 1}
          value={directorateFilter}
          handler={(e) => handleDirectorate(e.target.value)}
          options={directorateDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.companyTitle")}
          disabled={
            loading || companyDropdown.length < 1 || directorateFilter === "all"
          }
          value={companyFilter}
          handler={(e) => handleCompany(e.target.value)}
          options={companyDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.locationTitle")}
          disabled={
            loading || locationDropdown.length < 1 || companyFilter === "all"
          }
          value={locationFilter}
          handler={(e) => handleLocation(e.target.value)}
          options={locationDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.unitTitle")}
          disabled={
            loading || unitDropdown.length < 1 || locationFilter === "all"
          }
          value={unitFilter}
          handler={(e) => setUnitFilter(e.target.value)}
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.disciplineTitle")}
          disabled={
            loading || disciplineDropdown.length < 1 || locationFilter === "all"
          }
          value={disciplineFilter}
          handler={(e) => setDisciplineFilter(e.target.value)}
          options={disciplineDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} sx={{ pt: 0 }}>
        <Stack spacing={1} direction="row">
          <Tooltip title={t("general.moreFilters")}>
            <IconButtonStyled
              aria-label={"More filters"}
              onClick={(e) => setPopperAnchor(e.currentTarget)}
            >
              <MoreIconStyled />
            </IconButtonStyled>
          </Tooltip>
          <Tooltip title={t("general.applyFilters")}>
            <IconButtonStyled
              aria-label={"Apply filters"}
              onClick={submitFilters}
            >
              <IconStyled />
            </IconButtonStyled>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderMoreFilters = () => (
    <PopperBox
      filters
      disabled={loading}
      popperAnchor={popperAnchor}
      minWidth="20vw"
      content={
        <>
          <DateRangeInput
            disabled={loading}
            label={t("observations.viewObservations.filters.dateRangeButton")}
            startDate={dateFilter.start}
            endDate={dateFilter.end}
            handler={(start, end) => setDateFilter({ start, end })}
            offOption
            dateEnabled={dateEnabled}
            setDateEnabled={setDateEnabled}
          />
        </>
      }
      handlePopperClose={() => setPopperAnchor(null)}
    />
  );

  const renderTableFilters = () => (
    <Grid container style={{ marginTop: "30px" }} spacing={2}>
      <Grid item xs={12} sm={2} style={{ paddingLeft: 0 }}>
        <SelectInput
          allOption
          label={t("behaviorInput.category")}
          disabled={loading}
          value={categoryFilter}
          handler={(e) => setCategoryFilter(e.target.value)}
          options={categoryDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.observations")}
          disabled={loading}
          value={obsType}
          handler={(e) => setObsType(e.target.value)}
          options={safeUnsafeOptions}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          clearable={search && true}
          disabled={loading}
          label={t(`general.search`)}
          value={search}
          placeholder={t(`general.search`)}
          handler={(e) => setSearch(e.target.value)}
          clearHandler={() => setSearch("")}
        />
      </Grid>
      <Grid item xs={12} sm={1} style={{ paddingTop: "43px" }}>
        <Tooltip title={"Search and filter"}>
          <IconButtonStyled
            aria-label={"Search and filter"}
            onClick={submitTableFilters}
          >
            <IconStyled />
          </IconButtonStyled>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderPeopleLocationCharts = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Card style={cardStyling}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t("dashboards.smartTrends.people")}
            </Typography>
            <HelpIcon text={t("dashboards.tooltips.overall.trendsPeople")} />
          </Stack>
          {peopleBubbleData && peopleBubbleData.length > 1 ? (
            <BubbleChart
              height="95%"
              chartData={peopleBubbleData}
              chartOptions={{
                ...bubbleChartOptions,
                colors: ["#3A5743", "#3B7080", "#CFFFB3", "#FCEC52", "#ADE25D"],
              }}
            />
          ) : peopleBubbleData ? (
            renderNoData()
          ) : (
            <div style={loaderStyling}>
              <PageLoader />
            </div>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} style={{ paddingLeft: "12px" }}>
        <Card style={cardStyling}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t("dashboards.smartTrends.location")}
            </Typography>
            <HelpIcon text={t("dashboards.tooltips.overall.trendsLocation")} />
          </Stack>
          {locationBubbleData && locationBubbleData.length > 1 ? (
            <BubbleChart
              height="95%"
              chartData={locationBubbleData}
              chartOptions={{
                ...bubbleChartOptions,
                colors: ["#36382E", "#E99B79", "#F06449", "#EC9512", "#5BC3EB"],
              }}
            />
          ) : locationBubbleData ? (
            renderNoData()
          ) : (
            <div style={loaderStyling}>
              <PageLoader />
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );

  const renderEquipmentBehaviorCharts = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Card style={cardStyling}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t("dashboards.smartTrends.equipment")}
            </Typography>
            <HelpIcon text={t("dashboards.tooltips.overall.trendsEquipment")} />
          </Stack>
          {equipmentBubbleData && equipmentBubbleData.length > 1 ? (
            <BubbleChart
              height="95%"
              chartData={equipmentBubbleData}
              chartOptions={{
                ...bubbleChartOptions,
                colors: ["#FCAA67", "#FCEC52", "#B0413E", "#ADE25D", "#473335"],
              }}
            />
          ) : equipmentBubbleData ? (
            renderNoData()
          ) : (
            <div style={loaderStyling}>
              <PageLoader />
            </div>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} style={{ paddingLeft: "12px" }}>
        <Card style={cardStyling}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t("dashboards.smartTrends.behavior")}
            </Typography>
            <HelpIcon text={t("dashboards.tooltips.overall.trendsBehaviour")} />
          </Stack>
          {behaviorBubbleData && behaviorBubbleData.length > 1 ? (
            <BubbleChart
              height="95%"
              chartData={behaviorBubbleData}
              chartOptions={{
                ...bubbleChartOptions,
                colors: ["#419982", "#136F63", "#4CE0D2", "#22AAA1", "#84CAE7"],
              }}
            />
          ) : behaviorBubbleData ? (
            renderNoData()
          ) : (
            <div style={loaderStyling}>
              <PageLoader />
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );

  const renderTableView = () => (
    <Card style={{ ...cardStyling, height: "auto" }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.smartTrends.tableView")}
        </Typography>
        <HelpIcon text={t("dashboards.tooltips.overall.trendsTable")} />
      </Stack>
      {loading || tableLoading ? (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      ) : (
        <TableChart
          columns={tableColumns}
          rows={rows}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          tableInfo={tableInfo}
          loading={tableLoading}
        />
      )}
    </Card>
  );

  const renderAlerts = () => (
    <Stack>
      <Collapse in={alertsLoaded}>
        {alertData.map((a, index) => (
          <AiAlert
            handler={() => {
              navigate(
                `/dashboard/directorate-dashboard/smartTrends/${a.directorateId}`,
                {
                  state: {
                    alertStartDate: a.createdAt,
                    companyId: a.companyId,
                    directorateName: a.directorateName,
                  },
                }
              );
            }}
            mt={index > 0 ? 1 : 0}
            labelComponent={
              <Typography variant="alertText">
                <Trans
                  i18nKey={
                    a.LabelCountChange
                      ? "dashboards.alerts.trends"
                      : "dashboards.alerts.trendsNull"
                  }
                  values={{
                    companyName: a.companyName,
                    texts: a.Texts,
                    countChange: returnPositive(a.LabelCountChange),
                    countDifference: returnPositive(a.LabelCountDifferenceNER),
                    countChangeCrement:
                      a.LabelCountChange > 0 ? "increased" : "decreased",
                    countDiffCrement:
                      a.LabelCountDifferenceNER > 0 ? "more" : "less",
                  }}
                  components={{
                    1: <Box component="span" fontWeight="bold" />,
                    2: <Box component="span" fontWeight="bold" />,
                    3: (
                      <Box
                        component="span"
                        fontWeight="bold"
                        color={theme.palette.primary.red}
                      />
                    ),
                    4: (
                      <Box
                        component="span"
                        fontWeight="bold"
                        color={theme.palette.primary.red}
                      />
                    ),
                  }}
                />
              </Typography>
            }
          />
        ))}
      </Collapse>
    </Stack>
  );

  const getTableData = async () => {
    try {
      setLoading(true);
      setTableLoading(true);
      const data = await smartTrendsTableDirectoratesApi({
        ...filterParams,
        directorateId: directorateFilter === "all" ? null : directorateFilter,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        companyId: companyFilter === "all" ? null : companyFilter,
        startDate: dateEnabled
          ? moment(dateFilter.start).format("YYYY-MM-DD")
          : null,
        endDate: dateEnabled
          ? moment(dateFilter.end).format("YYYY-MM-DD")
          : null,
        category: categoryFilter === "all" ? null : categoryFilter,
        obsType: obsType === "all" ? null : obsType,
        search,
      });
      setRows(data.data);
      setTableInfo({
        totalPages: data.totalPages,
        totalCount: data.totalItems,
      });
      setLoading(false);
      setTableLoading(false);
    } catch (err) {
      setLoading(false);
      setTableLoading(false);
    }
  };

  const getPeopleChartData = async () => {
    try {
      setLoading(true);
      const peopleData = await smartTrendsBubbleApi(
        "PEOPLE",
        directorateFilter === "all" ? null : directorateFilter,
        companyFilter === "all" ? null : companyFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        locationFilter === "all" ? null : locationFilter,
        dateEnabled ? moment(dateFilter.start).format("YYYY-MM-DD") : null,
        dateEnabled ? moment(dateFilter.end).format("YYYY-MM-DD") : null
      );
      setPeopleBubbleData(peopleData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getLocationChartData = async () => {
    try {
      setLoading(true);
      const locationData = await smartTrendsBubbleApi(
        "LOCATION",
        directorateFilter === "all" ? null : directorateFilter,
        companyFilter === "all" ? null : companyFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        locationFilter === "all" ? null : locationFilter,
        dateEnabled ? moment(dateFilter.start).format("YYYY-MM-DD") : null,
        dateEnabled ? moment(dateFilter.end).format("YYYY-MM-DD") : null
      );
      setLocationBubbleData(locationData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getEquipmentChartData = async () => {
    try {
      setLoading(true);
      const equipmentData = await smartTrendsBubbleApi(
        "TOOLS",
        directorateFilter === "all" ? null : directorateFilter,
        companyFilter === "all" ? null : companyFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        locationFilter === "all" ? null : locationFilter,
        dateEnabled ? moment(dateFilter.start).format("YYYY-MM-DD") : null,
        dateEnabled ? moment(dateFilter.end).format("YYYY-MM-DD") : null
      );
      setEquipmentBubbleData(equipmentData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getBehaviorChartData = async () => {
    try {
      setLoading(true);
      const behaviorData = await smartTrendsBubbleApi(
        "BEHAVIOR",
        directorateFilter === "all" ? null : directorateFilter,
        companyFilter === "all" ? null : companyFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        locationFilter === "all" ? null : locationFilter,
        dateEnabled ? moment(dateFilter.start).format("YYYY-MM-DD") : null,
        dateEnabled ? moment(dateFilter.end).format("YYYY-MM-DD") : null
      );
      setBehaviorBubbleData(behaviorData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getTrendsAlerts = async () => {
    try {
      setAlertsLoaded(false);
      setLoading(true);
      const trendAlerts = await getTrendsAlertsApi({
        directorateId: directorateFilter === "all" ? null : directorateFilter,
        companyId: companyFilter === "all" ? null : companyFilter,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
      });
      const fullAlerts = trendAlerts.map((ta) => {
        return {
          ...ta,
          companyName: ta.Company?.name || "No contractor",
          directorateName: ta.Directorate?.name || "No directorate",
        };
      });
      setAlertData(fullAlerts);
      setAlertsLoaded(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitFilters = () => {
    setPeopleBubbleData(null);
    setLocationBubbleData(null);
    setEquipmentBubbleData(null);
    setBehaviorBubbleData(null);
    getPeopleChartData();
    getLocationChartData();
    getEquipmentChartData();
    getBehaviorChartData();
    getTrendsAlerts();
    getTableData();
  };

  const submitTableFilters = () => {
    setFilterParams({
      ...filterParams,
      pageNo: 1,
    });
  };

  useEffect(() => {
    getTableData();
  }, [filterParams]);

  useEffect(() => {
    getTrendsAlerts();
    getPeopleChartData();
    getLocationChartData();
    getEquipmentChartData();
    getBehaviorChartData();
  }, []);

  useEffect(() => {
    setDirectorateDropdown(directorateFilters);
  }, [directorateFilters]);

  useEffect(() => {
    if (directorateFilter && directorateFilter !== "all") {
      setCompanyFilter("all");
      getCompanyFilters({
        setLoading,
        directorateFilter,
        setCompanyDropdown,
      });
    }
  }, [directorateFilter]);

  useEffect(() => {
    if (companyFilter && companyFilter !== "all") {
      setLocationFilter("all");
      getLocationsAndUnitsFilters({
        setLoading,
        companyFilter,
        setLocationDropdown,
        setUnitDropdown,
      });
    }
  }, [companyFilter]);

  useEffect(() => {
    if (
      companyFilter &&
      companyFilter !== "all" &&
      locationFilter &&
      locationFilter !== "all"
    ) {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter,
        locationFilter,
        unitFilter,
        setDisciplineDropdown,
      });
    }
  }, [companyFilter, locationFilter, unitFilter]);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {renderAlerts()}
      {renderPeopleLocationCharts()}
      {renderEquipmentBehaviorCharts()}
      {renderTableFilters()}
      {renderTableView()}
      {renderMoreFilters()}
    </Stack>
  );
}
