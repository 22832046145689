export const chartDefaults = {
    noOfObservationsBar: {
        title: "Number of observations",
        chartOptions: {
            colors: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: {colors: ["#FEFEFE"]},
            legend: {floating: true, horizontalAlign: "center"},
            dataLabels: {enabled: true, dropShadow: {enabled: false}},
            xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
        },
        chartData: [
            {
                name: "series-1",
                data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
        ],
    },
};

export const stackedBarOptions = {
    chart: {
        type: "bar",
        stacked: true,
        fontFamily: "Cairo, sans-serif",
    },
    colors: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
    legend: {
        floating: false,
        horizontalAlign: "center",
        show: true,
    },
    dataLabels: {
        enabled: true,
        dropShadow: {enabled: false},
        style: {
            colors: ["#1C1C1C"],
        },
    },
    xaxis: {
        type: "category",
        labels: {
            show: true,
            trim: true,
            hideOverlappingLabels: false,
            showDuplicates: true,
        },
        categories: [],
    },
    yaxis: {
        title: {
            text: "Count",
        },
    },
    plotOptions: {
        bar: {
            columnWidth: "30%",
        },
    },
};

export const barChartOptions = {
    colors: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
    legend: {
        floating: false,
        horizontalAlign: "center",
        show: true,
    },
    dataLabels: {
        enabled: true,
        dropShadow: {enabled: false},
        style: {
            colors: ["#1C1C1C"],
        },
        textAnchor: "middle",
        offsetY: -20,
    },
    xaxis: {
        labels: {
            show: true,
            trim: true,
            // minHeight: 120,
            // maxHeight: 200,
            minWidth: 120,
            maxWidth: 200,
            hideOverlappingLabels: false,
            showDuplicates: true,
        },
    },
    grid: {
        show: true,
        xaxis: {
            lines: {
                show: false,
            }
        },
        yaxis: {
            lines: {
                show: true,
            }
        }
    },
    plotOptions: {
        bar: {
            distributed: true,
            columnWidth: "40%",
            dataLabels: {
                position: 'top', // top, center, bottom
            },
        },
    },
};

export const lineChartOptions = {
    colors: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
    legend: {
        floating: false,
        horizontalAlign: "center",
        show: true,
    },
    dataLabels: {
        enabled: true,
        dropShadow: {enabled: false},
        style: {
            colors: ["#1C1C1C"],
        },
    },
    xaxis: {
        labels: {
            show: true,
            trim: true,
            hideOverlappingLabels: false,
            showDuplicates: true,
        },
    },
    yaxis: {
        showForNullSeries: true,
        max: 100,
        min: 0,

    }
};
