import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

CellText.propTypes = {
  title: PropTypes.string,
};

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
    border: "none",
  },
}));

export default function CellText({ title, ...other }) {
  return (
    <StyledTooltip title={title}>
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }} {...other}>
        {title}
      </span>
    </StyledTooltip>
  );
}
