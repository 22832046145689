export const observationDetailsDefault = {
  observationType: "BEHAVIOR",
  company: "",
  directorate: "",
  location: "",
  unit: "",
  discipline: null,
  date: new Date(),
  unsafeConditions: [],
  observationSubType: "INTERVENTION",
  peopleObserved: 0,
};

export const additionalDetailsDefault = {
  feedback: "",
  rootCause: "",
  actionToPrevent: "",
  suggestions: "",
  isEscalated: false,
};

export const behaviorDetailsDefault = {
  observedNumber: 0,
  observedBehaviors: [],
};

export const infoBoxDefaults = {
  open: false,
  title: "",
  content: "",
  buttonLabel: "",
  handleClose: null,
  handleAction: null,
};

//  DEPRECATED
export const _DEPRECATED_behaviorCategories = [
  {
    value: "Body Mechanics",
    label: "Body Mechanics",
  },
  {
    value: "Communications",
    label: "Communications",
  },
  {
    value: "Body Position",
    label: "Body Position",
  },
  {
    value: "Housekeeping",
    label: "Housekeeping",
  },
  {
    value: "Tool and Equipment Use",
    label: "Tool and Equipment Use",
  },
  {
    value: "Personal Protective Equipment (selection, use, condition)",
    label: "Personal Protective Equipment (selection, use, condition)",
  },
  {
    value: "Procedure",
    label: "Procedure",
  },
  {
    value: "Environment",
    label: "Environment",
  },
  {
    value: "Ignition control",
    label: "Ignition control",
  },
  {
    value: "Ability to Perform",
    label: "Ability to Perform",
  },
  {
    value: "Ergonomics",
    label: "Ergonomics",
  },
  {
    value: "Driving",
    label: "Driving",
  },
];
