import apiHelper from "./apiHelper";
import { generateFormData } from "../utils/general";

export const manageActivityApi = (body) => {
  const bodyFormData = generateFormData(body);
  const { activityTypeStub } = body;
  const formDataRequired = [
    "SiteEngagement",
    "PreSiteEngagement",
    "KickOffMeeting",
  ];
  return new Promise((resolve, reject) => {
    apiHelper
      .post(
        `/api/implementations/manage-activities`,
        formDataRequired.includes(activityTypeStub) ? bodyFormData : body
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const closeOutActivityApi = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/close-out`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const executeActivityApi = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/execute-activity`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getActivityTypesApi = (type = null) => {
  let params = {};
  if (type) params = { type };
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/activity-types`, { params })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const addActivityApi = (body, planId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/${planId}/activities`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getActivityByIdApi = (activityId, activityStub) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/activities/${activityId}`, {
        params: { activityStub },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const implementationContractorsApi = (payload) => {
  const url = `/api/implementations/${payload.planId}/user-directory`;
  return new Promise((resolve, reject) => {
    apiHelper
      .get(url, {
        params: {
          ...(payload ? { companyId: payload.companyId } : {}),
          ...(payload ? { textSearch: payload.textSearch } : {}),
          ...(payload ? { locationIds: payload.locationIds } : {}),
          ...(payload ? { unitIds: payload.unitIds } : {}),
          ...(payload && payload.pageSize
            ? { pageSize: payload.pageSize }
            : { pageSize: 99999999 }),
          ...(payload && payload.pageNo
            ? { pageNo: payload.pageNo }
            : { pageNo: 1 }),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const deleteCustomActivityApi = (planId, activityId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .delete(`/api/implementations/${planId}/activities/${activityId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateActivityApi = (planId, activityId, body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/implementations/${planId}/activities/${activityId}`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
