import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const IconButtonStyle = styled(IconButton)(({ theme, primary, red }) => ({
  //   height: "50px",
  //   width: "50px",
  borderRadius: "8px",
  color: primary
    ? theme.palette.primary.buttonOrange
    : red
    ? theme.palette.primary.red
    : theme.palette.primary.hoverGrey,
}));

const TableButton = ({ label, icon, onClick, disabled, primary, red }) => {
  return (
    <Tooltip title={label}>
      <IconButtonStyle
        aria-label={label}
        onClick={onClick}
        disabled={disabled}
        primary={primary}
        red={red}
      >
        {icon}
      </IconButtonStyle>
    </Tooltip>
  );
};

TableButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  red: PropTypes.bool,
};

export default TableButton;
