import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

AlertBox.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  handler: PropTypes.func,
  message: PropTypes.string,
};

export default function AlertBox({ open, handler, type, message }) {
  return (
    <Collapse in={open}>
      <Alert
        action={
          handler ? (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handler}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          ) : null
        }
        sx={{ mb: 2 }}
        severity={type}
      >
        {message}
      </Alert>
    </Collapse>
  );
}
