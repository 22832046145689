import React from "react";

const ArIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.732 32L15.432 18.22H19.252L22.952 32H21.012L20.112 28.7H14.552L13.652 32H11.732ZM14.932 27.06H19.752L17.832 19.82H16.852L14.932 27.06ZM24.9038 32V18.22H30.1038C31.1171 18.22 31.9638 18.3667 32.6438 18.66C33.3238 18.9533 33.8304 19.4133 34.1638 20.04C34.5104 20.6667 34.6838 21.4867 34.6838 22.5C34.6838 23.5 34.4838 24.3467 34.0838 25.04C33.6971 25.7333 33.0904 26.22 32.2638 26.5L34.7838 32H32.6838L30.4238 26.92H26.8238V32H24.9038ZM26.8238 25.3H30.1238C30.7638 25.3 31.2704 25.18 31.6438 24.94C32.0304 24.7 32.3038 24.3733 32.4638 23.96C32.6238 23.5333 32.7038 23.0533 32.7038 22.52C32.7038 21.6267 32.4904 20.96 32.0638 20.52C31.6504 20.08 30.9971 19.86 30.1038 19.86H26.8238V25.3Z"
        fill="black"
      />
    </svg>
  );
};

export default ArIcon;
