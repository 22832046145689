import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import SecondaryButton from "../buttons/SecondaryButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SelectInput from "../inputs/SelectInput";

UnsafeConditionInput.propTypes = {
  disabled: PropTypes.bool,
  unsafeConditions: PropTypes.arrayOf(PropTypes.object),
  setUnsafeConditions: PropTypes.func,
  rawData: PropTypes.arrayOf(PropTypes.object),
  displayOnly: PropTypes.bool,
};

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.red,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverRed,
  },
}));

const IconDeleteStyled = styled(DeleteOutlineOutlined)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function UnsafeConditionInput({
  disabled,
  unsafeConditions,
  rawData,
  setUnsafeConditions,
  displayOnly,
}) {
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSecondOption, setSelectedSecondOption] = useState("");
  const [firstDropdown, setFirstDropdown] = useState([]);
  const [secondDropdown, setSecondDropdown] = useState([]);
  const currentLanguage = i18n.language;

  const submitNewUnsafeCondition = () => {
    const targetFirstOption = rawData.find((f) => f.id === selectedOption);
    if (targetFirstOption) {
      const targetSecondOption = targetFirstOption.behaviors.find(
        (b) => b.id === selectedSecondOption
      );
      const toBeAdded = {
        label: targetSecondOption.name,
        value: targetSecondOption.id,
        labelAR: targetSecondOption.nameAR,
        mainConditionId: targetFirstOption.id,
        unsafeConditionId: targetSecondOption.id,
      };

      const updatedArray = unsafeConditions;
      updatedArray.push(toBeAdded);

      const updatedOptions = _.filter(secondDropdown, (b, i) => {
        return b.value !== targetSecondOption.id;
      });

      setSecondDropdown(updatedOptions);
      setUnsafeConditions(updatedArray);
    }
  };

  const handleRemoveUnsafeCondition = (condition) => {
    const updatedUnsafe = _.filter(unsafeConditions, (c, i) => {
      return c.value !== condition.value;
    });

    const updatedDropdown = secondDropdown;
    updatedDropdown.push(condition);
    setUnsafeConditions(updatedUnsafe);
    setSecondDropdown(updatedDropdown);
  };

  useEffect(() => {
    if (rawData && rawData.length > 0) {
      const firstDropdownData = rawData.map((uc) => {
        return {
          label: uc.name,
          value: uc.id,
          labelAR: uc.nameAR,
        };
      });

      setFirstDropdown(firstDropdownData);

      const secondDropdownInitial = rawData[0].behaviors.map((b) => {
        return {
          label: b.name,
          value: b.id,
          labelAR: b.nameAR,
        };
      });

      setSecondDropdown(secondDropdownInitial);
    }
  }, [rawData]);

  useEffect(() => {
    if (rawData) {
      setSelectedSecondOption("");
      const targetMain = rawData.find((m) => m.id === selectedOption);
      if (targetMain) {
        const existingIds = unsafeConditions.map((uc) => {
          return uc.unsafeConditionId;
        });
        const secondDropdownInitial = [];

        targetMain.behaviors.forEach((b) => {
          if (!existingIds.includes(b.id)) {
            secondDropdownInitial.push({
              label: b.name,
              value: b.id,
              labelAR: b.nameAR,
            });
          }
        });

        setSecondDropdown(secondDropdownInitial);
      }
    }
  }, [selectedOption, rawData, unsafeConditions]);

  return (
    <Box>
      {!displayOnly && (
        <Typography variant="h6">
          {t("observations.observationApp.unsafeConditions")}
        </Typography>
      )}
      {unsafeConditions.length > 0 ? (
        unsafeConditions.map((c, i) => (
          <Stack direction="row" style={{ marginTop: "22px" }}>
            <div
              style={{
                width: "95%",
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                border: "1px solid #C2C2C2",
                borderRadius: "10px",
                height: "43px",
                display: "flex",
                alignItems: "center",
                padding: "0 23px",
                marginRight: "32px",
              }}
              key={`uploaded-attachment-${i}-${c.label}`}
            >
              <Typography variant="body1">
                {currentLanguage === "ar" && c.labelAR ? c.labelAR : c.label}
              </Typography>
            </div>

            {!displayOnly && (
              <Tooltip
                title={t("observations.observationApp.removeUnsafeConditions")}
              >
                <IconButtonStyle
                  aria-label={t(
                    "observations.observationApp.removeUnsafeConditions"
                  )}
                  onClick={() => {
                    handleRemoveUnsafeCondition(c);
                    setSelectedOption(null);
                  }}
                  disabled={disabled}
                >
                  <IconDeleteStyled />
                </IconButtonStyle>
              </Tooltip>
            )}
          </Stack>
        ))
      ) : (
        <Typography variant="body1" style={{ marginTop: "20px" }}>
          {t("observations.observationApp.noUnsafeConditions")}
        </Typography>
      )}

      {!displayOnly && (
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          style={{ marginTop: "10px" }}
        >
          <Grid item xs={12} sm={10} md={4}>
            <SelectInput
              value={selectedOption}
              handler={(e) => setSelectedOption(e.target.value)}
              options={firstDropdown}
              label={t("behaviorInput.category")}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={4}>
            <SelectInput
              value={selectedSecondOption}
              handler={(e) => setSelectedSecondOption(e.target.value)}
              options={secondDropdown}
              label={t("observations.observationApp.unsafeCondition")}
              disabled={
                disabled || !selectedOption || secondDropdown.length < 1
              }
            />
          </Grid>
          <Grid item xs={12} sm={2} md={4}>
            <SecondaryButton
              icon={<AddCircleIcon />}
              label={t("observations.observationApp.addUnsafeCondition")}
              alignment={"left"}
              onClick={submitNewUnsafeCondition}
              disabled={disabled || !selectedOption || !selectedSecondOption}
              style={{ marginTop: "32px" }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
