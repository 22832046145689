import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const IconButtonStyle = styled(IconButton)({
  height: "50px",
  width: "50px",
  borderRadius: "10px",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.11)",
});

const NavigationIconButton = ({ label, icon, onClick }) => {
  return (
    <Tooltip title={label}>
      <IconButtonStyle aria-label={label} onClick={onClick}>
        {icon}
      </IconButtonStyle>
    </Tooltip>
  );
};

NavigationIconButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default NavigationIconButton;
