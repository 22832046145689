import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { displayToast } from "../utils/general";
import { styled } from "@mui/material/styles";
import { Typography, Box, Stack, IconButton, Tooltip } from "@mui/material";
import { InfoOutlined, ContentCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HelpText from "./HelpText";

CopyBox.propTypes = {
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  copyText: PropTypes.string,
  hoverText: PropTypes.string,
};

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.grey,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverGrey,
  },
}));

const IconDeleteStyled = styled(ContentCopy)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function CopyBox({
  helpText,
  disabled,
  labelText,
  copyText,
  hoverText,
}) {
  const { t } = useTranslation();

  const handleCopied = () => {
    displayToast(null, "successLoading", t("general.copySuccess"), null);
  };

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ pb: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{labelText}</Typography>
        {helpText && (
          <HelpText title={helpText}>
            <IconStyle />
          </HelpText>
        )}
      </Stack>

      <Stack direction="row">
        <div
          style={{
            width: "95%",
            background:
              "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
            border: "1px solid #C2C2C2",
            borderRadius: "10px",
            height: "43px",
            display: "flex",
            alignItems: "center",
            padding: "0 12px",
            marginRight: "12px",
          }}
        >
          <Typography variant="body1">{copyText}</Typography>
        </div>

        <CopyToClipboard text={copyText} onCopy={handleCopied}>
          <Tooltip title={hoverText}>
            <IconButtonStyle aria-label={t("general.copy")} disabled={disabled}>
              <IconDeleteStyled />
            </IconButtonStyle>
          </Tooltip>
        </CopyToClipboard>
      </Stack>
    </Box>
  );
}
