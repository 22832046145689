import apiHelper from "./apiHelper";
import { generateFormData } from "../utils/general";

export const createImplementation = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getById = ({ id, pageSize, pageNo }) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/${id}/activities`, {
        params: {
          pageSize,
          pageNo,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getImplementationCompaniesApi = ({
  pageSize = 9999,
  pageNo = 1,
  directorateId = null,
  companyId = null,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations`, {
        params: {
          pageSize,
          pageNo,
          directorateId: `${
            directorateId?.length > 0 ? directorateId.map((e) => e) : ""
          }`,
          companyId: `${companyId?.length > 0 ? companyId?.map((e) => e) : ""}`,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getPlanLocationsApi = (id) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/${id}/locations`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getPlanUnitsApi = (id) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/${id}/units`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getPlanById = (id) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getFocalPoints = (companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/focal-points`, { params: { companyId } })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const uploadContractorsCSV = (body, planId, companyId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(
        `/api/implementations/${planId}/contractors?companyId=${companyId}`,
        bodyFormData
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const getImplementationsByCompanyApi = (companyId, pageNo, pageSize) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/company/${companyId}`, {
        params: {
          pageNo,
          pageSize,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationsAndUnitsApi = (planId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/${planId}/locationsAndUnits`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTrainingTypes = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/get-training`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTrainingPlans = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/get-all-training-plans?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const scheduleTraining = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/create-training`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateTrainingAndUsers = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/update-training-plans-and-users`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const concludeTraining = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/conclude-training`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTrainingPlanUsersById = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/get-all-training-plans-users?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTrainingPlanUsersByImplementation = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/trained-users?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getNotTrainingPlanUsersByImplementation = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/non-trained-users?${query}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTrainingPlanUsersByTpId = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/all-training-plans-users-by-TPId?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getImplementationPlanUsers = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/implementation-plan-users?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTraineeUser = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/trainee-users?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateTraineeInviteTask = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/update-trainee-status`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const markTrainingComplete = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/implementations/mark-user-trained`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const optForTraining = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/opted-for`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const OptForDataByTaskId = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/opted-for-data?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const optForTaskSubmit = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/implementations/opted-for-request-update`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const trainingRequestAction = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/add-user-in-total-seats`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getVerifiersAndManagersApi = (planId, companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/implementations/${planId}/company/${companyId}/verifiersAndManagers`
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateVerifiersApi = (planId, body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/${planId}/updateVerifiers`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getVisits = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/getVisits${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getVisit = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/getVisit${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const createVisit = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/createVisit`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const saveVisit = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/implementations/saveVisit`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const deleteVisit = (id) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .delete(`api/implementations/removeVisit/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const removeUserFromTraining = (traineeId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .delete(`/api/implementations/trainingPlan/removeUser/${traineeId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getImplementationCompaniesFiltersApi = (directorateIds) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/companies?directorateIds=${directorateIds}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
