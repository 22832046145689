import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

BubbleChart.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
};

export default function BubbleChart(props) {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.chartData && props.chartOptions) {
      setChartOptions(props.chartOptions);
      setChartData(props.chartData);
    }
  }, [props.chartOptions, props.chartData]);

  if (chartData && chartOptions) {
    return (
      <div
        style={{
          height: props.height || "88%",
          width: "100%",
          direction: "ltr",
        }}
      >
        <ReactApexChart
          type="bubble"
          options={chartOptions}
          series={chartData}
          height={props.height || "88%"}
          width={"100%"}
        />
      </div>
    );
  } else {
    return t("general.loading");
  }
}
