import React from "react";
import PropTypes from "prop-types";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useTheme, styled } from "@mui/material/styles";
import HelpText from "../HelpText";

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const RadioSet = ({
  label,
  options,
  handleChange,
  value,
  helpText,
  disabled,
}) => {
  const theme = useTheme();

  return (
    <Box>
      {label && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{label}</Typography>
          {helpText && (
            <HelpText title={helpText}>
              <IconStyle />
            </HelpText>
          )}
        </Stack>
      )}
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label={label}
          name={label}
          defaultValue={options && options[0].value}
          onChange={handleChange}
          value={value}
        >
          {options.map((o, i) => (
            <FormControlLabel
              value={o.value}
              key={`radioSet-${o.value}-${i}`}
              disabled={disabled}
              control={
                <Radio
                  sx={{
                    color: theme.palette.primary.orange,
                    "&.Mui-checked": { color: theme.palette.primary.orange },
                    "&:hover": {
                      backgroundColor: "rgba(248,147,31,0.08)",
                    },
                  }}
                />
              }
              label={o.label}
              sx={{
                marginRight: "35px",
              }}
              {...o.optionProps}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

RadioSet.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioSet;
