export const requiredFields = {
  total_observation_report: {
    required: ["fromTo", "groupBy"],
    extra: [],
  },
  ihtimam_total_performance: {
    required: ["fromTo", "groupBy"],
    extra: [],
  },
  observation_summary: {
    required: ["fromTo", "cardStatus", "directorate", "groupBy"],
    extra: ["cardStatus"],
  },
  complete_observation: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  action_close_out_reports: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  behavioral_trend: {
    required: [
      "fromTo",
      "behavior",
      "groupBy",
      "timeInterval",
      "directorate",
      "contractorCompany",
      "location",
      "discipline",
    ],
    extra: [],
  },
  total_lsr_report: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  lsr_violation_report: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  behavior_linked_lsr_report: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  lsr_type_classification_report: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  root_cause_against_observation: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  root_cause_trend: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  root_cause_with_behaviour: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
  unit_performance: {
    required: [
      "fromTo",
      "directorate",
      "groupBy",
      "behavior",
      "contractorCompany",
      "location",
      "discipline",
    ],
    extra: [],
  },
  directorate_report: {
    required: ["fromTo", "directorate", "groupBy"],
    extra: [],
  },
};
