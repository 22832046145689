/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { subDays, addDays } from "date-fns";
import { barChartOptions } from "./constants";
// material
import { Grid, Stack, Card, Typography, Tooltip } from "@mui/material";
// components
import SelectInput from "../../../components/inputs/SelectInput";
import BarChart from "../../../components/charts/BarChart";
import TableChart from "../../../components/charts/TableChart";
import DateRangeInput from "../../../components/inputs/DateRangeInput";
import PageLoader from "../../../components/PageLoader";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
import PopperBox from "../../../components/PopperBox";
import TextInput from "../../../components/inputs/TextInput";
import HelpIcon from "../../../components/HelpIcon";
import {
  IconButtonStyled,
  IconStyled,
  MoreIconStyled,
  SearchIcon,
} from "../../observation/ListObservations/styledComponents";
//  API
import { getLsrBarChartsApi, getLsrTableApi } from "../../../api/lsrDashboard";
import { getDisciplineFilters } from "../utils";

// ----------------------------------------------------------------------

const cardStyling = {
  height: 400,
  padding: "16px ",
  borderRadius: "6px",
};

const loaderStyling = {
  height: "90%",
  display: "flex",
  alignItems: "center",
};

export default function LSRs({
  companyId,
  locationOptions = [],
  topicOptions,
  unitOptions = [],
  state,
}) {
  const yearToDate = new Date(new Date().getFullYear(), 0, 1);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [topicFilters, setTopicFilters] = useState([]);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [locationFilter, setLocationFilter] = useState("all");
  const [unitFilter, setUnitFilter] = useState("all");
  const [disciplineOptions, setDisciplineOptions] = useState([]);

  //  If coming from alert, set start and end date as previous 30 days from alert start date
  const [dateFilter, setDateFilter] = useState({
    start: state.alertStartDate
      ? subDays(new Date(state.alertStartDate), 31)
      : yearToDate,
    end: state.alertStartDate
      ? new Date(state.alertStartDate)
      : moment().diff(yearToDate, "days") < 31
      ? addDays(yearToDate, 31)
      : new Date(),
  });
  const [filterParams, setFilterParams] = useState({
    directorateId: state.directorateId,
    disciplineId: "all",
    locationId: "all",
    startDate: state.alertStartDate
      ? subDays(new Date(state.alertStartDate), 31)
      : yearToDate,
    endDate: state.alertStartDate
      ? new Date(state.alertStartDate)
      : moment().diff(yearToDate, "days") < 31
      ? addDays(yearToDate, 31)
      : new Date(),
  });
  const [pageFilters, setPageFilters] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [tableInfo, setTableInfo] = useState({ totalPages: 1, totalCount: 10 });
  const [rows, setRows] = useState([]);
  const [lsrTopicOptions, setLsrTopicOptions] = useState(null);
  const [lsrTopicData, setLsrTopicData] = useState([]);
  const [topicDataLoaded, setTopicDataLoaded] = useState(false);
  const [lsrLocationOptions, setLsrLocationOptions] = useState(null);
  const [lsrLocationData, setLsrLocationData] = useState([]);
  const [locationDataLoaded, setLocationDataLoaded] = useState(false);
  const [lsrDisciplineOptions, setLsrDisciplineOptions] = useState(null);
  const [lsrDisciplineData, setLsrDisciplineData] = useState([]);
  const [disciplineDataLoaded, setDisciplineDataLoaded] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [search, setSearch] = useState("");

  const tableColumns = [
    {
      headerName: t("table.obsCardNo"),
      field: "observationId",
    },
    {
      headerName: t("table.location"),
      field: "locationName",
    },
    {
      headerName: t("table.discipline"),
      field: "disciplineName",
    },
    {
      headerName: t("table.topic"),
      field: "Label",
    },
    {
      headerName: t("reporting.text"),
      field: "Text",
    },
    {
      headerName: t("table.observation"),
      field: "Observation",
    },
  ];

  const renderFilters = () => (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12} sm={7}>
        <MultiSelectInput
          placeholder={t("general.all")}
          label={t("observations.viewObservations.filters.topics")}
          disabled={loading}
          options={topicOptions}
          handler={(newValue) => setTopicFilters(newValue)}
          values={topicFilters}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <DateRangeInput
          disabled={loading}
          label={t("observations.viewObservations.filters.dateRangeButton")}
          startDate={dateFilter.start}
          endDate={dateFilter.end}
          handler={(start, end) => setDateFilter({ start, end })}
        />
      </Grid>
      <Grid item xs={1} sm={1}>
        <Stack spacing={1} direction="row">
          <Tooltip title={t("general.moreFilters")}>
            <IconButtonStyled
              aria-label={"More filters"}
              onClick={(e) => setPopperAnchor(e.currentTarget)}
            >
              <MoreIconStyled />
            </IconButtonStyled>
          </Tooltip>
          <Tooltip title={t("general.applyFilters")}>
            <IconButtonStyled
              aria-label={t("general.applyFilters")}
              onClick={submitFilters}
            >
              <IconStyled />
            </IconButtonStyled>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderMoreFilters = () => (
    <PopperBox
      filters
      disabled={loading}
      popperAnchor={popperAnchor}
      minWidth="20vw"
      content={
        <>
          <SelectInput
            allOption
            label={t("observations.viewObservations.filters.locationTitle")}
            disabled={loading || locationOptions.length < 1}
            value={locationFilter}
            handler={(e) => setLocationFilter(e.target.value)}
            options={locationOptions}
          />
          <SelectInput
            allOption
            label={t("observations.viewObservations.filters.unitTitle")}
            disabled={loading || unitOptions.length < 1}
            value={unitFilter}
            handler={(e) => setUnitFilter(e.target.value)}
            options={unitOptions}
          />
          <SelectInput
            allOption
            label={t("observations.viewObservations.filters.disciplineTitle")}
            disabled={loading || disciplineOptions.length < 1}
            value={disciplineFilter}
            handler={(e) => setDisciplineFilter(e.target.value)}
            options={disciplineOptions}
          />
        </>
      }
      handlePopperClose={() => setPopperAnchor(null)}
    />
  );

  const renderTableFilters = () => (
    <Grid container style={{ marginTop: "30px" }} spacing={2}>
      <Grid item xs={12} sm={3} style={{ paddingLeft: 0 }}>
        <TextInput
          clearable={search && true}
          disabled={loading}
          label={t(`general.search`)}
          value={search}
          placeholder={t(`general.search`)}
          handler={(e) => setSearch(e.target.value)}
          clearHandler={() => setSearch("")}
        />
      </Grid>
      <Grid item xs={12} sm={1} style={{ paddingTop: "43px" }}>
        <Tooltip title={"Search and filter"}>
          <IconButtonStyled
            aria-label={"Search and filter"}
            onClick={submitTableFilters}
          >
            <SearchIcon />
          </IconButtonStyled>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderNoData = () => (
    <Stack
      spacing={1}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NoDataIcon />
      <Typography variant="body1">{t("general.noData")}</Typography>
    </Stack>
  );

  const renderBarCharts = () => (
    <Stack spacing={1} direction="column">
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Card style={cardStyling}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                {t("dashboards.implementationDashboards.lsrTopics")}
              </Typography>
              <HelpIcon
                text={t("dashboards.tooltips.contractor.lsrs.topics")}
              />
            </Stack>
            {!topicDataLoaded || loading ? (
              <div style={loaderStyling}>
                <PageLoader />
              </div>
            ) : lsrTopicData.length > 0 &&
              lsrTopicData[0].data.length > 0 &&
              lsrTopicOptions ? (
              <BarChart
                blankLoading
                height="95%"
                chartData={lsrTopicData}
                chartOptions={lsrTopicOptions}
              />
            ) : (
              renderNoData()
            )}
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card style={cardStyling}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                {t("dashboards.implementationDashboards.lsrLocations")}
              </Typography>
              <HelpIcon
                text={t("dashboards.tooltips.contractor.lsrs.locations")}
              />
            </Stack>
            {!locationDataLoaded || loading ? (
              <div style={loaderStyling}>
                <PageLoader />
              </div>
            ) : lsrLocationData.length > 0 &&
              lsrLocationData[0].data.length > 0 &&
              lsrLocationOptions ? (
              <BarChart
                blankLoading
                height="95%"
                chartData={lsrLocationData}
                chartOptions={lsrLocationOptions}
              />
            ) : (
              renderNoData()
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingLeft: "12px" }}>
          <Card style={cardStyling}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                {t("dashboards.implementationDashboards.lsrDisciplines")}
              </Typography>
              <HelpIcon
                text={t("dashboards.tooltips.contractor.lsrs.disciplines")}
              />
            </Stack>

            {!disciplineDataLoaded || loading ? (
              <div style={loaderStyling}>
                <PageLoader />
              </div>
            ) : lsrDisciplineData.length > 0 &&
              lsrDisciplineData[0].data.length > 0 &&
              lsrDisciplineOptions ? (
              <BarChart
                blankLoading
                height="95%"
                chartData={lsrDisciplineData}
                chartOptions={lsrDisciplineOptions}
              />
            ) : (
              renderNoData()
            )}
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );

  const renderLsrTable = () => (
    <Card style={{ ...cardStyling, height: "auto" }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.lsrTable")}
        </Typography>
        <HelpIcon text={t("dashboards.tooltips.contractor.lsrs.table")} />
      </Stack>
      {tableLoading || loading ? (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      ) : (
        <TableChart
          columns={tableColumns}
          rows={rows}
          filterParams={pageFilters}
          setFilterParams={setPageFilters}
          tableInfo={tableInfo}
          loading={tableLoading}
        />
      )}
    </Card>
  );

  const getTableData = async (startDate, endDate) => {
    try {
      setTableLoading(true);
      const data = await getLsrTableApi({
        ...pageFilters,
        directorateId: state.directorateId,
        companyId,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        startDate: moment(startDate).startOf("day").toISOString(),
        endDate: moment(endDate).endOf("day").toISOString(),
        topics:
          topicFilters.length === 0 ? null : topicFilters.map((t) => t.value),
        search,
      });
      setRows(data.data);
      setTableInfo({
        totalPages: data.totalPages,
        totalCount: data.totalItems,
      });
      setTableLoading(false);
    } catch (err) {
      setTableLoading(false);
    }
  };

  const submitFilters = () => {
    getTableData(dateFilter.start, dateFilter.end);
    setFilterParams({
      ...filterParams,
      disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
      locationId: locationFilter === "all" ? null : locationFilter,
      startDate: dateFilter.start,
      endDate: dateFilter.end,
      topics:
        topicFilters.length === 0 ? null : topicFilters.map((t) => t.value),
    });
  };

  const submitTableFilters = () => {
    setPageFilters({
      ...pageFilters,
      pageNo: 1,
    });
  };

  const getLsrTopicData = async () => {
    try {
      setChartLoading(true);
      const rawData = await getLsrBarChartsApi({
        directorateId: state.directorateId,
        companyId,
        type: "topic",
        topics:
          topicFilters.length === 0 ? null : topicFilters.map((t) => t.value),
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        startDate: moment(filterParams.startDate).startOf("day").toISOString(),
        endDate: moment(filterParams.endDate).endOf("day").toISOString(),
      });

      const tempCats = Object.keys(rawData);
      const chartCats = tempCats.map((c) => {
        const words = c.split(" ");
        return words;
      });

      const chartData = tempCats.map((cat) => {
        return parseFloat(rawData[cat]) || 0;
      });
      setLsrTopicOptions({
        ...barChartOptions,
        xaxis: {
          ...barChartOptions.xaxis,
          categories: chartCats,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value.toFixed(0);
            },
          },
        },
      });
      setLsrTopicData([{ name: "Observations count", data: chartData }]);
      setTopicDataLoaded(true);
      setChartLoading(false);
    } catch (err) {
      setChartLoading(false);
    }
  };

  const getLsrLocationData = async () => {
    try {
      setChartLoading(true);
      const rawData = await getLsrBarChartsApi({
        directorateId: state.directorateId,
        companyId,
        type: "location",
        topics:
          topicFilters.length === 0 ? null : topicFilters.map((t) => t.value),
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        startDate: moment(filterParams.startDate).startOf("day").toISOString(),
        endDate: moment(filterParams.endDate).endOf("day").toISOString(),
      });

      const tempCats = Object.keys(rawData);
      const chartCats = tempCats.map((c) => {
        const words = c.split(" ");
        return words;
      });

      const chartData = tempCats.map((cat) => {
        return parseFloat(rawData[cat]) || 0;
      });
      setLsrLocationOptions({
        ...barChartOptions,
        colors: ["#EC9512"],
        xaxis: {
          ...barChartOptions.xaxis,
          categories: chartCats,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value.toFixed(0);
            },
          },
        },
      });
      setLsrLocationData([{ name: "Observations count", data: chartData }]);
      setLocationDataLoaded(true);
      setChartLoading(false);
    } catch (err) {
      setChartLoading(false);
    }
  };

  const getLsrDisciplineData = async () => {
    try {
      setChartLoading(true);
      const rawData = await getLsrBarChartsApi({
        directorateId: state.directorateId,
        companyId,
        type: "discipline",
        topics:
          topicFilters.length === 0 ? null : topicFilters.map((t) => t.value),
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        startDate: moment(filterParams.startDate).startOf("day").toISOString(),
        endDate: moment(filterParams.endDate).endOf("day").toISOString(),
      });

      const tempCats = Object.keys(rawData);
      const chartCats = tempCats.map((c) => {
        const words = c.split(" ");
        return words;
      });

      const chartData = tempCats.map((cat) => {
        return parseFloat(rawData[cat]) || 0;
      });
      setLsrDisciplineOptions({
        ...barChartOptions,
        colors: ["#22AAA1"],
        xaxis: {
          ...barChartOptions.xaxis,
          categories: chartCats,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value.toFixed(0);
            },
          },
        },
      });
      setLsrDisciplineData([{ name: "Observations count", data: chartData }]);
      setDisciplineDataLoaded(true);
      setChartLoading(false);
    } catch (err) {
      setChartLoading(false);
    }
  };

  useEffect(() => {
    getLsrTopicData();
    getLsrLocationData();
    getLsrDisciplineData();
  }, [filterParams]);

  useEffect(() => {
    getTableData(filterParams.startDate, filterParams.endDate);
  }, [pageFilters]);

  useEffect(() => {
    if (companyId && locationFilter && locationFilter !== "all") {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter: companyId,
        locationFilter,
        unitFilter,
        setDisciplineDropdown: setDisciplineOptions,
      });
    }
  }, [companyId, locationFilter]);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {renderBarCharts()}
      {renderTableFilters()}
      {renderLsrTable()}
      {renderMoreFilters()}
    </Stack>
  );
}
