import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
// material
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
//  Components
import AccessControl from "./AccessControl";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.sidebar.inactive,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const UnderlineStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.orange,
  textDecoration: "underline",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.hoverOrange,
  },
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const { t } = useTranslation();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.orange",
    fontWeight: "fontWeightBold",
  };

  const activeSubStyle = {
    color: "primary.black",
    backgroundColor: "primary.orange",
    fontWeight: "fontWeightBold",
    "&:hover": {
      backgroundColor: "primary.light",
    },
  };

  const iconStyle = {
    width: 16,
    height: 16,
    ml: 1,
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          key={item.title}
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={t(title)} />
          {info && info}
          {open ? (
            <KeyboardArrowUpIcon sx={iconStyle} />
          ) : (
            <KeyboardArrowDownIcon sx={iconStyle} />
          )}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item, index) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <AccessControl
                  accessName={item.accessName}
                  accessNames={item.accessNames}
                  key={`${item.title}-${index}-ac`}
                >
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                      ml: 3,
                    }}
                  >
                    <ListItemText disableTypography primary={t(title)} />
                  </ListItemStyle>
                </AccessControl>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={t(title)} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  const handleNavigatePolicy = () => {
    navigate("/privacy", { state: { back: true } });
  };

  return (
    <Box {...other}>
      <Stack justifyContent="space-between" height="94vh">
        <List>
          {navConfig.map((item, index) => (
            <AccessControl
              accessName={item.accessName}
              accessNames={item.accessNames}
              key={`navSectionAC-${index}`}
            >
              <NavItem
                key={`nav-item-${item.title}-${index}`}
                item={item}
                active={match}
              />
            </AccessControl>
          ))}
        </List>
        <Stack direction="row" justifyContent="center">
          <UnderlineStyle variant="caption" onClick={handleNavigatePolicy}>
            {t("general.privacy")}
          </UnderlineStyle>
        </Stack>
      </Stack>
    </Box>
  );
}
