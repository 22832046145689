import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import PageLoader from "../PageLoader";

GroupedBarChart.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
  name: PropTypes.string,
};

export default function GroupedBarChart(props) {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.chartData && props.chartOptions) {
      setChartOptions(props.chartOptions);
      setChartData(props.chartData);
    }
  }, [props.chartOptions, props.chartData]);
  return (
    <>
      {chartOptions && chartData?.length > 0 ? (
        <>
          {props?.name && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {props?.name}
            </div>
          )}
          <div
            style={{
              height: props.height || "88%",
              width: "100%",
              direction: "ltr",
            }}
          >
            <ReactApexChart
              type="bar"
              options={chartOptions}
              series={chartData}
              height={props?.height || "90%"}
              width={"100%"}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: props?.height || "90%",
            direction: "ltr",
          }}
        >
          <PageLoader />
        </div>
      )}
    </>
  );
}
