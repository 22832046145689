import { percentFormatter } from "../../../utils/general";

const exampleMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const exampleDirectorates = [
  {
    name: "UID",
    value: 1,
  },
  {
    name: "UWD",
    value: 2,
  },
  {
    name: "XD",
    value: 3,
  },
];

export const exampleCompanies = [
  {
    name: "AL BARAKA",
    value: 1,
  },
  {
    name: "AL SAJ AL",
    value: 2,
  },
  {
    name: "BA OMAR",
    value: 3,
  },
  {
    name: "CACTUS",
    value: 4,
  },
  {
    name: "GULF",
    value: 5,
  },
  {
    name: "AL GHALBI",
    value: 6,
  },
];

const exampleQualityCategories = [
  "Variable 1",
  "Variable 2",
  "Variable 3",
  "Variable 4",
  "Variable 5",
  "Variable 6",
];

export const safeUnsafeOptions = [
  {
    label: "Safe",
    value: "Safe",
  },
  {
    label: "Unsafe",
    value: "Unsafe",
  },
];

export const barChartOptions = {
  colors: ["#43A1D7"],
  legend: {
    floating: true,
    horizontalAlign: "center",
    itemMargin: {
      vertical: 20,
    },
    show: false,
  },
  dataLabels: {
    enabled: false,
    dropShadow: { enabled: false },
  },
  xaxis: {
    categories: exampleQualityCategories,
    labels: {
      maxHeight: 1000,
    },
  },
  yaxis: {
    labels: {
      formatter: percentFormatter,
    },
  },
  plotOptions: {
    bar: {
      distributed: true,
      columnWidth: "40%",
    },
  },
};

export const lineChartOptions = {};

export const generateHeatmapData = (type) => {
  if (type === "directorate") {
    return exampleDirectorates.map((c) => {
      return {
        name: c.name,
        data: exampleMonths.map((m) => {
          return {
            x: m,
            y: Math.random().toFixed(2),
            companyId: c.value,
          };
        }),
      };
    });
  } else {
    return exampleCompanies.map((c) => {
      return {
        name: c.name,
        data: exampleMonths.map((m) => {
          return {
            x: m,
            y: Math.random().toFixed(2),
            companyId: c.value,
          };
        }),
      };
    });
  }
};

export const generateBarChartData = () => {
  return exampleQualityCategories.map((c) => {
    return Math.random().toFixed(2);
  });
};
