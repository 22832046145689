import React from "react";
import ReactApexChart from "react-apexcharts";
import PageLoader from "../PageLoader";
import { Stack, Typography } from "@mui/material";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

export default function TrendChart(props) {
  const { t } = useTranslation();
  let chartOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: { enabled: false },
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    stroke: { width: [3] },
  };
  let chartData = [
    {
      name: "Training",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
  ];
  return (
    <div
      style={{
        height: props.height || "88%",
        width: "100%",
        direction: "ltr",
      }}
    >
      {!props.chartData ? (
        <PageLoader />
      ) : props.chartData.length < 1 ? (
        <Stack
          spacing={1}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoDataIcon />
          <Typography variant="body1">{t("general.noData")}</Typography>
        </Stack>
      ) : (
        <ReactApexChart
          options={props?.chartOptions || chartOptions}
          series={props?.chartData || chartData}
          type="line"
          height={props?.height || "95%"}
        />
      )}
    </div>
  );
}
