import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";
//  Material UI
import { Typography, Box, Container, Tabs, Tab } from "@mui/material";
//  Components
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import Page from "../../../components/Page";
import KpiConfigurations from "./KpiConfiguration";
import ManageWeightage from "../KpiConfigurationComponents/ManageWeightage";

export const KpiManagement = () => {
  const { state } = useLocation();
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(null);
  const [tabRenders, setTabRenders] = useState([]);
  const [allowedOptions, setAllowedOptions] = useState([]);

  const companyOptions = async () => {
    try {
      setLoading(true);
      const getOptions = state.kpiPermissions;
      if (getOptions?.length > 0) {
        let tempTabs = [];
        if (
          getOptions.includes("KPI_LDRSHP_VIS") ||
          getOptions.includes("KPI_STEER_CMITE") ||
          getOptions.includes("KPI_SAFE_SCORE") ||
          getOptions.includes("KPI_AUDIT") ||
          getOptions.includes("KPI_RETIRED_BEHAVIORS")
        ) {
          setTabRenders([
            { value: "one", label: "kpi.companywide" },
            { value: "two", label: "kpi.locationunitwide" },
            { value: "three", label: "kpi.qualityObservationTab" },
            { value: "four", label: "kpi.knowledge" },
          ]);
          tempTabs.push({ value: "one", label: "kpi.companywide" });
        }
        if (
          getOptions.includes("KPI_NUM_PLANNED_OBS") ||
          getOptions.includes("KPI_INTV_OBS") ||
          getOptions.includes("KPI_ACT_CLOSE_OUTS") ||
          getOptions.includes("KPI_TRAIN_COMPLETION")
        ) {
          tempTabs.push({ value: "two", label: "kpi.locationunitwide" });
        }
        if (getOptions.includes("KPI_QUALITY_OBS")) {
          tempTabs.push({ value: "three", label: "kpi.qualityObservation" });
        }
        if (getOptions.includes("KPI_KNOWLEDGE")) {
          tempTabs.push({ value: "four", label: "kpi.knowledge" });
        }
        //  If the user has any kpi permissions
        tempTabs.push({ value: "five", label: "kpi.weightage" });

        setTabRenders(tempTabs);
        if (tempTabs?.length > 0) {
          setTabValue(tempTabs[0]?.value);
        }
        setAllowedOptions(getOptions);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.kpiPermissions) {
      companyOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.kpiPermissions]);

  return (
    <Page title={t("kpi.configurations")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">{t("kpi.back")}</Typography>
          </div>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
          >
            {tabRenders?.map((entry) => (
              <Tab value={entry?.value} label={t(`${entry?.label}`)} />
            ))}
          </Tabs>
        </Box>

        {tabValue === null && (
          <Typography variant="h5">{t("kpi.noAccess")}</Typography>
        )}

        {tabValue === "one" && (
          <KpiConfigurations type="company" allowed={allowedOptions} />
        )}
        {tabValue === "two" && (
          <KpiConfigurations type="locationunit" allowed={allowedOptions} />
        )}
        {tabValue === "three" && (
          <KpiConfigurations type="quality" allowed={allowedOptions} />
        )}
        {tabValue === "four" && (
          <KpiConfigurations type="knowledge" allowed={allowedOptions} />
        )}
        {tabValue === "five" && <ManageWeightage companyId={companyId} />}
      </Container>
    </Page>
  );
};
