import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  Popper,
} from "@mui/material";
import {
  InfoOutlined,
  ArrowDropDownCircleOutlined,
  Close,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import HelpText from "../HelpText";

const primaryOrange = "rgba(248, 147, 31, 0.6)";
const hoverColor = "rgba(248, 147, 31, 0.16)";

const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  ".MuiOutlinedInput-root": {
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  "& .MuiFormControl-root": {
    borderRadius: "10px",
    background:
      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
    "&.Mui-disabled": {
      boxShadow: "none",
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    border: "1px solid #C2C2C2",
    "&:hover": {
      borderColor: "black",
    },
    "&.Mui-disabled": {
      background: "#F1F1F1",
    },
    "&.Mui-focused": {
      borderColor: theme.palette.primary.buttonOrange,
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.buttonOrange}`,
    },
  },
}));

const PopperStyled = styled(Popper)({
  ".MuiAutocomplete-option.Mui-focused": {
    "&:hover": {
      background: hoverColor,
    },
  },
});

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const AutocompleteInput = ({
  label,
  helpText,
  loading,
  disabled,
  keyPressHandler,
  handler,
  options,
  multiple = false,
  placeholder,
  value,
  disablePortal = true,
  disableClearable = false,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const returnOptionLabel = (option) => {
    if (option) {
      if (currentLanguage.includes("en")) {
        return option.label;
      } else if (option.labelAR) {
        return option.labelAR;
      } else {
        return option.label;
      }
    } else {
      return "";
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        style={{ paddingBottom: "4px" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{label}</Typography>
        {helpText && (
          <HelpText title={helpText}>
            <IconStyle />
          </HelpText>
        )}
      </Stack>
      <AutocompleteStyled
        noOptionsText={t("general.searchTyping")}
        disableClearable={disableClearable}
        disabled={disabled}
        loading={loading}
        loadingText={t("general.loading")}
        multiple={multiple}
        disablePortal={disablePortal}
        value={value}
        options={options}
        fullWidth
        ChipProps={{
          deleteIcon: (
            <Close
              style={{ fontSize: "0.9rem", color: theme.palette.primary.black }}
            />
          ),
          sx: {
            backgroundColor: primaryOrange,
            color: theme.palette.primary.black,
            fontSize: "0.9rem",
            borderRadius: "2px",
            height: "27px",
          },
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder || label} />
        )}
        popupIcon={
          <ArrowDropDownCircleOutlined
            style={{
              fill: disabled ? "#9A9A9A" : theme.palette.primary.orange,
            }}
          />
        }
        onChange={handler}
        onInputChange={keyPressHandler}
        PopperComponent={PopperStyled}
        getOptionLabel={returnOptionLabel}
        isOptionEqualToValue={
          multiple ? undefined : (option, value) => option.value === value.value
        }
      />
    </Box>
  );
};

AutocompleteInput.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  handler: PropTypes.func,
  keyPressHandler: PropTypes.func,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  disablePortal: PropTypes.bool,
  disableClearable: PropTypes.bool,
};

export default AutocompleteInput;
