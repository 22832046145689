import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./counterChart.css";
import PageLoader from "../PageLoader";

CounterChart.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
};

export default function CounterChart(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    let temp = [];
    if (!props?.demo) {
      for (let i = 0; i < props?.data?.labels?.length; i++) {
        temp.push({
          label: props?.data?.labels[i],
          value: props?.data?.values[i],
        });
      }
    } else {
      temp.push({
        label: "Pending",
        value: "100",
      });
      temp.push({
        label: "Closed",
        value: "200",
      });
      temp.push({
        label: "OverDue",
        value: "300",
      });
    }

    setData(temp);
  }, []);

  useEffect(() => {
    let temp = [];
    if (!props?.demo) {
      for (let i = 0; i < props?.data?.labels?.length; i++) {
        temp.push({
          label: props?.data?.labels[i],
          value: props?.data?.values[i],
        });
      }
    } else {
      temp.push({
        label: "Pending",
        value: "100",
      });
      temp.push({
        label: "Closed",
        value: "200",
      });
      temp.push({
        label: "OverDue",
        value: "300",
      });
    }

    setData(temp);
  }, [props.data]);
  return (
    <>
      {!props?.demo ? (
        <>
          {data?.length > 0 ? (
            <div className="">
              <Stack className="counter-chart-title-card">
                <div className="counter-chart-title-text">
                  {props?.title ? props?.title : "Demo Counter chart"}
                </div>
              </Stack>
              <Stack
                style={{ justifyContent: "space-evenly" }}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                {data.map((entry) => (
                  <div className={`${data?.length<3 ? 'counter-chart-rowCard-extra-width' : 'counter-chart-rowCard'}`}>
                    <div>{entry.label}</div>
                    <div className="counter-chart-rightEnd">{entry.value}</div>
                  </div>
                ))}
              </Stack>
            </div>
          ) : (
            <PageLoader />
          )}
        </>
      ) : (
        <div className="secondary-animated-background">
          <Stack className="counter-chart-title-card">
            <div className="counter-chart-title-text">
              {props?.title ? props?.title : "Demo Counter chart"}
            </div>
          </Stack>
          <Stack
            style={{ justifyContent: "space-evenly" }}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            {data.map((entry) => (
              <div className={`${data?.length<3 ? 'counter-chart-rowCard-extra-width' : 'counter-chart-rowCard'}`}>
                <div>{entry.label}</div>
                <div className="counter-chart-rightEnd">{entry.value}</div>
              </div>
            ))}
          </Stack>
        </div>
      )}
    </>
  );
}
