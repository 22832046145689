import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

// material
import { Grid, Typography, Dialog, DialogActions } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// components
import TextInput from "../../../components/inputs/TextInput";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { displayToast } from "../../../utils/general";

// api
import { executeActivityApi } from "../../../api/activities";

const ExecuteActivity = ({
  isOpen,
  onClose,
  titleJustifyContent = "flex-start",
  actionButton,
  buttonJustifyContent = "flex-end",
  size = "sm",
  transparentBg = false,
  row,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);
  const [minutes, setMinutes] = useState(undefined);

  const handleSubmit = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating")
    );
    try {
      const body = {
        minutes,
        activityId: row.id,
      };

      const res = await executeActivityApi(body);

      displayToast(
        setLoading,
        "success",
        t("implementations.detailsUpdated"),
        activeToast
      );
      navigate("/implementations/details", {
        state: { planId: state.planId, companyName: state.companyId },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    } finally {
      setLoading(false);
      onClose();
      setMinutes(null);
      setContent(null);
    }
  };

  const handleOnChange = (value) => {
    setMinutes(value);
  };

  useEffect(() => {
    const createContent = async () => {
      setLoading(true);
      setTitle(`Execute activity`);
      setContent(
        <Grid>
          <Grid>
            <TextInput
              label={t("implementationsList.minutes")}
              placeholder={t("implementationsList.enterMinutesInput")}
              handler={(e) => handleOnChange(e.target.value)}
              value={minutes}
            ></TextInput>
          </Grid>
        </Grid>
      );

      setLoading(false);
    };
    createContent(row);
  }, [row]);

  return (
    <Dialog
      PaperProps={
        transparentBg
          ? {
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }
          : {}
      }
      maxWidth={size}
      fullWidth
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      {title && (
        <DialogTitle
          id="form-dialog-title"
          sx={{ display: "flex", justifyContent: titleJustifyContent }}
        >
          <Grid>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
      <Grid sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <DialogActions>
          <Grid sx={{ display: "flex", gap: 1 }}>
            <PrimaryButton
              variant="outlined"
              color="error"
              label={"Submit"}
              onClick={() => handleSubmit()}
            ></PrimaryButton>
          </Grid>
        </DialogActions>
        {actionButton && (
          <DialogActions>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: buttonJustifyContent,
              }}
            >
              {actionButton}
            </Grid>
          </DialogActions>
        )}{" "}
      </Grid>
    </Dialog>
  );
};
export default ExecuteActivity;
