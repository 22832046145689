/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../context/useAuth";
import { displayToast } from "../../../utils/general";
// defaults
import {
  defaultFiltersPayload,
  defaultObservationAttr,
  filterModalDefaults,
  filterParamDefaults,
} from "./defaults";
// material
import ResetIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import {
  Badge,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// components
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import InfoBox from "../../../components/InfoBox";
import DateInput from "../../../components/inputs/DateInput";
import SelectInput from "../../../components/inputs/SelectInput";
import TableSearch from "../../../components/inputs/TableSearch";
import Page from "../../../components/Page";
import DefaultTable from "../../../components/tables/DefaultTable";
import { IconButtonStyled, IconStyled } from "./styledComponents";
import CellText from "../../../components/CellText";
//  api
import { getAssignedActionsApi } from "../../../api/actions";
import {
  getUserPreferencesApi,
  saveUserPreferencesApi,
} from "../../../api/users";

export default function ListActions() {
  const { t } = useTranslation();
  const { access } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const today = new Date();
  const [tabValue, setTabValue] = useState("myActions");
  const [actionRows, setActionRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionsLoading, setActionsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterModal, setFilterModal] = useState(filterModalDefaults);
  const [filterTypesDropdown, setFilterTypesDropdown] = useState([]);
  const [userPreferences, setUserPreferences] = useState(defaultFiltersPayload);
  const [triggerFlag, setTriggerFlag] = useState(false);

  const actionFilterTypesDefault = [
    // { label: "REJECTED", value: "REJECTED" },
    { label: t("RETURNED", { ns: "backend" }), value: "RETURNED" },
    { label: t("RE-ASSIGNED", { ns: "backend" }), value: "RE-ASSIGNED" },
    // { label: "COMPLETED", value: "COMPLETED" },
    { label: t("PENDING", { ns: "backend" }), value: "PENDING" },
    { label: t("APPROVED", { ns: "backend" }), value: "APPROVED" },
    { label: t("general.all"), value: "ALL" },
  ];

  const observationColumnDefaults = [
    { field: "id", headerName: t("table.id"), width: 200 },
    { field: "status", headerName: t("table.status"), width: 300 },
    { field: "location", headerName: t("table.location"), width: 300 },
    { field: "observer", headerName: t("table.observer"), width: 200 },
    {
      field: "observationDate",
      headerName: t("table.observationDate"),
      width: 200,
    },
  ];

  const actionColumnDefaults = [
    { field: "generatedActionId", headerName: t("table.id"), width: 200 },
    {
      field: "status",
      headerName: t("table.status"),
      width: 100,
      renderCell: (param) => (
        <CellText title={t(param.row.status, { ns: "backend" })} />
      ),
    },
    {
      field: "actionRequired",
      headerName: t("table.actionRequired"),
      width: 300,
    },
    { field: "observer", headerName: t("table.observer"), width: 200 },
    {
      field: "targetDate",
      headerName: t("table.targetDateAction"),
      width: 400,
    },
  ];

  const observationColumnDictionary = {
    id: { field: "id", headerName: t("table.id"), width: 200 },
    observer: {
      field: "observer",
      headerName: t("table.observer"),
      width: 200,
    },
    discipline: {
      field: "discipline",
      headerName: t("table.discipline"),
      width: 300,
    },
    directorate: {
      field: "directorate",
      headerName: t("table.directorate"),
      width: 300,
    },
    location: {
      field: "location",
      headerName: t("table.location"),
      width: 300,
    },
    unit: { field: "unit", headerName: t("table.unit"), width: 300 },
    behavior: {
      field: "behavior",
      headerName: t("table.behavior"),
      width: 200,
    },
    actions: { field: "actions", headerName: t("table.actions"), width: 200 },
    attachments: {
      field: "attachments",
      headerName: t("table.attachments"),
      width: 200,
    },
    observationType: {
      field: "observationType",
      headerName: t("table.observationType"),
      width: 300,
    },
    observationSubType: {
      field: "observationSubType",
      headerName: t("table.observationSubType"),
      width: 300,
    },
    observerType: {
      field: "observerType",
      headerName: t("reporting.observerType"),
      width: 200,
    },
    observedAt: {
      field: "observationDate",
      headerName: t("table.observationDate"),
      width: 200,
    },
    isAnonymous: {
      field: "isAnonymous",
      headerName: t("table.anonymous"),
      width: 200,
    },
    isEscalated: {
      field: "isEscalated",
      headerName: t("table.escalated"),
      width: 200,
    },
    feedback: {
      field: "feedback",
      headerName: t("observations.observationDetails.feedback"),
      width: 400,
    },
    rootCause: {
      field: "rootCause",
      headerName: t("reporting.rootCause"),
      width: 200,
    },
    actionToPrevent: {
      field: "actionToPrevent",
      headerName: t("observations.observationApp.actionCondition"),
      width: 200,
    },
    status: { field: "status", headerName: t("table.status"), width: 300 },
    suggestions: {
      field: "suggestions",
      headerName: t("observations.observationApp.suggestions"),
      width: 200,
    },
    appreciation: {
      field: "appreciation",
      headerName: t("observations.observationDetails.appreciation"),
      width: 200,
    },
    lessonLearnt: {
      field: "lessonLearnt",
      headerName: t("table.lessonLearnt"),
      width: 200,
    },
    markedAsCompleteAt: {
      field: "markedAsCompleteAt",
      headerName: t("table.markCompletedDate"),
      width: 200,
    },
    safeObservation: {
      field: "safeObservation",
      headerName: t("observations.observationDetails.safeObservation"),
      width: 200,
    },
    nearMissMarkedAt: {
      field: "nearMissMarkedAt",
      headerName: t("table.markNearMissDate"),
      width: 200,
    },
  };

  const returnUserColumns = (userPrefAttributes) => {
    const attributes = userPrefAttributes.split("+");
    const finalColumnValues = attributes.map((a) => {
      return {
        label: observationColumnDictionary[a].headerName,
        value: a,
      };
    });

    return finalColumnValues;
  };

  const submitFilter = () => {
    const key = filterModal.tab;
    let finalColumnString = "";
    filterModal[key].columnValues.forEach((c, index) => {
      if (index === 0) {
        finalColumnString += c.value;
      } else {
        finalColumnString += `+${c.value}`;
      }
    });

    setFilterParams({
      ...filterParams,
      pageNo: 1,
      [key]: {
        observationType: filterModal[key].observationType,
        type: filterModal[key].type,
        startTargetDate: filterModal[key].filterDates
          ? new Date(filterModal[key].startTargetDate)
          : null,
        endTargetDate: filterModal[key].filterDates
          ? new Date(filterModal[key].endTargetDate)
          : null,
        attr: finalColumnString,
        locationId: filterModal[key].locationId,
        directorateId: filterModal[key].directorateId,
        unitId: filterModal[key].unitId,
        companyId: filterModal[key].companyId,
      },
    });
    setFilterModal({ ...filterModal, open: false });
    setTriggerFlag(true);
  };

  const submitSearch = async () => {
    setActionsLoading(true);
    try {
      if (tabValue === "myActions") {
        setFilterParams({ ...filterParams, pageNo: 1, searchTerm: search });
        const actionsData = await getAssignedActionsApi({
          ...filterParams,
          pageNo: 1,
          searchTerm: search,
        });
        const parsedData = actionsData.data.map((a) => {
          return {
            id: a.id,
            observationId: a.observation.id,
            status: a.status,
            actionRequired: a.actionRequired,
            generatedActionId: a.generatedActionId,
            observer: a?.observation?.observedBy,
            targetDate: moment(a.targetDate).format("DD/MM/YY"),
          };
        });
        setActionRows(parsedData);
        setTableInfo({
          totalCount: actionsData.totalItems,
          totalPages: actionsData.totalPages,
        });
        setActionsLoading(false);
      } else if (
        tabValue === "allObservations" ||
        tabValue === "myObservations"
      ) {
        setFilterParams({ ...filterParams, pageNo: 1, searchTerm: search });
        setTriggerFlag(true);
      }
    } catch (err) {
      setActionsLoading(false);
    }
  };

  const submitSaveFilters = async (action) => {
    setLoading(true);
    const target = tabValue;
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("general.savingPreferences")
    );
    try {
      let payload;

      if (action === "reset") {
        setFilterParams(filterParamDefaults);
        const key = filterModal.tab;
        filterModal[key].type = filterModalDefaults[key].type;
        payload = {
          ...defaultFiltersPayload,
        };
      } else if (action === "save") {
        let finalColumnString = "";
        filterModal[target].columnValues.forEach((c, index) => {
          if (index === 0) {
            finalColumnString += c.value;
          } else {
            finalColumnString += `+${c.value}`;
          }
        });

        payload = {
          ...userPreferences,
          [`${target}FilterType`]: filterModal[target].type,
          [`${target}FilterObsType`]: filterModal[target].observationType,
          [`${target}FilterDateFlag`]: filterModal[target].filterDates,
          [`${target}FilterDateStart`]: filterModal[target].startTargetDate,
          [`${target}FilterDateEnd`]: filterModal[target].endTargetDate,
          [`${target}Attr`]: finalColumnString,
          [`${target}FilterLocation`]: filterModal[target].locationId,
          [`${target}FilterDirectorate`]: filterModal[target].directorateId,
          [`${target}FilterUnit`]: filterModal[target].unitId,
          [`${target}FilterCompany`]: filterModal[target].companyId,
        };
      }

      await saveUserPreferencesApi(payload);
      submitFilter();
      displayToast(
        setLoading,
        "successLoading",
        t("general.savedPreferences"),
        activeToast
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const renderObservationFilters = () => {
    const key = filterModal.tab;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SelectInput
            value={filterModal[key].type}
            handler={(e) =>
              setFilterModal({
                ...filterModal,
                [key]: {
                  ...filterModal[key],
                  type: e.target.value,
                },
              })
            }
            options={filterTypesDropdown}
            label={t(`observations.viewObservations.filters.status`)}
            disabled={loading}
          />
        </Grid>
        {/* Since columns are not supported by action or task backend, hide */}
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterModal[key].filterDates}
                  name={"filterDates"}
                  onChange={() =>
                    setFilterModal({
                      ...filterModal,
                      [key]: {
                        ...filterModal[key],
                        filterDates: !filterModal[key].filterDates,
                      },
                    })
                  }
                  sx={{
                    color: theme.palette.primary.orange,
                    "&.Mui-checked": {
                      color: theme.palette.primary.orange,
                    },
                    "&:hover": {
                      backgroundColor: "rgba(248,147,31,0.08)",
                    },
                  }}
                />
              }
              disabled={loading}
              label={t(`observations.viewObservations.filters.dateRange`)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label={t(`observations.viewObservations.filters.startDate`)}
              date={filterModal[key].startTargetDate}
              disabled={loading || !filterModal[key].filterDates}
              handler={(newDate) => {
                setFilterModal({
                  ...filterModal,
                  [key]: {
                    ...filterModal[key],
                    startTargetDate: newDate,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label={t(`observations.viewObservations.filters.endDate`)}
              date={filterModal[key].endTargetDate}
              disabled={loading || !filterModal[key].filterDates}
              handler={(newDate) => {
                setFilterModal({
                  ...filterModal,
                  [key]: {
                    ...filterModal[key],
                    endTargetDate: newDate,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <SecondaryButton
              label={t(`observations.viewObservations.filters.save`)}
              alignment="left"
              onClick={() => submitSaveFilters("save")}
              disabled={loading}
              icon={<SaveIcon />}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SecondaryButton
              label={t(`observations.viewObservations.filters.reset`)}
              alignment="left"
              onClick={() => submitSaveFilters("reset")}
              disabled={loading}
              icon={<ResetIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getUserPrefs = async () => {
    try {
      setLoading(true);
      const userPrefs = await getUserPreferencesApi();
      setUserPreferences(userPrefs);
      const key = tabValue;
      const prefsUpdated = {
        myObservations: {
          type: userPrefs.myObservationsFilterType || "ALL",
          filterDates: userPrefs.myObservationsFilterDateFlag || false,
          startTargetDate: userPrefs.myObservationsFilterDateStart || today,
          endTargetDate: userPrefs.myObservationsFilterDateEnd || today,
          observationType: userPrefs.myObservationsFilterObsType || "UNSAFE",
          attr: userPrefs.myObservationsAttr || defaultObservationAttr,
          columnValues: userPrefs.myObservationsAttr
            ? returnUserColumns(userPrefs.myObservationsAttr)
            : observationColumnDefaults,
          locationId: userPrefs.myObservationsFilterLocation || "",
          directorateId: userPrefs.myObservationsFilterDirectorate || "",
          companyId: userPrefs.myObservationsFilterCompany || "",
          unitId: userPrefs.myObservationsFilterUnit || "",
        },
        allObservations: {
          type: userPrefs.allObservationsFilterType || "ALL",
          filterDates: userPrefs.allObservationsFilterDateFlag || false,
          startTargetDate: userPrefs.allObservationsFilterDateStart || today,
          endTargetDate: userPrefs.allObservationsFilterDateEnd || today,
          observationType: userPrefs.allObservationsFilterObsType || "UNSAFE",
          attr: userPrefs.allObservationsAttr || defaultObservationAttr,
          columnValues: userPrefs.allObservationsAttr
            ? returnUserColumns(userPrefs.allObservationsAttr)
            : observationColumnDefaults,
          locationId: userPrefs.myObservationsFilterLocation || "",
          directorateId: userPrefs.myObservationsFilterDirectorate || "",
          companyId: userPrefs.myObservationsFilterCompany || "",
          unitId: userPrefs.myObservationsFilterUnit || "",
        },
        myActions: {
          type: userPrefs.myActionsFilterType || "ALL",
          filterDates: userPrefs.myActionsFilterDateFlag || false,
          startTargetDate: userPrefs.myActionsFilterDateStart || today,
          endTargetDate: userPrefs.myActionsFilterDateEnd || today,
          observationType: userPrefs.myActionsFilterObsType || "UNSAFE",
          attr: userPrefs.myActionsAttr || defaultObservationAttr,
          columnValues: userPrefs.myActionsAttr
            ? returnUserColumns(userPrefs.myActionsAttr)
            : observationColumnDefaults,
          locationId: userPrefs.myObservationsFilterLocation || "",
          directorateId: userPrefs.myObservationsFilterDirectorate || "",
          companyId: userPrefs.myObservationsFilterCompany || "",
          unitId: userPrefs.myObservationsFilterUnit || "",
        },
      };

      setFilterParams({
        ...filterParams,
        [key]: {
          observationType: prefsUpdated[key].observationType || "UNSAFE",
          type: prefsUpdated[key].type || "ALL",
          startTargetDate:
            prefsUpdated[key].filterDates || false
              ? prefsUpdated[key].startTargetDate
              : null,
          endTargetDate:
            prefsUpdated[key].filterDates || false
              ? prefsUpdated[key].endTargetDate
              : null,
          attr: prefsUpdated[key].attr,
          locationId: prefsUpdated[key].locationId,
          companyId: prefsUpdated[key].companyId,
          directorateId: prefsUpdated[key].directorateId,
          unitId: prefsUpdated[key].unitId,
        },
      });

      setFilterModal({ ...filterModal, ...prefsUpdated });
      setLoading(false);
      setTriggerFlag(true);
    } catch (err) {
      console.log(err);
      const key = tabValue;
      const defaultPrefs = {
        myObservations: {
          type: "ALL",
          filterDates: false,
          startTargetDate: today,
          endTargetDate: today,
          observationType: "UNSAFE",
          attr: defaultObservationAttr,
          columnValues: observationColumnDefaults,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
        allObservations: {
          type: "ALL",
          filterDates: false,
          startTargetDate: today,
          endTargetDate: today,
          observationType: "UNSAFE",
          attr: defaultObservationAttr,
          columnValues: observationColumnDefaults,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
        myActions: {
          type: "ALL",
          filterDates: false,
          startTargetDate: today,
          endTargetDate: today,
          observationType: "UNSAFE",
          attr: defaultObservationAttr,
          columnValues: observationColumnDefaults,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
      };

      setFilterParams({
        ...filterParams,
        [key]: {
          observationType: "UNSAFE",
          type: "ALL",
          startTargetDate: null,
          endTargetDate: null,
          attr: defaultPrefs[key].attr,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
      });
      setFilterModal({ ...filterModal, ...defaultPrefs });
      setTriggerFlag(true);
      setLoading(false);
    }
  };

  const getObservations = async () => {
    setLoading(true);
    setActionsLoading(true);
    try {
      //  If role allows for all observations tab 0 becomes all observations

      const targetParams = {
        ...filterParams[tabValue],
        pageSize: filterParams.pageSize,
        pageNo: filterParams.pageNo,
        searchTerm: filterParams.searchTerm,
        sort: filterParams.sort,
      };

      if (tabValue === "myActions") {
        const payload = {
          ...targetParams,
          ...(targetParams.startTargetDate
            ? {
                startTargetDate: moment(targetParams.startTargetDate)
                  .startOf("day")
                  .toISOString(),
              }
            : {}),
          ...(targetParams.endTargetDate
            ? {
                endTargetDate: moment(targetParams.endTargetDate)
                  .endOf("day")
                  .toISOString(),
              }
            : {}),
        };

        const actionsData = await getAssignedActionsApi(payload);
        const parsedData = actionsData.data.map((a) => {
          return {
            id: a.id,
            observationId: a.observation.id,
            status: a.status,
            actionRequired: a.actionRequired,
            generatedActionId: a.generatedActionId,
            observer: a?.observation?.observedBy,
            targetDate: moment(a.targetDate).format("DD/MM/YY"),
          };
        });
        setActionRows(parsedData);
        setTableInfo({
          totalCount: actionsData.totalItems,
          totalPages: actionsData.totalPages,
        });
      }
      setLoading(false);
      setActionsLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", err);
      setActionsLoading(false);
    }
  };

  const handleSortModelChange = useCallback((newModel) => {
    if (newModel.length > 0) {
      setFilterParams({
        ...filterParams,
        sort: newModel[0],
      });
    } else {
      setFilterParams({ ...filterParams, sort: null });
    }
    setTriggerFlag(true);
  }, []);

  useEffect(() => {
    getUserPrefs();
  }, [tabValue]);

  useEffect(() => {
    setTriggerFlag(false);
    if (triggerFlag || !userPreferences.myActionsFilterObsType) {
      getObservations();
    }
  }, [filterParams, tabValue, access, t, triggerFlag]);

  return (
    <Page title={t("sideBar.viewObservations")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">
              {t("observations.actionsTitle")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={12} style={{ paddingTop: "20px" }}>
            <TabsBar value={tabValue} handler={handleTabChange} tabs={tabs} />
          </Grid> */}
          {tabValue === "myActions" && (
            <Grid item xs={12} md={12} style={{ paddingTop: "20px" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TableSearch
                  searchTerm={search}
                  handler={(e) => setSearch(e.target.value)}
                  disabled={loading}
                  submitSearch={submitSearch}
                  clearable={filterParams.searchTerm}
                  clearHandler={() => {
                    setSearch("");
                    setFilterParams({
                      ...filterParams,
                      pageNo: 1,
                      searchTerm: "",
                    });
                    setTriggerFlag(true);
                  }}
                />
                <Tooltip title={t("general.advancedFilters")}>
                  <IconButtonStyled
                    aria-label={"Open filters"}
                    onClick={() => {
                      setFilterTypesDropdown(actionFilterTypesDefault);
                      setFilterModal({
                        ...filterModal,
                        open: true,
                        tab: tabValue,
                      });
                    }}
                  >
                    <Badge color="primary" variant="dot">
                      <IconStyled />
                    </Badge>
                  </IconButtonStyled>
                </Tooltip>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} lg={12}>
            {tabValue === "myActions" && (
              <DefaultTable
                rows={actionRows}
                columns={actionColumnDefaults}
                loading={actionsLoading}
                tableInfo={tableInfo}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                triggerFunction={setTriggerFlag}
                handleSortModelChange={handleSortModelChange}
                topTextContent={
                  <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                    {filterParams.searchTerm && (
                      <Typography
                        variant="body2"
                        style={{
                          color: theme.palette.primary.grey,
                          fontWeight: "bold",
                        }}
                      >{`${t("table.searchResults")} "${
                        filterParams.searchTerm
                      }" `}</Typography>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.orange,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.totalListed")} ${
                      tableInfo.totalCount
                    } `}</Typography>
                  </Stack>
                }
                onRowClick={(r) =>
                  navigate(
                    `/actions/details/${r.row.observationId}/${r.row.id}`
                  )
                }
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <InfoBox
        compact
        open={filterModal.open}
        title={t("observations.viewObservations.filters.title")}
        content={renderObservationFilters()}
        buttonLabel={t(
          "observations.viewObservations.filters.filterModalApply"
        )}
        handleClose={() => setFilterModal({ ...filterModal, open: false })}
        handleAction={submitFilter}
        minWidth="1000px"
      />
    </Page>
  );
}
