import { Grid, Container, Typography, Stack } from "@mui/material";
import PieChart from "../../components/charts/PieChart";
import BarChart from "../../components/charts/BarChart";
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import { mockResponse } from "./mockData";

export default function ReportView() {
  const renderHeader = () => (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Logo />
      </Grid>
      <Grid item xs={9}>
        <Typography variant={mockResponse.header.style.fontSize}>
          {mockResponse.header.text}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderChart = ({ type, chartOptions, chartData }) => {
    let chart;

    switch (type) {
      case "noOfObservationsPie":
        chart = <PieChart />;
        break;

      case "noOfObservationsBar":
        chart = <BarChart chartOptions={chartOptions} chartData={chartData} />;
        break;

      default:
        chart = "Unknown chart type";
        break;
    }

    return chart;
  };

  const renderChartCard = (chartDetails) => (
    <div
      style={{
        border: "1px solid #F7D5AE",
        background:
          "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
        borderRadius: 0,
        padding: "18px",
      }}
    >
      <Stack>
        <Typography variant="h7" align="center">
          {chartDetails.title}
        </Typography>
      </Stack>
      {renderChart(chartDetails)}
    </div>
  );

  const renderTextCard = (textDetails) => (
    <Stack>
      <Typography variant={textDetails.variant} align={textDetails.align}>
        {console.log(textDetails.text)}
        {textDetails.text}
      </Typography>
    </Stack>
  );

  const renderPage = (p) => (
    <Grid
      container
      spacing={2}
      style={{ pageBreakAfter: "always" }}
      key={p.page}
    >
      {p.layout.map((item) => (
        <Grid item xs={item.w}>
          {item.type === "chart" && renderChartCard(item.chartDetails)}
          {item.type === "text" && renderTextCard(item.textDetails)}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Page title="View report">
      <Container maxWidth="xl">
        {renderHeader()}
        {mockResponse.pages.map((p) => renderPage(p))}
      </Container>
    </Page>
  );
}
