import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/buttons/PrimaryButton";
// material
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Container, Typography, Stack, Link } from "@mui/material";
import KnowledgeTitleIcon from "@mui/icons-material/Title";
import KnowledgeTypeIcon from "@mui/icons-material/AssignmentLateOutlined";
import KnowledgeDescriptionIcon from "@mui/icons-material/Description";
import KnowledgeLinkIcon from "@mui/icons-material/Link";
import ViewCount from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import ShareIcon from "@mui/icons-material/Share";
// components
import Page from "../../components/Page";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import { displayToast } from "../../utils/general";
import InfoBox from "../../components/InfoBox";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import AccessControl from "../../components/AccessControl";
import { deleteKnowledgeBase } from "../../api/knowledge";
//  api
import {
  getKnowledgeDetailsApi,
  updateKnowledgeBaseApi,
  ShareFileApi,
  updateKnowledgeViewCountApi,
} from "../../api/knowledge";
import { searchUsersApi } from "../../api/users";

const knowledgeDetailDefaults = {
  id: "",
  name: "",
  description: "",
  link: "",
  fileType: "",
  viewCount: "",
};

export default function KnowledgeDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const timeout = useRef();
  const navigate = useNavigate();
  const theme = useTheme();
  const [pageLoading, setPageLoading] = useState(true);
  const [details, setDetails] = useState(knowledgeDetailDefaults);
  const [loading, setLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [userDropdown, setUserDropdown] = useState([]);
  const [deleteDetails, setDeleteDetails] = useState({
    open: false
  })

  const knowledgeTypes = [
    {
      label: t("knowledge.dropdown.video"),
      value: "video",
    },
    {
      label: t("knowledge.dropdown.audio"),
      value: "audio",
    },
    {
      label: t("knowledge.dropdown.document"),
      value: "document",
    },
    {
      label: t("knowledge.dropdown.webLink"),
      value: "webLink",
    },
  ];

  useEffect(() => {
    const handleGetDetails = async () => {
      setPageLoading(true);
      try {
        const detailsData = await getKnowledgeDetailsApi(id);
        setDetails(detailsData);
      } catch (err) {
        setPageLoading(false);
      }
    };

    handleGetDetails();
  }, [id]);

  const submitEditDocument = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("knowledge.editDetails.editDocumentLoading")
    );
    try {
      const payload = {
        name: details.name,
        link: details.link,
        fileType: details.fileType,
        description: details.description,
      };
      await updateKnowledgeBaseApi(payload, id);
      setDetails({ ...details, open: false });
      displayToast(
        setLoading,
        "success",
        t("knowledge.editDetails.editDocumentSuccess")
      );
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const shareFileWithUsers = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("knowledge.editDetails.shareLoading")
    );
    try {
      const userIds = emailList.map((e) => e.id);

      const payload = {
        userIds,
        knowledgeId: id,
      };
      await ShareFileApi(payload);
      setEmailList([]);
      displayToast(
        setLoading,
        "success",
        t("knowledge.editDetails.shareSuccess"),
        activeToast
      );
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const renderEditModal = () => (
    <InfoBox
      disabled={loading || !details.name || !details.fileType || !details.link}
      open={details.open}
      title={t("knowledge.editDetails.editDocument")}
      content={
        <Stack spacing={2}>
          <TextInput
            disabled={loading}
            label={t("knowledge.editDetails.editDocumentName")}
            value={details.name}
            handler={(e) => setDetails({ ...details, name: e.target.value })}
          />
          <TextInput
            disabled={loading}
            label={t("knowledge.editDetails.editDocumentDescription")}
            value={details.description}
            handler={(e) =>
              setDetails({ ...details, description: e.target.value })
            }
          />
          <TextInput
            disabled={loading}
            label={t("knowledge.editDetails.editDocumentLink")}
            value={details.link}
            handler={(e) => setDetails({ ...details, link: e.target.value })}
          />
          <SelectInput
            label={t("knowledge.editDetails.editFileType")}
            options={knowledgeTypes}
            value={details.fileType}
            disabled={loading}
            handler={(e) =>
              setDetails({ ...details, fileType: e.target.value })
            }
          />
        </Stack>
      }
      handleClose={() => setDetails({ ...details, open: false })}
      buttonLabel={t("knowledge.editDetails.saveDocument")}
      handleAction={submitEditDocument}
    />
  );

  const handleViewCountAndNavigate = async (kb) => {
    const viewCount = kb.viewCount + 1;
    await updateKnowledgeViewCountApi({ viewCount: viewCount }, id);
    setDetails({ ...details, viewCount: viewCount });
    navigate("/exitPage", {
      state: {
        exitUrl: details.link,
      },
    });
  };

  const renderDeleteModal = () => (
    <InfoBox
      disabled={loading}
      open={deleteDetails.open}
      title={`${t(`remove`)} ${
        details.name
      }`}
      content={
        <Stack>
          <Typography variant="h6" style={{ color: theme.palette.primary.red }}>
            {t(`knowledge.deleteConfirmation`)}
          </Typography>
        </Stack>
      }
      buttonLabel={t(`general.delete`)}
      handleClose={() => setDeleteDetails({ope: false})}
      handleAction={handleDelete}
    />
  );
  const handleDelete = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("knowledge.deleteLoading")
    );
    try{
      await deleteKnowledgeBase(id);
      displayToast(
        setLoading,
        "success",
        t("knowledge.deleteSuccess"),
        activeToast
      );
      navigate("/library/list")
    }catch(error){
      displayToast(setLoading, "error", error, activeToast);
      console.log(error);
    }
  }

  return (
    <Page title="Library details">
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {t(`knowledge.editDetails.editTitle`)}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center">
              <KnowledgeTitleIcon style={{ color: "#7D7D7D" }} />
              <Stack direction="column" sx={{ ml: 3 }}>
                <Typography
                  variant="h6"
                  style={{ color: "#7D7D7D", textShadow: "none" }}
                >
                  {t(`knowledge.attributes.name`)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: 1 }}
                  style={{ color: "#4C4C4E", textShadow: "none" }}
                >
                  {details.name}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center">
              <KnowledgeDescriptionIcon style={{ color: "#7D7D7D" }} />
              <Stack direction="column" sx={{ ml: 3 }}>
                <Typography
                  variant="h6"
                  style={{ color: "#7D7D7D", textShadow: "none" }}
                >
                  {t(`knowledge.attributes.description`)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: 1 }}
                  style={{ color: "#4C4C4E", textShadow: "none" }}
                >
                  {details.description}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center">
              <KnowledgeTypeIcon style={{ color: "#7D7D7D" }} />
              <Stack direction="column" sx={{ ml: 3 }}>
                <Typography
                  variant="h6"
                  style={{ color: "#7D7D7D", textShadow: "none" }}
                >
                  {t(`knowledge.attributes.fileType`)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: 1 }}
                  style={{
                    color: "#4C4C4E",
                    textShadow: "none",
                    textTransform: "capitalize",
                  }}
                >
                  {details.fileType}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center">
              <ViewCount style={{ color: "#7D7D7D" }} />
              <Stack direction="column" sx={{ ml: 3 }}>
                <Typography
                  variant="h6"
                  style={{ color: "#7D7D7D", textShadow: "none" }}
                >
                  {t(`knowledge.attributes.viewCount`)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: 1 }}
                  style={{ color: "#4C4C4E", textShadow: "none" }}
                >
                  {details.viewCount}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              <KnowledgeLinkIcon style={{ color: "#7D7D7D" }} />
              <Stack direction="column" sx={{ ml: 3 }}>
                <Typography
                  variant="h6"
                  style={{ color: "#7D7D7D", textShadow: "none" }}
                >
                  {t(`knowledge.attributes.link`)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: 1 }}
                  style={{
                    color: theme.palette.primary.hyperlinkBlue,
                    textShadow: "none",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => handleViewCountAndNavigate(details)}
                >
                  {details.link}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "60px" }}>
          <AutocompleteInput
            multiple
            value={emailList}
            handler={(e, v) => setEmailList(v)}
            keyPressHandler={handleSearchUser}
            label={t("knowledge.editDetails.shareFile")}
            placeholder={t("knowledge.editDetails.shareFilePlaceholder")}
            loading={searchLoading}
            disabled={loading}
            options={userDropdown}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <SecondaryButton
            icon={<ShareIcon />}
            onClick={() => shareFileWithUsers()}
            label={t("knowledge.editDetails.share")}
            disabled={loading || emailList.length < 1}
            alignment="left"
          />
        </Grid>
        <AccessControl accessName="KNW_BASE_CU">
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <PrimaryButton
              disabled={loading}
              alignment="left"
              label={t(`knowledge.editKnowledge`)}
              icon={<EditIcon />}
              onClick={() => setDetails({ ...details, open: true })}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <SecondaryButton
              disabled={loading}
              alignment="left"
              label={t(`general.delete`)}
              icon={<DeleteIcon />}
              onClick={() => setDeleteDetails({ open: true })}
            />
          </Grid>
        </AccessControl>
      </Container>
      {renderEditModal()}
      {renderDeleteModal()}
    </Page>
  );
}
