import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import PageLoader from "../PageLoader";

DenseTable.propTypes = {
  labels: PropTypes.string,
  dataSet: PropTypes.object,
  maxHeight: PropTypes.bool,
};

export default function DenseTable(props) {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props?.labels && props?.dataSet && props?.demo === false) {
      let dataSet = [...props?.dataSet];
      let entriesArray = [];
      let tempLabels = [...props?.labels];
      setLabels(tempLabels);
      if (dataSet?.length > 0) {
        for (let i = 0; i < dataSet.length; i++) {
          let entryArray = [];
          for (let j = 0; j < tempLabels?.length; j++) {
            for (let entryKey in dataSet[i]) {
              if (tempLabels[j] === entryKey)
                entryArray.push(dataSet[i][entryKey]);
            }
          }
          entriesArray.push(entryArray);
        }
      }
      setData(entriesArray);
    } else {
      setLabels(["Name (BU)", "# Employees", "# Observations"]);
      setData([
        ["Test Business Unit", "123", "12311"],
        ["Test Business Unit1", "123", "12311"],
        ["Test Business Unit2", "123", "12311"],
        ["Test Business Unit3", "123", "12311"],
        ["Test Business Unit4", "123", "12311"],
        ["Test Business Unit5", "123", "12311"],
        ["Test Business Unit6", "123", "12311"],
        ["Test Business Unit7", "123", "12311"],
        ["Test Business Unit8", "123", "12311"],
        ["Test Business Unit9", "123", "12311"],
        ["Test Business Unit1", "123", "12311"],
        ["Test Business Unit2", "123", "12311"],
        ["Test Business Unit3", "123", "12311"],
        ["Test Business Unit4", "123", "12311"],
        ["Test Business Unit5", "123", "12311"],
        ["Test Business Unit6", "123", "12311"],
        ["Test Business Unit7", "123", "12311"],
        ["Test Business Unit8", "123", "12311"],
        ["Test Business Unit9", "123", "12311"],
        ["Test Business Unit0", "123", "12311"],
        ["Test Business Unit1", "123", "12311"],
        ["Test Business Unit2", "123", "12311"],
        ["Test Business Unit3", "123", "12311"],
        ["Test Business Unit4", "123", "12311"],
        ["Test Business Unit5", "123", "12311"],
        ["Test Business Unit6", "123", "12311"],
        ["Test Business Unit7", "123", "12311"],
        ["Test Business Unit8", "123", "12311"],
        ["Test Business Unit9", "123", "12311"],
        ["Test Business Unit0", "123", "12311"],
        ["Test Business Unit1", "123", "12311"],
        ["Test Business Unit2", "123", "12311"],
        ["Test Business Unit3", "123", "12311"],
        ["Test Business Unit4", "123", "12311"],
        ["Test Business Unit5", "123", "12311"],
        ["Test Business Unit6", "123", "12311"],
        ["Test Business Unit7", "123", "12311"],
        ["Test Business Unit8", "123", "12311"],
        ["Test Business Unit9", "123", "12311"],
        ["Test Business Unit0", "123", "12311"],
      ]);
    }
  }, []);

  useEffect(() => {
    if (props?.labels && props?.dataSet && props?.demo === false) {
      let dataSet = [...props?.dataSet];
      let entriesArray = [];
      let tempLabels = [...props?.labels];
      setLabels(tempLabels);
      if (dataSet?.length > 0) {
        for (let i = 0; i < dataSet.length; i++) {
          let entryArray = [];
          for (let j = 0; j < tempLabels?.length; j++) {
            for (let entryKey in dataSet[i]) {
              if (tempLabels[j] === entryKey)
                entryArray.push(dataSet[i][entryKey]);
            }
          }
          entriesArray.push(entryArray);
        }
      }
      setData(entriesArray);
    }
  }, [props.labels, props.dataSet]);
  return (
    <>
      {props?.demo === false ? (
        <>
          {props?.labels?.length > 0 && props?.dataSet ? (
            <Paper sx={{ ...(props.maxHeight ? { height: "90%" } : {}) }}>
              <TableContainer
                sx={{ height: props.maxHeight ? "100%" : "220px" }}
              >
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      {labels?.length > 0 &&
                        labels.map((entry, index) => {
                          if (index === 0) {
                            return <TableCell>{entry}</TableCell>;
                          } else {
                            return <TableCell align="right">{entry}</TableCell>;
                          }
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      <>
                        <TableRow
                          key={index + 999}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {row.map((entry, entryIndex) => {
                            if (entryIndex === 0) {
                              return (
                                <TableCell component="th" scope="row">
                                  {entry}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell align="right">{entry}</TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <PageLoader />
          )}
        </>
      ) : (
        <Paper>
          <TableContainer sx={{ height: "220px" }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {labels?.length > 0 &&
                    labels.map((entry, index) => {
                      if (index === 0) {
                        return <TableCell>{entry}</TableCell>;
                      } else {
                        return <TableCell align="right">{entry}</TableCell>;
                      }
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <>
                    <TableRow
                      key={index + 999}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {row.map((entry, entryIndex) => {
                        if (entryIndex === 0) {
                          return (
                            <TableCell component="th" scope="row">
                              {entry}
                            </TableCell>
                          );
                        } else {
                          return <TableCell align="right">{entry}</TableCell>;
                        }
                      })}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
}
