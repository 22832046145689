/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { lineChartOptions } from "./constants";
import { safeUnsafeOptions } from "../directorateDashboard/constants";
import { subDays, addDays } from "date-fns";
// material
import { Grid, Stack, Card, Typography, Tooltip } from "@mui/material";
// components
import SelectInput from "../../../components/inputs/SelectInput";
import DateRangeInput from "../../../components/inputs/DateRangeInput";
import VariableLineChart from "../../../components/charts/VariableLineChart";
import {
  IconButtonStyled,
  IconStyled,
} from "../../observation/ListObservations/styledComponents";
import PageLoader from "../../../components/PageLoader";
import HelpIcon from "../../../components/HelpIcon";
// API
import { getQualityOverviewApi } from "../../../api/qualityDashboard";
import { getDisciplineFilters } from "../utils";
// ----------------------------------------------------------------------

const cardStyling = {
  height: 300,
  padding: "16px ",
  borderRadius: "6px",
};

const loaderStyling = {
  height: "90%",
  display: "flex",
  alignItems: "center",
};

const yearToDate = new Date(new Date().getFullYear(), 0, 1);

export default function Overview({
  companyId,
  locationOptions = [],
  unitOptions = [],
  state,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [locationFilter, setLocationFilter] = useState("all");
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [unitFilter, setUnitFilter] = useState("all");
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [obsType, setObsType] = useState("Unsafe");
  const [categories, setCategories] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    start: state.alertStartDate
      ? subDays(new Date(state.alertStartDate), 31)
      : yearToDate,
    end: state.alertStartDate
      ? new Date(state.alertStartDate)
      : moment().diff(yearToDate, "days") < 31
      ? addDays(yearToDate, 31)
      : new Date(),
  });

  const [finalFilters, setFinalFilters] = useState({
    directorateId: state.directorateId,
    companyId,
    disciplineId: null,
    locationId: null,
    startDate: state.alertStartDate
      ? subDays(new Date(state.alertStartDate), 31)
      : yearToDate,
    endDate: state.alertStartDate
      ? new Date(state.alertStartDate)
      : moment().diff(yearToDate, "days") < 31
      ? addDays(yearToDate, 31)
      : new Date(),
    obsType: "Unsafe",
  });

  const renderFilters = () => (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.locationTitle")}
          disabled={loading || locationDropdown.length < 1}
          value={locationFilter}
          handler={(e) => setLocationFilter(e.target.value)}
          options={locationDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.unitTitle")}
          disabled={loading || unitDropdown.length < 1}
          value={unitFilter}
          handler={(e) => setUnitFilter(e.target.value)}
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.disciplineTitle")}
          disabled={loading || disciplineDropdown.length < 1}
          value={disciplineFilter}
          handler={(e) => setDisciplineFilter(e.target.value)}
          options={disciplineDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.observations")}
          disabled={loading}
          value={obsType}
          handler={(e) => setObsType(e.target.value)}
          options={safeUnsafeOptions}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <DateRangeInput
          disabled={loading}
          label={t("observations.viewObservations.filters.dateRangeButton")}
          startDate={dateFilter.start}
          endDate={dateFilter.end}
          handler={(start, end) => setDateFilter({ start, end })}
        />
      </Grid>
      <Grid item xs={1} sm={1}>
        <Tooltip title={t("general.applyFilters")}>
          <IconButtonStyled
            aria-label={t("general.applyFilters")}
            onClick={submitFilters}
          >
            <IconStyled />
          </IconButtonStyled>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderBreakdownChart = () => {
    if (categories && lineData && lineData.length > 0) {
      return lineData.map((chart) => (
        <Card style={cardStyling}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {`${chart.name} ${t(
                "dashboards.implementationDashboards.variableQualityScore"
              )}`}
            </Typography>
            <HelpIcon
              text={t(
                `dashboards.tooltips.contractor.overview.${chart.name.replace(
                  /\s/g,
                  ""
                )}`
              )}
            />
          </Stack>
          <VariableLineChart
            height="90%"
            chartOptions={{
              ...lineChartOptions,
              series: [{ name: chart.name, data: chart.data }],
              xaxis: { ...lineChartOptions.xaxis, categories },
              yaxis: {
                ...lineChartOptions.yaxis,
                min: 0,
                max: 1,
              },
            }}
          />
        </Card>
      ));
    } else {
      return (
        <div style={{ ...loaderStyling, height: "50vh" }}>
          <PageLoader />
        </div>
      );
    }
  };

  const getQualityOverview = async () => {
    try {
      setLoading(false);
      const finalData = await getQualityOverviewApi({
        ...finalFilters,
        startDate: moment(finalFilters.startDate).startOf("day").toISOString(),
        endDate: moment(finalFilters.endDate).endOf("day").toISOString(),
      });
      setCategories(finalData.categories);
      setLineData(finalData.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitFilters = () => {
    setFinalFilters({
      directorateId: state.directorateId,
      companyId,
      disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
      locationId: locationFilter === "all" ? null : locationFilter,
      startDate: dateFilter.start,
      endDate: dateFilter.end,
      obsType: obsType === "all" ? null : obsType,
    });
  };

  useEffect(() => {
    setLocationDropdown(locationOptions);
    setUnitDropdown(unitOptions);
  }, [locationOptions, unitOptions]);

  useEffect(() => {
    getQualityOverview();
  }, [finalFilters]);

  useEffect(() => {
    if (companyId && locationFilter && locationFilter !== "all") {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter: companyId,
        locationFilter,
        unitFilter,
        setDisciplineDropdown,
      });
    }
  }, [companyId, locationFilter]);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {renderBreakdownChart()}
    </Stack>
  );
}
