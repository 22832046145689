import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { displayToast } from "../utils/general";
// MUI
import { Box, Grid, Container, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// Components
import Page from "../components/Page";
import HeaderBackButton from "../components/buttons/HeaderBackButton";
import LikertInput from "../components/inputs/LikertInput";
import TextInput from "../components/inputs/TextInput";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SelectInput from "../components/inputs/SelectInput";
import InfoBox from "../components/InfoBox";
import DoneIcon from "@mui/icons-material/Done";
// API
import {
  getLatestReviewApi,
  saveResponseApi,
} from "../api/sustainabilityReviews";

const defaultlanguageTypes = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "हिन्दी(Hindi)",
    value: "hi",
  },
  {
    label: "عربي(Arabic)",
    value: "ar",
  },
];

export default function SustainabilityReview() {
  const navigate = useNavigate();
  const { planId, companyId } = useParams();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [responseDictionary, setResponseDictionary] = useState({});
  const [languageString, setLanguageString] = useState("en");
  const currentLanguage = i18n.language;
  const theme = useTheme();
  const [successBox, setSuccessBox] = useState({
    open: false,
  });

  const renderHeaderQuestion = ({ itemText }) => (
    <Typography variant="h6">{itemText}</Typography>
  );

  const renderTextQuestion = ({
    itemText,
    itemTextAR,
    itemTextHI,
    questionNumber,
    id,
  }) => (
    <Stack spacing={1}>
      <Typography variant="h7">{`${questionNumber}. ${
        languageString === "en"
          ? itemText
          : languageString === "ar"
          ? itemTextAR
          : itemTextHI
      }`}</Typography>
      <TextInput
        value={responseDictionary[id]}
        disabled={loading}
        handler={(e) =>
          setResponseDictionary({ ...responseDictionary, [id]: e.target.value })
        }
      />
    </Stack>
  );

  const renderLikertQuestion = ({
    itemText,
    itemTextAR,
    itemTextHI,
    questionNumber,
    id,
  }) => (
    <Stack spacing={2}>
      <Typography variant="h7">{`${questionNumber}. ${
        languageString === "en"
          ? itemText
          : languageString === "ar"
          ? itemTextAR
          : itemTextHI
      }`}</Typography>
      <LikertInput
        disabled={loading}
        responses={responseDictionary}
        languageString={languageString}
        handler={(v) =>
          setResponseDictionary({ ...responseDictionary, [id]: v })
        }
        activeValue={responseDictionary[id] || 3}
      />
    </Stack>
  );

  const renderReview = () => (
    <Grid container spacing={3}>
      {questionList && questionList.length > 0
        ? questionList.map((q, index) => (
            <Grid
              item
              key={index}
              xs={12}
              style={{
                background: index % 2 ? "#FFF3E4" : "none",
                padding: "28px 10px 32px 32px",
              }}
            >
              {q.itemType === "HEADER"
                ? renderHeaderQuestion(q)
                : q.itemType === "QUESTION" && q.questionType === "TEXTFIELD"
                ? renderTextQuestion(q)
                : renderLikertQuestion(q)}
            </Grid>
          ))
        : t("reviews.loadingQs")}
    </Grid>
  );

  const getLatestReview = async () => {
    try {
      setLoading(true);
      const reviewData = await getLatestReviewApi();
      const qList = reviewData
        ? reviewData.items.map((q) => {
            return {
              ...q,
              questionNumber: q.sequence,
            };
          })
        : [];
      setQuestionList(qList);
      setReview(reviewData);
      setResponseDictionary({});
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submitAnswers = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("reviews.submitLoading")
    );
    try {
      const answers = questionList.map((q) => {
        return {
          questionnaireItemId: q.id,
          ...(q.questionType === "TEXTFIELD"
            ? { answer: responseDictionary[q.id] }
            : q.questionType === "LIKERT" && responseDictionary[q.id]
            ? { likertScore: responseDictionary[q.id] }
            : { likertScore: 3 }),
        };
      });

      const payload = {
        implementationId: planId,
        questionnaireId: review.id,
        items: answers,
      };

      await saveResponseApi(payload);
      setQuestionList([]);
      setResponseDictionary({});
      getLatestReview();
      setSuccessBox({ open: true });
      setLoading(false);
      displayToast(
        setLoading,
        "success",
        t("reviews.submitSuccess"),
        activeToast
      );
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  useEffect(() => {
    getLatestReview();
  }, [planId]);

  useEffect(() => {
    currentLanguage === "ar"
      ? setLanguageString("ar")
      : setLanguageString("en");
  }, [currentLanguage]);

  return (
    <Page
      title={t(
        "implementations.sustainabilityReviews.sustainabilityReviewsTitle"
      )}
    >
      <Container maxWidth="xl">
        <Box sx={{ pb: 5, mt: 5 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {t(
                "implementations.sustainabilityReviews.sustainabilityReviewsTitle"
              )}
            </Typography>
          </div>
        </Box>
        <Grid
          container
          spacing={3}
          style={{ direction: languageString === "ar" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12}>
            <SelectInput
              // label={t("reviews.changeLanguage")}
              options={defaultlanguageTypes}
              value={languageString}
              disabled={loading}
              handler={(e) => {
                setLanguageString(e.target.value);
                // changeLanguage(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {renderReview()}
          </Grid>
          <Grid item xs={12}>
            <PrimaryButton
              alignment="left"
              label={t("general.submit")}
              onClick={submitAnswers}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Container>
      <InfoBox
        open={successBox.open}
        noClose
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DoneIcon
              style={{
                height: "100px",
                width: "100px",
                color: theme.palette.primary.green,
                marginBottom: "30px",
              }}
            />
            <Typography variant="h5">{successBox.observationId}</Typography>
            <Typography variant="h5">{t(`reviews.submitSuccess`)}</Typography>
          </div>
        }
        buttonLabel={t("general.close")}
        handleAction={() => setSuccessBox({ open: false })}
      />
    </Page>
  );
}
