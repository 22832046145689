import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import MoreIcon from "@mui/icons-material/MoreHorizOutlined";
import InfoIcon from "@mui/icons-material/Info";
import Search from "@mui/icons-material/Search";

export const IconButtonStyled = styled(IconButton)(({ theme, disabled }) => ({
  height: "45px",
  width: "45px",
  borderRadius: "10px",
  background: disabled
    ? theme.palette.primary.orange
    : theme.palette.primary.grey,
  boxShadow: disabled ? "none" : "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverGrey,
  },
}));

export const IconStyled = styled(FilterIcon)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const MoreIconStyled = styled(MoreIcon)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const InfoIconStyled = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const SearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
