export const checkAccess = (role, feature) => {
  try {
    const accessList = {
      BBSCONVIEW: {},
      SUPERVISOR: {
        allObservations: true,
        normalActions: true,
        isSupervisor: true,
        observationSubType: true,
      },
      HSE_MANAGER: {
        allObservations: true,
        escalatedActions: true,
        observationSubType: true,
      },
    };

    if (accessList[role][feature]) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const hasAccess = (accessList, feature) => {
  switch (feature) {
    case "allObservations":
      if (
        accessList.some((a) =>
          [
            "SUPERVISOR",
            "HSE_MANAGER",
            "VERIFIER",
            "PDO_VERIFIER",
            "PDO_HSE_MANAGER",
            "VIEW_OBS",
          ].includes(a)
        )
      ) {
        return true;
      } else {
        return false;
      }

    case "normalActions":
      if (accessList.includes("SUPERVISOR")) {
        return true;
      } else {
        return false;
      }

    case "isSupervisor":
      if (accessList.includes("SUPERVISOR")) {
        return true;
      } else {
        return false;
      }

    case "escalatedActions":
      if (accessList.includes("HSE_MANAGER")) {
        return true;
      } else {
        return false;
      }

    case "observationSubType":
      if (
        accessList.some((a) =>
          ["SUPERVISOR", "HSE_MANAGER", "VERIFIER"].includes(a)
        )
      ) {
        return true;
      } else {
        return false;
      }

    case "reports":
      if (accessList.includes("REPORTS")) {
        return true;
      } else {
        return false;
      }

    default:
      return false;
  }
};

export const accessList = {
  myTasks: {
    accessName: "NONE",
  },
  dashboards: {
    accessName: "NONE",
  },
  aiDashboards: {
    accessName: "VIEW_DASH_AI",
  },
  kpis: {
    accessName: "VIEW_DASH_KPI",
  },
  observationDashboard: {
    accessName: "NONE",
  },
  library: {
    accessName: "NONE",
  },
  observations: {
    accessName: "NONE",
  },
  viewObservations: {
    accessName: "NONE",
  },
  submitObservation: {
    accessName: "NONE",
  },
  bulkUploadObservation: {
    accessName: "BULK_UP_OBS",
  },
  observationCards: {
    accessName: "PRNT_OBS_CRD",
  },
  actions: {
    accessName: "NONE",
  },
  implementations: {
    accessNames: {
      type: "OR",
      features: [
        "VIEW_IMPL_PLN",
        "CON_DIR_IMPL_PLN",
        "CRUD_IMPL_PLN",
        "KPI_NUM_PLANNED_OBS",
        "KPI_INTV_OBS",
        "KPI_ACT_CLOSE_OUTS",
        "KPI_TRAIN_COMPLETION",
        "KPI_CB_TO_INCIDENT",
        "KPI_RETIRED_BEHAVIORS",
        "KPI_SAFE_SCORE",
        "KPI_LDRSHP_VIS",
        "KPI_STEER_CMITE",
        "KPI_KNOWLEDGE",
        "KPI_QUALITY_OBS",
        "KPI_AUDIT",
      ],
    },
  },
  viewImplementations: {
    accessNames: {
      type: "OR",
      features: [
        "VIEW_IMPL_PLN",
        "CON_DIR_IMPL_PLN",
        "CRUD_IMPL_PLN",
        "KPI_NUM_PLANNED_OBS",
        "KPI_INTV_OBS",
        "KPI_ACT_CLOSE_OUTS",
        "KPI_TRAIN_COMPLETION",
        "KPI_CB_TO_INCIDENT",
        "KPI_RETIRED_BEHAVIORS",
        "KPI_SAFE_SCORE",
        "KPI_LDRSHP_VIS",
        "KPI_STEER_CMITE",
        "KPI_KNOWLEDGE",
        "KPI_QUALITY_OBS",
        "KPI_AUDIT",
      ],
    },
  },
  createImplementation: {
    accessName: "CRUD_IMPL_PLN",
  },
  reporting: {
    accessName: "NONE",
  },
  createReport: {
    accessName: "REPORTS",
  },
  progressReports: {
    accessName: "PROG_REPORT",
  },
  scheduleReport: {
    accessName: "NONE",
  },
  training: {
    accessName: "NONE",
  },
};
