import React, { useState, useEffect } from "react";
import { Grid, DialogTitle, DialogContent } from "@mui/material";
import DateInput from "../../../../components/inputs/DateInput";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../../components/inputs/SelectInput";
import TextInput from "../../../../components/inputs/TextInput";
import { useParams } from "react-router-dom";
import moment from "moment";
//Api
import {
  getTrainingTypes,
  scheduleTraining,
} from "../../../../api/implementation";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { displayToast } from "../../../../utils/general";

export default function ScheduleTraining(props) {
  const { planId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [trainingTypeId, setTrainingTypeId] = useState(null);
  const [description, setDescription] = useState("");
  const [totalSeats, setTotalSeats] = useState(0);
  const [waitingSeats, setWaitingSeats] = useState(0);
  const [trainingTypes, setTrainingTypes] = useState([]);

  const setTrainingTypesData = async () => {
    let tempTypes = await getTrainingTypes();
    if (tempTypes?.length > 0) {
      let tempData = tempTypes.map((type) => {
        return {
          label: type.name,
          value: type.id,
        };
      });
      setTrainingTypes(tempData);
    }
  };

  const handleSchedule = async () => {
    try {
      setLoading(true);
      let dataObj = {
        TrainingTypesId: trainingTypeId,
        planId: planId,
        description: description,
        numTotal: totalSeats,
        numAwaiting: waitingSeats >= 0 ? waitingSeats : 0,
        startDate: moment(startDate).startOf("day").toISOString(),
        endDate: moment(endDate).endOf("day").toISOString(),
      };
      if (trainingTypeId == null) {
        throw t("training.errors.selectTrainingType");
      }
      if (!moment(dataObj.endDate).isAfter(moment(dataObj.startDate))) {
        throw t("training.errors.startDateAfter");
      }
      if (description.length === 0) {
        throw t("training.errors.descriptionEmpty");
      }
      if (totalSeats <= 0) {
        throw t("training.errors.totalNumEmpty");
      }
      let schedule = await scheduleTraining(dataObj);
      if (schedule) {
        props.onSuccess();
        setLoading(false);
      }
    } catch (e) {
      displayToast(setLoading, "error", e, "errorScheduleTraining");
    }
  };

  useEffect(() => {
    setTrainingTypesData();
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <DateInput
            disabled={loading}
            label={t("implementations.startDate")}
            date={startDate}
            handler={(newDate) => {
              setStartDate(newDate);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            disabled={loading}
            label={t("implementations.endDate")}
            date={endDate}
            handler={(newDate) => {
              setEndDate(newDate);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput
            label={t("trainingSchedule.types")}
            value={trainingTypeId}
            disabled={loading}
            handler={(e) => setTrainingTypeId(e.target.value)}
            options={trainingTypes}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInput
            label={t(`trainingSchedule.totalSeats`)}
            value={totalSeats}
            type={"number"}
            disabled={loading}
            handler={(e) => setTotalSeats(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInput
            label={t(`trainingSchedule.waitingSeats`)}
            value={waitingSeats}
            type={"number"}
            disabled={false}
            handler={(e) => setWaitingSeats(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextInput
            label={t(`trainingSchedule.description`)}
            value={description}
            disabled={loading}
            handler={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PrimaryButton
            label={t(`trainingSchedule.scheduleTraining`)}
            alignment="left"
            onClick={() => {
              handleSchedule();
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </>
  );
}
