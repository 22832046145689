import React, { useMemo, useState } from "react";
import { Card, Grid, Typography, Stack } from "@mui/material";
import TrendChart from "../../../components/charts/TrendChart";
import { getLocationKpis } from "../../../api/kpiDashboards";
import { displayToast } from "../../../utils/general";
import SelectInput from "../../../components/inputs/SelectInput";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import FilterIcon from "@mui/icons-material/SortOutlined";
import DateInput from "../../../components/inputs/DateInput";
import moment from "moment";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { CloudDownload } from "@mui/icons-material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";
import { returnColourArray } from "../../../utils/general";

const cardStyling = {
  height: 600,
  padding: "16px ",
  borderRadius: "6px",
};

function KPICharts(props) {
  const { companyId, directorateId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [filterDropDown, setFilterDropDown] = useState(
    props?.locationOptions ?? []
  );
  const [selectedFilter, setSelectedFilter] = useState(
    props?.selectedLocation ?? null
  );
  const [startDate, setStartDate] = useState(moment().startOf("year"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [filterStartDate, setFilterStartDate] = useState(
    moment().startOf("year")
  );
  const [filterEndDate, setFilterEndDate] = useState(moment().endOf("month"));
  const [months, setMonths] = useState([]);
  const [showAudit, setShowAudit] = useState("false");
  const pagesRef = useRef([]);

  const auditDropDown = [
    {
      label: t("general.yes"),
      value: "true",
    },
    {
      label: t("general.no"),
      value: "false",
    },
  ];

  const renderFilters = () => (
    <Grid container spacing={1} sx={{ mt: 1 }} alignItems="flex-end">
      <Grid item xs={12} sm={4} md={2}>
        <SelectInput
          label={t("kpiDashboards.locationFilter")}
          disabled={false}
          value={selectedFilter}
          handler={(e) => setSelectedFilter(e.target.value)}
          options={filterDropDown}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <DateInput
          disabled={false}
          label={t("kpiDashboards.startDate")}
          date={startDate}
          handler={(newDate) => setStartDate(newDate)}
          monthView={true}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <DateInput
          disabled={false}
          label={t("kpiDashboards.endDate")}
          date={endDate}
          handler={(newDate) => setEndDate(newDate)}
          monthView={true}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <SelectInput
          label={t(`kpi.includeAudit`)}
          disabled={false}
          value={showAudit}
          handler={(e) => setShowAudit(e.target.value)}
          options={auditDropDown}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <SecondaryButton
          icon={<FilterIcon />}
          label={t("observations.viewObservations.filters.apply")}
          alignment="left"
          disabled={false}
          onClick={() => applyFilters()}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <SecondaryButton
          icon={<CloudDownload />}
          label={t("general.downloadPdf")}
          disabled={loading}
          onClick={() => submitDownload()}
        />
      </Grid>
    </Grid>
  );

  const RenderTrendChart = () => {
    return useMemo(() => {
      return (
        <TrendChart
          chartData={chartData}
          chartOptions={{
            chart: {
              height: 350,
              type: "line",
              zoom: { enabled: false },
            },
            colors: returnColourArray(),
            xaxis: {
              categories: months,
            },
            yaxis: {
              min: 0,
              max: 100,
              labels: {
                formatter: (val) => `${val}%`,
              },
              title: {
                text: "KPI in %",
              },
            },
            tooltip: {
              y: {
                formatter: (val) => `${val || 0}%`,
              },
            },
            stroke: { width: 3 },
          }}
          height="90%"
        />
      );
    }, [chartData]);
  };

  const getLocationsKpis = async () => {
    try {
      let query = `?companyId=${companyId}&directorateId=${directorateId}&`;
      if (startDate) {
        query += `startDate=${startDate.format("MM-YYYY")}`;
      }
      if (endDate) {
        query += `&endDate=${endDate.format("MM-YYYY")}`;
      }
      if (selectedFilter) {
        let location = true;
        let splitLocation = selectedFilter?.split("--")[1];
        if (selectedFilter?.split("--unit")?.length > 1) location = false;
        query += `&locationId=${splitLocation}&isLocation=${location}`;
      }
      if (showAudit === "true") {
        query += `&audit=true`;
      }
      const reqKpis = await getLocationKpis(query);
      if (reqKpis) {
        setChartData(reqKpis?.data);
        setMonths(reqKpis?.categories);
        if (reqKpis?.locations?.length > 0) {
          setFilterDropDown(reqKpis.locations);
          setSelectedFilter(reqKpis?.locations[0]?.value);
        }
      }
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
    } catch (e) {
      if (
        e === `Named parameter ":locationId" has no value in the given object.`
      ) {
        displayToast(
          setLoading,
          "error",
          "There is no KPI Data and Location/Units available for this Directorate -> Contractor",
          "loadingCharts"
        );
      } else {
        displayToast(setLoading, "error", e, "loadingCharts");
      }
      setFilterStartDate(moment().startOf("year"));
      setFilterEndDate(moment().endOf("month"));
    }
  };

  const submitDownload = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("reporting.downloading")
    );
    try {
      const pdf = new jsPDF("portrait", "pt", "a4");
      let data = await html2canvas(pagesRef.current[0], {
        scale: 1.3,
      });
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      let pdfWidth = pdf.internal.pageSize.getWidth();
      let pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");

      pdf.save("ObservationDashboard.pdf");
      displayToast(
        setLoading,
        "success",
        t("reporting.downloadSuccess"),
        activeToast
      );
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("reporting.downloadError"),
        activeToast
      );
    }
  };

  const applyFilters = () => {
    getLocationsKpis();
  };

  useState(() => {
    getLocationsKpis();
  }, []);

  return (
    <>
      {renderFilters()}
      <Card
        ref={(el) => {
          pagesRef.current[0] = el;
        }}
        style={cardStyling}
      >
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="h6">
            {t("kpiDashboards.performanceChart", {
              companyName: props?.companyName ?? "Contractor",
            })}
          </Typography>
          <Typography variant="subtitle1">{`${moment(filterStartDate).format(
            "MMM/YY"
          )} - ${moment(filterEndDate).format("MMM/YY")}`}</Typography>
        </Stack>
        {RenderTrendChart()}
      </Card>
    </>
  );
}

export default KPICharts;
