import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import moment from "moment";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useParams } from "react-router-dom";
import {
  getTraineeUser,
  updateTraineeInviteTask,
} from "../../api/implementation";
import { displayToast } from "../../utils/general";

function TrainingInvite() {
  const { t } = useTranslation();
  const { trainingPlanId, userId, taskId } = useParams();
  const theme = useTheme();
  const [data, setData] = useState({});
  const [loading,setLoading] = useState(false)

  const getUserData = async () => {
    let query = `trainingPlanId=${trainingPlanId}&userId=${userId}`;
    const user = await getTraineeUser(query);
    if (user) {
      setData(user);
      console.log(user)
    }
  };

  const handleInviteRespond = async (value) => {
    try {
      setLoading(true);
      let tempBody = {
        taskId,
        userId,
        userStatus: value,
        TrainingPlansId: trainingPlanId,
      };
      await updateTraineeInviteTask(tempBody);
      displayToast(setLoading, "success", value === "Rejected" ? t("training.rejectSuccess") : t("training.acceptSuccess"), "errorLoadingTrainingDetails");
      getUserData();
    } catch (e) {
      displayToast(setLoading, "pureError", e);
      setLoading(false)
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">{t("training.invitation")}</Typography>
          </div>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack justifyContent="space-between" direction="row">
            <Typography variant="h6">{`${t("observations.observationDetails.status")} ${data?.userStatus === "pending" ? t("training.status.pending") : data?.userStatus === "Accepted" ? t("training.status.accepted") : t("training.status.rejected")}`}</Typography>
            <Typography
              variant="h6"
              style={{
                color: "#7D7D7D",
                textShadow: "none",
              }}
            >
              {`${t(`training.invitedBy`)} 
                  ${data?.Invited?.firstName} ${data?.Invited?.lastName}
                  at ${moment(data?.createdAt).format("DD/MM/YY  hh:mm a")}`}
            </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              spacing={4}
              style={{
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                border: "1px solid #C2C2C2",
                padding: "18px",
                borderRadius: "4px",
              }}
            >
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingType`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.TrainingPlan?.TrainingType?.name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingDescription`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.TrainingPlan?.description}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingPeriod`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {`${moment(data?.TrainingPlan?.startDate).format(
                      "DD/MM/YYYY"
                    )} to ${moment(data?.TrainingPlan?.endDate).format(
                      "DD/MM/YYYY"
                    )}`}
                  </Typography>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          {data?.userStatus === "pending" && (
            <>
              <Grid item xs={12} md={6}>
                <SecondaryButton
                  isRed
                  icon={<NotInterestedIcon />}
                  label={t(`training.trainingReject`)}
                  alignment="left"
                  disabled={loading}
                  onClick={() => handleInviteRespond("Rejected")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PrimaryButton
                  isgreen
                  disabled={loading}
                  alignment="left"
                  label={t(`training.trainingAccept`)}
                  icon={<CheckCircleOutlineIcon />}
                  onClick={() => handleInviteRespond("Accepted")}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default TrainingInvite;
