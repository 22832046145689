import { startCase } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//  Constants
import { taskTypes } from "../../utils/constants";
//  MUI
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
//  Components
import Page from "../../components/Page";
import DefaultTable from "../../components/tables/DefaultTable";
import CellText from "../../components/CellText";
//  API
import { getMyTasksApi } from "../../api/tasks";

export default function ListTasks() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [taskRows, setTaskRows] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterParams, setFilterParams] = useState({
    pageNo: 1,
    pageSize: 10,
    type: "PENDING",
  });

  const tasksColumnDefaults = [
    { field: "id", headerName: t("table.id"), width: 100, sortable: false },
    {
      field: "title",
      headerName: t("table.title"),
      width: 150,
      sortable: false,
      renderCell: (param) => (
        <CellText title={t(param.row.dbType, { ns: "backend" })} />
      ),
    },
    {
      field: "observationType",
      headerName: t("table.observationType"),
      width: 150,
      sortable: false,
      renderCell: (param) => {
        return !param.row?.observation ? (
          <CellText
            style={{ fontWeight: "bold" }}
            title={t(taskTypes[param.row.dbType], { ns: "backend" })}
          />
        ) : param.row?.observation?.safeObservation ? (
          <CellText
            style={{ fontWeight: "bold", color: theme.palette.primary.green }}
            title={t(
              `Safe ${param.row?.observation?.observationType?.toLowerCase()}`,
              { ns: "backend" }
            )}
          />
        ) : (
          <CellText
            style={{ fontWeight: "bold", color: theme.palette.primary.red }}
            title={t(
              `Unsafe ${param.row?.observation?.observationType?.toLowerCase()}`,
              { ns: "backend" }
            )}
          />
        );
      },
    },
    {
      field: "type",
      headerName: t("table.type"),
      width: 200,
      sortable: false,
      renderCell: (param) => (
        <CellText title={t(param.row.type, { ns: "backend" })} />
      ),
    },
    {
      field: "description",
      headerName: t("table.description"),
      width: 400,
      sortable: false,
      renderCell: (param) => (
        <CellText
          title={t(`DESC_${param.row.dbType}`, {
            ns: "backend",
            user: param.row.assignedBy,
            inviter: param.row.inviter,
          })}
        />
      ),
    },
    {
      field: "taskDate",
      headerName: t("table.taskDate"),
      width: 150,
      sortable: false,
    },
    {
      field: "targetDate",
      headerName: t("table.targetDate"),
      width: 100,
      sortable: false,
    },
    {
      field: "assignedBy",
      headerName: t("table.assignedBy"),
      width: 200,
      sortable: false,
    },
  ];

  const getTaskList = async () => {
    try {
      setLoading(true);

      const tasksData = await getMyTasksApi(filterParams);
      const parsedData = tasksData.data.map((task) => {
        const inviter =
          task.taskType === "TRAINING_INVITE"
            ? task.description.trim().split(" ").pop()
            : "";

        return {
          id: task.id.substring(1, 8).toUpperCase(),
          dbTaskId: task.id,
          dbId: task.observationId,
          dbUserId: task.userId,
          aId: task.action?.id,
          trainingId: task.TrainingPlansId,
          type: startCase(task.taskType),
          dbType: task.taskType,
          title: taskTypes[task.taskType],
          description: task.description,
          implementationId: task.implementationId,
          trainingUserId: task.trainingUserId,
          taskDate: moment(task.createdAt).format("DD/MM/YY hh:mm a"),
          targetDate: moment(task.action?.targetDate).format("DD/MM/YY"),
          assignedBy: `${task?.action?.AssignedBy?.firstName ?? ""} ${
            task?.action?.AssignedBy?.lastName ?? ""
          }`,
          observationType: task,
          observation: task.observation,
          inviter,
        };
      });
      setTaskRows(parsedData);

      setTableInfo({
        totalCount: tasksData.totalItems,
        totalPages: tasksData.totalPages,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaskList();
  }, [filterParams]);

  return (
    <Page title={t("myTasks.title")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{t("myTasks.title")}</Typography>
          </div>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <DefaultTable
              rows={taskRows}
              columns={tasksColumnDefaults}
              loading={loading}
              tableInfo={tableInfo}
              filterParams={filterParams}
              setFilterParams={(e) => {
                setFilterParams(e);
              }}
              minHeight="65vh"
              onRowClick={(r) => {
                if (
                  r.row.dbType === "SUPERVISOR_ATTENTION_NEEDED" ||
                  r.row.dbType === "REQUIRES_EDIT"
                ) {
                  navigate(`/observations/details/${r.row.dbId}`);
                } else if (
                  r.row.implementationId &&
                  r.row.dbType === "SUSTAINABILITY_REVIEW_ASSIGNED"
                ) {
                  navigate(`/review/view/${r.row.implementationId}`);
                } else if (r.row.dbType === "TRAINING_INVITE") {
                  navigate(
                    `/training/invite/${r.row.dbTaskId}/${r.row.trainingId}/${r.row.dbUserId}`
                  );
                } else if (r.row.dbType === "TRAINING_OPTED_FOR") {
                  navigate(
                    `/training/optFor/${r.row.dbTaskId}/${r.row.trainingId}/${r.row.trainingUserId}`
                  );
                } else {
                  navigate(`/actions/details/${r.row.dbId}/${r.row.aId}`);
                }
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
